import React, { useState } from "react";
import {
  Card,
  Grid,
  Header,
  List,
  Text,
  Button,
  Notification,
} from "platyplex_ui";
import { gradient, primaryColor } from "../../../config";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Bar from "../../checkout/components/bar";
import { InputNumber, Radio } from "antd";
import { selectBank } from "../../../store/actions/bank";
import api from "../../../api";
import { reloadUserDetails } from "../../../store/actions/user";
import PayoutMethod, { PayoutMethod_Paypal, PayoutMethod_StripeBankAccount } from "../payout-method";

const Earnings = () => {
  const dispatch = useDispatch();
  const { payoutMethods, loading: loadingBank, selectedPayoutMethod } = useSelector(
    (state: any) => state.banks
  );

  const { details } = useSelector((state: any) => state.user);

  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const disabled = !amount || loading || loadingBank || payoutMethods?.length === 0;

  const payout = () => {
    setLoading(true);
    api
      .payout(amount || 0, selectedPayoutMethod)
      .then((res: any) => {
        if (res.success) {
          Notification.success({
            message: "Payout Successful",
            title: "Success",
          });
          setSuccess(true);
          dispatch(reloadUserDetails())
        } else throw new Error("Payout Failed");
      })
      .catch((e) =>
        Notification.error({ message: "Payout Failed", title: "Error" })
      )
      .finally(() => setLoading(false));
  };

  return (
    success?
    <div className="grid" style={{ height: "100%" }}>
    <Card shadow style={{ maxWidth: 800, minWidth: "40vw" }} grid>
      <i
        className="fas fa-check-circle txt-lgx"
        style={{ color: primaryColor }}
      />
      <Header>Your payout was sucessful!</Header>
      <Text>
        Please allow 5-7 business days for earnings to appear in your bank account.
      </Text>
        <Link style={{fontSize: '1.2em'}} to='/wallet'><Button rounded>Done</Button></Link>
    </Card>
  </div>:
    <>
      <Grid placeSelf="stretch">
        <Grid customCols="1fr auto" placeSelf="stretch">
          <Link to="/wallet" style={{ placeSelf: "start" }}>
            <Text
              textAlign="left"
              color={primaryColor}
              size={"1.25em"}
              className="pointer"
            >
              <i className="fas fa-arrow-circle-left" /> &nbsp; Back
            </Text>
          </Link>
        </Grid>
        <Grid style={{ width: 400, maxWidth: "90vw" }} placeItems="stretch">
          <Text textAlign="left">
            <Header bold color={primaryColor} textAlign="left" size={2}>
              {(details?.parkstash_wallet_v1_pretty?.payoutable)}
            </Header>
            Available for Payout
          </Text>
          <Bar style={{ borderColor: "white" }} />

          <Text textAlign="left">
            <Header bold color={"coral"} textAlign="left" size={2}>
              {(
                details?.parkstash_wallet_v1_pretty?.pending_payoutable
              )}
            </Header>
            Pending Earnings
          </Text>
          <Bar style={{ borderColor: "white" }} />

          <InputNumber
            placeholder="Enter a payout amount"
            style={{ width: "100%" }}
            min={0}
            step={0.25}
            max={(details?.parkstash_wallet_v1?.payoutable || 0) / 100}
            onChange={(e) => typeof e === "number" && setAmount(e * 100)}
            value={amount ? amount / 100 : undefined}
          />
          <Bar style={{ borderColor: "white" }} />

          {payoutMethods?.length > 0 && <Text>Confirm payout account</Text>}
          <List
             loading={loadingBank}
            empty={
              <>
              <Text color="red">
                No payout method present. Add a payout method.
              </Text>

              <Text textAlign='right' color='blue'>
                <Link to='/wallet/payout-methods'>Add Payout</Link>
              </Text>
              </>
            }
            list={payoutMethods}
            map={(payoutMethod: PayoutMethod) => (
              <Card
                key={payoutMethod._id}
                shadow
                mobileLayout={false}
                margin="0"
                style={{ width: 400, maxWidth: "90vw" }}
                grid
                onClick={() => dispatch(selectBank((payoutMethod?._id) as any))}
                gridGap="2em"
                customCols="auto 1fr auto"
                className="pointer noselect highlight"
              >
               
               {payoutMethod.stripeBankAccount? <BankItem bank={payoutMethod.stripeBankAccount}/>: 
              <PaypalItem paypal={payoutMethod.paypal as any}/>}
                <Radio checked={selectedPayoutMethod === payoutMethod?._id} />


              </Card>
            )}
          />

          <Button
            rounded
            disabled={disabled}
            loading={loading || loadingBank}
            onClick={payout}
            style={{
              padding: ".5em",
              marginTop: "1em",
              placeSelf: "stretch",
              border: 0,
            }}
            noHover
            bg={gradient}
            type="submit"
          >
            {" "}
            Request Payout
          </Button>
        </Grid>
      </Grid>
    </>
  );
};



const PaypalItem = (props: { paypal: PayoutMethod_Paypal})=>{
  return (<> <i className="fab fa-paypal txt-md"></i>
  <div style={{ placeSelf: "stretch" }}>
    <Text textAlign="left">{props.paypal.name} </Text>
    <Text textAlign="left">
      Account with email <span>{props.paypal.email}</span>
    </Text>
  </div>
</>)
}




const BankItem = (_props: { bank: PayoutMethod_StripeBankAccount })=>{
  return (<> <i className="fa fa-university txt-lg" aria-hidden="true" />
  <div style={{ placeSelf: "stretch" }}>
    <Text textAlign="left">{_props.bank.bankName} </Text>
    <Text textAlign="left">
      {`Account # ending in ${_props.bank.last4}`}{" "}
    </Text>
  </div>
 </>)
}


export default Earnings;
