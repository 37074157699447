import React, { useEffect, useState } from "react";
import { Grid, Header, Notification, Switch, Button, Text } from "platyplex_ui";
import Images from "../../../../components/images";
import {  disabledTxtColor,
  disabledTxtColor2,
  gradient,
  primaryColor,
  primaryTxtColor,
} from "../../../../config";
import api from "../../../../api";
import { NearbyParking, Parking } from "../../../../model/domain/parking";
import Information from "./information";
import Amenities from "./amenties";
import Reviews from "./reviews";
import useReviews from "../../../../hooks/reviews";
import { toQuery } from "../../../../utils";
import { SearchNearbyQuery } from "../../../../hooks/nearby-parking";
import CloseBtn from "../../../../components/close-btn";
import { getParkingDate } from "../../../../utils/date";
import { Select } from 'antd';
enum Menus {
  INFORMATION = "INFORMATION",
  AMENITIES = "AMENITIES",
  REVIEWS = "INFORMATION",
}

interface Props {
  parking: NearbyParking & { price: number;  booking_type: string};
  close: () => void;
  style?: React.CSSProperties;
  query: SearchNearbyQuery;
}
const ParkingInfo = ({ parking, close, style, query }: Props) => {
  const {
    image_url,
    owner,
    spot_type,
    _id,
    name,
    rate_rounded_pretty,
    avg_host_rating,
    total_unrounded_pretty,
    rating_count,
    minutes_walk_pretty,
  } = parking;

  const [menu, setMenu] = useState<string | Menus>(Menus.INFORMATION);
  const [loading, setLoading] = useState(parking ? false : true);

  const [additionalInfo, setAdditionalInfo] = useState<undefined | Parking>(
    undefined
  );
  const [  spot_count,   set_spot_count] = useState(1)
  const { reviews } = useReviews(_id);
  const getParking = () => {
    api
      .getOneParking(_id)
      .then((res) => {
        res.success && setAdditionalInfo(res.parking);
      })
      .catch((e) =>
        Notification.error({
          message: e.message || e,
          title: "Failed Getting Parking",
        })
      );
    setLoading(false);
  };

 
  useEffect(() => {
    setMenu(Menus.INFORMATION);
    if (_id) {
      setLoading(true);
      getParking();
    }
  }, [parking]);

  return (
    <>
 
      <Grid
        className="slide-left shadow"
        placeItems="start stretch"
        height="100%"
        background={"white"}
        gridGap=".5em"
        customRows="auto 1fr auto"
        style={{
          position: "absolute",
          zIndex: 10,
          height: "calc(100vh - 11.5em)",
          maxWidth: "90vw",
          width: "550px",
          ...style,
        }}
      >

         <CloseBtn close={close}/>

        <Grid
          placeItems="stretch"
          style={{
            maxHeight: "100%",
            overflowY: "auto",
          }}
        >
          <Grid placeSelf="stretch" style={{ minHeight: "30vh" }}>
            {image_url?.length > 0 ? (
              <Images
                rounded={false}
                style={{ width: "99%", height: "auto", maxHeight: "30vh" }}
                images={image_url}
              />
            ) : (
              <i
                style={{ fontSize: "9em", color: disabledTxtColor }}
                className="fas fa-parking"
              ></i>
            )}
          </Grid>
          <Grid
            color={primaryColor}
            mobileLayout={false}
            style={{ borderBottom: "1px solid " + disabledTxtColor2 }}
            customCols={"3em 1fr 3em"}
          >
            <span />
            <Header bold className="capitalize" size={3}>
              {name || owner?.user_name + "'s " + spot_type} &nbsp;
            </Header>
            {/* <i className="far fa-heart txt-md"></i> */}
          </Grid>
          <Grid customCols='1fr 5em'  mobileLayout={false}>
            <Text textAlign='left'>Select Number of Spots </Text>
            <Select style={{minWidth: '4em', marginRight: '1em'}} value={spot_count} onChange={(e)=>set_spot_count(e)}>
              {[...new Array(additionalInfo?.max_spots_per_reservation)].map((_, i: number)=><Select.Option value={i + 1}>{i + 1}</Select.Option>)}
            </Select>
          </Grid>
          <Grid customCols="1fr auto 1fr" mobileLayout={false}>
            <Grid gridGap=".25em" placeItems="stretch">
              <Text bold textAlign="left">
                Parking From
              </Text>
              <Text color={"gray"} textAlign="left">
                {getParkingDate(query.booking_type, query.start_date, query.start_hour)}
              </Text>
            </Grid>

            <i
              style={{ color: primaryColor }}
              className="fas fa-arrow-right txt-md"
            />

            <Grid gridGap=".25em" placeItems="stretch">
              <Text bold textAlign="left">
                Parking Until
              </Text>
              <Text color={"gray"} textAlign="left">
                {getParkingDate(query.booking_type, query.end_date, query.end_hour)}
              </Text>
            </Grid>
          </Grid>
          <Grid
            placeSelf="stretch"
            placeItems="stretch"
            customCols="1fr 1fr 1fr"
            mobileLayout={false}
            gridGap="0"
            background={disabledTxtColor2}
            padding=".5em"
          >
            <Grid gridGap=".5em">
              <Text bold color={primaryColor} style={{ fontSize: "1.2em" }}>
                {rate_rounded_pretty}
              </Text>
              <Text>{total_unrounded_pretty} Total</Text>
            </Grid>

            <Grid
              gridGap=".5em"
              style={{
                borderRight: "1px solid #b2b2b2",
                borderLeft: "1px solid #b2b2b2",
                margin: "0 .25em",
              }}
            >
              <Text bold color={primaryColor} style={{ fontSize: "1.2em" }}>
                {minutes_walk_pretty}
              </Text>
              <Text>To Destination</Text>
            </Grid>

            <Grid gridGap=".5em">
              <Text bold color={primaryColor} style={{ fontSize: "1.2em" }}>
               
                {avg_host_rating.toFixed(1) + " " }
                <i className="fa fa-star" style={{ color: "gold" }} />
              </Text>
              <Text>{rating_count} Rating{rating_count > 1 && 's'}</Text>
            </Grid>
          </Grid>

          <Switch.underlined
            onClick={setMenu}
            style={{
              position: "sticky",
              background: "white",
              top: 0,
            }}
            color={primaryTxtColor}
            border={"3px solid " + primaryColor}
            items={Object.keys(Menus)}
            active={menu}
          />
          <Grid placeItems="stretch">
            {menu === Menus.AMENITIES ? (
              <Amenities loading={loading} additionalInfo={additionalInfo} />
            ) : menu === Menus.INFORMATION ? (
              <Information parking={parking} additionalInfo={additionalInfo} />
            ) : (
              <Reviews reviews={reviews} loading={loading} />
            )}
          </Grid>
        </Grid>
        <Grid placeSelf="stretch" placeItems="end stretch">
        <a target="_blank" rel="noopener noreferrer" href={'/checkout/?' + toQuery({...query, ...{parking_id: _id,   spot_count}})}>
          <Button
            noHover
            rounded
            bg={gradient}
            width="100%"
            style={{ minHeight: "2.75em" }}
          >
            Book This Spot
          </Button>
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default ParkingInfo;
