import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

export default function Modal({ parent, className, modal }: any) {
  const el = React.useMemo(() => document.createElement("div"), []);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const target = parent && parent.appendChild ? parent : document.body;

    const classList = ["portal-container"];

    if (className)
      className.split(" ").forEach((item: any) => classList.push(item));
    classList.forEach((item) => el.classList.add(item));
    target.appendChild(el);
    return () => {
      target.removeChild(el);
    };
  }, [el, parent, className]);

  return {
    close: () => setOpen(false),
    modal: open && createPortal(modal, el),
    open: () => setOpen(true),
  };
}
