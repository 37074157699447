const defaultVehicleClasses = [
    "medium_truck",
    "heavy_truck",
    "class_a_rv",
    "class_b_rv",
    "class_c_rv",
    "travel_trailer",
    "popup_camper",
    "fifth_wheel",
  ]

const getArraysIntersection = (a1: string[], a2: string[]) =>
a1.filter((n: string) => a2.indexOf(n) !== -1);

export const hasVehicleClasses = (
allVehicleClasses: string[],
vehicleClasses = defaultVehicleClasses
): boolean =>
getArraysIntersection(allVehicleClasses, vehicleClasses).length !== 0;