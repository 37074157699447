import visa from "../assets/logo-visa.png";
import masterCard from "../assets/logo-mastercard.png";
import amex from "../assets/logo-amex.png";
import discover from "../assets/logo-discover.png";

export const getPaymentLogo = (card: string) => {
  card = card.toLowerCase();
  switch (card) {
    case "visa":
      return visa;

    case "amex":
      return amex;

    case "discover":
      return discover;
    default:
      return masterCard;
  }
};
