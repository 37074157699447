import React, { useEffect } from "react";
import markerGreen from "../../../../assets/marker-green.png";
import markerOrange from "../../../../assets/marker-orange.png";
import markerRed from "../../../../assets/marker-orange.png";
import markerRedElectric from "../../../../assets/marker-orange.png";
import markerGreenElectric from "../../../../assets/marker-green-electric.png";
import margerOrangeElectric from "../../../../assets/marker-orange-electric.png";
import { Text } from "platyplex_ui";
import useParking from "../../../../hooks/parking";

const Marker = (props: any) => {
  const {
    _id,
    className,
    select,
    isSelected,
    height = 60,
    price,
    width = 50,
    setOpenParking,
    image,
    isSold
  } = props;

  const { parking } = useParking(_id);
  const isElectric = parking?.amenities?.includes('electric_charging');

  const top = height / -2;
  const priceTop = height * -1.25;
  const left = width / 2;

  const style = {
    width,
    height,
    left,
  };


  const markerImg = isSold && isElectric?
  markerRedElectric: 
  isSold?
    markerRed :
    isSelected && isElectric?
    margerOrangeElectric: isSelected?
      markerOrange :
      isElectric?
      markerGreenElectric:
      markerGreen;
  return (
    <div
      className={isSelected ? 'pop ' : '' + (className || '')}
      onClick={() => !isSold && setOpenParking()}
      style={isSelected ? { zIndex: 100, position: 'fixed' } : {}}
      onMouseOver={() => !isSold && select && select()}
    >
      <img
        src={image || markerImg}
        style={{
          ...style,
          position: "relative",
          top,
        }}
        alt='marker'
      />
      {price && <Text
        bold
        style={{
          ...style,
          left: isElectric? left- 5: left,
          top: isElectric? priceTop + 5: priceTop,
          position: "relative"
        }}
      >{price}
      </Text>}
    </div>
  );
};

export default Marker;