import React, { useEffect, useState } from "react";
import { Grid, Text, Button, Header, Notification } from "platyplex_ui";
import { primaryColor } from "../../../../config";
import { DailyParking } from "../../../listings/components/availability";
import api from "../../../../api";
import dailyParking from "../../../../utils/daily-parking";
import { useDispatch, useSelector } from "react-redux";
import { reloadUserDetails } from "../../../../store/actions/user";
import useAsync from "../../../../hooks/async";

const WeeklyScheduler = ({ close }: { close?: () => void }) => {
    const { details } = useSelector(
        (state: any) => state.user
    );

    const { loading, runFunction} = useAsync(async () => {
        await saveAvailability();
        close && close()
    });

    const dispatch = useDispatch();
    const [schedule, setSchedule] = useState({ time: dailyParking.workdays })

    const saveAvailability = async () => {
        await api
            .addParkingSchedule(schedule.time)
            .then((res)=> res?.success && dispatch(reloadUserDetails()))
            .catch((e) => Notification.error({ title: "Error", message: e.message || e }))
    }

    useEffect(() => {
        if (details?.parking_schedule) {
            setSchedule({ time: details?.parking_schedule })
        }
    }, [])
    return (
        <Grid padding="1em 2em" customRows="auto auto auto auto 1fr">
            <Header color={primaryColor} bold>
                My Weekly Schedule
          </Header>
            <Text textAlign="left">
                Please select below days and time when you need parking space in a
                week
          </Text>
            <DailyParking daily_parking={schedule} setDailyParking={setSchedule} />
            <Text style={{fontStyle: 'italic'}} textAlign="left">
                Please note price of the spots would very depending on number of
                hours you park in the week
          </Text>
            <Button
                rounded
                loading={loading}
                disabled={loading}
                style={{ margin: ".5em", placeSelf: "end stretch" }}
                onClick={runFunction}
            >
                Done
          </Button>
        </Grid>)
};


export default WeeklyScheduler;
