import React, { useState } from "react";
import { Grid, Text } from "platyplex_ui";
import { Drawer } from "antd";
import andriodImg from "../../assets/andriod.png";
import appleImg from "../../assets/apple.png";
import useQuery from "../../hooks/query";
import { toBoolean } from "../../utils/extra";

const MobilePopup = () => {
  const [open, setOpen] = useState(true);
  const { query } = useQuery<{STORE_LINKS: string}>();
  const STORE_LINKS = query.STORE_LINKS? toBoolean(query.STORE_LINKS) : true;
  const close = () => setOpen(false);
  return (
    STORE_LINKS?
    <div className='show-mobile'>
      <Drawer
        className="show-mobile"
        closeIcon={false}
        title="Using ParkStash application on a mobile device? We recommend downloading our app for best experience!"
        placement={"bottom"}
        style={{ padding: 0 }}
        height="225px"
        onClose={close}
        visible={open}
        key={"1"}
      >
        <Grid customCols="1fr 1fr" mobileLayout={false} gridGap='.25em .5em'>
          <a target='_' href="https://play.google.com/store/apps/details?id=io.ionic.parkstash_parking_app">
            <img
              src={andriodImg}
              style={{ maxWidth: "40vw" }}
              alt="Andriod Img"
            />
          </a>
          <a  target='_' href="https://apps.apple.com/us/app/parkstash-parking-made-easy/id1295558287?ls=1">
            <img src={appleImg} style={{ maxWidth: "40vw" }} alt="Apple Img" />
          </a>
          <Text
            bold
            className='pointer'
            size="1.25em"
            style={{ gridColumn: "1/-1" }}
            onClick={close}
          >
            Continue in browser
          </Text>
        </Grid>
      </Drawer>
    </div>: null
  );
};

export default MobilePopup;
