import React, { useEffect } from "react";
import { Grid, Text, List, Modal } from "platyplex_ui";
import { Switch, Tooltip } from "antd";
import Collapse from "../collapse";
import useVehicleClasses from "../../hooks/vehicle-classes";
import Bar from "../../pages/checkout/components/bar";
import { primaryColor } from "../../config";
import PrimaryModal from "../primary-modal";

interface Size {
  width_ft: number;
  length_ft: number;
}
interface Props {
  selectedVehicles: string[];
  setSelectedVehicles: (vehicles: string[]) => void;
  openedCategories?: string[];
  setSize?: (e: any) => void;
  size?: Size;
}

const VehicleClasses = ({
  selectedVehicles = [],
  setSelectedVehicles,
  openedCategories,
  size,
  setSize,
}: Props) => {
  const { vehicles, loading, vehicleTypes } = useVehicleClasses();

  const handleToggle = (value: string, checked: boolean) => {
    if (checked) {
      if (selectedVehicles?.includes(value)) return;
      setSelectedVehicles([...selectedVehicles, value]);
    } else {
      if (!selectedVehicles?.includes(value)) return;
      setSelectedVehicles(selectedVehicles.filter((a: any) => a !== value));
    }
  };

  const openModal = (info: any) =>{

   const InfoModal = Modal({
    title: "",
    className: 'top1',
    modal: (
      <PrimaryModal
      style={{zIndex: 1000}}
      onCancel={() => {InfoModal.close()}}
    >
      <Grid gridGap=".25em" padding="1em">
       {info}
      </Grid>
    </PrimaryModal>
    ),
    hideCancel: true,
  });
  InfoModal.open();
}

  useEffect(() => {
    if (size && setSize) {
      const newSize: Size = selectedVehicles.reduce(
        (acc: Size, v: string) => {
          const [car] = vehicles.filter(
            ({ name }) => name === v
          ) as any;
          if (!car) return acc;
          const newSize: Size = {
            width_ft: car.parkingDimenW,
            length_ft: car.parkingDimenL,
          };

          return newSize.length_ft > acc.length_ft ? newSize : acc;
        },
        {
          width_ft: 0,
          length_ft: 0,
        }
      );
      setSize(newSize);
    }
  }, [ selectedVehicles, vehicles ]);

  return (
    <>
      {vehicleTypes.map(({ displayName, category, tooltip }) => {
        return (
          <Grid
            margin="0 0 1em 0"
            gridGap={0}
            padding="0 0 0 .5em"
            key={displayName}
          >
            <Collapse
              collapse={!openedCategories?.includes(category)}
              heading={
                <Text className="med" textAlign="left">
                  {displayName} &nbsp; {tooltip && <i className='fa fa-info-circle' style={{color: primaryColor}} onClick={()=>openModal(tooltip)}/>}
                </Text>
              }
            >
              <List
                loading={loading}
                list={vehicles.filter(
                  (vehicle: any) => vehicle.category === category
                )}
                map={(props: any, index: number) => {
                  const { displayName, imageUrl, name: value } = props;
                  return (
                    <Grid
                      key={index}
                      customCols="1fr auto"
                      placeItems="stretch"
                      gridGap=".25em"
                      padding="0 0 0 .5em"
                      mobileLayout={false}
                    >
                      <Text textAlign="left">
                        <img
                          src={imageUrl}
                          style={{ width: 30 }}
                          alt="Vehicle Types"
                        />
                        &nbsp; {displayName}
                      </Text>
                      <Switch
                        checked={selectedVehicles.includes(value)}
                        onChange={(e) => handleToggle(value, e)}
                      />
                    </Grid>
                  );
                }}
              />
            </Collapse>
            <Bar/>
          </Grid>
        );
      })}
    </>
  );
};

export default VehicleClasses;
