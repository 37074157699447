import React from "react";
import { Grid, Text } from "platyplex_ui";
import { Switch, InputNumber } from "antd";

type Time = "hourly" | "max_daily" | "weekly" | "monthly";

const Pricing = ({ pricings, setPricings, currency }: any) => (
  <Grid
    margin=".6em 0"
    placeSelf="stretch"
    placeItems="stretch"
    gridGap="1em"
    customCols="1fr auto auto"
    mobileLayout={false}
  >
    {["hourly", "max_daily", "weekly", "monthly"].map((time) => {
      const certainPricing = pricings[time as Time];
      return (
        <React.Fragment key={time}>
          <Text
            textAlign="left"
            placeSelf="end start"
            bold
            className="capitalize"
          >
            {time.replace("_", " ")}
          </Text>
          <span>
            {currency === "CAD" ? "C$" : "$"}&nbsp;
            <InputNumber
              min={0}
              disabled={!certainPricing.active}
              value={certainPricing.price / 100}
              step={1}
              onChange={(price: any) =>
                setPricings(time, {
                  ...certainPricing,
                  ...{ price: Math.floor(price) * 100 },
                })
              }
              style={{ placeSelf: "center" }}
            />
          </span>
          <Switch
            style={{ placeSelf: "center" }}
            onChange={(active) =>
              setPricings(time, { ...certainPricing, active })
            }
            checked={certainPricing.active}
          />
        </React.Fragment>
      );
    })}
  </Grid>
);

export default Pricing;
