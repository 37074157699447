import React from "react";
import { Card, Empty, Grid, List, Text } from "platyplex_ui";
import { Enterprise } from "../../../../hooks/enterprise";
import useEnterpriseGarages from "../../../../hooks/enterprise-resource";
import { Announcement } from "../../../../model/domain/announcements";

interface Props {
  enterprise: Enterprise;
}

const Announcements = ({ enterprise }: Props) => {
  const { announcements, loading, legend } = useEnterpriseGarages(
    enterprise.id,
    "announcements"
  );
  const hasData = !loading && announcements.length > 0;
  return (
    <Grid
      gridGap=".5em"
      customRows="auto 1fr"
      style={{ maxHeight: "95vh", overflowY: "auto", fontSize: "1em" }}
      placeItems={!hasData ? "center" : "start stretch"}
      mobileLayout={false}
    >
      <div>
        <Text size="1.2em" bold>
          Announcements
        </Text>
        {Object.keys(legend).map((key: any) => (
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <i
              className="fa fa-bell"
              style={{ color: legend[key].bgColor }}
            />{" "}
            {legend[key].label}
          </span>
        ))}
      </div>

      <div
        style={{
          maxHeight: "100%",
          placeSelf: "stretch",
          overflowY: "auto",
          fontSize: "1em",
        }}
      >
        <List
          loading={loading}
          empty={<Empty text='There are no announcements at this point of time.'/>}
          map={(g: Announcement) => (
            <Card shadow style={{ background: legend[g.importance]?.bgColor }}>
              <Text textAlign="left">{g.title}</Text>
              <Text textAlign="left">{g.body}</Text>
              <Text bold textAlign="right">
                {g.launchAt.prettyDate}
              </Text>
            </Card>
          )}
          list={announcements.filter(a => a.status === 'active')}
        />
      </div>
    </Grid>
  );
};

export default Announcements;
