import React from "react";
import Layout from "../../layout";
import { Grid, Card, Text } from "platyplex_ui";
import Bar from "../checkout/components/bar";
import { Link, Route, Switch } from "react-router-dom";
import { primaryColor } from "../../config";
import PaymentMethod from "./payment-methods";
import Promo from "./promo";
import Earnings from "./earnings";
import ParkstashWallet from "./parkstash-wallet";
import PayoutMethod from "./payout-method";
import { useSelector } from "react-redux";

const Wallet = () => {
  const { details }= useSelector((s: any)=>s.user)
  const routes = [
    {
      title: "My Earnings",
      route: "/earnings",
      icon: "fas fa-money-bill-wave",
    },
    {
      title: "My ParkStash Wallet",
      route: "/parkstash-wallet",
      icon: "fas fa-wallet",
    },
    {
      title: "Payment Methods",
      route: "/payment-methods",
      icon: "fas fa-credit-card",
    },
    {
      title: "Payout Methods",
      route: "/payout-methods",
      icon: "fas fa-university",
    },
    { title: "Promo Code & Gift Cards", route: "/promos", icon: "fas fa-gift" },
    {
      title: "Currency",
      route: "",
      icon: (
        <Text color={primaryColor} bold>
          {details?.currency}-$
        </Text>
      ),
    },
  ];

  return (
    <Layout sidebar style={{ gridTemplateRows: "1fr auto" }}>
      <Layout.Top>
        <span />
      </Layout.Top>
      <Layout.Bottom>
        <Grid
          placeItems="start stretch"
          padding="1em"
          height="100%"
          style={{
            width: 1200,
            maxWidth: "100vw",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Switch>
            <Route
              path="/wallet/payment-methods"
              render={() => <PaymentMethod />}
            />

            <Route
              path="/wallet/payout-methods"
              render={() => <PayoutMethod />}
            />

            <Route path="/wallet/earnings" render={() => <Earnings />} />

            <Route
              path="/wallet/parkstash-wallet"
              render={() => <ParkstashWallet />}
            />

            <Route path="/wallet/promos" render={() => <Promo />} />
            <Route
              path="/"
              render={() => (
                <Card shadow grid placeItems="stretch" className="noselect">
                  {routes.map((route) => (
                    <React.Fragment key={route.title}>
                      <NavItem {...route} />
                      <Bar />
                    </React.Fragment>
                  ))}
                </Card>
              )}
            />
          </Switch>
        </Grid>
      </Layout.Bottom>
    </Layout>
  );
};

const NavItem = ({ title = "", route = "", icon }: any) => {
  return (
    <Link to={`/wallet${route}`}>
      <Grid
        customCols="1fr auto"
        style={{ fontSize: "1.25em" }}
        mobileLayout={false}
        className="hover-highlight"
        placeItems="center stretch"
      >
        <Text
          className="pointer med"
          textAlign="left"
          color="inherit"
          style={{ fontWeight: "inherit" }}
        >
          {title}
        </Text>
        {typeof icon === "string" ? <i className={icon + " txt-md"} /> : icon}
      </Grid>
    </Link>
  );
};

export default Wallet;
