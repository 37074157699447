import React, { useState } from "react";
import { Grid, Text, Notification, Loader } from "platyplex_ui";

import api from "../../../../api";
import { Input, message } from "antd";
import { useFormik } from "formik";
import { disabledTxtColor, primaryColor } from "../../../../config";

const ApplyPromo = ({ reload }: { reload?: () => void }) => {
  const [state, setState] = useState({
    success: false,
    message: ''
  })
  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    initialValues: { promo_name: "", loading: false },
    async onSubmit(values) {
      setFieldValue('loading', true)
      api
        .addPromo(values)
        .then((res: any) => {
          setState({ 
            success: res.success, 
            message: res.message
          })
          if (!res.success) {
            throw new Error(res.message);
          } else {
            message.success(res.message);
            setFieldValue('promo_name', "")
            reload && reload();
          }
       
        })
        .catch((e) =>
          Notification.error({ title: "Error", message: e.message })
        )
        .finally(() => setFieldValue('loading', false))
    },
  });
  const canSubmit = !values.loading && values.promo_name.length > 0
  return (
    <form onSubmit={handleSubmit}>
    <Grid customCols="1fr auto">
      <Input
        placeholder="Promo"
        name="promo_name"
        onChange={handleChange}
        value={values.promo_name}
      />
      <div onClick={(e: any) => canSubmit && handleSubmit(e)}>
        {values.loading && <Loader />}
        <Text color={!canSubmit? disabledTxtColor : "blue"} className="pointer">
          Apply Promo Code
        </Text>
      </div>
    </Grid>
     <Text 
     style={{height: '1em', width: '100%', color: !state.success? 'red':  primaryColor}} 
     textAlign='left'
     >
      {state.message && <span className='slide-down'>{state.message}</span>}
    </Text>
    </form>
  );
};

export default ApplyPromo;
