import React, { useEffect } from "react";
import { Grid, Text  } from "platyplex_ui";
import Wrapper from "../../../../../components/overlay-wrapper";
import Bar from "../../../../checkout/components/bar";
import { useDispatch } from "react-redux";
import { primaryColor } from "../../../../../config";
import { removeBank } from "../../../../../store/actions/bank";
import PayoutMethod from "../..";

const BankItem = ({ close, payoutMethod }: { close: () => void; payoutMethod: PayoutMethod, selectedPayoutMethod: string }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isBank = !!payoutMethod?.stripeBankAccount?.stripeId

 
  return (
    <Wrapper
      style={{
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 100,
      }}
      back={close}
    >
      <Grid
        placeSelf="start center"
        placeItems="start center"
        style={{ width: 400, maxWidth: "90vw" }}
      >
          {!isBank? <i className="fab fa-paypal txt-lgx" aria-hidden="true" />:
           <i className="fa fa-university txt-lgx" aria-hidden="true" />}
        <Grid customCols="1fr auto" width="100%" placeItems="center stretch">
        <Text textAlign="left" color={primaryColor}>
           Name
          </Text>
          <Text textAlign="right">
            {payoutMethod?.stripeBankAccount?.bankName || payoutMethod?.paypal?.name}
          </Text>
          <Bar style={{ gridColumn: "1/-1" }} />

         
          {isBank? 
          <><Text textAlign="left" color={primaryColor}>
            Account Number
          </Text>
          <Text textAlign="right">
            Ending in {payoutMethod?.stripeBankAccount?.last4}
          </Text>
          </>:    
          <>
          <Text textAlign="left" color={primaryColor}>
            PayPal Email
          </Text>
          <Text textAlign="right">
             {payoutMethod?.paypal?.email}
          </Text>
          </>}
          <Bar style={{ gridColumn: "1/-1" }} />
          
          <Text
            textAlign="left"
            bold
            color={'red'}
            className="pointer"
            onClick={() => {
              dispatch(removeBank(payoutMethod._id));
              close();
            }}
          >
            Delete {isBank? 'Bank': 'PayPal Account'}
          </Text>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default BankItem ;
