import React, { useEffect, useState } from "react";
import { Card, Text, Grid, Notification, Modal, Button } from "platyplex_ui";
import { BookingHistory } from "./type";
import Avatar from "../../components/avatar";
import {
  disabledTxtColor,
  disabledTxtColor2,
  primaryColor,
} from "../../config";
import Images from "../../components/images";
import api from "../../api";
import { message, Popconfirm } from "antd";

import { useHistory } from "react-router";
import useCopy from "../../hooks/copy";
import { formatAddress } from "../../utils/address";

interface BookingProps {
  booking: BookingHistory
  user: string;
  reload: () => void;
  type: string;
  extend: (p: BookingHistory) => void;
  select: () => void
}
const Booking = (props: BookingProps) => {
  const {
    booking,
    extend,
    reload,
    type,
    user,
  } = props;

  const { owner,
  driver,
  pretty_start,
  pretty_end,
  assigned_parking_spot_no,
  transaction,
  vehicle,
  parking,
  _id,
  par_id} = booking
  const { select } = props;
  const history = useHistory();
  const {copy } = useCopy();
  const cancel = (id: string) => {
    api
      .cancelBooking(id)
      .then((res: any) => {
        if (res.success) {
          message.success("Cancelled");
          setTimeout(reload, 1000);
        } else throw new Error(res.message || "Error");
      })
      .catch((e) => Notification.error({ title: "Error", message: e.message }));
  };

  return (
    <Card
      className="noselect pointer highlight slide-up"
      margin="0"
      shadow
      grid
      placeItems="stretch"
      style={{
        width: "100%",
        borderRadius: "1em",
        fontSize: "1.2em",
        padding: 0,
      }}
    >
      <Grid
        customCols="auto 1fr"
        placeItems="start stretch"
        padding=".5em 1.5em"
      >
        <Grid style={{ gridRow: "1/-1" }} placeSelf="center">
          <Text>{user === "DRIVER"? transaction?.pretty_total: transaction.pretty_owner_earnings}</Text>
          {user === "DRIVER" ? (
            parking?.image_url?.length && parking?.image_url?.length > 0 ? (
              <Images
                hideIndex={parking?.image_url?.length > 5}
                images={parking?.image_url || []}
                style={{ width: 150, height: 150 }}
              />
            ) : (
              <i
                style={{ fontSize: "9em", color: disabledTxtColor }}
                className="fas fa-parking"
              ></i>
            )
          ) : (
            <Avatar src={driver.image_url} style={{ fontSize: "2em" }} />
          )}
          {assigned_parking_spot_no === -1?
          <Text>Any Spot Available</Text>:
          <Text>Spot #{assigned_parking_spot_no}</Text>}
        </Grid>
        <Grid gridGap="0" placeItems="center" onClick={() => select()}>
          <Grid placeItems="start" gridGap=".5em" color={disabledTxtColor}>
            <Text textAlign="left" bold className="capitalize">
              {parking.name || `${owner.user_name}'s Driveway`} &nbsp;
            </Text>
            <Text textAlign="left" color="inherit">
              Booked By: {driver.user_name}
            </Text>
            <Text textAlign="left" color="inherit">
              {pretty_start}
            </Text>
            <Grid gridGap="0em" color="inherit">
              {[...new Array(4)].map((i) => (
                <Text
                  key={i}
                  textAlign="left"
                  color="inherit"
                  style={{ lineHeight: ".8em" }}
                >
                  ●
                </Text>
              ))}
            </Grid>
            <Text textAlign="left" color="inherit">
              {pretty_end}
            </Text>
            {user !== "DRIVER" ? (
              <Text textAlign="left" color="inherit">
                License Plate #{vehicle.license_plate}
                <br />
                {`${vehicle.color} ${vehicle.year} ${vehicle.model}`}
              </Text>
            ) : (
              getAddress(parking)
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        placeSelf="stretch"
        placeItems="stretch"
        gridGap={0}
        customCols={
          type === "UPCOMING" && user === "DRIVER" ? "1fr 1fr 1fr" : "1fr 1fr"
        }
        style={{
          borderTop: "1px solid " + disabledTxtColor2,
          padding: ".5em 0 .25em 0",
        }}
      >
        {user === "DRIVER" && (
          <a
            target="_blank"
            href={
              `https://www.google.com/maps/place/${formatAddress(parking.address)}` 
            }
          >
            {" "}
            <Text bold color="dodgerblue" padding=".5em 1.5em">
              Navigate
            </Text>{" "}
          </a>
        )}

        {user === "HOST" && (
          <Text
            bold
            color={primaryColor}
            padding=".5em 1.5em"
            onClick={() => select()}
          >
            View Details
          </Text>
        )}

        {user === "HOST" && (
          <Text
            bold
            color={"red"}
            padding=".5em 1.5em"
            onClick={() => {
              history.push('/help');
              copy(par_id, 'Booking ID copied to the clipboard.')
            }}
          >
            Report Concern
          </Text>
        )}

        {type === "UPCOMING" && user === "DRIVER" && (
          <Text
            bold
            color={primaryColor}
            padding=".5em 1.5em"
            onClick={() => {
              extend(props.booking);
            }}
          >
            Extend
          </Text>
        )}

        {type === "UPCOMING" && user === "DRIVER" && (
          <Popconfirm
            title={<BookingCancel id={_id} />}
            onConfirm={() => cancel(_id)}
          >
            <Text bold color={"red"} padding=".5em 1.5em">
              Cancel
            </Text>
          </Popconfirm>
        )}
      </Grid>
    </Card>
  );
};

const BookingCancel = ({ id }: { id: string }) => {
  const [message, setMessage] = useState<string | undefined>(undefined);
  useEffect(() => {
    api
      .checkCancelBooking(id)
      .then((res) => {
        if (res.success) {
          setMessage(res.message_prompt);
        } else throw new Error("");
      })
      .catch(() => setMessage("Error: Can not cancel booking."));
  }, []);

  return <span>{message}</span>;
};

const getAddress = (parking: any) => {
  return (
    <Text textAlign="left" color="inherit">
      {parking.address.street}, {parking.address.city}, {parking.address.state}{" "}
      {parking.address.zipcode}, {parking.country}
    </Text>
  );
};


export default Booking;
