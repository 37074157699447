import { Card } from "platyplex_ui";
import React, { useEffect, useState } from "react";
import moment from 'moment';
import { LAYER } from "../../../config";

const Timer = ({ time = 10000, message = "", finish = () => {} }) => {
  const [timer, setTimmer] = useState(time);

  useEffect(() => {
    if (timer > 0) {
      const time = setInterval(() => setTimmer(timer - 1000), 1000);
      return () => {
        clearInterval(time);
      };
    } else {
      finish();
    }
  }, [timer]);
  return (

      <Card shadow style={{ 
        minWidth: 200, borderRadius: '.5em',       
        position: "fixed",
        zIndex: LAYER.last,
        display: "grid",
        placeItems: "center",
        margin: '0 auto',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        gridTemplateColumns: '1fr auto',
        top: 60, }} padding=".25em .5em">
        {message}
        <span style={{ color: "red" }}>{moment(timer).format("mm:ss")}</span>
      </Card>
  );
};

export default Timer;
