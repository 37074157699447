import React from "react";
import { Grid, Text, Button, Header } from "platyplex_ui";
import * as Yup from "yup";
import { useFormik } from "formik";
import {  gradient, primaryColor } from "../../../config";
import Availability from "../components/availability";
import { Form } from ".";
import PricingComp from "../components/pricing";
import SecurityDeposit from "../components/security-deposit";
import dailyParking from "../../../utils/daily-parking";
import useCountry from "../../../hooks/country";
import Bar from "../../checkout/components/bar";
import PopupInfo from "../../../components/popup-info";
import { hasVehicleClasses } from "../../../utils/vehicles";

const defaultValue = {
  daily_parking: {
    time: dailyParking.always
  },
  weekly: { active: true, price: 4000 },
  monthly: { active: true, price: 10000 },
  hourly: { active: true, price: 100 },
  max_daily: { active: true, price: 1000 },
  availability: "always",
  security_deposit: {
    active: false,
    price: 10000,
  },
};

const Pricing = (props: any) => {
  const { loading } = props;
  const { currency } = useCountry();
  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {...defaultValue, ...props.listing},
    onSubmit(values) {
      props.setInfo(values);
    },
  });

  const error = !values.weekly.active && !values.monthly.active && !values.hourly.active && !values.max_daily.active;
  
  const showSecurityDeposit: boolean = hasVehicleClasses(values.vehicle_classes);
  const submit = (
    <Grid placeItems="start stretch">
      {" "}
      {loading ? (
        <Button
          loading
          disabled
          style={{ borderRadius: "1.3em", padding: ".5em" }}
        >
          Loading
        </Button>
      ) : (
        <Button.Normal
          style={{
            borderRadius: "1.3em",
            padding: ".5em",
            ...(!error
              ? { backgroundImage: `${gradient}`, color: "white", border: 0 }
              : {}),
          }}
          type="submit"
          disabled={error}
          onSubmit={(e: any) => handleSubmit(e)}
        >
          Finish
        </Button.Normal>
      )}
    </Grid>
  );
  return (
    <Form onSubmit={handleSubmit} back={props.back} footer={submit}>
      <Header bold>Pricing and Availability</Header>
      {props.progress}
      <PopupInfo 
          title={
            <Grid placeItems='stretch'>
              <Text bold size='1.2em'>Availability & Price</Text>

              <Text textAlign='left'>ParkStash provides 3 types of pricing and availability selection for your listing:</Text>
              <div>
                <Text bold  textAlign='left'>1. Daily</Text>
                <Text  textAlign='left'>Choose daily if you would like to rent out your spot for everday use at a per hour or daily max price. This is ideal if your spot is located in downtown, near shopping centers, grocery stores etc. Ex. Mon - Fri, 8AM - 6PM for$2/hr or $8 max.</Text>
              </div>

              <div>
                <Text bold  textAlign='left'>2. Weekly</Text>
                <Text  textAlign='left'>Choose this if you can rent your spot for specific hours or 24/7 in a week. Ex. Mon - Fri, 8AM - 6PM for $30/week or Available 24/7 for $50/week.</Text>
              </div>

              <div>
                <Text bold  textAlign='left'>3. Monthly</Text>
                <Text  textAlign='left'>Rent your spot at a monthly rate available for specific days and time in a week or 24/7. Ideal if you have an empty space available for long term parking. Ex. Jan 1 - Jan 31; Mon - Fri 8AM - 6PM for $110/month or Available 24/7 for $200/month.</Text>
              </div>
              <Text bold  textAlign='left'>If you have any questions, feel free to shoot an email to support@findparkstash.com.</Text>

            </Grid>
          }
        >
          <Text color='blue' textAlign='left' className='pointer' style={{textDecoration: 'underlined'}}>How to set my spot's availability and price?</Text>
        </PopupInfo>
      <Grid
        margin=".6em 0"
        placeSelf="stretch"
        placeItems="stretch"
        gridGap="0"
        customCols="1fr auto"
        mobileLayout={false}
      >

  
        <Text textAlign="left" placeSelf="end start" bold>
          Currency
        </Text>
        <Text bold color={primaryColor}>
          {currency}-$
        </Text>
      </Grid>
      <Bar />
      <PricingComp pricings={values} setPricings={setFieldValue} currency={currency}/>
      {showSecurityDeposit && <SecurityDeposit
            currency={currency}
            securityDeposit={values.security_deposit}
            setSecurityDeposit={(props) =>
              setFieldValue("security_deposit", props)
            }
          />}
      <Bar />
      <Availability
        setAvailability={(e: any) => setFieldValue("availability", e)}
        availability={values.availability}
        daily_parking={values.daily_parking}
        dailyParkingStyle={{ gridTemplateColumns: "auto 1fr" }}
        setDailyParking={(e: any) => setFieldValue("daily_parking", e)}
      />
    </Form>
  );
};

export default Pricing;
