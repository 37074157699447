import React from "react";
import { Card, Grid, List, Text, Button, Notification } from "platyplex_ui";
import { gradient, primaryColor } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { CreditCard } from "../../pages/booking-history/type";
import visa from "../../assets/visa.jpg";
import Bar from "../../pages/checkout/components/bar";
import { InputNumber, Radio } from "antd";
import { selectCard } from "../../store/actions/card";
import useRecharge from "../../hooks/recharge";
import NewModal from "../new-modal";
import CardForm from "../../pages/wallet/payment-methods/card-form";
import { reloadUserDetails } from "../../store/actions/user";
import Success from "../success";

const Recharge = ({
  back,
  hideCardAdder,
}: {
  back?: () => void;
  hideCardAdder?: boolean;
}) => {
  const dispatch = useDispatch();
  const { cards, loading: loadingCard, selectedCard } = useSelector(
    (state: any) => state.cards
  );
  const { details } = useSelector((state: any) => state.user);

  const defaultPrice = `${details?.currency_sym}0`;
  const {
    rechargeAmount,
    rechargeWallet,
    loading,
    success,
    setRechargeAmount,
    rechargeReview,
  } = useRecharge(10000);
  const disabled = rechargeAmount < 1000 || loading || !selectedCard?.id;

  const CardModal = NewModal({
    modal: <CardForm close={() => CardModal.close()} />,
  });
  
  if (success)
    return (
      <Success 
        message={"Your wallet recharge was successful"} 
        footer={
          <div>
            <Text bold size='1.5em' color={primaryColor}>{rechargeReview?.currentWalletBalance}</Text>
            <Text bold >Current Wallet Balance</Text>
          </div>
        } 
        link="/wallet" 
      />
    );
  return (
    <Grid style={{ width: 400, maxWidth: "90vw" }} placeItems="stretch">
      {!hideCardAdder && CardModal.modal}
      <Text textAlign="left">
        Current wallet balance: &nbsp;
        <span style={{ color: primaryColor }}>
          {rechargeReview?.currentWalletBalance}
        </span>
      </Text>
      <Bar style={{ borderColor: "white" }} />
      <Text textAlign="left">Recharge Amount</Text>
      <Grid cols="3" placeItems="center stretch"  mobileLayout={false}>
        {[25, 50, 100, 200, 300, 500].map((num) => {
          return (
            <Button
              invert={num * 100 !== rechargeAmount}
              key={num}
              onClick={() => setRechargeAmount(num * 100)}
            >
              {details?.currency_sym}
              {num}
            </Button>
          );
        })}
      </Grid>
      <InputNumber
        placeholder="Enter a payment amount"
        style={{ width: "100%" }}
        min={0}
        step={0.25}
        max={500}
        onChange={(e) => typeof e === "number" && setRechargeAmount(e * 100)}
        value={rechargeAmount ? rechargeAmount / 100 : undefined}
      />

      <Bar style={{ borderColor: "white" }} />
      <Text textAlign="left">
        One time fee: {rechargeReview?.fee || defaultPrice}
      </Text>

      <Text textAlign="left">
        Total Amount Due: {rechargeReview?.total || defaultPrice}
      </Text>

      <Bar style={{ borderColor: "white" }} />

      <Text textAlign="left" bold>Payment Method</Text>
      <List
        loading={loadingCard}
        empty={
          <>
            <Text color="red">
              No payment method present. Add a payment method.
            </Text>
            {!hideCardAdder && (
              <Text
                color="blue"
                textAlign="right"
                className="pointer bold noselect"
                onClick={CardModal.open}
              >
                Add a card.
              </Text>
            )}
          </>
        }
        list={cards}
        map={(card: CreditCard) => (
          <Card
            key={card.id}
            shadow
            mobileLayout={false}
            margin="0"
            grid
            onClick={() => dispatch(selectCard(card))}
            gridGap="2em"
            customCols="auto 1fr auto"
            className="pointer noselect highlight"
          >
            <img src={visa} style={{ maxWidth: 60 }} alt="visa" />
            <div style={{ placeSelf: "stretch" }}>
              <Text textAlign="left">{card.brand} </Text>
              <Text textAlign="left">{`ending in ${card.last4}`} </Text>
            </div>
            <Radio checked={selectedCard?.id === card.id} />
          </Card>
        )}
      />

      <Button
        rounded
        disabled={disabled}
        loading={loading || loadingCard}
        onClick={async () => {
          if (await rechargeWallet(selectedCard?.id)) {
            Notification.success({
              message: "Wallet Recharge Success",
              title: "Success",
            });
            back && setTimeout(back, 1000);
            dispatch(reloadUserDetails());
          } else {
            Notification.error({
              message: "Wallet Recharge Fail",
              title: "Error",
            });
          }
        }}
        noHover
        style={{
          padding: ".5em",
          marginTop: "1em",
          border: 0,
        }}
        bg={gradient}
        type="submit"
      >
        {" "}
        Recharge Wallet
      </Button>
    </Grid>
  );
};

export default Recharge;
