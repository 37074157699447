import React from "react";
import { Grid, Text, Header } from "platyplex_ui";
import Bar from "../../../checkout/components/bar";
import { useSelector } from "react-redux";

export type PayoutType = 'BANK' | 'PAYPAL'

const PayoutSelect = ({ next }: { next: (p: PayoutType) => void }) => {
  const { country } = useSelector((s: any) => s.user.details);
  return (
    <Grid placeItems="stretch" placeSelf="stretch" >
      <Header bold textAlign="left">
        Choose how to get paid
      </Header>
      <Text textAlign="left" >
        Payout methods in {country === "CA" ? "Canada" : "United States"}
      </Text>
      <Bar />
      <Grid customCols="1fr auto" placeItems="center stretch" mobileLayout={false} className="pointer" onClick={()=>next('BANK')}>
        <Text textAlign="left" bold>
          Bank Account
        </Text>
        <i style={{gridRow: 'span 2'}} className="fa fa-chevron-right bold pointer" />
        <ul>
          <li className="txt-left">Get paid in 5-7 business days</li>
          <li className="txt-left">No fees</li>
      </ul>
      </Grid>

      <Grid customCols="1fr auto" placeItems="center stretch" className="pointer" onClick={()=>next('PAYPAL')} mobileLayout={false}>
        <Text  textAlign="left" bold>
          Paypal
        </Text>
        <i style={{gridRow: 'span 2'}} className="fa fa-chevron-right bold pointer" />
        <ul>
          <li className="txt-left">Get paid within 1 business day</li>
          <li className="txt-left">May include fees</li>
      </ul>
      </Grid>
   
      <Bar />
    </Grid>
  );
};
  export default PayoutSelect;