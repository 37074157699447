import { Card, Header, Text, Grid } from "platyplex_ui";
import React from "react";
import { primaryColor } from "../../../config";
import { CreditCard, Vehicle } from "../../booking-history/type";
import { Review } from "../type";
import VehicleFormat from "../../../components/vehicle-modal/vehicle-format";
import Timer from "./timer";

interface Props {
  review: Review;
  card: CreditCard;
  vehicle: Vehicle;
}
const Success = ({ review, card, vehicle }: Props) => {
  return (
    <div className="grid" style={{ height: "100%" }}>
       <Timer
        finish={() => window.close()}
        time={60000 * 2}
        message="This Tab will close in: "
      />
      <Card shadow style={{ maxWidth: 800, minWidth: "40vw" }} grid>
        <i
          className="fas fa-check-circle txt-lgx"
          style={{ color: primaryColor }}
        />
        <Header bold>Payment Successful!</Header>
        <Text bold>
          Duration: {review.start} to {review.end}
        </Text>
        <Grid customCols="auto auto auto 1fr">
          <Text bold>Vehicle: </Text>
          <VehicleFormat vehicle={vehicle} style={{ gridColumn: "2" }} />
        </Grid>
      </Card>
     
    </div>
  );
};

export default Success;
