import { ActionTypes } from "../reducer/cards";
import api from "../../api";
import { CreditCard } from "../../pages/booking-history/type";
import { Notification } from 'platyplex_ui';

const ErrorModal = ({error}: {error: any}) => Notification.error({ title: 'Error', message: error.message || error})
export const asyncActions = async (
  apiCall: () => Promise<any>,
  dispatch: any,
  showError = false,
  modal = ErrorModal
) => {
  let payload = null;
  try {
    dispatch({ type: ActionTypes.REQUEST_CARD });
    payload = await apiCall();
    if (!payload || !payload.success)
      throw new Error(payload?.message || "Error");

    dispatch({ type: ActionTypes.SUCCESS_CARD });
    return await payload;
  } catch (e) {
    if (showError) {
      modal({ error: e });
    }
    dispatch({ type: ActionTypes.ERROR_CARD });
    return payload;
  }
};

export const getCards = (modal = ErrorModal) => {
  return async (dispatch: any) => {
    const payload = await asyncActions(
      () => api.getCards(),
      dispatch,
      true,
      modal
    );
    if (payload && payload.success) {
      dispatch({
        type: ActionTypes.LOAD_CARD,
        payload: {cards : payload.data },
      });
    }
  };
};

export const addCard = (id: string, modal = ErrorModal) => {
  return async (dispatch: any) => {
    const payload = await asyncActions(
      () => api.addPayment(id),
      dispatch,
      true,
      modal
    );

    if (payload && payload.success) {
      Notification.success({ title: 'Success', message: payload.message});
      dispatch(getCards());
    } 

    return payload;
  };
};



export const removeCard = (card: CreditCard, modal = ErrorModal) => {
  return async (dispatch: any) => {
    const payload = await asyncActions(
      () => api.removePayment(card.id),
      dispatch,
      true,
      modal
    );

    if (payload && payload.success) {

      dispatch(getCards());
    }
    return payload;
  };
};

export const selectCard = (card: CreditCard) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.SELECT_CARD,
      payload: { card },
    });
  };
};
