import React from "react";
import { Button, Header,  Text } from "platyplex_ui";
import { useFormik } from "formik";
import { gradient } from "../../../config";
import { Parking } from "../../../model/domain/parking";
import { Form } from ".";
import VehicleClasses from "../../../components/vehicle-classes";
import Sizing from "../components/sizing";

const defaultValue = {
  vehicle_classes: [
    "mid_size_car",
    "compact_car",
    "full_size_car",
    "compact_suv",
    "standard_suv",
    "light_truck",
  ],
  parking_size: {
    // light truck size
    width_ft: 9,
    length_ft: 20,
  },
  touched: false,
};

const VehicleSelection = (props: any) => {
  const { loading } = props;

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {...defaultValue, ...props.listing} as Parking | any,
    onSubmit(values) {
      props.setInfo(values);
    },
  });

  const error = false;
  return (
    <Form back={props.back} onSubmit={handleSubmit}>
      <Header bold>Vehicle Selection</Header>
      {props.progress}
      <div
        style={{ placeSelf: "stretch", display: "grid", placeItems: "stretch" }}
      >
        <Text textAlign="left" margin="0 0 1em 0">
          Please select type of vehicles you would like to allow that will fit
          in your parking space
        </Text>
        <VehicleClasses
          selectedVehicles={values.vehicle_classes}
          setSize={
            !values.touched
              ? (e) => setFieldValue("parking_size", e)
              : undefined
          }
          setSelectedVehicles={(e) => setFieldValue("vehicle_classes", e)}
          openedCategories={["passenger_vehicle", "truck"]}
          size={values.parking_size}
        />

        <Sizing
          parkingSize={values.parking_size}
          setParkingSize={(p) => setFieldValue("parking_size", p)}
        />
      </div>

      {loading ? (
        <Button
          loading
          disabled
          style={{ borderRadius: "1.3em", padding: ".5em" }}
        >
          Loading
        </Button>
      ) : (
        <Button.Normal
          style={{
            borderRadius: "1.3em",
            padding: ".5em",
            ...(!error
              ? { backgroundImage: `${gradient}`, color: "white", border: 0 }
              : {}),
          }}
          type="submit"
          disabled={error}
          onSubmit={(e: any) => handleSubmit(e)}
        >
          Next
        </Button.Normal>
      )}
    </Form>
  );
};

export default VehicleSelection;
