import React from "react";
import { Card, Grid , Button } from "platyplex_ui";
import { LAYER, primaryTxtColor } from "../../config";

interface Props {
    children: JSX.Element;
    style?: React.CSSProperties;
    confirmText ?: string | JSX.Element;
    cancelText?: string | JSX.Element;
    onConfirm ?:() => void;
    onCancel ?: ()=> void
}

const PrimaryModal = ({
    children,
    style,
    confirmText,
    cancelText,

    onConfirm,
    onCancel
}: Props) => (
    <Grid height="100%" >
        <Card 
        shadow 
        padding={0}
        style={{
          maxHeight: '95vh',
          width: 450, 
          maxWidth: '95vw',
           ...style , 
        }}>

          <div style={{ overflowY: 'auto', maxHeight: '70vh'}}>
            {children}
          </div>
            { onCancel && <Grid placeSelf="stretch" placeItems="stretch" gridGap={"0"} customCols={onConfirm? '1fr 1fr': "1fr"}>
            {onConfirm && <Button
              invert
              bg={primaryTxtColor}
              noBorder
              style={{ boxShadow: `0 0 0` }}
              onClick={onConfirm}
            >
              {confirmText || 'Confirm'}
            </Button>}

            <Button
              invert
              bg={primaryTxtColor}
              noBorder
              style={{ boxShadow: `0 0 0` }}
              onClick={onCancel}
            >
              {cancelText || 'Cancel'}
            </Button>
          </Grid>}
        </Card>
    
    </Grid>
);

export default PrimaryModal;