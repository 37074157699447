import { useState, useEffect } from "react";
import API from "../api";
import { asyncHandler } from "../utils";
import moment from 'moment';
import { useTimedInterval } from "./interval";
import { NearbyParking } from "../model/domain/parking";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../store/actions/user";


export interface SearchNearbyQuery {
  long: number;
  lat: number;
  start_date: string;
  end_date: string;
  end_hour?: string;
  start_hour?: string;
  vehicle_classes: string [];
  booking_type : "daily" | "weekly" | "monthly";
  save_parking_preset?: boolean;
};


interface Props {
  parkings : NearbyParking[],
  loading: boolean;
  error: boolean;
}
export const useNearbyParking = ({ 
  onResults = (p: any)=> {}}) => {
  const { query } = useSelector((s: any)=>s.search);
  const dispatch = useDispatch();
  const [state, setter] = useState<Props>({
    parkings: [],
    loading: false,
    error: false,
  });

  const setState = (obj: any) => setter({ ...state, ...obj });

  const getParkings = async (q = query) => {
   if(query.lat !== -1 && query.long !== -1){
    reset();
    setState({ loading: true, error: false });
    const res = await asyncHandler({
      apiCall: () => API.getNearbyParkings(q),
    });
    const { success, parkings } = res;
    if(success && parkings?.length > 0){
      //onResults(parkings[0].geo);
    }
    setState({ loading: false, error: !success, parkings: parkings || [] });
  }
  };
  const { reset } = useTimedInterval(() => getParkings(), 5);


  useEffect(() => {
    getParkings();
  }, [query]);

  return {
    ...state,
    getParkings,
    reload: getParkings
  };
};

export default useNearbyParking;