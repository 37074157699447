import React from "react";
import { Grid, Button } from "platyplex_ui";

const shareSources = (link: string, body = '') => [
    {
        faIcon: 'fa fa-envelope',
        shareUrl: `mailto:?subject=ParkStash Garage Link&body=${body} ${link}`,
        label:  'Email'
    },
    {
        faIcon: 'fab fa-facebook-f',
        shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${link}&t=${body}`,
        label: 'Facebook'
    },
    {
        faIcon: 'fab fa-twitter',
        shareUrl: `http://twitter.com/share?url=${link}&text=${body}`,
        label: 'Twitter'
    },
    {
        faIcon: 'fab fa-linkedin',
        shareUrl: `https://www.linkedin.com/sharing/share-offsite/?url=${link}`,
        label: 'LinkedIn'
    },
    {
        faIcon: 'fab fa-tumblr',
        shareUrl: `https://www.tumblr.com/widgets/share/tool?shareSource=legacy&canonicalUrl=${link}&posttype=link`,
        label: 'Tumblr'
    }
];

const ShareSources = ({ link, body}: any)=>{
    const shares = shareSources(link, body)
    return    <Grid width='100%' customCols={`repeat(${shares.length}, auto)`}>
        {shares.map((props)=>(<ShareBtn {...props}/>))}
    </Grid>
}

const ShareBtn = ({ faIcon,  shareUrl, label }: { faIcon: string, shareUrl: string, label: string }) => {
    const openWindow = (shareUrl: string) => {
        window.open(
            shareUrl,
            "",
            "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        )
    }
    return (
        <Grid gridGap="0">
            <Button
                rounded
                invert
                faIcon={faIcon}
                onClick={() => openWindow(shareUrl)}
                minWidth="3em"
            />
            {label}
        </Grid>
    );
}

export default ShareSources;