import React from "react";
import { Grid, Placeholder, List, Empty } from "platyplex_ui";
import Booking from "./booking";
import { BookingHistory } from "./type";

const BookingList = ({ bookings, loading,  type , user, reload, selectBooking, extend}: any) => {
  
  return (
    <Grid
      placeItems="stretch"
      padding="1em"
      height="100%"
      customCols={loading || bookings?.length > 0? '1fr 1fr 1fr': '1fr'}
      customRows={bookings?.length === 0? "auto auto": ''}
    >
        <List
          empty={<Empty text='No Bookings'/>}
          loading={loading}
          loader={<Placeholder number={5} style={{ width: "100%" }} />}
          list={bookings}
          map={(booking: BookingHistory, i: number) =>
            <Booking
              key={i}
              booking={booking}
              type={type}
              extend={extend}
              user={user}
              reload={reload}
              select={() => selectBooking(booking._id)}
            />}
        />
    </Grid>
  );
};

export default BookingList;