import React from "react";
import Layout from "../../layout";
import { Grid } from "platyplex_ui";
import useParking from "../../hooks/parkings";
import { Analytics as AnalyticsItem } from "./analytics";

const Analytics = () => {
  const { parkings } = useParking();

  return (
    <Layout sidebar style={{ gridTemplateRows: "1fr auto" }}>
      <Layout.Top></Layout.Top>
      <Layout.Bottom>
        <Grid placeItems="start stretch" padding="1em" height="100%">
          <AnalyticsItem parkings={parkings} />
        </Grid>
      </Layout.Bottom>
    </Layout>
  );
};

export default Analytics;
