import { DatePicker, message, Radio } from "antd";
import { Grid, Text, Button, Notification, List, Modal } from "platyplex_ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/overlay-wrapper";
import { selectCard } from "../../../../store/actions/card";
import { getPaymentLogo } from "../../../../utils/payment-logo";
import Bar from "../../../checkout/components/bar";
import CardForm from "../../../checkout/components/payment/card-form";
import Recharge from "../../../../components/recharge";
import { BookingExtensionReview, CreditCard } from "../../type";
import NewModal from "../../../../components/new-modal";
import { Switch } from "antd";
import { PaymentMethod } from "../../../checkout/components/payment";
import { LAYER, primaryColor } from "../../../../config";

interface Props {
 paymentMethod: PaymentMethod;
 setPaymentMethod: (s: PaymentMethod)=> void,
 review?: BookingExtensionReview
}
const Payment = ({ paymentMethod, setPaymentMethod, review }: Props) => {
    const dispatch = useDispatch();
    const { cards, loading, selectedCard } = useSelector(
      (state: any) => state.cards
    );
    const { details } = useSelector((s: any) => s.user);
  
    const CardModal = NewModal({
      modal: <CardForm close={() => CardModal.close()} />,
    });
  
    const RechargeModal = NewModal({
      modal: (
        <Wrapper
          style={{
            position: "fixed",
            top: 0,
            width: "100vw",
            height: "100vh",
            zIndex: LAYER.last,
          }}
          back={() => RechargeModal.close()}
        >
          <Recharge back={() => RechargeModal.close()} />
        </Wrapper>
      ),
    });
  
    return (
      <Grid gridGap=".75em" placeItems="start" placeSelf="stretch">
        {RechargeModal.modal}
        {CardModal.modal}
        
        <Text bold>Payment Method</Text>
        {paymentMethod === "wallet" ? (
            <Text>
              Your savings with wallet: <span className='bold' style={{color: primaryColor}} >{review?.save_with_wallet_today_amount}</span>
            </Text>
          ) : (
            <Text>
              Save&nbsp;
              <span style={{ color: primaryColor }}>
                {review?.save_with_wallet_today_percent}
              </span>
              &nbsp;by using ParkStash Wallet
            </Text>
          )}

          {paymentMethod === "wallet" ?
          <Text>That's <span className='bold' style={{color: primaryColor}} >{review?.save_with_wallet_today_percent} </span> savings!</Text>:
          <Text>
            Amount Due with Wallet: &nbsp;
            <span style={{ color: "red", textDecoration: "line-through" }}>
              {review?.total_with_card}
            </span>{" "}
            &nbsp;
            <span style={{ color: primaryColor }}>
              {review?.total_with_wallet}
            </span>
            <Text></Text>
          </Text>}
        <Text>
          ParkStash Wallet Balance:{" "}
          {details?.parkstash_wallet_v1_pretty?.non_payoutable}
          &nbsp;{" "}
          <Switch
            checked={paymentMethod === "wallet"}
            onChange={(e) => setPaymentMethod(e ? "wallet" : "card")}
          />
        </Text>
  
        <Text
          color="blue"
          className="pointer noselect"
          onClick={RechargeModal.open}
        >
          Recharge my Wallet
        </Text>
  
        <Bar />
        <Grid customCols="1fr auto">
          <List
            empty={
              <Text color="red">
                No payment method present. Add a payment method to pay and book.
              </Text>
            }
            list={cards}
            map={(card: CreditCard, i: number) => (
              <React.Fragment key={i}>
                <Text>
                  <img
                    src={getPaymentLogo(card.brand)}
                    style={{ height: 25 }}
                    alt="payment logo"
                  />{" "}
                  {`${card.brand} ending in ${card.last4}`}{" "}
                </Text>
                <Radio
                  checked={
                    selectedCard?.id === card.id && paymentMethod !== "wallet"
                  }
                  onClick={() => {
                    dispatch(selectCard(card));
                    setPaymentMethod("card");
                  }}
                />
              </React.Fragment>
            )}
          />
        </Grid>
        <Text color="blue" className="pointer" onClick={CardModal.open}>
          Add a Card
        </Text>
      </Grid>
    );
  };

  export default Payment;