import { Grid, Text } from "platyplex_ui";
import React from "react";
import { disabledTxtColor } from "../../../config";
import { InputNumber, Switch } from "antd";
import useStrings from "../../../hooks/strings";

interface Deposit {
  active: boolean;
  price: number;
 
}

interface Props {
  securityDeposit: Deposit;
  currency: string;
  setSecurityDeposit: (d: Deposit) => void;
}

const SecurityDeposit = ({ securityDeposit, setSecurityDeposit, currency }: Props) => {
  const handleChange = (props: Partial<Deposit>) => {
    setSecurityDeposit({
      ...securityDeposit,
      ...props,
    });
  };
  const { strings } = useStrings();
  return (
    <Grid
      margin=".6em 0"
      placeSelf="stretch"
      placeItems="center"
      customCols="1fr auto auto"
    >
      <Text textAlign="left" placeSelf="start" bold>
        Security Deposit
      </Text>
      <span>
        {currency === 'CAD'? 'C$': '$'}&nbsp;
        <InputNumber
          min={0}
          disabled={!securityDeposit.active}
          value={securityDeposit?.price / 100}
          step={1}
          onChange={(price: any) => handleChange({ price: price * 100 })}
          style={{ placeSelf: "center" }}
        />
      </span>
      <Switch
        checked={securityDeposit.active}
        onChange={(active) => handleChange({ active })}
      />
      <Text
        textAlign="left"
        placeSelf="start"
        style={{ gridColumn: "1/-1", color: disabledTxtColor }}
      >
        {strings?.listing_securityDeposit}
      </Text>
      <Text textAlign="left">
        <a href="https://www.findparkstash.com/security-deposits">
          Learn more about how we handle security deposit
        </a>
      </Text>
    </Grid>
  );
};

export default SecurityDeposit;
