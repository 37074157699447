import React, { useState } from "react";
import styled from "styled-components";
import { Grid, Text as Textt, Avatar as Avatarr, Card, Modal } from "platyplex_ui";
import {
  cloudinaryPrefix,
  mobileWidth,
  primaryTxtColor,
  top2,
} from "../../config";
import { Popover, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/parkstash_logo.png";
import user from "../../assets/user.svg";
import { logout, ssoLogout } from "../../store/actions/user";
import ParkstashText from "../parkstash-text";
import Sidebar from "../sidebar";
import Icon from "../../assets/AccountMenuIcon.png";
import Avatar from "../avatar";
import { Link } from "react-router-dom";
import PrimaryModal from "../primary-modal";
import { useHistory } from 'react-router';
const TopNavBar = () => {
  const { details, userState } = useSelector((state: any) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const InfoModal = Modal({
    title: "",
    modal: (
      <PrimaryModal
      onCancel={() => InfoModal.close()}
      onConfirm={() => {window.location.href = "https://www.findparkstash.com";}}
    >
      <Grid gridGap=".25em" padding="1em">
        <Textt bold>Taking you to ParkStash Landing Page</Textt>
      </Grid>
    </PrimaryModal>
    ),
    hideCancel: true,
  });

  return (
    <MiniNav>
      <Sidebar />
      <Link to="/" style={{ placeSelf: "center start" }}>
        <>
          <Text
            className="capitalize bold txt-md txt-left"
            style={{ minWidth: "100px", width: "100%" }}
          >
            <Avatarr
              url={logo}
              style={{ fontSize: ".7em", objectFit: "contain", width: "auto" }}
            />
            <ParkstashText />
          </Text>
        </>
      </Link>
      <Textt
        className="pointer hide-mobile"
        bold
        onClick={InfoModal.open}
        style={{ color: primaryTxtColor, marginRight: "3em" }}
      >
        Home
      </Textt>
      <Popover
        trigger={["hover", "click"]}
        placement="bottomRight"
        content={
          <Menu style={{ zIndex: top2 }}>
            { (
              <Menu.ItemGroup>
                <Menu.Item className="noselect" key="0">
                  <Link to="/profile">
                    <Textt textAlign="left">Account</Textt>
                  </Link>
                </Menu.Item>

                <Menu.Item className="noselect" key="1">
                  <Link to="/help">
                    <Textt textAlign="left">Help</Textt>
                  </Link>
                </Menu.Item>

                <Menu.Item className="noselect" key="2">
                  {details?.is_guest?
                    <Textt
                    textAlign="left"
                    onClick={() => {
                      history.push('/login')
                    }}
                  >
                    Login/Signup
                  </Textt>:
                   <Textt
                    textAlign="left"
                    onClick={() => {
                      dispatch(ssoLogout());
                    }}
                  >
                    Logout
                  </Textt>}
                </Menu.Item>
              </Menu.ItemGroup>
            )}
          </Menu>
        }
      >
        <Grid
          mobileLayout={false}
          customCols="auto auto"
          className="shadow pointer"
          style={{ borderRadius: "5em", padding: ".25em .5em" }}
          gridGap=".5em"
        >
          <img
            style={{ maxWidth: "30px", paddingLeft: "5px" }}
            alt="icon"
            src={Icon}
          />
          <Avatar
            style={{ borderRadius: "50%", background: "white" }}
            notCloudinaryImg
            src={
              details?.image_url ? cloudinaryPrefix + details?.image_url : user
            }
          />
        </Grid>
      </Popover>
    </MiniNav>
  );
};

const Text = styled.div`
  @media (max-width: ${mobileWidth}) {
    padding-left: 0;
    font-size: 1.2em;
  }
`;

const MiniNav = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  font-size: 15px;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 1.5em 0 0;
  z-index: 100;
  width: 100vw;
  height: 4em;
  background: white;
  color: white;
  width: 100vw;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: auto 1fr auto auto auto;
  grid-template-rows: 1fr;
  place-items: center end;
  @media (max-width: ${mobileWidth}) {
    top: inherit;
    padding: 0;
    grid-gap: 0.5em;
  }
`;

export default TopNavBar;
