import { DatePicker } from "antd";
import { Card, Grid, Text } from "platyplex_ui";
import React, { useState } from "react";
import { primaryColor } from "../../../../config";
import { Review } from "../../type";
import Bar from "../bar";
import moment from "moment";
import { Link } from "react-router-dom";
import { PaymentMethod } from "../payment/index";
import { Parking } from "../../../../model/domain/parking";
import ListingDetails from "../../../../components/listing-details";

interface Props {
  review: Review;
  reviewQuery: any;
  paymentMethod: PaymentMethod;
  parking?: Parking;
}

const Summary = ({ review, reviewQuery, paymentMethod }: Props) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Card shadow>
      <Grid customCols="1fr auto" placeItems="center stretch">
        <Text textAlign="left" bold style={{ fontSize: "1.5em" }}>
          Summary
        </Text>
      </Grid>
      <Grid customCols="1fr auto 1fr" margin="1em 0">
        <Grid gridGap=".25em" placeItems="stretch">
          <Text bold textAlign="left">
            Parking From
          </Text>
          {!editMode ? (
            <Text color={"gray"} textAlign="left" style={{ maxWidth: 150 }}>
              {review.start}
            </Text>
          ) : (
            <DatePicker value={moment(reviewQuery.start_date)} />
          )}
        </Grid>

        <i
          style={{ color: primaryColor }}
          className="fas fa-arrow-right txt-md"
        />

        <Grid gridGap=".25em" placeItems="stretch">
          <Text bold textAlign="left">
            Parking Until
          </Text>
          {!editMode ? (
            <Text color={"gray"} textAlign="left" style={{ maxWidth: 150 }}>
              {review.end}
            </Text>
          ) : (
            <DatePicker value={moment(reviewQuery.end_date)} />
          )}
        </Grid>
      </Grid>

      <ListingDetails paymentMethod={paymentMethod} review={review} />
      <Grid customCols="1fr auto" placeItems="start" gridGap=".25em">
        <Bar />
        <Bar />

        <Text
          bold
          style={{
            gridColumn: "1/-1",
            marginTop: ".5em",
            paddingTop: ".5em",
          }}
        >
          {" "}
          Total
        </Text>
        <Text>With Debit/Credit Card</Text>
        <Text>{review?.total_with_card_with_security_deposit}</Text>

        <Text>With ParkStash Wallet</Text>
        <Text>
          <span style={{ color: "red", textDecoration: "line-through" }}>
            {review?.total_with_card_with_security_deposit}{" "}
          </span>
          <span style={{ color: primaryColor }}>
            {" "}
            {review?.total_with_wallet_with_security_deposit}
          </span>
        </Text>
        <Text bold>Total Amount Due:</Text>
        <Text bold>
          {paymentMethod === "card"
            ? review?.total_with_card_with_security_deposit
            : review?.total_with_wallet_with_security_deposit}
        </Text>

        <Link
          target="_"
          style={{ gridColumn: "1/-1" }}
          to="/wallet/parkstash-wallet"
        >
          <Text color="blue"> Recharge my ParkStash Wallet</Text>
        </Link>
      </Grid>
    </Card>
  );
};

export default Summary;
