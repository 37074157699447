import { Vehicle } from "../../pages/booking-history/type";

interface State {
  vehicles: Vehicle[];
  selectedVehicle?: Vehicle;
  selectedVehicles: Vehicle[];
  error: boolean;
  loading: boolean;
}
interface VehicleActions {
  type: ActionTypes;
  payload: Partial<State> & {
    vehicle : Vehicle;
  };
}

const intialState : State = {
  vehicles: [],
  selectedVehicle: undefined,
  selectedVehicles: [],
  error: false,
  loading: false,
};

export enum ActionTypes {
  ADD_VEHICLE = "ADD_VEHICLE",
  REMOVE_MULTIPLE_VEHICLE = "REMOVE_MULTIPLE_VEHICLE",
  SELECT_MULTIPLE_VEHICLE = 'SELECT_MULTIPLE_VEHICLE',
  EDIT_VEHICLE = "EDIT_VEHICLE",
  REMOVE_VEHICLE = "REMOVE_VEHICLE",
  LOAD_VEHICLE = "LOAD_VEHICLE",
  SELECT_VEHICLE = "SELECT_VEHICLE",
  REQUEST_VEHICLE = "REQUEST_VEHICLE",
  ERROR_VEHICLE = "ERROR_VEHICLE",
  SUCCESS_VEHICLE = "SUCCESS_VEHICLE",
}

export default function rootReducer(
  state = intialState,
  action: VehicleActions
): any {
  switch (action.type) {
    case ActionTypes.LOAD_VEHICLE:
      const [selectedVehicle] = (action.payload?.vehicles)?.filter(({ is_default_for_user}: any)=> is_default_for_user) as any;
      return { ...state, vehicles: action.payload.vehicles, selectedVehicle, selectedVehicles: selectedVehicle? [selectedVehicle]: [] };

    case ActionTypes.SELECT_VEHICLE:
      return { ...state, selectedVehicle: action.payload.vehicle };

    
      case ActionTypes.SELECT_MULTIPLE_VEHICLE:
        let selectedVehicles = Array.from(new Set([...state.selectedVehicles, state.selectedVehicle, action.payload.vehicle]));
        return { ...state, selectedVehicles };
    case ActionTypes.REMOVE_VEHICLE:
      return { ...state, vehicles: state.vehicles.filter((v: Vehicle)=> v._id !== action.payload.vehicle._id) };
      
      case ActionTypes.REMOVE_MULTIPLE_VEHICLE:
        return { ...state, selectedVehicles: state.selectedVehicles.filter((v: Vehicle)=> v._id !== action.payload.vehicle._id) };
        
      case ActionTypes.EDIT_VEHICLE:
        return { ...state };

    case ActionTypes.ERROR_VEHICLE:
      return { ...state, error: true, loading: false };

    case ActionTypes.REQUEST_VEHICLE:
      return { ...state, error: false, loading: true };

    case ActionTypes.SUCCESS_VEHICLE:
      return { ...state, loading: false };
    // case ActionTypes.ADD_VEHICLE:
    //   return { ...state, vehicles: [...state.vehicles, action.payload.vehicle] };

    default:
      return state;
  }
}
