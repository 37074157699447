import { useEffect } from "react";


const useCloseConfirm = () => {
    useEffect(()=>{
        let ask = true;
        window.onbeforeunload = function (e: any) {
          if(!ask) return null
          e = e || window.event;
          //old browsers
          if (e) {e.returnValue = 'Leave Site?';}
          //safari, chrome(chrome ignores text)
          return 'Leave Site?';
        };
    
        return ()=> {
          ask = false;
        }
      }, [])  
};


export default useCloseConfirm;
