import React from "react";
import { Input } from "antd";
import { primaryTxtColor } from "../../config";

interface Props {
  onChange: (e: any) => void;
  max: number;
  value: number;
}
const PageInput = ({ onChange, max, value }: Props) => {
  const onPageChange = (e: any) => {
    const page = Number(e.target.value);
    const isValidPage = !!page;
    const isInRange = page > 0 && page < max + 1;
    if (isValidPage && isInRange) {
      onChange(page);
    } else if (e.target.value === "") {
      onChange(undefined);
    }
  };
  return (
    <Input
      value={value}
      onChange={onPageChange}
      style={{
        display: "inline-block",
        width: 25,
        fontSize: 18,
        fontWeight: 'bold',
        padding: 0,
        background: "transparent",
        color: primaryTxtColor,
      }}
    />
  );
};

export default PageInput;
