import { post, authGet, authPost, authDelete } from "./utils/request";
import { API } from "../config";
const route = (r: string) => API + r;

const payment = {
  payout: (amount: number, payoutMethodId: string) =>
    authPost(route(`/v1/payout`), { amount, payoutMethodId }),

  removeBank: (_id: string) =>
    authPost(route("/v1/payoutMethods/remove"), { _id } as any),

  addPayoutMethod: (type: string, token: string) =>
    authPost(route("/v1/payoutMethods/add"), { type, token }),

  getPayoutMethods: () =>
    authPost(route(`/v1/payoutMethods/list`), { includeDeleted: false }),
  addPayment: (stripe_token: string) =>
    authPost(route("/payment-sources/card"), { stripe_token }),

  removePayment: (card_id: string) =>
    authDelete(route("/payment-sources/card"), { card_id } as any),

  addBank: (stripe_token: string) =>
    authPost(route("/payment-sources/payout-account"), { stripe_token }),

  addStripe: (stripe_token: string) =>
    authPost(route("/payment-sources/stripe-account"), { stripe_token }),
    
 getCards: () => authGet(route(`/payment-sources/card`))
};

export default payment;
