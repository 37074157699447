import { authDelete, authGet, authPost, authPut } from "./utils/request";
import { API } from "../config";

const route = (r: string) => API + r;

let vehicles = {
    getVehicles: () => authGet(route(`/vehicles`)),
    addVehicle : (vehicle: any) => authPost(route(`/vehicles`), vehicle),
    editVehicle : (vehicle: any, id: string) => authPut(route(`/vehicles/${id}`), vehicle),
    removeVehicle : (id: string) => authDelete(route(`/vehicles/${id}`)),
    setDefaultVehicle : (id: string) => authPost(route(`/vehicles/${id}/set-default`), {})
};

export default vehicles;
