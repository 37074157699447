import React, { useEffect, useState } from "react";
import { Grid, Text } from "platyplex_ui";
import { Switch, Select, TimePicker, Collapse } from "antd";
import moment, { Moment } from 'moment';
import { Availability } from "../../../model/domain/parking";
import dailyParking from "../../../utils/daily-parking";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface DailyParking {
  time: { startHour: number[]; endHour: number[]; active: boolean; }[];
}
interface Props {
  setAvailability: (a: string) => void;
  availability: Availability | string;
  daily_parking: DailyParking;
  setDailyParking: (d: DailyParking) => void;
  collapsable?: boolean;
  dailyParkingStyle?: React.CSSProperties;
}
const AvailabilitySelector = ({
  setAvailability,
  availability,
  daily_parking,
  setDailyParking,
  collapsable,
  dailyParkingStyle,
}: Props) => {
  const parking = (
    <DailyParking
      disabled={availability === 'always'}
      daily_parking={daily_parking}
      setDailyParking={setDailyParking}
      dailyParkingStyle={dailyParkingStyle}
    />
  );

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (availability === 'always' && !initialLoad) {
      setDailyParking({ time: dailyParking.always })
    } else if (availability === 'partial' && !initialLoad) {
      setDailyParking({ time: dailyParking.workdays })
    }

    setInitialLoad(false)
  }, [availability])
  return (
    <>
      <Grid
        margin=".6em 0"
        placeSelf="stretch"
        placeItems="stretch"
        gridGap="0"
        customCols="1fr auto"
        mobileLayout={false}
      >
        <Text textAlign="left" placeSelf="start" bold>
          Choose any availability:
        </Text>
        <Select
          style={{ minWidth: 150 }}
          value={availability}
          onChange={setAvailability}
        >
          <Select.Option value="always">Available 24/7</Select.Option>
          <Select.Option value="partial">Specific Day</Select.Option>
        </Select>
      </Grid>
      {true &&
        (collapsable ? (
          <Collapse defaultActiveKey={[]}>
            <Collapse.Panel header="Select available day and time" key="1">
              {parking}
            </Collapse.Panel>
          </Collapse>
        ) : (
          parking
        ))}
    </>
  );
};

export const DailyParking = ({
  daily_parking,
  disabled,
  setDailyParking,
}: {
  daily_parking: any;
  disabled?: boolean;
  setDailyParking: (value: any) => void;
  dailyParkingStyle?: React.CSSProperties;
}) => {
  const changeDailyParking = (newValue: any, i: number) => {
    const time = [...daily_parking.time];
    time[i] = { ...time[i], ...newValue };
    setDailyParking({ time });
  };
  return (
    <Grid width={"100%"} style={{ gridGap: 0 }} placeSelf="stretch">
      {["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].map((day, i) => {
        const format = "hh:mm A";
        const time = daily_parking.time[i];
        const startTime = moment(
          `${time.startHour[0]}:${time.startHour[1]}`,
          format
        );
        const endTime = moment(`${time.endHour[0]}:${time.endHour[1]}`, format);
        return (
          <Grid
            customCols="auto auto 1fr"
            placeItems="stretch"
            width="100%"
            key={i}
            mobileLayout={false}
          >
            <Switch
              disabled={disabled}
              style={{ placeSelf: "start" }}
              onChange={(active) => changeDailyParking({ active }, i)}
              checked={time.active}
            />
            <Text>{day}</Text>
            <Grid
              style={{ marginBottom: "1em" }}
              customCols="1fr auto auto auto"
              gridGap=".7em"
            >
              <span/>
<div>
              <DatePicker
        
      selected={startTime.toDate()}
        onChange={(d: Date | null) => {
          if (!d) return;
          const m = moment(d)
          const hour = m.hour();
          const minute = m.minute();
          changeDailyParking({ startHour: [hour, minute] }, i);
        }}
               showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="h:mm aa"
    />
    </div>
            
              to
              <div>
<DatePicker
      selected={endTime.toDate()}
        onChange={(d: Date | null) => {
          if (!d) return;
          const m = moment(d)
          const hour = m.hour();
          const minute = m.minute();
          changeDailyParking({ endHour: [hour, minute] }, i);
        }}
               showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="h:mm aa"
    />
</div>
              {endTime <= startTime && (
                <Text color="red" style={{ gridColumn: "-1/1" }}>
                  Start time cannot be greater than end time
                </Text>
              )}
            </Grid>

          </Grid>
        );
      })}
    </Grid>
  );
};

export default AvailabilitySelector;
