import React, { useEffect, useState } from "react";
import { Grid, Text } from "platyplex_ui";
import { Popover, DatePicker } from "antd";
import { disabledTxtColor2 } from "../../../../config";
import CloseBtn from "../../../../components/close-btn";
import RangePicker from "../../../../components/range-picker";
import { useDispatch, useSelector } from "react-redux";
import { setQuery } from "../../../../store/actions/search";
import { message } from "antd";
import moment, { Moment } from 'moment';

interface State {
  start_date: Moment;
  end_date: Moment;
}

const DateSelector = () => {
  const { query } = useSelector((state: any) => state.search);
  const dispatch = useDispatch();

  const [date, setDate] = useState<State>({
    start_date: moment(),
    end_date: moment().add("hours", 2),
  });

  const [initialDate] = useState(date);
  const [visible, setVisible] = useState(false);

  const needHours = query.booking_type === "daily";

  const submitDate = () => {
    const { start_date: s, end_date: e } = date;
    const time = needHours
      ? { start_hour: s?.format("HH:mm"), end_hour: e?.format("HH:mm") }
      : undefined;

    dispatch(
      setQuery({
        start_date: s.format("YYYY-MM-DD"),
        end_date: e.format("YYYY-MM-DD"),
        ...time,
      })
    );
  };

  const dateFormat = needHours ? "ddd, MMM DD, hh:mm A" : "ddd, MMM DD, YYYY";
  
  const getEndDate = (booking_type: string, start_date: Moment) => {
    const end_date = moment(start_date);
    if (booking_type === "daily") {
      end_date.add("hours", 2);
    } else if (booking_type === "weekly") {
      end_date.add("days", 6);
    } else {
      end_date.add("days", 29);
    }
    return end_date;
  };

  const handleTimeChange = (field: string, time: Moment | null) => {
    if (!time) return;
    if (field === "start") {
      setDate({
        end_date: getEndDate(query.booking_type, time),
        start_date: time,
      });
    } else setDate({ ...date, end_date: time });
  };

  const error = () => {
    const diffrence = moment.duration(date.end_date.diff(date.start_date));
    let e = date.start_date > date.end_date;

    if (query.booking_type !== "daily") {
      e = e || diffrence.days() < 1;
    }
    return e;
  };


  useEffect(() => {
    setDate({
      ...date,
      end_date: getEndDate(query.booking_type, query.start_date),
    });
  }, [query.booking_type]);

  useEffect(() => {
    handleTimeChange('start', moment())
  }, []);

  return (
    <Grid
      padding=".5em"
      style={{
        borderLeft: "2px solid " + disabledTxtColor2,
        paddingLeft: "1.5em",
      }}
    >
      <Popover
        placement="bottom"
        visible={visible}
        content={
          visible && (
            <>
              <CloseBtn
                close={() => {
                  setVisible(false);
                  setDate(initialDate);
                  message.error("Date selected not saved");
                }}
                right
              />
              <RangePicker
                handleTimeChange={handleTimeChange}
                date={date}
                error={error}
                submitDate={() => {
                  submitDate();
                  setVisible(false);
                }}
                hideTimePicker={query.booking_type !== "daily"}
              />
            </>
          )
        }
      >
        <span onClick={() => setVisible(true)}>
          <Grid customCols="repeat(2, auto)" mobileLayout={false}>
            <Grid
              gridGap=".25em"
              customCols="auto auto"
              className="dates"
              mobileLayout={false}
            >
              <Text bold className='hide-mobile'>From </Text>
              <DatePicker
                style={{
                  background: disabledTxtColor2,
                  borderRadius: "2em",
                  maxWidth: "180px",
                }}
                open={false}
                value={date.start_date}
                format={dateFormat}
              />
            </Grid>

            <Grid
              gridGap=".25em"
              customCols="auto auto"
              className="dates"
              mobileLayout={false}
            >
              <Text bold className='hide-mobile'>To </Text>
              <DatePicker
                style={{
                  background: disabledTxtColor2,
                  borderRadius: "2em",
                  maxWidth: "180px",
                }}
                open={false}
                value={date.end_date}
                format={dateFormat}
              />
            </Grid>
          </Grid>
        </span>
      </Popover>
    </Grid>
  );
};

export default DateSelector;
