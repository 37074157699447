//The main exports of this files are loading graph data
//and a convinient hook wrapper around that.
import * as CH from '@pstash/charts';
import * as TS from '@pstash/model/domain/timeseries';
import { useState, useEffect } from "react";
import api from '../../api'

export interface GraphDataRequestItem
{
  key: string;
  unit: string;
  color: string;
  metricType: TS.MetricType_E;
  reducerFunction: TS.ReducerFunction;
  metricSpecificParameter: TS.MetricSpecificParameter;
}

export interface GraphDataRequest
{
  binSize: number;
  binUnit: TS.BinUnit_E;
  tz: string;
  intervalPreset: TS.IntervalPreset;
  customInterval?: { t0: Date; t1: Date; };
  graphDataRequestItems: GraphDataRequestItem[];
  setMissingValueTo?: number | null;
}

export interface LoadGraphDataStatus
{
  error: boolean,
  loading: boolean,
  data: CH.GraphData | null
}

export async function loadGraphData(param: GraphDataRequest): Promise<CH.GraphData>
{
  let graphDataRequestItems = param.graphDataRequestItems;

  if (!graphDataRequestItems.length)
  {
    
    graphDataRequestItems = [{
      key: '',
      unit: 'none',
      color: '#ffffff',
      metricType: TS.MetricType_E.mock,
      reducerFunction: TS.ReducerFunction.min,
      metricSpecificParameter: {
        mock: {
          min: 0,
          max: 0,
          floats: false
        }
      }
    }];
  }

  const apiParams: TS.TimeseriesRequest[] = graphDataRequestItems.map(g =>
  ({
    metricType: g.metricType,
    binSize: param.binSize,
    binUnit: param.binUnit,
    tz: param.tz,
    intervalPreset: param.intervalPreset,
    customInterval: param.customInterval,
    reducerFunction: g.reducerFunction,
    metricSpecificParameter: g.metricSpecificParameter
  }));

  const tsResults = await Promise.all(apiParams.map(api.getTimeseries));

  let missingValue: null | number = null;
  if (param.setMissingValueTo || param.setMissingValueTo == 0)
  {
    missingValue = param.setMissingValueTo;
  }

  const dates = tsResults[0].points.map(d => d.at.valueOf());
  const description = graphDataRequestItems.map(r =>
  ({
    key: r.key,
    unit: r.unit,
    color: r.color,
  }));

  const points2d = tsResults.map(tsResult =>
  {
    const points = tsResult.points.map(p => 
    {
      const hasValue = !!p.value || p.value == 0;
      const value = hasValue? p.value: missingValue;
      return value;
    });
    return points;
  });

  return {
    dates,
    points2d,
    description,
    binSize: param.binSize,
    binUnit: param.binUnit,
    tz: param.tz
  };
}

//NOTE: eslint-disable below is due to the linter rule that says the react
//      hooks has to start with use*** keyword
/* eslint-disable*/
export function hook_loadGraphData(param: GraphDataRequest)
{
  const [request, setRequest] = useState(param);
  const [refreshId, triggerRefresh] = useState(0);
  const [graphDataStatus, setGraphDataStatus] = useState<LoadGraphDataStatus>({
    error: false,
    loading: true,
    data: null
  });

  const load = async (param: GraphDataRequest) => 
  {
    try 
    {
      const result = await loadGraphData(param);
      setGraphDataStatus({ loading: false, error: false, data: result });
    } 
    catch (e) 
    {
      console.error(e);
      setGraphDataStatus({
        loading: false,
        error: true,
        data: null
      });
    }
  };
  
  useEffect(() => { load(param); }, [refreshId]);
  
  return {
    graphDataStatus,
    refresh: (param?: GraphDataRequest) =>
    {
      if (param) setRequest(param);
      setTimeout(() =>
      {
        //NOTE: don't know what happens if there are two consecutive setState
        //calls. There might be race condition, this setTimeout is intended
        //to prevent that.
        triggerRefresh(refreshId + 1);
      });
    }
  };
}
/* eslint-enable*/