import { authGet, authPost } from "./utils/request";
import { API } from "../config";

const route = (r: string) => API + r;

let wallet = {
  getRecharges: () => authGet(route(`/v1/wallet-recharge/ui-amount-boxes`)),

  reviewWallet: (rechargeAmount: number) =>
    authPost(route(`/v1/wallet-recharge/review-payment`), { rechargeAmount }),

  rechargeWallet: (rechargeAmount: number, cardId: string) =>
    authPost(route(`/v1/wallet-recharge/pay`), { rechargeAmount, cardId }),
};

export default wallet;
