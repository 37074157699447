import React, { useState } from "react";
import Layout from "../../layout";
import { Grid, Text, Button, Notification, Card, Header } from "platyplex_ui";
import { useDispatch, useSelector } from "react-redux";
import { cloudinaryPrefix, gradient } from "../../config";
import { Input, Upload } from "antd";
import PhoneInput from "react-phone-input-2";
import NewModal from "../../components/new-modal";
import VehicleModal from "../../components/vehicle-modal";
import { logout, reloadUserDetails } from "../../store/actions/user";
import { getBase64 } from "../../utils/images";
import api from "../../api";
import defaultImage from '../../assets/user.svg'

const Profile = () => {
  const {details } = useSelector((s: any) => s.user);
  const dispatch = useDispatch();
  const VehiclesModal = NewModal({
    modal: <VehicleModal close={() => VehiclesModal.close()} />,
  });
  const [state, setState] = useState({ loading: false });
  const uploadImage = async (e: any) => {
    setState({ loading: true });
    try {
      const file = e?.fileList[e?.fileList?.length - 1]?.originFileObj;
      if (!file) return;
      const str = (await getBase64(file)) as any;
      if (!str) return;
      await api.addProfileImage(str).then((res: any) => {
        if (res.success) {
          // Notification.success({ title: 'Success', message: 'Picture Successfully Uploaded'});
          dispatch(reloadUserDetails());
        } else throw new Error("Error");
      });
    } catch (error) {
      Notification.error({
        title: "Error",
        message: "Picture was not uploaded",
      });
    } finally {
      setState({ loading: false });
    }
  };
  if (VehiclesModal.modal) return VehiclesModal.modal;
  return (
    <Layout sidebar style={{ gridTemplateRows: "1fr auto" }}>
      <Layout.Top></Layout.Top>
      <Layout.Bottom>
       
        <Grid
          placeItems="start stretch"
          padding="1em"
          height="100%"
          gridGap=".5em"
          style={{
            width: 1200,
            maxWidth: "100vw",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >

          <Card shadow padding={0} grid placeItems="center stretch">
            <Grid
              background={gradient}
              customCols="5em 1fr 5em"
              padding=".5em"
              placeSelf="stretch"
            >
              <span />
              <Header bold color={"white"}>
                Your Profile
              </Header>

              <Text
                color="white"
                bold
                onClick={() => dispatch(logout())}
                className="pointer"
              >
                Logout
              </Text>

              <img
                src={ details.image_url? cloudinaryPrefix + details.image_url: defaultImage}
                alt=""
                style={{
                  width: 150,
                  height: 150,
                  objectFit: "cover",
                  borderRadius: "50%",
                  gridColumn: "1/-1",
                  border: "2px solid white",
                }}
              />
              <div style={{ gridColumn: "1/-1" }}>
                <Upload
                  showUploadList={false}
                  action={undefined}
                  onChange={uploadImage}
                >
                  <Button
                    rounded
                    disabled={state.loading}
                    loading={state.loading}
                  >
                    Change Profile Picture
                  </Button>
                </Upload>
              </div>
            </Grid>
            <Grid padding="1em">
              <div>
                <Text textAlign="left">Name</Text>
                <Input value={details?.user_name} style={{ minWidth: 300 }} disabled />
              </div>

              <div>
                <Text textAlign="left">Email</Text>
                <Input value={details?.user_email} style={{ minWidth: 300 }} disabled />
              </div>

              <div>
                <Text textAlign="left">Phone Number</Text>
                <PhoneInput
                  country={details?.country?.toLowerCase()}
                  value={details?.user_phoneno}
                  disableDropdown
                  disabled
                  countryCodeEditable={false}
                />
              </div>

              <div>
                <Text textAlign="left">Password</Text>
                <Input value={"●●●●●●●●"} style={{ minWidth: 300 }} disabled />
              </div>

              <div>
                <Text textAlign="left">Vehicle Information</Text>
                <Button
                  invert
                  onClick={VehiclesModal.open}
                  style={{ minWidth: 300 }}
                >
                  Add or Edit Vehicle
                </Button>
              </div>
            </Grid>
          </Card>
        </Grid>
      </Layout.Bottom>
    </Layout>
  );
};

export default Profile;
