import { useLocation } from "react-router";
import queryString from "query-string";


function useQuery<Data>() {
    const location = useLocation().search;
    const query: Data = queryString.parse(location) as any;


    return {
     query
    };
}

export default useQuery;
