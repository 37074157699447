import React from "react";
import { Text, Button, Header } from "platyplex_ui";
import { useFormik } from "formik";
import { Input } from "antd";
import { Tooltip } from "antd";
import ParkstashText from "../../../components/parkstash-text";
import { gradient } from "../../../config";
import { Form } from ".";
import SpotType from "../components/spot-type";
import SpaceCount from "../components/space-count";
import Amenities from "../../../components/amenities";
import Bar from "../../checkout/components/bar";
import { AddParkingModel } from "../../../model/domain/parking";

const defaultValue: Partial<AddParkingModel> = {
  spot_type: "garage",
  space_count: 1,
  assigned_parking_spot_start: 1,
  is_spot_number_assignable: false,
  amenities: [],
  name: "",
};

const ListingInfoForm = (props: any) => {
  const { loading } = props;

  const { handleSubmit, handleChange, setFieldValue, values } = useFormik({
    initialValues: { ...defaultValue, ...props.listing },
    onSubmit(values) {
      props.setInfo(values);
    },
  });

  const error = values.name.length < 6;
  return (
    <Form back={props.back} onSubmit={handleSubmit}>
      <Header bold>
        Create your <ParkstashText /> Listing
      </Header>
      {props.progress}
      <div
        style={{
          placeSelf: "stretch",
          display: "grid",
          placeItems: "stretch",
          gridGap: "1em",
        }}
      >
        <SpotType
          setSpotDecription={(e: string) =>
            setFieldValue("spot_type_other_description", e?.toLowerCase())
          }
          spotDescription={values.spot_type_other_description}
          spotType={values.spot_type}
          setSpotType={(e: string) =>
            setFieldValue("spot_type", e.toLowerCase())
          }
        />

        <div>
          <Text textAlign="left" bold>
            Enter your listing's name<span className="red">*</span>
          </Text>
          <div
            style={{
              placeSelf: "stretch",
              display: "grid",
              placeItems: "stretch",
            }}
          >
            <Input
              style={{ placeSelf: "end" }}
              type={"text"}
              name={"name"}
              placeholder="Ex: KFC's Parking Garage"
              suffix={
                <Tooltip title={"Listing must contain at least 6 characters"}>
                  <i
                    className={`fa fa-${
                      error ? "times-circle red" : "check green"
                    }`}
                    style={{ position: "relative" }}
                  ></i>
                </Tooltip>
              }
              value={values.name}
              onChange={handleChange}
            />
          </div>
        </div>
        <SpaceCount
          isAssignable={values.is_spot_number_assignable}
          setIsAssignable={(e: boolean) => setFieldValue("is_spot_number_assignable", e)}
          spaceCount={values.space_count}
          setSpaceCount={(e: number) => setFieldValue("space_count", e)}
          spotStart={values.assigned_parking_spot_start}
          setSpotStart={(e: number)=>
            setFieldValue("assigned_parking_spot_start", e)
          }
        />
        <Bar />
        <Text textAlign="left" placeSelf="start" bold>
          Choose any additional amenities your space has
        </Text>
        <Amenities
          info
          setAmenities={(e: any) => setFieldValue("amenities", e)}
          amenities={values.amenities}
        />
      </div>
      {loading ? (
        <Button
          loading
          disabled
          style={{ borderRadius: "1.3em", padding: ".5em" }}
        >
          Loading
        </Button>
      ) : (
        <Button.Normal
          style={{
            borderRadius: "1.3em",
            padding: ".5em",
            ...(!error
              ? { backgroundImage: `${gradient}`, color: "white", border: 0 }
              : {}),
          }}
          type="submit"
          disabled={error}
          onSubmit={(e: any) => handleSubmit(e)}
        >
          Next
        </Button.Normal>
      )}
    </Form>
  );
};

export default ListingInfoForm;
