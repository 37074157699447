import moment, { Moment } from 'moment';

export const getEndDate = (booking_type: string, start_date: Moment): Moment => {
  const end_date = moment(start_date);
  if (booking_type === "daily") {
    end_date.add("hours", 2);
  } else if (booking_type === "weekly") {
    end_date.add("days", 6);
  } else {
    end_date.add("days", 29);
  }
  return end_date;
};