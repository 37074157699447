import React from "react";
import { Card, Header, Text, Grid, Badge, Button, Modal } from "platyplex_ui";
import {
  cloudinaryPrefix,
  disabledTxtColor,
  primaryColor,
  primaryTxtColor,
} from "../../config";
import { Switch } from "antd";
import useParkings, { ParkingProps } from "../../hooks/parkings";
import useAmenities from "../../hooks/amenities";
import PrimaryModal from "../../components/primary-modal";
import { formatAddress } from "../../utils/address";
import Images from "../../components/images";
import useStrings from "../../hooks/strings";

type Edit = { edit: () => void; remove: () => void; refresh: () => void };

const ListingCard = (props: ParkingProps & Edit) => {
  const {
    address,
    approved,
    active,
    name,
    image_url,
    daily_parking,
    space_count,
    edit,
    extras,
    remove,
    _id,
    refresh,
    amenities,
  } = props;

  const { editListing } = useParkings();
  const prefix = (src: string) =>
    !src.includes(cloudinaryPrefix) ? cloudinaryPrefix : "";

  const { getAmenty } = useAmenities();

  const PauseModal = Modal({
    title: "",
    modal: (
      <PrimaryModal>
        <>
          <Grid gridGap=".25em" padding="1em">
            <Text bold>Pause Listing?</Text>
            <Text>
              Are you sure you want to pause this listing? It won't show up on
              the map anymore. Any active/upcoming booking will still be valid.
            </Text>
          </Grid>
          <Grid cols="2" placeSelf="stretch" placeItems="stretch" gridGap={"0"}>
            <Button
              invert
              bg={primaryTxtColor}
              noBorder
              style={{ boxShadow: `0 0 0` }}
              onClick={() => PauseModal.close()}
            >
              No, keep it alive
            </Button>
            <Button
              invert
              bg={primaryTxtColor}
              noBorder
              style={{ boxShadow: `0 0 0` }}
              onClick={async () => {
                if (await editListing({ active: false, _id })) {
                  refresh();
                }
                PauseModal.close();
              }}
            >
              Yes, pause
            </Button>
          </Grid>
        </>
      </PrimaryModal>
    ),
    hideCancel: true,
  });

  const WarningModal = Modal({
    title: "",
    modal: (
      <PrimaryModal>
        <>
          <Grid gridGap=".25em" padding="1em">
            <Text bold>Consider Pausing Your Listing</Text>
            <Text>
              You can pause this listing instead of permanently deleting it. It
              won't show up on the map anymore. Any active/upcoming booking will
              still be valid.
            </Text>
          </Grid>
          <Grid placeSelf="stretch" placeItems="stretch" gridGap={"0"}>
            <Button
              invert
              bg={primaryTxtColor}
              noBorder
              style={{ boxShadow: `0 0 0` }}
              onClick={async () => {
                if (await editListing({ active: false, _id })) {
                  refresh();
                }
                WarningModal.close();
              }}
            >
              Pause this listing
            </Button>

            <Button
              invert
              bg={primaryTxtColor}
              noBorder
              style={{ boxShadow: `0 0 0` }}
              onClick={() => {
                DeleteModal.open();
                WarningModal.close();
              }}
            >
              I want to permanently delete it
            </Button>
            <Button
              invert
              bg={primaryTxtColor}
              noBorder
              style={{ boxShadow: `0 0 0` }}
              onClick={() => WarningModal.close()}
            >
              Keep it live
            </Button>
          </Grid>
          </>
    </PrimaryModal>
    ),
    hideCancel: true,
  });
  const DeleteModal = Modal({
    title: "",
    modal: (
      <Grid height="100%">
        <Card shadow padding={0} width={"350px"}>
          <Grid gridGap=".25em" padding="1em">
            <Text bold>Permanently Delete Listing?</Text>
            <Text>
              Are you sure you want to permanently delete this listing? It won't
              show up on the map anymore. Any active/upcoming booking will still
              be valid.
            </Text>
          </Grid>
          <Grid cols="2" placeSelf="stretch" placeItems="stretch" gridGap={"0"}>
            <Button
              invert
              bg={primaryTxtColor}
              noBorder
              style={{ boxShadow: `0 0 0` }}
              onClick={() => DeleteModal.close()}
            >
              No, Keep it alive
            </Button>
            <Button
              invert
              bg={primaryTxtColor}
              noBorder
              style={{ boxShadow: `0 0 0` }}
              onClick={async () => {
                remove();
                DeleteModal.close();
              }}
            >
              Yes, delete
            </Button>
          </Grid>
        </Card>
      </Grid>
    ),
    hideCancel: true,
  });
 const { strings } = useStrings();
  const ReviewModal = Modal({
    title: "",
    modal: (
      <PrimaryModal onCancel={()=>ReviewModal.close()} cancelText='Ok'>
          <Grid gridGap=".25em" padding="1em">
            <Text bold>Your listing is in review</Text>
    <Text>{strings?.listing_success_pleaseLookForAnEmail}</Text>
          </Grid>
      </PrimaryModal>
    ),
    hideCancel: true,
  });

  return (
    <Card shadow style={{ maxWidth: "90vw", width: "800px" }}>
      <Grid customCols="auto 1fr" placeItems="start stretch" gridGap=".5em">
        <Grid
          placeSelf="stretch"
          style={{
          //  borderRight: "2px solid " + disabledTxtColor,
            paddingRight: "1em",
            marginRight: "1em",
            gridRow: "span 2",
          }}
        >
          {image_url && image_url[0] ? 
             <Images
             hideIndex={
              image_url?.length
                 ? image_url?.length > 5
                 : true
             }
             images={image_url || []}
             style={{ width: 250, height: 250, minWidth: "100%" }}
           /> : (
            <i
              style={{ fontSize: "280px", color: disabledTxtColor }}
              className="fas fa-parking"
            ></i>
          )}
        </Grid>
        <Grid placeItems="stretch" gridGap=".5em">
          <Grid gridGap="0" placeItems="stretch">
            <Header textAlign="left">
              <Grid customCols="auto 1fr auto" placeSelf="end" gridGap="0">
                <Text bold>{name}</Text>
                <div></div>
                <Switch
                  checked={active}
                  onChange={async (active) => {
                    if(approved === false){
                      ReviewModal.open()
                    }
                    else if (!active) {
                      PauseModal.open();
                    } else if (await editListing({ active, _id })) {
                      refresh();
                    }
                  }}
                />
              </Grid>
            </Header>

            <Text color="gray" textAlign="left"></Text>
          </Grid>
          <Text textAlign="left">
            Status:{" "}
            <span style={{ color: approved === false? 'coral': active ? primaryColor : "coral" }}>
              {approved === false? 'In Review': active ? "Active" : "Paused"}
            </span>
          </Text>
          <Grid
           mobileLayout={false}
            placeSelf="stretch"
            customCols={`auto 1fr`}
            placeItems="start"
            gridGap=".75em"
          >
            <Text> Amenities : </Text>
            <Grid
              placeSelf="stretch"
              customCols={`repeat(10, auto) 1fr`}
              placeItems="start"
              mobileCols={`repeat(4, auto) 1fr`}
              gridGap=".5em"
            >
              {amenities.map((a, i) => {
                const amenity = getAmenty(a);

                if (!amenity) return null;
                return (
                  <img
                    key={i}
                    src={amenity.imageUrl}
                    style={{ width: "25px" }}
                    alt={amenity.displayName}
                  />
                );
              })}
              {amenities.length === 0 && (
                <Badge
                  inverted={space_count}
                  disabled={!space_count}
                  clasName={"grid"}
                >
                  N/A
                </Badge>
              )}
            </Grid>
          </Grid>
          <Text textAlign="left">
            Active Bookings: {extras?.active_bookings_count}
          </Text>
          <Text textAlign="left">
            {(extras?.host_rating_summary?.avg_host_rating || 0).toFixed(1)}{" "}
            <i className="fa fa-star" style={{ color: "gold" }} />
            &nbsp; ({extras?.host_rating_summary?.rating_count})
          </Text>

          <Grid gridGap="0" placeItems="stretch" customCols="auto 1fr"  mobileLayout={false}>
            <Text>Prices :&nbsp; </Text>
            <Text color={primaryColor} textAlign="left">
              {extras?.pretty_rates}
            </Text>
          </Grid>

          <Text textAlign="left">
            <i className="far fa-compass"></i>
            {` ${formatAddress(address)} `}
          </Text>
        </Grid>

        <Grid placeSelf="end stretch" cols="2" placeItems="stretch" gridGap="0"  mobileLayout={false}>
          <Button
            faIcon="fa fa-edit"
            onClick={edit}
            noHover
            bg={"white"}
            style={{ boxShadow: "0 0 0" }}
            invert
          >
            {" "}
            Edit
          </Button>
          <Button
            faIcon="fa fa-trash"
            bg={"white"}
            invert
            noHover
            onClick={WarningModal.open}
            style={{ color: "red", boxShadow: "0 0 0" }}
          >
            {" "}
            Delete
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};


export default ListingCard;
