import { authGet, authPost } from './utils/request';
import { API  } from '../config'
import user from './user';
import parking from './parkings';
import wallet from './wallet';
import payment from './payment';
import booking from './booking';
import events from './events';
import vehicles from './vehicles';
import googlePlaces from './google-places';
import getTimeseries from './timeseries';

const route = (r: string) => API + r;

//Enterprise
const getEnterprise = (lat: number, long: number)=> authGet(route(`/v1/enterprises/search/by-geo?lat=${lat}&long=${long}`));
const getEnterpriseResource = (eId: string, resource: string)=> authGet(route(`/v1/enterprises/${eId}/${resource}`))

// Rating
const getPendingRatings = () => authGet(route(`/host-ratings/pendings`));
const addRating = (props: any) => authPost(route(`/host-ratings`), props);

const addPromo = (promo: any) => authPost(route(`/promos/apply`), promo);
const getConfig = () => authGet(route(`/config`));
const getString = () => authGet(route('/v1/utils/strings'));


export default {
  ...user,
  ...parking, 
  ...wallet,
  ...payment,
  ...booking,
  ...events,
  ...vehicles,
  ...googlePlaces,
  getPendingRatings, addRating, addPromo,
  getConfig, getString, getEnterprise, getEnterpriseResource,
  getTimeseries
}