import React, { useEffect, useState } from "react";
import StripeCard from "../../../components/stripe-card";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Grid, Text, Button, Header, Loader } from "platyplex_ui";
import {
  disabledTxtColor2,
  gradient,
  primaryTxtColor,
  STRIPE_API_KEY,
} from "../../../config";
import Wrapper from "../../../components/overlay-wrapper";

import { useFormik } from "formik";

const stripePromise = loadStripe(STRIPE_API_KEY);

const CardForm = ({ close }: any) => {
  const border = `2px solid ` + disabledTxtColor2;
  const { handleSubmit } = useFormik({
    initialValues: { firstName: "", lastName: "" },
    async onSubmit(values) {},
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  //  alert(loading)
  }, [loading]);

  return (
    <Wrapper
      style={{
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1000,
      }}
      back={undefined}
      header={
        <Grid
          style={{ borderTop: border, borderBottom: border }}
          placeSelf="stretch"
          customCols={"auto 1fr"}
          padding="1em"
        >
          <Header
            color={primaryTxtColor}
            placeSelf="start"
            onClick={close}
            size={3.5}
            className="pointer med noselect"
          >
            <i className="fa fa-chevron-left"></i> Back
          </Header>
          <Header bold>Add Card</Header>
        </Grid>
      }
    >
      <Grid placeSelf="start center" placeItems="start center">
        <Text textAlign="left" placeSelf="stretch">
          Please add your card details below
        </Text>

        <Elements stripe={stripePromise}>
          <StripeCard close={close} setLoading={setLoading}>
            <Grid placeItems="stretch" style={{minWidth: "40vw"}}>
              <Button.Normal
                disabled={loading}
                style={{
                  borderRadius: "1.3em",
                  padding: ".5em",
                  marginTop: "1em",
                  ...!loading?
                  {backgroundImage: `${gradient}`}:
                  {background: disabledTxtColor2},
                  color: "white",
                  border: 0,
                }}
                type="submit"
                onSubmit={(e: any) => handleSubmit(e)}
              >
               {loading? <Loader.Spinner/> :
                'Add'}
              </Button.Normal>
            </Grid>
          </StripeCard>
        </Elements>
      </Grid>
    </Wrapper>
  );
};

export default CardForm;
