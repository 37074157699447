import { ActionTypes } from "../reducer/vehicles";
import api from "../../api";
import { Vehicle } from "../../pages/booking-history/type";
import { Notification } from 'platyplex_ui';
import { message } from "antd";

const ErrorModal = ({error}: {error: any}) => Notification.error({ title: 'Error', message: error.message || error})

export const asyncActions = async (
  apiCall: () => Promise<any>,
  dispatch: any,
  showError = false,
  modal = ErrorModal
) => {
  let payload = null;
  try {
    dispatch({ type: ActionTypes.REQUEST_VEHICLE });
    payload = await apiCall();
    if (!payload || !payload.success)
      throw new Error(payload?.message || "Error");

    dispatch({ type: ActionTypes.SUCCESS_VEHICLE });
    return await payload;
  } catch (e) {
    if (showError) {
      modal({ error: e });
    }
    dispatch({ type: ActionTypes.ERROR_VEHICLE });
    return payload;
  }
};

export const getVehicles = (modal = ErrorModal) => {
  return async (dispatch: any) => {
    const payload = await asyncActions(
      () => api.getVehicles(),
      dispatch,
      true,
      modal
    );

    if (payload && payload.success) {
      dispatch({
        type: ActionTypes.LOAD_VEHICLE,
        payload: { vehicles: payload.data },
      });
    }
  };
};

export const addVehicle = (vehicle: Vehicle, modal = ErrorModal) => {
  return async (dispatch: any) => {
    const payload = await asyncActions(
      () => api.addVehicle(vehicle),
      dispatch,
      true,
      modal
    );

    if (payload && payload.success) {
      // dispatch({
      //   type: ActionTypes.ADD_VEHICLE,
      //   payload: { vehicle: { ...vehicle, id: payload.vehicle_id } },
      // });
      dispatch(getVehicles());
    } else {
      message.error('Error: ' + payload?.message || 'Error');
      throw new Error(payload?.message || 'Error')
    }

    return payload;
  };
};

export const editVehicle = (vehicle: Vehicle, modal = ErrorModal) => {
  return async (dispatch: any) => {
    const payload = await asyncActions(
      () => api.editVehicle(vehicle, vehicle._id),
      dispatch,
      true,
      modal
    );

    if (payload && payload.success) {
      // dispatch({
      //   type: ActionTypes.EDIT_VEHICLE,
      //   payload: { vehicle: { ...vehicle, id: payload.vehicle_id } },
      // });

      dispatch(getVehicles());
    }

    return payload;
  };
};

export const removeVehicle = (vehicle: Vehicle, modal = ErrorModal) => {
  return async (dispatch: any) => {
    const payload = await asyncActions(
      () => api.removeVehicle(vehicle._id),
      dispatch,
      true,
      modal
    );

    if (payload && payload.success) {
      // dispatch({
      //   type: ActionTypes.REMOVE_VEHICLE,
      //   payload: { vehicle: { ...vehicle, id: payload.vehicle_id } },
      // });
      dispatch(getVehicles());
    }
    return payload;
  };
};

export const selectVehicle = (vehicle: Vehicle) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.SELECT_VEHICLE,
      payload: { vehicle },
    });

    api.setDefaultVehicle(vehicle._id)
    .catch((e)=>Notification.error({ message: 'Error', title: "Error"}))
  };
};

export const removeMultipleVehicle = (vehicle: Vehicle) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.REMOVE_MULTIPLE_VEHICLE,
      payload: { vehicle },
    });

  };
};


export const selectMultipleVehicle = (vehicle: Vehicle) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.SELECT_MULTIPLE_VEHICLE,
      payload: { vehicle },
    });

  };
};

