import React, { useEffect } from "react";
import { Header, Grid } from "platyplex_ui";
import { primaryTxtColor } from "../../config";

const Wrapper = ({ back, children, rightNav, header, style, onCreate, onDestory }: any) => {

  useEffect(()=>{
    if(onCreate){
      onCreate();
    }

    return () => {
      if(onDestory){
        onDestory();
      }
   }
  }, [])
  return (
    <Grid
      height="100vh"
      customRows={header ? "auto auto 1fr" : "auto 1fr"}
      gridGap="1em"
      className='opacity'
      background="white"
      style={{ overflowY: "auto", ...style }}
    >
      <Grid
        style={{ gridColumn: "-1/1", padding: "1% 1% 0 1%" }}
        width="100%"
        customCols="auto auto 1fr auto"
      >
        {back && (
          <Header
            color={primaryTxtColor}
            placeSelf="start"
            onClick={back}
            size={3.5}
            height='100%'
            className="pointer med noselect"
          >
            <i className="fa fa-chevron-left"></i> Back
          </Header>
        )}
        <div></div>
        {rightNav}
      </Grid>
      {header}

      {children}
    </Grid>
  );
};

export default Wrapper;
