import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import {
  Button,
  Card,
  Empty,
  Grid,
  List,
  Loader,
  Modal,
  Notification,
  Text,
} from "platyplex_ui";
import { disabledTxtColor, gradient, primaryColor } from "../../config";
import useQuery from "../../hooks/query";
import Bar from "../checkout/components/bar";
import logo from "../../assets/parkstash_logo.png";
import { Checkbox, Input, Radio, Select, Switch } from "antd";
import { toBoolean } from "../../utils/extra";
import {
  Event,
  EventIntent,
  EventTicket,
  ParkingTicket,
  ReviewReservation,
} from "./type";
import api from "../../api";
import VehicleModal from "../../components/vehicle-modal";
import NewModal from "../../components/new-modal";
import { useDispatch, useSelector } from "react-redux";
import { CreditCard, Vehicle } from "../booking-history/type";
import VehicleFormat from "../../components/vehicle-modal/vehicle-format";
import { PaymentMethod } from "../checkout/components/payment";
import { getPaymentLogo } from "../../utils/payment-logo";
import { selectCard } from "../../store/actions/card";
import CardForm from "../checkout/components/payment/card-form";
import Recharge from "../../components/recharge";
import WrapperComp from "../../components/overlay-wrapper";
import PrimaryModal from "../../components/primary-modal";
import Images from "../../components/images";
import { useParams } from "react-router";

const InputNumber = ({
  min,
  max,
  onChange,
  disabled,
  value,
}: {
  min: number;
  max: number;
  disabled?: boolean;
  value: number;
  onChange: (e: number) => void;
}) => {
  return (
    <Select
      value={value}
      disabled={disabled}
      onChange={(e) => onChange(Number(e))}
      style={{ minWidth: 100 }}
    >
      {new Array(max + 1).fill(0).map((_, i) => {
      if(i < min && i !== 0) return;
      return (
        <Select.Option value={i} key={i}>
          {i}
        </Select.Option>
      )})}
    </Select>
  );
};


const Events = () => {
  const { query } = useQuery<{NAV: string, eid: string}>();
  const sidebar = query.NAV ? toBoolean(query.NAV) : true;
  const [step, setStep] = useState(0);

  const defaultEvent = {
    payment_method: "none",
    event_id: "",
    event_tickets: [],
    additional_members: [],
    temp_tickets: [],
    vehicle_ids: [],
  }
  const [event, setter] = useState<EventIntent>(defaultEvent);

  const componentProps = {
    event,
    shadow: sidebar,
    setEvent: (obj: Partial<EventIntent>) => setter({ ...event, ...obj }),
    next: () => {
      const newStep = step + 1;

      if(newStep >= 6){
        setStep(0)
        setter(defaultEvent)
      } else {
        setStep(newStep);
      }

    },
    back: () => setStep(step - 1),
  };

  const Steps = [
    <EventList {...componentProps} />,
    <TicketList {...componentProps} />,
    <ParkingList {...componentProps} />,
    <Vehicles {...componentProps} />,
    <Checkout {...componentProps} />,
    <Success {...componentProps} />,
  ];

  return (
    <Layout
      background={sidebar ? undefined : "white"}
      sidebar={sidebar}
      style={{ gridTemplateRows: "1fr auto" }}
      hideFooter={!sidebar}
    >
      <Layout.Top></Layout.Top>
      <Layout.Bottom>
        <Grid
          placeItems="start stretch"
          padding=".5em"
          height="100%"
          style={{
            width: 800,
            paddingTop: '1em',
            height: sidebar ? "calc(99vh - 7em)" : "99vh",
            gridTemplateRows: "1fr auto",
            maxWidth: "100vw",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {query.eid}
          {Steps[step]}
        </Grid>
      </Layout.Bottom>
    </Layout>
  );
};

interface WrapperProps {
  children?: JSX.Element | any;
  back: () => void;
  loading?: boolean;
  next: () => void;
  disabledNext?: boolean;
  nextText?: string;
  hideBack?: boolean;
}

interface ComponentProps {
  event: EventIntent;
  shadow: boolean;
  back: () => void;
  next: () => void;
  setEvent: (e: Partial<EventIntent>) => void;
}

const Wrapper = ({
  nextText,
  children,
  back,
  next,
  disabledNext,
  loading,
  hideBack,
}: WrapperProps) => {
  return (
    <>
      <Grid
        placeItems="start stretch"
        style={{
          overflowY: "auto",
          gridTemplateRows: "3em 1fr",
          height: "100%",
        }}
      >
        {hideBack ? (
          <span />
        ) : (
          <Text
            style={{ placeSelf: "stretch start" }}
            className="bold pointer"
            onClick={back}
          >
            <i className="fa fa-chevron-left" /> &nbsp; Back{" "}
          </Text>
        )}
        {children}
      </Grid>
      <Button
        rounded
        disabled={disabledNext || loading}
        onClick={next}
        noHover
        className="pointer med"
        style={{
          padding: ".75em",
          marginTop: "1em",
          border: 0,
          fontWeight: 600,
        }}
        bg={gradient}
        type="submit"
      >
        {nextText || "Next"}
      </Button>
    </>
  );
};
const EventList = (props: ComponentProps) => {
  const [state, setter] = useState({
    loading: false,
    error: false,
    events: [],
  });
  const setState = (obj: any) => setter({ ...state, ...obj });
  let { eId } = useParams<any>();
  const getEvents = () => {
    setState({ loading: true, error: false });
    api
      .getEvents({enterprise_id: eId})
      .then((res: any) => {
        if (res.success) {
          console.log(res);
          setState({ events: res.events || [], loading: false });
        } else throw new Error(res.message || "Error");
      })
      .catch((e) => {
        Notification.error({ message: e.message, title: "Error" });
        setState({ events: [], loading: false, error: true });
      }
     );
  };
  useEffect(() => getEvents(), []);
  return (
    <Wrapper
      hideBack
      back={() => window.close()}
      next={props.next}
      disabledNext={!props.event.event_id}
    >
      <Card
        shadow={props.shadow}
        grid
        placeItems="stretch"
        className="noselect slide-up"
        margin={0}
      >
        <Text textAlign="left">Choose your event from list below</Text>
        <Bar />
        {state.error && !state.loading?
        <>
         <Empty text={`Error Loading Events. Please Try again.`} color={primaryColor} style={{ marginTop: 0 }} />
              <Button
                rounded
                noHover
                bg={gradient}
                padding={".5em"}
                onClick={()=>getEvents()}
                style={{ margin: "1em" }}
              >
                Retry
              </Button>
        </>:
          <List
          loading={state.loading}
          empty={<Empty text='No Events Available'/>}
          list={state.events}
          map={(e: Event) => (
            <EventItem
              key={e._id}
              checked={props.event.event_id === e._id}
              event={e}
              selectEvent={() =>
                props.setEvent({
                  event_id: e._id,
                  temp_tickets: e.event_tickets,
                })
              }
            />
          )}
        />}
      </Card>
    </Wrapper>
  );
};

const EventItem = ({
  event,
  selectEvent,
  checked,
}: {
  event: Event;
  selectEvent: () => void;
  checked: boolean;
}) => {
  const ViewMoreModal = NewModal({
    modal: (
      <WrapperComp
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100vh",
          padding: "1em",
          zIndex: 100,
        }}
        back={() => ViewMoreModal.close()}
      >
        <div style={{ placeSelf: "stretch" }}>
          <img
            src={event.image_url || logo}
            style={{ width: "7em", height: "7em", objectFit: "contain" }}
            alt="logo"
          />
          <br /> <br />
          <Text bold textAlign="left">
            {event.description}
          </Text>
          <br />
          {event.organizer_url && (
            <a href={event.organizer_url}>{event.organizer_url}</a>
          )}
        </div>
      </WrapperComp>
    ),
  });

  return (
    <React.Fragment>
      {ViewMoreModal.modal}
      <Grid
        customCols={"5em 1fr 2em"}
        mobileLayout={false}
        onClick={selectEvent}
        className="pointer"
      >
        <img
          src={event.image_url || logo}
          style={{ width: "5em", height: "5em", objectFit: "contain" }}
          alt="logo"
        />
        <div style={{ textAlign: "left" }}>
          <Text bold textAlign="left">
            {event.name}
          </Text>
          <Text color={disabledTxtColor} textAlign="left">
            {event.extras?.pretty_event_start} -{" "}
            {event.extras?.pretty_event_end}
          </Text>

          <span
            onClick={ViewMoreModal.open}
            className="bold"
            style={{ color: primaryColor }}
          >
            View Info
          </span>
        </div>
        <Radio checked={checked} />
      </Grid>
      <Bar />
    </React.Fragment>
  );
};

const TicketList = (props: ComponentProps) => {
  const addTicket = (t: EventTicket, i: number) => {
    const tickets = props.event.event_tickets.filter((tt) => tt.event_ticket_id !== t._id);

    const newItem =
      i > 0
        ? [
            {
              event_ticket_id: t._id,
              event_ticket_count: i,
              parking_tickets: [],
            },
          ]
        : [];

    props.setEvent({
     event_tickets: [...tickets, ...newItem],
    });
  };

  const getTicketNumber = (id: string) =>
    props.event.event_tickets.filter((e) => e.event_ticket_id === id)[0]
      ?.event_ticket_count || 0;
  return (
    <Wrapper
      back={props.back}
      next={props.next}
      disabledNext={props.event.event_tickets?.length === 0}
    >
      <Card
        shadow={props.shadow}
        grid
        placeItems="stretch"
        className="noselect slide-up"
        margin={0}
      >
        <Text textAlign="left">Choose your ticket from list below</Text>
        <Bar />

        <List
          list={props.event.temp_tickets}
          map={(e: EventTicket) => (
            <Ticket
              key={e._id}
              ticket={e}
              addTicket={addTicket}
              value={getTicketNumber(e._id)}
            />
          )}
        />
      </Card>
    </Wrapper>
  );
};

//

const Ticket = ({
  ticket,
  addTicket,
  value,
}: {
  ticket: EventTicket;
  addTicket: (t: EventTicket, i: number) => void;
  value: number;
}) => {
  const ViewMoreModal = NewModal({
    modal: (
      <WrapperComp
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100vh",
          padding: "1em",
          zIndex: 100,
        }}
        back={() => ViewMoreModal.close()}
      >
        <div style={{ placeSelf: "stretch" }}>
          <Text bold size="1.25em" color={primaryColor}>
            {ticket.name}
          </Text>
          <Bar />
          <Text textAlign="left">
            Sales ends on {ticket.extras?.pretty_sales_end}
          </Text>
          <Text textAlign="left">{ticket.description}</Text>
        </div>
      </WrapperComp>
    ),
  });
  return (
    <React.Fragment>
      {ViewMoreModal.modal}
      <Grid
        customCols={"1fr 7em"}
        mobileLayout={false}
        placeItems="center stretch"
      >
        <div>
          <Text textAlign="left">{ticket.name}</Text>
          <Text textAlign="left">
            <span className="bold">
              {ticket.extras?.pretty_pricing[0]?.price}
            </span>
            {/* <span style={{ color: disabledTxtColor }}> + $12.36 Fee</span> */}
          </Text>
        </div>
        <InputNumber
          max={Math.min(ticket.max_tickets_per_order, ticket?.precise_available?.count || Number.MAX_VALUE)}
          value={value}
          disabled={ticket.extras?.is_active === false}
          min={ticket.min_tickets_per_order}
          onChange={(i) => (typeof i === "number" ? addTicket(ticket, i) : {})}
        />

        <span
          onClick={ViewMoreModal.open}
          className="bold"
          style={{ color: primaryColor, placeSelf: "center start" }}
        >
          View Info
        </span>
        <Bar style={{ gridColumn: "1/-1" }} />
      </Grid>
    </React.Fragment>
  );
};

const ParkingList = (props: ComponentProps) => {
  const [noParking, setNoParking] = useState(false);
  const addParkingTicket = (index: number,parkingId: string, i: number) => {
    const parking_tickets =
      i <= 0
        ? []
        : [
            {
              parking_ticket_count: i,
              parking_ticket_id: parkingId,
            },
          ];
    if (i > 0) setNoParking(false);

     const event_tickets = [...props.event.event_tickets];

     event_tickets.forEach((_, index)=>{
      event_tickets[index] = {
        ...event_tickets[index],
        parking_tickets: []
      }
     })

     event_tickets[index] = {
       ...event_tickets[index],
       parking_tickets
     }

    props.setEvent({event_tickets});
  };

  const clearParkingTickets = () => {
     const event_tickets = [...props.event.event_tickets];
     event_tickets.forEach((_, index)=>{
      event_tickets[index] = {
        ...event_tickets[index],
        parking_tickets: []
      }
     })
     
    props.setEvent({event_tickets});
  };

 const hasOneParkingTicket = true;

 const getEventTicket = (id: string) => props.event.temp_tickets.filter((e) => e._id === id)[0];

 const getTotalTickets = ()=> props.event.event_tickets.reduce((acc, curr)=>curr.event_ticket_count + acc, 0)

  return (
    <Wrapper
      back={props.back}
      next={props.next}
      disabledNext={!(hasOneParkingTicket || noParking)}
    >
      <Card
        shadow={props.shadow}
        grid
        placeItems="stretch"
        className="noselect slide-up"
        margin={0}
      >
        <Text textAlign="left">
          Choose your preferred parking location from list below
        </Text>
        <Bar />

        {props.event.event_tickets.map((eventTicket, i)=>{
        const eventTIcket =  getEventTicket(eventTicket.event_ticket_id);
        console.log(eventTicket.event_ticket_id)
        if(!eventTIcket) return;
       return (
       <React.Fragment key={eventTIcket._id}>
       <Text bold textAlign={'left'} style={{marginTop: '.75em'}}>{eventTIcket.name}</Text>
       <List
          key={i}
          list={eventTIcket.parking_tickets}
          map={(e: ParkingTicket) => (
            <ParkingTicketItem
              key={e._id}
              parking_ticket={e}
              max={getTotalTickets()}
              value={eventTicket.parking_tickets.filter((p)=>p.parking_ticket_id === e._id)[0]?.parking_ticket_count || 0}
              addParkingTicket={(id: string, n: number) => addParkingTicket(i, id, n)}
            />
          )}
        />

      </React.Fragment>)})}
        


        <Grid customCols={"1fr 7em"} mobileLayout={false}>
          <div style={{ width: "100%", textAlign: "left" }}>
            I do not need Parking
          </div>
          <Radio
            checked={noParking}
            onClick={() => {
              clearParkingTickets();
              setNoParking(true);
            }}
          />
        </Grid>
      </Card>
    </Wrapper>
  );
};

const ParkingTicketItem = ({
  parking_ticket,
  addParkingTicket,
  value,
  max,
}: {
  parking_ticket: ParkingTicket;
  addParkingTicket: ( id: string, n: number) => void;
  value: number;
  max: number;
}) => {
  console.log(parking_ticket)
  const image_url = parking_ticket.extras?.parking?.image_url;
  const ViewMoreModal = NewModal({
    modal: (
      <WrapperComp
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100vh",
          padding: "1em",
          zIndex: 100,
        }}
        back={() => ViewMoreModal.close()}
      >
        <div style={{ placeSelf: "stretch" }}>
          <Text bold size="1.25em" color={primaryColor}>
            {parking_ticket.extras.parking.name}
          </Text>
          <Bar />
          <br />
          {image_url && image_url[0] ? (
            <Images
              hideIndex={image_url?.length ? image_url?.length > 5 : true}
              images={image_url || []}
              style={{ width: 250, height: 250, minWidth: "100%" }}
            />
          ) : (
            <i
              style={{ fontSize: "280px", color: disabledTxtColor }}
              className="fas fa-parking"
            ></i>
          )}
          <Bar />
          <br />
          <Text textAlign="left" bold color={primaryColor}>
            Description
          </Text>
          <Text textAlign="left">
            {parking_ticket.extras.parking.description}
          </Text>

          <Bar />
          <br />
          <Text textAlign="left" bold color={primaryColor}>
            Number of Spaces
          </Text>
          <Text textAlign="left">
            {parking_ticket.extras.parking.space_count}
          </Text>
        </div>
      </WrapperComp>
    ),
  });

  return (
    <React.Fragment>
      {ViewMoreModal.modal}
      <Grid customCols={"1fr 7em"} mobileLayout={false}>
        <div style={{ width: "100%" }}>
          <Text textAlign="left">
            {parking_ticket.extras.parking.name} (77% Sold)
          </Text>
          <Text bold textAlign="left">
            {!parking_ticket.is_free
              ? parking_ticket.extras.pretty_price
              : "Free"}
          </Text>
          <div style={{ textAlign: "left" }}>
            <span
              onClick={ViewMoreModal.open}
              className="bold"
              style={{ color: primaryColor }}
            >
              View Info
            </span>
          </div>
        </div>
        <InputNumber
          max={Math.min(parking_ticket.max_occupancy, max)}
          value={value}
          min={0}
          onChange={(i) =>
            typeof i === "number" ? addParkingTicket(parking_ticket._id, i) : {}
          }
        />
      </Grid>
      <Bar />
    </React.Fragment>
  );
};

const Vehicles = (props: ComponentProps) => {
  const { vehicles, loading } = useSelector((s: any) => s.vehicles);

  const { details } = useSelector((s: any) => s.user);
  const VehiclesModal = NewModal({
    modal: <VehicleModal close={() => VehiclesModal.close()} />,
  });

  const [member, setMember] = useState({ name: "", email: "" });

  const isValidMember =
    /^[a-z ,.'-]+$/i.test(member.name) && /^\S+@\S+\.\S+$/.test(member.email);

  const addMember = () => {
    props.setEvent({
      additional_members: [...props.event.additional_members, member],
    });
    setMember({ name: "", email: "" });
  };

  const removeMember = (index: number) => {
    props.setEvent({
      additional_members: props.event.additional_members.filter(
        (_, i) => i !== index
      ),
    });
  };

  const addVehicle = (id: string) => {
    props.setEvent({ vehicle_ids: [...props.event.vehicle_ids, id] });
  };

  const removeVehicle = (id: string) => {
    props.setEvent({
      vehicle_ids: props.event.vehicle_ids.filter((idd) => idd !== id),
    });
  };

  const maxVehicles = props.event.event_tickets.reduce((prev, curr)=> {
    return prev + curr.parking_tickets.reduce((p, c)=> p+ c.parking_ticket_count,0)
  }, 0) || 0;
  const maxMembers = props.event.event_tickets.reduce((prev, curr)=> curr.event_ticket_count + prev, 0);
  return (
    <Wrapper
      back={props.back}
      next={props.next}
      disabledNext={
        //  props.event.vehicle_ids.length === 0 ||
        props.event.vehicle_ids.length > maxVehicles ||
        maxMembers <= props.event.additional_members.length
      }
    >
      <Card
        shadow={props.shadow}
        grid
        placeItems="stretch"
        className="noselect slide-up"
        margin={0}
      >
        {VehiclesModal.modal}
        <Text textAlign="left" bold>
          Confirm your vehicle Info
        </Text>
        <Text textAlign="left">
          Your permit will be valid only for vehicle with License Plate # Listed
          Below
        </Text>

        <Text textAlign="left" color="red">
          Max Vehicles Allowed: {maxVehicles} &nbsp;
          {props.event.vehicle_ids.length > maxVehicles && (
            <i className="fa fa-times-circle" />
          )}
        </Text>
        <List
          loading={loading}
          empty={
            <Text textAlign="left" color="red">
              No vehicle present. Please add a vehicle to continue.
              <Text
                className="pointer"
                color="blue"
                textAlign="left"
                onClick={VehiclesModal.open}
              >
                Add Vehicle
              </Text>
            </Text>
          }
          list={vehicles}
          map={(vehicle: Vehicle) => {
            const checked = props.event.vehicle_ids.includes(vehicle._id);
            return (
              <Grid
                key={vehicle._id}
                customCols="1fr auto"
                placeItems="stretch"
                gridGap="0em 1em"
                mobileLayout={false}
              >
                <VehicleFormat vehicle={vehicle} />
                <Checkbox
                  checked={checked}
                  onClick={() =>
                    checked
                      ? removeVehicle(vehicle._id)
                      : addVehicle(vehicle._id)
                  }
                />
              </Grid>
            );
          }}
        />
        <Button
          rounded
          onClick={VehiclesModal.open}
          disabled={false}
          bg={gradient}
          loading={false}
          noHover
          style={{
            placeSelf: "center start",
            padding: ".75em",
            marginTop: "1em",
          }}
        >
          Add Vehicles
        </Button>
        <Bar />
        <Text textAlign="left" bold>
          Add Members
        </Text>
        <Text textAlign="left" color={"red"}>
          Max Members Allowed: {maxMembers} &nbsp;
          {maxMembers <= props.event.additional_members.length && (
            <i className="fa fa-times-circle" />
          )}
        </Text>

        <div>
          <Text textAlign="left">{details.user_name} (Primary)</Text>
          <Text textAlign="left">{details.user_email}</Text>
        </div>
        <List
          list={props.event.additional_members}
          empty={<Text textAlign="left" bold></Text>}
          map={(e: any, i: number) => (
            <Grid
              key={e._id}
              customCols="1fr auto"
              mobileLayout={false}
              placeItems={"center stretch"}
            >
              <div>
                <Text textAlign="left">{e.name}</Text>
                <Text textAlign="left">{e.email}</Text>
              </div>
              <div onClick={() => removeMember(i)}>
                <i className="fa fa-trash pointer" style={{ color: "red" }} />
              </div>
            </Grid>
          )}
        />

        <Bar />

        <Input
          placeholder="Enter Name"
          value={member.name}
          onChange={(e) => setMember({ ...member, name: e.target.value })}
        />
        <Input
          placeholder="Enter Email"
          value={member.email}
          onChange={(e) => setMember({ ...member, email: e.target.value })}
        />
        <Button
          rounded
          noHover
          bg={gradient}
          disabled={!isValidMember}
          loading={false}
          onClick={addMember}
          style={{
            placeSelf: "center start",
            padding: ".75em",
            marginTop: "1em",
          }}
        >
          Add Members
        </Button>
      </Card>
    </Wrapper>
  );
};

const Checkout = (props: ComponentProps) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<ReviewReservation | undefined>();
  const dispatch = useDispatch();
  const { cards, selectedCard } = useSelector((state: any) => state.cards);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("card");
  const reviewReservation = () => {
    setError(false);
    setLoading(true);
    api
      .reviewReservation({
        ...props.event,
        temp_tickets: undefined,
      })
      .then((res: any) => {
        if (res.success) {
          setState(res.review);
        } else throw new Error(res.message || "Error");
      })
      .catch((e) => {
        setError(true);
        Notification.error({ message: e.message, title: "Error" });
      })
      .finally(() => setLoading(false));
  };

  const CardModal = NewModal({
    modal: <CardForm close={() => CardModal.close()} />,
  });

  const RechargeModal = NewModal({
    modal: (
      <WrapperComp
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 1000,
        }}
        back={() => RechargeModal.close()}
      >
        <Recharge
          back={() => {
            RechargeModal.close();
            reviewReservation();
          }}
        />
      </WrapperComp>
    ),
  });
  const WarningModal = Modal({
    title: "",
    modal: (
      <PrimaryModal
        onCancel={() => WarningModal.close()}
        confirmText="Recharge Wallet"
        onConfirm={() => {
          RechargeModal.open();
          WarningModal.close();
        }}
      >
        <Grid gridGap=".25em" padding="1em">
          <Text bold>Wallet balance low</Text>
          <Text>Please Recharge your wallet.</Text>
        </Grid>
      </PrimaryModal>
    ),
    hideCancel: true,
  });

  const reserveEvent = () => {
    setLoading(true);
    api
      .reserveReservation({
        ...props.event,
        temp_tickets: undefined,
        payment_method: paymentMethod,
        card_id: paymentMethod === "card" ? selectedCard?.id : undefined,
      })
      .then((res: any) => {
        if (res.success) {
          props.setEvent({par_id: res.par_id})
          props.next();
        } else throw new Error(res.message || "Error");
      })
      .catch((e) => {
        Notification.error({ message: e.message, title: "Error" });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => reviewReservation(), []);
  const total =
    paymentMethod === "wallet"
      ? state?.with_wallet.total
      : state?.with_card.total;
  if (error) {
    return (
      <Wrapper
        back={props.back}
        loading={loading}
        next={reviewReservation}
        nextText={"Retry"}
      >
        {" "}
        <Card
          shadow={props.shadow}
          grid
          placeItems="stretch"
          className="noselect slide-up"
          margin={0}
        >
          <Text textAlign="left" bold>
            Error
          </Text>
          <Text textAlign="left">
            There was an error with the options you picked
          </Text>
          <Text bold onClick={props.back} color={primaryColor}>
            Go Back
          </Text>
        </Card>
      </Wrapper>
    );
  }

  if (!state) return <Loader />;

  return (
    <Wrapper
      loading={loading}
      back={props.back}
      nextText={`Pay ${total}`}
      next={reserveEvent}
      disabledNext={!(paymentMethod === "wallet" || selectedCard?.id)}
    >
      <Card
        shadow={props.shadow}
        grid
        placeItems="stretch"
        className="noselect slide-up"
      >
        <Text textAlign="left" bold>
          Order Summary
          {RechargeModal.modal}
          {CardModal.modal}
        </Text>
        <Bar />

        <Grid
          customCols={"5em 1fr"}
          mobileLayout={false}
          className="pointer"
          placeItems="center stretch"
        >
          <img
            src={state?.image_url ? state?.image_url[0] : logo}
            style={{ width: "5em", height: "5em", objectFit: "contain" }}
            alt="logo"
          />
          <div>
            <Text textAlign="left">{state?.order_label1}</Text>
            {/* <Text color={disabledTxtColor} textAlign="left">
            {event.extras?.pretty_event_start} - {event.extras?.pretty_event_end}
          </Text> */}
          </div>
        </Grid>
            {state?.order_label2.map((e, i)=>{
              return(<React.Fragment key={i}> 
              <Text textAlign="left">Tickets: {e.event_ticket_label}</Text>
              {e.parking_ticket_labels.length === 0 && <Text textAlign='left'>Parking: None</Text>}
              {e.parking_ticket_labels.map((p, i)=><Text key={i} textAlign="left" style={{marginLeft: '.75em'}}>Parking: {p}</Text>)}
              
              </React.Fragment>)
            })}
        

        <Bar />
        <Text bold textAlign="left">
          Payment Method
        </Text>
        {paymentMethod === "wallet" ? (
          <Text textAlign="left">
            Your savings with wallet:{" "}
            <span className="bold" style={{ color: primaryColor }}>
              {state?.save_with_wallet_today_amount}
            </span>
          </Text>
        ) : (
          <Text textAlign="left">
            Save&nbsp;
            <span style={{ color: primaryColor }}>
              {state?.save_with_wallet_today_percent}
            </span>
            &nbsp;by using ParkStash Wallet
          </Text>
        )}

        {paymentMethod === "wallet" ? (
          <Text textAlign="left">
            That's{" "}
            <span className="bold" style={{ color: primaryColor }}>
              {state?.save_with_wallet_today_percent}{" "}
            </span>{" "}
            savings!
          </Text>
        ) : (
          <Text textAlign="left">
            Amount Due with Wallet: &nbsp;
            <span style={{ color: "red", textDecoration: "line-through" }}>
              {state?.with_card.total}
            </span>{" "}
            &nbsp;
            <span style={{ color: primaryColor }}>
              {state?.with_wallet.total}
            </span>
            <Text></Text>
          </Text>
        )}
        <Grid
          customCols="1fr auto"
          mobileLayout={false}
          placeItems="center stretch"
        >
          <Text textAlign="left">
            ParkStash Wallet Balance: {state?.wallet_balance}
          </Text>
          <Switch
            checked={paymentMethod === "wallet"}
            onChange={(e) => {
              if (state?.has_sufficient_wallet_balance) {
                setPaymentMethod(e ? "wallet" : "card");
              } else {
                WarningModal.open();
              }
            }}
          />
          <div style={{ textAlign: "left" }}>
            <span
              style={{ color: primaryColor }}
              className="pointer noselect bold"
              onClick={RechargeModal.open}
            >
              Recharge my Wallet
            </span>
          </div>
          <span />
          <Text textAlign="left">Subtotal</Text>
          <Text textAlign="left">
            {paymentMethod === "wallet"
              ? state?.with_wallet.subtotal
              : state?.with_card.subtotal}
          </Text>

          <Text textAlign="left">Fee</Text>
          <Text textAlign="left">
            {paymentMethod === "wallet"
              ? state?.with_wallet.fee
              : state?.with_card.fee}
          </Text>

          {/* <Text textAlign="left">Taxes</Text>
          <Text textAlign="left">$35.00</Text> */}
          <Bar style={{ gridColumn: "1/-1" }} />

          <Text textAlign="left" bold>
            Total
          </Text>
          <Text textAlign="left">{total}</Text>

          {/* <Text textAlign="left" bold>
            Apply Promo Code
          </Text>
          <Text textAlign="right" bold>
            +
          </Text> */}
        </Grid>
        <Bar />

        <Grid
          customCols="1fr auto"
          mobileLayout={false}
          placeSelf="stretch"
          placeItems="stretch"
        >
          <Text bold textAlign="left">
            Select Card :
          </Text>

          <Text color="blue" className="pointer" onClick={CardModal.open}>
            Add a Card
          </Text>
          <List
            empty={
              <Text color="red">
                No payment method present. Add a payment method to pay and book.
              </Text>
            }
            list={cards}
            map={(card: CreditCard, i: number) => (
              <React.Fragment key={i}>
                <Text textAlign="left">
                  <img
                    src={getPaymentLogo(card.brand)}
                    style={{ height: 25 }}
                    alt="payment logo"
                  />{" "}
                  {`${card.brand} ending in ${card.last4}`}{" "}
                </Text>
                <Radio
                  checked={
                    selectedCard?.id === card.id && paymentMethod !== "wallet"
                  }
                  onClick={() => {
                    dispatch(selectCard(card));
                    setPaymentMethod("card");
                  }}
                />
              </React.Fragment>
            )}
          />
        </Grid>
      </Card>
    </Wrapper>
  );
};

const Success = (props: ComponentProps) => {

  return (
    <Wrapper back={props.back} next={()=>{
      window.location.href = window.location.href + "&CLOSE=true";
    }} nextText="Great" hideBack>
      <Card
        shadow={props.shadow}
        grid
        placeItems="stretch"
        className="noselect slide-up"
        margin={0}
      >
        <i
          className="fas fa-check-circle txt-lgx"
          style={{ color: primaryColor }}
        />
        <Text bold>Your ticket purchase was Successful!</Text>
        <Text bold>Ticket #: {props.event.par_id}</Text>
        <Text>You will receive an email receipt and text confirmation</Text>

        <Text>
          You can view details about your ticket in booking history section of
          our app
        </Text>

        <Text bold>Enjoy the event! </Text>
      </Card>
    </Wrapper>
  );
};

export default Events;
