import { Text } from "platyplex_ui";
import React, { useEffect, useState } from "react";
import API from "../api";
import { primaryColor } from "../config";
import Bar from "../pages/checkout/components/bar";
import { asyncHandler } from "../utils";

const vehicleTypes = [
  {
    displayName: "Passenger Vehicle",
    tooltip: <div>
      <Text size='1.2' bold color={primaryColor}>Passenger Vehicle</Text>
      <Bar />
      <Text textAlign='left' color={primaryColor}>Compact Car</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >6ft x 15ft</Text>
      <br />
      <Text textAlign='left' >Examples</Text>
      <Text textAlign='left' >Examples Honda Civic, Hyundai Elantra, Ford Focus, Toyota Corolla, Volkswagen Golf, Acura ILX, Audi A3, BMW 1 Series, Lexus CT, Mercedes. Benz A-Class </Text>

      <Bar />
      <Text textAlign='left' color={primaryColor}>Mid Size Car</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >6.5ft x 16ft</Text>
      <br />
      <Text textAlign='left' >Examples</Text>
      <Text textAlign='left' >Ford Mondeo, Toyota Camry, Peugeot 508, Mazda6, Volkswagen Passat, Alfa Romeo 3iulia, Audi A4, BMW 3 Series, Lexus IS, Mercedes-Benz C-Class </Text>

      <Bar />
      <Text textAlign='left' color={primaryColor}>Full Size Car</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >6.5ft x 17ft</Text>
      <br />
      <Text textAlign='left' >Examples</Text>
      <Text textAlign='left' >Chevrolet Impala, Chrysler 300, Ford Taurus</Text>


      <Bar />
      <Text textAlign='left' color={primaryColor}>Compact SUV</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >6.5ft x 16ft</Text>
      <br />
      <Text textAlign='left' >Examples</Text>
      <Text textAlign='left' >Honda CR-V, Mazda CX-5, Kia Spoilage, Ford Escape, Nissan Rogue, Chevrolet Equinox, Toyota RAV 4. </Text>


      <Bar />
      <Text textAlign='left' color={primaryColor}>Standard SUV</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >6.5ft x 18ft</Text>
      <br />
      <Text textAlign='left' >Examples</Text>
      <Text textAlign='left' >Ford Edge, Hyundai Santa Fe, Kia Telluride, Mazda CX-9, Kia Sorento, Dodge Durango, Chevrolet Traverse, Hyundai Palisade, Subaru Ascent. </Text>
    </div>,
    category: "passenger_vehicle",
  },
  {
    displayName: "Truck",
    category: "truck",
    tooltip: <div>
      <Text size='1.2' bold color={primaryColor}>Truck</Text>
      <Bar />
      <Text textAlign='left' color={primaryColor}>Light Truck</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >6.5ft x 20ft</Text>
      <br />
      <Text textAlign='left' >Examples</Text>
      <Text textAlign='left' >Chevrolet Colorado/GMC Canyon, Ford Ranger, Nissan Navara, Jeep Gladiator, Toyota Tacoma, Honda Ridgeline, Chevrolet Silverado/GMC Sierra 1500, Ford F-150, Nissan Titan, Ram 1500, Toyota Tundra, Dodge Dakota, Honda Ridgeline Chevrolet Silverado/GMC Sierra 2500, Ford F-250, Nissan Titan XD, Ram 2500</Text>

      <Bar />
      <Text textAlign='left' color={primaryColor}>Heavy Truck</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >9ft x 35ft</Text>
      <br />
      <Text textAlign='left' >Examples</Text>
      <Text textAlign='left' >Isuzu NPR, Chevrolet Silverado/GMC Sierra 3500, Ford F-350, Ram 3500, Ford F-450 (pickup only), Isuzu NPR-HD, Chevrolet Silverado 4500, Ford F-450 (chassis cab), Ram Isuzu NRR, Chevrolet Silverado 5500, Ford F-550, Ram 5500, Kenworth T170, Peterbilt 325, International TerraStar, Chevrolet Kodiak C6500, Ford F-650, Kenworth T270, Peterbilt 330, International Durastar</Text>
    </div>,
  },
  {
    displayName: "RV",
    category: "rv",
    tooltip: <div>
      <Text size='1.2em' bold color={primaryColor}>RV</Text>
      <Text textAlign='left'>Please refer to the link below for more information about Boat classification: </Text>

      <Text textAlign='left' color={primaryColor}>
        <a target='_' href='https://www.outdoorsy.com/blog/big-guide-to-rv-classes'>https://www.outdoorsy.com/blog/big-guide-to-rv-classes</a>
      </Text>

      <Bar />
      <Text textAlign='left' color={primaryColor}>Class A</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >21ft x 45ft</Text>
      <br />

      <Bar />
      <Text textAlign='left' color={primaryColor}>Class B</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >17ft x 19ft</Text>
      <br />
      <Bar />
      <Text textAlign='left' color={primaryColor}>Class C</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >20ft x 31ft</Text>
    </div>,
  },
  {
    displayName: "Boat",
    category: "boat",
    tooltip: <div>
      <Text size='1.2em' bold color={primaryColor} textAlign='left'>Boat</Text>
      <Text textAlign='left' >Please refer to the link below for more information about RV classification: </Text>

      <Text textAlign='left' color={primaryColor}>
        <a target='_' href='https://www.omao.noaa.gov/learn/small-boat-program/resources/length-vessel-class'>https://www.omao.noaa.gov/learn/small-boat-program/resources/length-vessel-class</a>
      </Text>

      <Bar />
      <Text textAlign='left' color={primaryColor}>Class A</Text>
      <Text textAlign='left' >Less than 16ft length overall</Text>
      <br />

      <Bar />
      <Text textAlign='left' color={primaryColor}>Class I</Text>
      <Text textAlign='left' >Average Dimensions (WXL)</Text>
      <Text textAlign='left' >16ft to 26ft x 19ft</Text>
      <br />
      <Bar />
      <Text textAlign='left' color={primaryColor}>Class II</Text>
      <Text textAlign='left' >26ft to less than 40ft length overall</Text>

      <br />
      <Bar />
      <Text textAlign='left' color={primaryColor}>Class III</Text>
      <Text textAlign='left' >40ft to 65ft length overall</Text>
    </div>
  },
];

export const useVehicleClasses = () => {
  const [state, setter] = useState({
    vehicles: [],
    loading: false,
    error: false,
    vehicleTypes
  });

  const setState = (obj: any) => setter({ ...state, ...obj });

  const getVehicle = async () => {
    setState({ loading: true, error: false });
    const { success, vehicleClassDescriptors } = await asyncHandler({
      apiCall: () => API.getVehicleClasses(),
    });

    setState({
      loading: false,
      error: !success,
      vehicles: vehicleClassDescriptors || [],
    });
  };

  useEffect(() => {
    getVehicle();
  }, []);

  return state;
};

export default useVehicleClasses;
