import React, { useEffect, useState } from "react";
import { Card, Text, Header, Button, Grid, Notification } from "platyplex_ui";
import { PendingRating, Rate as RateType } from "../../hooks/ratings";
import { Rate } from "antd";
import { disabledTxtColor, gradient, primaryColor } from "../../config";
import TextArea from "antd/lib/input/TextArea";
import { Parking } from "../booking-history/type";
import useParking from "../../hooks/parking";
import Success from "../../components/success";
import Images from "../../components/images";

interface Props {
  pendingRating: PendingRating;
  addRating: (p: RateType) => Promise<boolean>;
  close: ()=> void;
}

interface State {
  rate: number;
  review: string;
  parking?: Parking;
  error: boolean;
  completed: boolean;
}
const PendingRatingModal = ({ pendingRating, addRating, close }: Props) => {
  const [state, setState] = useState<State>({
    rate: 0,
    review: "",
    parking: undefined,
    error: false,
    completed: false
  });

  const { parking } = useParking(pendingRating?.parking_id);
  const texts = [null, "Terrible", "Bad", "Ok", "Great", "Awesome"];

  useEffect(()=>{
    if(state.error){
      Notification.error({ title: 'Error', message: 'Error Submitting the review. Please try again'})
      setState({...state, error: false})
    }
  }, [state.error])
  return (
    <Grid style={{ minHeight: "100vh" }} placeItems="center">
      <Card
        shadow
        grid
        placeSelf="center stretch"
        placeItems="start stretch"
        padding="1em"
        height="100%"
        gridGap=".5em"
        style={{
          width: 1200,
          maxWidth: "100vw",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {
        state.completed?
        <Success message='Thanks for your feedback' style={{boxShadow: 'none'}} onBack={()=>close()}/>:
        <>
        <Header bold>Rate & Review</Header>
        <Grid>{parking?.image_url?.length && parking?.image_url?.length > 0 ? (
              <Images             
                images={parking?.image_url || []}
                style={{ width: 150, height: 150 }}
              />
            ) : (
              <i
                style={{ fontSize: "9em", color: disabledTxtColor }}
                className="fas fa-parking"
              ></i>
            )
          }
        </Grid>
        <Text>Please leave a rating and review for {parking?.name}</Text>
        <Text bold color={primaryColor} height="1.5em" size="1.2em">
          {texts[state.rate]}
        </Text>
        <Rate
          value={state.rate}
          onChange={(rate) => setState({ ...state, rate })}
        />
        <TextArea
          onChange={(e) => setState({ ...state, review: e.target.value })}
          style={{ maxWidth: 800, placeSelf: "center" }}
          placeholder={
            "Write your review here (optional). Reviews help other drivers make decision faster!"
          }
          value={state.review}
        ></TextArea>

        <Text>
          We will review your feedback before sharing anything with the host.
        </Text>
        <Button
          rounded
          bg={gradient}
          noHover
          placeSelf="center"
          minWidth="12em"
          disabled={!state.rate}
          onClick={async() =>{
           if(
             await addRating({
              index: 0,
              rating: state.rate,
              review: state.review,
            })){
              setState({...state, completed: true})
            } else  setState({...state, error: true})
          }
          }
        >
          Submit
        </Button>
        </>}
      </Card>
    </Grid>
  );
};

export default PendingRatingModal;
