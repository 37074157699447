import React, { useEffect, useState } from "react";
import { Grid, Notification, Loader } from "platyplex_ui";
import { useDispatch } from "react-redux";
import useQuery from "../../../../../hooks/query";
import { useHistory } from "react-router";
import api from "../../../../../api";
import useData from "../../../../../hooks/data";
import { getPayoutMethods } from "../../../../../store/actions/bank";

const PaypalRedirectRoute = () => {
  const {
    query: { code: token },
  } = useQuery<{ code: string }>();
  const [validToken, setValidToken] = useState(false);
  const dispatch = useDispatch()
  const history = useHistory();

  console.log('reached redirect route')

  const {
    state: { loading, data, error },
  } = useData(
    {
      dataKey: "payoutMethodId",
      successMessage: true,
      request: () => api.addPayoutMethod("paypal", token),
    },
    validToken
  );

  const handleError = () => {
      setTimeout(()=>  history.push('/wallet/payout-methods'), 4000)
  };

  useEffect(() => {
    if (data) {
      dispatch(getPayoutMethods());
      setTimeout(()=>  history.push('/wallet/payout-methods'), 4000)
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError();
    }
  }, [error]);

  useEffect(() => {
    if (token && token.length > 2) {
      setValidToken(true);
    } else {
      Notification.error({
        message: `Error Adding Paypal Account! Redirecting.`,
        title: "Error",
      });
      handleError();
    }
  }, [token]);

  return (
    <Grid
      placeItems="stretch"
      customRows="auto auto 1fr"
      placeSelf="stretch"
      gridGap={"2em"}
      height="100%"
    >
      {loading && <Loader />}
    </Grid>
  );
};

export default PaypalRedirectRoute;
