import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { NearbyParking } from "../../../../model/domain/parking";
import Marker from "./marker";
import { defaultLocation, googleAPIKey, primaryColor  } from "../../../../config";
import {  useSelector } from "react-redux";
import userLocation from "../../../../assets/location.png";
import useEnterprise, { Enterprise } from "../../../../hooks/enterprise";
import EnterpriseDrawer from "../enterprise-drawer";
import  useLocation from "../../../../hooks/location";
import { Grid, Text } from "platyplex_ui";

interface MapProps {
  parkings: NearbyParking[];
  style?: React.CSSProperties;
  activeParking: number;
  setActiveParking: (n: number) => void;
  setOpenParking: (p: NearbyParking | undefined) => void;
}
const Map = ({
  parkings,
  activeParking,
  setActiveParking,
  setOpenParking,
  style,
}: MapProps) => {
  const { addressData, user, query } = useSelector((s: any) => s.search);
  const { enterprises } = useEnterprise({ lat: query.lat, long: query.long });
  const { checkLocation } = useLocation();
  const zoom = 14;
  const start = { gridColumn: 1, gridRowStart: 1 };
  const center = addressData.geo.lat === -1 ? defaultLocation : addressData.geo;
  const [enterprise, setEnterprise] = useState<Enterprise | undefined>(undefined);

  return (
    <>
      <div
        className="shadow grid pointer"
        onClick={checkLocation}
        style={{
          zIndex: 9,
          fontSize: "1.2em",
          width: "auto",
          placeSelf: "end",
          margin: "0 .5em 8em 0",
          background: "white",
          borderRadius: "50%",
          padding: ".75em",
          ...style,
        }}
      >
        <i className="far fa-compass txt-md" />
      </div>
      {enterprise && <EnterpriseDrawer enterprise={enterprise} close={()=>setEnterprise(undefined)}/>}
      <div style={{ ...start, justifySelf: "stretch" }}>
        {query.lat !== -1 ?
    
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleAPIKey }}
          center={center}
          defaultZoom={zoom}
          distanceToMouse={() => 0}
        >
          {parkings.map((parking: NearbyParking, index: number) => {
            return (
              <Marker
                _id={parking._id}
                select={() => setActiveParking(index)}
                isSelected={activeParking === index}
                setOpenParking={() => setOpenParking(parking)}
                lat={parking.geo[1]}
                lng={parking.geo[0]}
                key={index}
                isSold={parking.isSold}
                price={parking.total_rounded_pretty}
              />
            );
          })}

          {enterprises.map((e) => (
            <Marker
              key={e.id}
              setOpenParking={() => setEnterprise(e)}
              isSelected={false}
              lat={e.geo[1]}
              lng={e.geo[0]}
              className="hover-highlight-img"
              width={75}
              height={75}
              image={e.imageUrl_mapMarker}
            />
          ))}

          <Marker
            select={() => setActiveParking(-1)}
            setOpenParking={() => setOpenParking(undefined)}
            isSelected={false}
            lat={user.geo.lat}
            lng={user.geo.lng}
            width={30}
            height={30}
            image={userLocation}
          />
        </GoogleMapReact>: 
       
       <div
       className='shadow' 
       style={{
         borderRadius: '1em',
         background: primaryColor
       }}>
          <Text bold size={'1.25em'} color='white'>
          <i className="fas fa-exclamation-circle" />&nbsp;
            Enter your destination or turn on your location to find parking spots nearby.
          </Text>
         </div>}
      </div>
    </>
  );
};

export default Map;
