
export interface Address {
    line1: string;
    line2: string;
    postal_code: string;
    city: string;
    state: string;
  }
  export enum Entity {
    INDIVIDUAL = "individual",
    COMPANY = "company",
  }
  
  export interface Individual {
    first_name: string;
    last_name: string;
    dob?: {
      day: string;
      month: string;
      year: string;
    };
    id_number?: string;
    ssn_last_4?: string;
    address: Address;
  }
  
  export  interface Company {
    name: string;
    tax_id: string;
    address: Address;
  }
  export interface Account {
    business_type: Entity;
    individual?: Individual;
    company?: Company;
    tos_shown_and_accepted?: boolean;
  }
  
  export interface Bank {
    country: string;
    currency: string;
    routing_number: string;
    account_number: string;
    account_holder_name: string;
    account_holder_type: string;
  }
  
  export interface BankAccount {
    routing_number: string;
    account_number: string;
    transit_number?: string;
    confirm_account_number: string;
  }
  