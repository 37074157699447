import React, { useEffect } from "react";
import { Grid, Text, Header } from "platyplex_ui";
import Wrapper from "../../../components/overlay-wrapper";
import { CreditCard } from "../../booking-history/type";
import Bar from "../../checkout/components/bar";
import visa from "../../../assets/visa.jpg";
import { useDispatch } from "react-redux";
import { removeCard } from "../../../store/actions/card";
import { primaryColor } from "../../../config";

const CardInfo = ({ close, card }: { close: () => void; card: CreditCard }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper
      style={{
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 100,
      }}
      back={close}
    >
      <Grid
        placeSelf="start center"
        placeItems="start center"
        style={{ width: 400, maxWidth: "90vw" }}
      >
        <img src={visa} style={{ maxWidth: 200 }} alt="visa" />
        <Grid customCols="1fr auto" width="100%" placeItems="center stretch">
          <Text textAlign="left" color={primaryColor}>
            Card Number
          </Text>
          <Text textAlign="right">
            {[...new Array(12)].map(() => "*")}
            {card.last4}
          </Text>
          <Bar style={{ gridColumn: "1/-1" }} />

          <Text textAlign="left" color={primaryColor}>
            Expires On
          </Text>
          <Text textAlign="right">
            {card.exp_month}/{card.exp_year}
          </Text>

          <Bar style={{ gridColumn: "1/-1" }} />
          <Text
            textAlign="left"
            bold
            color={'red'}
            className="pointer"
            onClick={() => {
              dispatch(removeCard(card));
              close();
            }}
          >
            Delete Card
          </Text>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default CardInfo;
