import { Coords } from "google-map-react";
import moment from "moment";
import { defaultLocation } from "../../config";
import { AddressData } from "../../hooks/places_v1";
import { getEndDate } from "../../utils/time";

const now = moment();
const later = moment().add("hours", 2);

export interface SearchNearbyQuery {
  long: number;
  lat: number;
  geo?: [number, number];
  start_date: string;
  amenities?: string[];
  end_date: string;
  end_hour?: string;
  start_hour?: string;
  vehicle_classes: string[];
  booking_type: "daily" | "weekly" | "monthly";
}

export interface SearchStoreState {
  query: SearchNearbyQuery;
  addressData: Partial<AddressData>;
  error: boolean;
  loading: boolean;
  user: {
    geo: Coords;
  };
}
export interface SearchAction {
  type: ActionTypes;
  payload: Partial<SearchStoreState>;
}

const intialState: SearchStoreState = {
  query: {
    lat: -1,
    long: -1,
    start_date: now.format("YYYY-MM-DD"),
    end_date: later.format("YYYY-MM-DD"),
    start_hour: now.format("HH:mm"),
    end_hour: later.format("HH:mm"),
    amenities: [],
    booking_type: "daily",
    vehicle_classes: ["mid_size_car"],
  },
  user: {
    geo: {
      lat: -1,
      lng: -1,
    },
  },

  addressData: {
    formattedAddress: "",
    geo: {
      lat: -1,
      lng: -1,
    },
    updatedLocation: false,
  },
  error: false,
  loading: false,
};

export enum ActionTypes {
  INIT_QUERY = "INIT_QUERY",
  SET_SEARCH = "SET_SEARCH",
  SET_ADDRESS_DATA = "SET_ADDRESS_DATA",
  SET_USER_GEO = "SET_USER_GEO",
}

export default function rootReducer(
  state = intialState,
  action: SearchAction
): SearchStoreState {
  switch (action.type) {
    case ActionTypes.SET_SEARCH:
      const query = action.payload.query;
      const newQuery = { ...state.query, ...query };
      if (query?.booking_type) {
        newQuery.end_date = getEndDate(
          query?.booking_type,
          moment(query.start_date)
        ).format("YYYY-MM-DD");
      }

      return { ...state, query: newQuery };

    case ActionTypes.INIT_QUERY:
      const initQuery = action.payload.query as any;

      return {
        ...state,
        query: {
          ...state.query,
          vehicle_classes: initQuery.vehicle_classes || [],
          lat: initQuery.geo[1] || -1,
          long: initQuery.geo[0] ||-1,
        },
      };

    case ActionTypes.SET_ADDRESS_DATA:
      return {
        ...state,
        addressData: { ...state.addressData, ...action.payload.addressData },
      };

    case ActionTypes.SET_USER_GEO:
      return {
        ...state,
        user: { ...state.user, ...action.payload.addressData },
      };
    default:
      return state;
  }
}
