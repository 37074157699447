
import { useState, useEffect } from "react";
import API from "../api";
import { asyncHandler} from "../utils";

export interface PendingRating {
    parking_id: string; 
    booking_id: string; 
  }

  export interface Rate {
    index: number;
    rating: number;
    review: string | null;
  }

interface Props {
    pendingRatings : PendingRating[];
    loading: boolean;
    error: boolean;
}
  
export const useRating = () => {
  const [state, setter] = useState<Props>({
    pendingRatings: [],
    loading: false,
    error: false,
  });

  const setState = (o: Partial<Props>) => setter({ ...state, ...o });

  const getPendingRatings = async () => {
    setState({ loading: true, error: false });
    const res = await asyncHandler({
      apiCall: () => API.getPendingRatings(),
    });
    const { success, data } = res;
    setState({ loading: false, error: !success, pendingRatings: data || []});
  };


  const addRating = async ({ rating, review, index}: Rate)=>{
    const ratingDetails = state.pendingRatings[index];
    if(ratingDetails?.booking_id){
        setState({ loading: true, error: false });
        const res = await asyncHandler({
        apiCall: () => API.addRating({ rating, review, ...ratingDetails}),
        });
        const { success, data } = res;
        setState({ loading: false, error: !success, pendingRatings: data || []});

        return success;
    } else{
     return false;
    }
  }

  useEffect(() => {
    getPendingRatings();
  }, []);

  return {
      ...state,
      addRating,
      refresh: getPendingRatings
  };
}

export default useRating;