import React, { useState, useRef } from "react";
import { Button, Notification } from "platyplex_ui";
import { API } from "../../config";
import { compressImage } from "../../utils/images";

interface Props {
  style?: React.CSSProperties;
  text?: string;
  showFile?: boolean;
  setImages: (e: string) => void;
}

const FileUpload = ({ style, text,  setImages }: Props) => {
  const [state, setter] = useState({ uploaded: false, loading: false });

  const setState = (obj: any) => setter({ ...state, ...obj });
  const ref = useRef<any>(null);

  const uploadClick = (e: any) => {
    e.preventDefault();
    console.log(ref.current);
    ref?.current?.click();
  };

  const uploadFile = (file: File) => {
    const url = API + "/v1/utils/uploadTempImages";
    const token = localStorage.getItem("USER_TOKEN") || "";
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
  
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("os", "web");
  
    xhr.setRequestHeader("usertoken", token);
  
    fd.append("file", file);
    xhr.send(fd);
    return new Promise((resolve, reject) => {
      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4) {
          if (xhr.status < 200 || xhr.status > 299) return reject();
          else {
            var response = JSON.parse(xhr.responseText);
            resolve(response);
          }
        }
      };
    });
  };

  const onChange = async (event: any) => {
    event.preventDefault();
    console.log(event);
    let file = event.target.files[0];
    if (file) {
      const compressedBlob = await compressImage(file)
      const compressedFile = new File([compressedBlob], compressedBlob.name, {type: compressedBlob.type, lastModified: Date.now()});

      setState({ uploading: true , loading: true});
      
      uploadFile(compressedFile)
        .then((result: any) => {
          
          const hasFirstResult = result?.success && result?.uploadResult?.length > 0;
        setState({ uploading: false, loading: false });
          if (hasFirstResult && result?.uploadResult[0]?.didUpload) {
            setImages(result.uploadResult[0]?.uploaded?.uploadedFilename);
          } 
          else if(hasFirstResult){
            throw new Error(result.uploadResult[0]?.rejected?.reason || 'Error Uploading')
          }
          else throw new Error('Error Uploading')
        })
        .catch((err) => {
          Notification.error({ title: "Error", message: err.message || JSON.stringify(err) });
          setState({ uploading: false, loading: false });
        });
    } else setState({ uploading: false, loading: false });
  };

  return (
    <>
      <Button
        loading={state.loading}
        shadow
        onClick={uploadClick}
        disabled={state.loading}
        style={{ ...style, ...{ placeSelf: "center" }, style }}
      >
        {!state.loading && <i className="fa fa-upload" />}
        {text}
      </Button>
      <input
        type="file"
        name="file"
        ref={ref}
        style={{ display: "none" }}
        onChange={(e) => onChange(e)}
      />
    </>
  );
};

export default FileUpload;
