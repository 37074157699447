import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Avatar, Grid } from "platyplex_ui";
import styled, { keyframes } from "styled-components";
import { cloudinaryPrefix, gradient, primaryColor, primaryTxtColor } from "../../config";

const Sidebar = () => {
  const { details } = useSelector((state: any) => state.user);
  const location = useLocation().pathname;
  const routes = [
    { to: [`/`], name: "Book", icon: "far fa-compass" },
    { to: [`/login`], name: "Login/Signup", icon: "fa fa-user" },
  ];

  const authRoute = [
    { to: [`/`], name: "Book", icon: "far fa-compass" },
    { to: [`/listings`], name: "Listings", icon: "fas fa-warehouse" },
    { to: [`/analytics`], name: "Analytics", icon: "fas fa-chart-line" },
    {
      to: [`/booking-history`],
      name: "Booking History",
      icon: "fa fa-history",
    },
    { to: [`/wallet`], name: "Wallet", icon: "fa fa-wallet" },
    { to: [`/help`], name: "Help", icon: "fa fa-question-circle" },
    { to: [`/legal`], name: "Legal", icon: "fa fa-gavel" },
  ];

  return (
    <Sideba
      avatar={
        details?.image_url? (cloudinaryPrefix + details?.image_url) :
        "https://www.sunsetlearning.com/wp-content/uploads/2019/09/User-Icon-Grey-300x300.png"
      }
      avatarStyle={{
        borderRadius: "50%",
      }}
      name={`Hi ${details?.shortName || details?.user_name?.split(" ")[0]}`}
      active={location}
      routes={details?._id ? authRoute : routes}
      Router={NavLink}
    />
  );
};

const Sideba = ({
  active = "",
  routes = [],
  avatar,
  role,
  name,
  Router,
  avatarStyle,
  message,
}: any) => {
  const [collapsed, setCollapsed] = useState(true);
  const location = active;

  const makeStr = (bool: boolean): string => String(bool);
  const activeStyle = {
    borderLeft: ".3em solid " + primaryColor,
  };

  const events = {
    onClick: () => setCollapsed(false),
  };

  const collapsedHidden = collapsed ? "hide" : "";
  const shouldShow = collapsed ? avatar : avatar || name || role;
  const iconStyle = {
    color: primaryColor,
    padding: ".75em",
    height: '100%',
    width: '100%',
    placeSelf: "center end",
  };
  return<>
  <div onClick={()=>setCollapsed(false)} ><i  className="fas fa-bars txt-md pointer" style={iconStyle}/></div>
  {(!collapsed &&
    <LeftNav
      background={""}
      collapsed={collapsed}
      className="noselect slide-left"
    >
      {shouldShow && (
        <Grid.Item style={{background: gradient, height: "100%", borderRadius: 0}} placeSelf='sretch'>
       
          <i
            className="fa fa-times pointer txt-md"
            onClick={() => setCollapsed(true)}
            style={{...iconStyle, color: 'white'}}
          />
             <Link to='/profile'>
          <Grid customCols="auto 1fr" color='white' placeSelf='stretch' placeItems='center stretch' padding={'0 0 2.5em 1em'} mobileLayout={false}>
            {avatar && <Avatar style={avatarStyle} medium src={avatar} />}
            {name && <div className="capitalize txt-md txt-overflow" style={{width: '100px'}}> {name}</div>}
          </Grid>
        </Link>
        </Grid.Item>
      )}

      {routes.map(({ to, name, icon }: any) => {
        return (
          <Router key={name} to={to[0] || "/"}>
            <NavItem
              collapsed={collapsed}
              active={makeStr(
                to.reduce((acc: boolean, _: any) => acc || false, false)
              )}
              style={{
                gridTemplateColumns: collapsed ? "1fr" : "auto 1fr",
                ...(to.includes(location) ? activeStyle : {}),
              }}
            >
              <i
                style={{ fontSize: "1.6em", padding: "0 .3em" }}
                className={icon}
              ></i>
              <div className={"txt-center " + collapsedHidden}>
                {name}
              </div>
              <div style={{ height: "2px" }}></div>
              <div style={{ width: "100%", height: "2px" }}>
                <Line />
              </div>
            </NavItem>
          </Router>
        );
      })}
    </LeftNav>)}
    </>
};

const Line = styled.hr`
  transform: scaleX(0);
`;
const border = keyframes`from { transform: scaleX(0); } to { transform: scaleX(1);}`;

const NavItem = styled.div<{ active: string; collapsed: boolean }>`
  display: grid;
  text-decoration: none;
  place-items: center;
  color: ${primaryTxtColor};
  font-size: 1.35em;
  cursor: pointer;
  padding: 0.8em 0.4em;


  &:hover ${Line} {
    color: ${primaryColor};
    animation: ${border} 300ms ease-out;
    transform: scaleX(1);
  }
`;

const LeftNav = styled.div<{ background: string; collapsed: boolean }>`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 0.8em;
  display: grid;
  position: fixed;
  left: 0;
  z-index: 1000;
  bottom: 0;
  top: 0;
  color: ${primaryColor};
  ${({ collapsed }: any) => `width: ${collapsed ? "6em" : "250px"};`}
  grid-template-rows: repeat(16, auto);
  align-items: center;
  justify-items: stretch;
  transition: 100ms ease-out;
  background-color: white;
  background-image: url(${(props) => props.background});
  background-attachment: fixed;
`;

export default Sidebar;
