import React, { useState } from "react";
import { Grid, Text, Header } from "platyplex_ui";
import Wrapper from "../overlay-wrapper";
import Bar from "../../pages/checkout/components/bar";
import { Link } from "react-router-dom";
import AllowedVehicles from "../allowed-vehicles/allowed-vehicles";
import { primaryColor } from "../../config";

interface Props {
  close: () => void;
  allowedVehicles?: string[];
}
const VehicleWarning = ({ close, allowedVehicles }: Props) => {
  const [showVehicles, setShowVehicles] = useState(false);
  if (showVehicles)
    return (
      <ShowAllowedVehicles
        close={() => setShowVehicles(false)}
        allowedVehicles={allowedVehicles}
      />
    );

  return (
    <Wrapper
      style={{
        position: "absolute",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1000,
      }}
      back={close}
    >
      <Grid placeSelf="start center">
        <div>
          <Header bold color={primaryColor}>
            Opps!
          </Header>
          <Header bold color={primaryColor}>
          Your vehicle type might not fit or isn't
          </Header>
          <Header bold color={primaryColor}>
          allowed by the host to park in this spot!
          </Header>
        </div>
        <Bar />
     
          <Grid
            className="pointer"
            placeSelf="stretch"
            placeItems="center stretch"
            customCols="1fr auto"
            onClick={() => setShowVehicles(true)}
          >
            <Text textAlign="left">
              See allowed vehicle types for this spot
            </Text>
            <i className="fa fa-chevron-right bold" />
          </Grid>
      
        <Bar />
        <Link to={"/"} style={{ placeSelf: "stretch" }}>
        <Grid
          className="pointer"
          placeSelf="stretch"
          placeItems="center stretch"
          customCols="1fr auto"
       
        >
          <Text textAlign="left">
            Search parking spots that fit your vehicle type
          </Text>
          <i className="fa fa-chevron-right bold" />
        </Grid>
        </Link>
        <Bar />
      </Grid>
    </Wrapper>
  );
};

const ShowAllowedVehicles = ({ close, allowedVehicles }: Props) => {
  return (
    <Wrapper
      style={{
        position: "absolute",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1000,
      }}
      back={close}
    >
      <Grid placeSelf="start center">
        <Header bold color={primaryColor}>
          Allowed Vehicles
        </Header>

        <Text textAlign="left">
          The following type of vehicles are allowed that will fit in this
          parking space
        </Text>

        <Bar />
        <Grid placeItems="center start" placeSelf="start">
          <AllowedVehicles allowedVehicles={allowedVehicles || []} />
        </Grid>
      </Grid>
    </Wrapper>
  );
};
export default VehicleWarning;
