import moment from 'moment';

export const getParkingDate = (
    booking_type: string,
    date: string,
    time?: string
  ): string => {
    const isDaily = booking_type === "daily";
    const newDate = isDaily ? moment(`${date} ${time}`) : moment(`${date}`);
    const isToday = newDate.isSame(moment(), "day");
    let format = "";
    if (isDaily) {
      format = isToday ? "hh:mm A" : "ddd, MMM DD, hh:mm A";
    } else {
      format = "ddd, MMM DD, YYYY";
    }

    return (isToday ? "Today, " : "") + newDate.format(format);
  };

  export const getDateFormat = (booking_type: string)=> booking_type === "daily"? "ddd, MMM DD, hh:mm A": "ddd, MMM DD, YYYY";