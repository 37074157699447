import React, { useState, useEffect, useRef } from "react";

let autoComplete: any;

function handleScriptLoad(
  updateQuery: (s: string) => void,
  updatePlaceId: (e: string) => void,
  autoCompleteRef: React.RefObject<any>,
  setLoaded: (b: boolean) => void
) {
  try {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: [], componentRestrictions: { country: ["us", "ca"] } }
    );
    autoComplete.setFields(["place_id", "name", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery, updatePlaceId)
    );
    setLoaded(true);
  } catch (e) {
    setLoaded(false);
  }
}


async function handlePlaceSelect(
  updateQuery: (s: string) => void,
  updatePlaceId: (s: string) => void
) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  updatePlaceId(addressObject.place_id || "");
  console.log(addressObject);
}

function AutoComplete({
  style,
  updatePlaceId,
  className
}: {
  style: React.CSSProperties;
  value?: string;
  className?: string;
  updatePlaceId: (s: any) => void;
}) {
  const [query, setQuery] = useState("");
  const [loaded, setLoaded] = useState(false);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    if (!loaded)
      handleScriptLoad(setQuery, updatePlaceId, autoCompleteRef, (e: boolean) =>
        setLoaded(e)
      );
  }, [loaded]);

  return (
    <div className={"search-location-input "+ (className || '') }>
      <input
        ref={autoCompleteRef}
        onChange={(event) => {
          setQuery(event.target.value);
        }}
        placeholder="Where to? "
        value={query}
        style={style}
      />
    </div>
  );
}

export default AutoComplete;