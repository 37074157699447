import React from 'react';
import BookingInfoHost from './booking-info-host';
import BookingInfoDriver from './booking-info-driver';
import { Drawer } from 'antd';

export default ({  close, booking, as, type, reload, extend}: any) => {
    const props = {
        loading: false,
        close, 
        booking,
        type,
        extend: (e: any)=>{
            close();
            extend(e);
        },
        as, 
        reload: ()=>{
            close();
            reload();
        }
    }
    return (
        <Drawer
            placement="right"
            width={450}
            closable={false}
            bodyStyle={{padding: 0}}
            onClose={close}
            visible={true}
        >
            {as === 'HOST'? 
            <BookingInfoHost {...props}/> :
            <BookingInfoDriver {...props}/>}
        </Drawer>);
}