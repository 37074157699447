import React, { useEffect, useState } from "react";
import { primaryColor } from "../../../../config";
import api from "../../../../api";
import { Link } from "react-router-dom";
import { Text, Grid } from "platyplex_ui";
import { PopupThread } from "../../../../model/domain/bookingthread";

const BookingPopup = ({ style }: { style?: React.CSSProperties }) => {
  const [popup, setPopup] = useState<undefined | PopupThread>(undefined);

  const getPopup = () => {
    api
      .getBookingPopup()
      .then((res: any) => {
        if (res.success && res.thread) {
          setPopup(res.thread);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => getPopup(), []);

  if (!popup) return null;

  return (
    <Grid
      className="shadow grid slide-down"
      customCols="auto 1fr"
      mobileLayout={false}
      style={{
        zIndex: 9,
        background: primaryColor,
        fontSize: "1.2em",
        width: "auto",
        placeSelf: "start stretch",
        borderRadius: "2em",
        padding: ".2em .5em",
        margin: ".25 .5em",
        ...style,
      }}
    >
      <i
        className="fa fa-times-circle txt-md pointer"
        style={{ color: "white" }}
        onClick={() => setPopup(undefined)}
      />
      <Link className="pointer" to={`/booking-history/?booking=${popup._id}`}>
        <Text color="white">
          You have an active boooking starting {popup.pretty_start}. Click to
          view details &nbsp; <i className="fas fa-arrow-circle-right" />
        </Text>
      </Link>
    </Grid>
  );
};

export default BookingPopup;
