import { Card, Header, Text, Button } from "platyplex_ui";
import React from "react";
import { Link } from "react-router-dom";
import { gradient, primaryColor } from "../../config";

interface Props {
  title?: string;
  message?: string;
  footer?: string |  JSX.Element;
  link?: string;
  onBack?: () => void;
  style ?: React.CSSProperties;
}
const Success = ({ title, message, footer, link, onBack, style }: Props) => {
  return (
   
      <Card shadow style={{ maxWidth: 800, minWidth: "40vw", placeSelf: 'start center',...style }} grid>
        <i
          className="fas fa-check-circle txt-lgx"
          style={{ color: primaryColor }}
        />
        <Header>{title}</Header>
        <Text bold>{message}</Text>
        {footer}
        {link ? (
          <Link to={link}>
             <Button rounded noHover bg={gradient}>Done</Button>
          </Link>
        ) : onBack ? (
          <Button rounded noHover bg={gradient} onClick={onBack}>Done</Button>
        ) : null}
      </Card>
  );
};

export default Success;
