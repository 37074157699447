import React from "react";
import { Grid, Text, List } from "platyplex_ui";
import { Switch, Tooltip } from "antd";
import useAmenities from "../../hooks/amenities";
import { primaryColor } from "../../config";

interface Props {
  amenities: string[];
  setAmenities: (s: string[]) => void;
  info?: boolean
}
const  Amenities = ({ amenities = [], setAmenities, info }: Props) => {
  const { amenities: amenitiesOptions, loading } = useAmenities();
  const addAmenities = (checked: boolean, amenity: string) => {
    if (checked) {
      if (amenities?.includes(amenity)) return;
      setAmenities([...amenities, amenity]);
    } else {
      if (!amenities?.includes(amenity)) return;
      setAmenities(amenities.filter((a: any) => a !== amenity));
    }
  };
  return (
    <Grid placeSelf="stretch" placeItems="stretch" gridGap="0">
      <List
        loading={loading}
        list={amenitiesOptions}
        map={({ name, displayName, imageUrl }: any) => (
          <Grid customCols="1fr auto" key={displayName} mobileLayout={false}>
            <Text textAlign="left" width="100%" margin=".25em 0">
              <img
                src={imageUrl}
                style={{ maxWidth: "25px" }}
                alt={displayName}
              />{" "}
              &nbsp; {displayName}  &nbsp;
              {name === "drop_off" && info && (
                <Tooltip
                  title={
                    <div>
                      <Text bold color="white">
                        Drop off feature
                      </Text>
                      <Text color="white">
                        Turn this on if you are willing to drop off the driver
                        to their final destinations after they park in your
                        spot, within reasoable distance of 3 miles radius for a
                        small fee
                      </Text>
                      <Text color="white">
                        If your spot is not nearby any vent centers, university
                        downtown, grocerry shops. shopping centers etc,
                        selecting this feature will double the chances of your
                        spot etting booked.{" "}
                      </Text>
                    </div>
                  }
                >
                  <i
                    className="fa fa-info-circle"
                    style={{ color: primaryColor }}
                  />
                </Tooltip>
              )}
            </Text>
            <Switch
              onChange={(e) => addAmenities(e, name)}
              checked={amenities?.indexOf(name) !== -1}
            />
          </Grid>
        )}
      />
    </Grid>
  );
};

export default Amenities;
