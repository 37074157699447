import { Grid, Modal, Text } from 'platyplex_ui';
import React from 'react';
import PrimaryModal from '../primary-modal';

const BannedModal = (logout: () => void) => {
    const modal = Modal({
      title: "",
      modal: (
        <PrimaryModal
          cancelText="Ok"
          onCancel={() => {
            logout();
            modal.close();
          }}
        >
          <Grid gridGap=".25em" padding="1em">
            <Text bold>Account Locked</Text>
            <Text>
              Your account has been locked for security reasons. Please contact
              support@findparkstash.com to request an account review.
            </Text>
          </Grid>
        </PrimaryModal>
      ),
      hideCancel: true,
    });
    modal.open();
  };
  
  export default BannedModal;
  