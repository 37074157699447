import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Card, Grid, Text, Notification, Loader, Button } from "platyplex_ui";
import queryString from "query-string";
import { disabledTxtColor } from "../../config";
import { useLocation } from "react-router";
import { Parking } from "../../model/domain/parking";
import api from "../../api";
import Images from "../../components/images";
import Summary from "./components/summary";
import { Review } from "./type";
import BookingReview from "./components/booking-review";
import { useDispatch, useSelector } from "react-redux";
import Timer from "./components/timer";
import Payment from "./components/payment";
import Success from "./components/success";
import NewModal from "../../components/new-modal";
import VehicleWarning from "../../components/vehicle-modal/vehicle-warning";
import { Vehicle } from "../booking-history/type";
import { reloadUserDetails } from "../../store/actions/user";
import { Link } from "react-router-dom";
import { PaymentMethod } from "@stripe/stripe-js";
import { overflow, overflowTxt } from "../../utils/text";

enum Stage {
  REVIEW,
  PAYMENT,
}

interface State {
  stage: Stage;
  error: boolean;
  errorMessage: string;
  loading: boolean;
  success: boolean;
}

const Checkout = () => {
  const location = useLocation().search;
  const { selectedVehicle, selectedVehicles} = useSelector((s: any) => s.vehicles);
  const { selectedCard } = useSelector((s: any) => s.cards);
  const query = queryString.parse(location) as any;

  const {
    start_date,
    end_date,
    parking_id,
    end_hour,
    start_hour,
    booking_type,
    spot_count
  } = query;

  const [reviewQuery, setReviewQuery] = useState({
    start_date,
    end_date,
    parking_id,
    end_hour,
    start_hour,
    parking_type: booking_type,
    spot_count
  });

  const [parking, setParking] = useState<any | Parking>(undefined);
  const dispatch = useDispatch();
  
  const getParking = () => {
    api
      .getOneParking(parking_id)
      .then((res: any) => {
        res.success && setParking(res.parking);
      })
      .catch((e) => {
        Notification.error({
          message: e.message || e,
          title: "Failed Getting Parking",
        });
      });
  };
  const [state, setter] = useState<State>({
    stage: Stage.REVIEW,
    error: false,
    loading: false,
    errorMessage: '',
    success: false,
  });

  const setState = (obj: Partial<State>) => setter({ ...state, ...obj });

  const [review, setReview] = useState<Review | undefined>(undefined);

  const reviewBooking = () => {
    const hours =
      booking_type === "daily"
        ? {
            start_hour,
            end_hour,
          }
        : {};
    api
      .reviewBooking({
        ...reviewQuery,
        ...hours,
      })
      .then((res: any) => {
        if (res.success) {
          setReview(res.review);
        } else throw new Error(res.message)
      }).catch((e)=>setState({ error: true, errorMessage: e?.message || e }))
  };

  useEffect(() => {
    getParking();
    reviewBooking();
  }, [reviewQuery]);

  const submit = async (payment_method = "card") => {
    await api
      .book({
        parking_type: booking_type,
        parking_id,
        vehicle_ids: selectedVehicles?.map((v: Vehicle)=> v?._id),
        payment_method,
        start_date,
        spot_count,
        end_date,
        end_hour,
        start_hour,
        card_id: payment_method === "card" ? selectedCard?.id : undefined,
      })
      .then((res: any) => {
        if (!res.success) {
          throw new Error(res.message);
        } else {
          Notification.success({ title: "Success", message: res.message });
          setState({ success: true });
          dispatch(reloadUserDetails())

        }
      })
      .catch((e) => Notification.error({ title: "Error", message: e.message }));
  };

  const canVehicleFit = (vehicle?: Vehicle, allowedVehicles?: string[]) => {
    const hasVehicleClass = allowedVehicles?.reduce(
      (prev: boolean, curr: string) => prev || curr === vehicle?.vehicle_class,
      false
    );

    return hasVehicleClass || false;
  };

  const VehicleWarningModal = NewModal({
    modal: (
      <VehicleWarning
        close={() => VehicleWarningModal.close()}
        allowedVehicles={parking?.vehicle_classes}
      />
    ),
  });
  const completeBookingPreview = () => {
    if (canVehicleFit(selectedVehicle, parking?.vehicle_classes))
      setState({ stage: Stage.PAYMENT });
    else {
      VehicleWarningModal.open();
    }
  };
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | any>(
    review?.has_sufficient_wallet_balance? "wallet" : "card"
  );

  return (
    <Layout sidebar style={{ gridTemplateRows: "1fr auto" }}>
      {VehicleWarningModal.modal}
      {state.error?
      <Grid width='100%' height='100%'>
        <Card style={{minWidth: '50vw'}} shadow grid>
          <div>
          <Text bold size='1.2em'>Error</Text>
          <Text>{state.errorMessage}</Text>
          </div>
          <Link to='/'><Button rounded>Back to Homepage</Button></Link>

        </Card>
        </Grid>:
      !review ? (
        <Loader />
      ) : state.success ? (
        <Success
          review={review as any}
          card={selectedCard}
          vehicle={selectedVehicle}
        />
      ) : (
        <>
          <Timer
            finish={() => window.close()}
            time={600000}
            message={"We will hold this spot for : "}
          />
          <Grid
            placeItems="stretch"
            customCols="1fr auto"
            style={{ maxWidth: "1300px", margin: "auto" }}
          >
            <Grid placeItems="start stretch" gridGap="0" customRows="auto 1fr">
              {review && (
                <>
                  <BookingReview
                    active={state.stage === Stage.REVIEW}
                    review={review}
                    spot_count={spot_count}
                    next={completeBookingPreview}
                    edit={() => setState({ stage: Stage.REVIEW })}
                  />

                  <Payment
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                    review={review as any}
                    next={submit}
                    active={state.stage === Stage.PAYMENT}
                    reviewBooking={reviewBooking}
                  />
                </>
              )}
            </Grid>

            <Grid placeItems="stretch" gridGap="0" customRows="auto auto 1fr">
              <Card shadow style={{minHeight: '10em'}}>
                {!parking? 
                <Loader.Spinner />:
                (
                  <Grid customCols="auto 1fr" >
                    {(parking?.image_url?.length as any) > 0 ? (
                      <Images
                        style={{ width: "11em" }}
                        images={parking?.image_url}
                      />
                    ) : (
                      <i
                        style={{ fontSize: "10em", color: disabledTxtColor }}
                        className="fas fa-parking"
                      ></i>
                    )}

                    <Text placeSelf="start">
                      <Text textAlign="left" bold>
                        {overflow(parking?.name)}
                      </Text>
                    
                    </Text>
                  </Grid>
                )}
             
              </Card>
              {review && (
                <Summary
                  parking={parking}
                  paymentMethod={paymentMethod}
                  review={review}
                  reviewQuery={reviewQuery}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  );
};

export default Checkout;
