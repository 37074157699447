import React, { useState } from "react";
import { Grid } from "platyplex_ui";
import Wrapper from "../../../../../components/overlay-wrapper";
import useBank from "../../../../../hooks/stripe-token";
import { useDispatch, useSelector } from "react-redux";
import { reloadUserDetails } from "../../../../../store/actions/user";
import BankAccountForm from "./bank-account-form";
import { Account, Entity, Individual, BankAccount } from "../../type";
import BankForm from "./bank-form";
import BankInfo from "./bank-info";
import { getPayoutMethods } from "../../../../../store/actions/bank";

const Bank = ({ close }: { close: () => void }) => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const account = useBank("ACCOUNT");
  const bank = useBank("BANK");
  const { country, currency, stripe_acc_id } = useSelector(
    (s: any) => s.user.details
  );
  const defaultAddress = {
    line1: "",
    line2: "",
    postal_code: "",
    city: "",
    state: "",
  };

  const defaultIndividual: Individual = {
    first_name: "",
    last_name: "",
    dob: undefined,
    ...(country === "CA" ? { id_number: "" } : { ssn_last_4: "" }),
    address: defaultAddress,
  };

  const defaultCompany = {
    name: "",
    tax_id: "",
    address: defaultAddress,
  };

  const [accountInfo, setAccountInfo] = useState<Account>({
    business_type: Entity.INDIVIDUAL,
    company: defaultCompany,
    individual: defaultIndividual,
  });

  const [bankInfo, setBankInfo] = useState<BankAccount>({
    routing_number: "",
    transit_number: "",
    account_number: "",
    confirm_account_number: "",
  });
  const hasStripeAccount = !!stripe_acc_id;
  const nextPage = () => setPage(page + 1);
  const previousPage = () => setPage(page - 1);

  const submitBank = async () => {
    let createdAccount = true;
    if (!hasStripeAccount) {
      createdAccount =
        (await account.createToken({
          tos_shown_and_accepted: true,
          business_type: accountInfo.business_type,
          [accountInfo.business_type]: accountInfo[accountInfo.business_type],
        })) || false;
    }
    const newBankInfo = {
      country,
      currency,
      ...(country === "CA"
        ? {
            transit_number: bankInfo.transit_number,
          }
        : {}),
      routing_number: bankInfo.routing_number,
      account_number: bankInfo.account_number,
      account_holder_name: ``,
      account_holder_type: accountInfo.business_type,
    };
    if (createdAccount) {
      dispatch(reloadUserDetails());
      const createdBank = await bank.createToken(newBankInfo);
      if (createdBank) {
        dispatch(getPayoutMethods());
        close();
      }
    }
  };
  const components = [
    <BankInfo next={nextPage} />,
    ...(hasStripeAccount
      ? []
      : [
          <BankAccountForm
            next={nextPage}
            loading={account.loading}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
          />,
        ]),
    <BankForm
      loading={bank.loading}
      country={country}
      submit={submitBank}
      bankInfo={bankInfo}
      setBankInfo={setBankInfo}
    />,
    <BankForm
    loading={bank.loading}
    country={country}
    submit={submitBank}
    bankInfo={bankInfo}
    setBankInfo={setBankInfo}
  />,
  ];

  return (
    <Wrapper
      style={{
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 100,
      }}
      back={page === 0 ? close : previousPage}
    >
      <Grid
        placeSelf="start center"
        placeItems="start center"
        style={{ width: 400, maxWidth: "90vw" }}
      >
        {components[page]}
      </Grid>
    </Wrapper>
  );
};

// enum PayoutComponent {
//   PAYOUT_SELECT,
//   PAYPAL_INFO,
//   BANK_INFO,
//   BANK_ACCOUNT_FORM,
//   BANK_FORM
// };

// const PayoutComponentSelect = (component: PayoutComponent)=>{
//   switch(component){
//     case PayoutComponent.PAYOUT_SELECT:
//       </>
//     default: 

//   }
// }

export default Bank;
