import React, {  useState } from "react";
import { Grid, Overlay } from "platyplex_ui";
import Location from "./location";
import useParkings from "../../../hooks/parkings";
import { Progress } from "antd";
import General from "./general";
import Pricing from "./pricing";
import Descriptions from "./description";
import Wrapper from "../../../components/overlay-wrapper";
import CreateListing from "./create-listing";
import VehicleSelection from "./vehicle-selection";
import useCloseConfirm from "../../../hooks/close-confirm";

export const ListingMultiStep = ({ close, refresh, uId }: any) => {
  const [state, setState] = useState({
    step: 0,
  });
  useCloseConfirm();
  const { addListing } = useParkings();
  const [listing, setListing] = useState<any>({
    name: "",
    active: true,
    geo: [0, 0],
    address: {
      city:  "",
      country: "",
      zipcode: "",
      street:  "",
      state:  "",
  }
  });

  const setGeo = ({ lat, lng, address }: any) => {
    setListing({
      ...listing,
      geo: [lng, lat],
      address,
    });
  };

  const setInfo = (info: any) => {
    setListing({ ...listing, ...info });
    forward();
  };

  const finish = () => {
    refresh();
    close();
  };
  const forward = () => {
    if (state.step < InputComponents.length) {
      setState({ step: state.step + 1 });
    }
  };

  const backward = () => {
    if (state.step > 0) {
      setState({ step: state.step - 1 });
    } else close();
  };

  const progress = (
    <Progress percent={(state.step / 5) * 100} showInfo={false} />
  );

  const InputComponents = [
    <Location
      forward={forward}
      setGeo={setGeo}
      progress={progress}
      back={backward}
      listing={listing}
    />,
    <General setInfo={setInfo} progress={progress} back={backward} listing={listing}/>,
    <VehicleSelection setInfo={setInfo} progress={progress} back={backward} listing={listing}/>,
    <Descriptions setInfo={setInfo} progress={progress} back={backward} listing={listing}/>,
    <Pricing setInfo={setInfo} progress={progress} back={backward} listing={listing}/>,
    <CreateListing
      addListing={() => addListing(listing)}
      finish={finish}
      images={listing.images}
      progress={progress}
      back={backward}
    />,
  ];


  return (
    <Overlay className="opacity" image={"white"}>
      {InputComponents[state.step]}
    </Overlay>
  );
};

const Wrap = ({ children, back }: any) => (
  <Wrapper back={back}>
    <Grid >{children}</Grid>
  </Wrapper>
);

export const Form = ({
  children,
  footer,
  header,
  back,
  style,
  onSubmit,
}: any) => (
  <Wrap back={back}>
    <form
      onSubmit={onSubmit}
      style={{
        height: "90vh",
        display: "grid",
        maxWidth: "90vw",
        width: "800px",
        placeItems: "stretch",
        gridGap: "1.25em",
        paddingBottom: '1em'
      }}
    >
      {header}
      <Grid
        style={{ maxHeight: "100%", overflowY: "auto", ...style }}
        placeItems="stretch"
        padding="0 1em"
        placeSelf="center stretch"
      >
        {children}
      </Grid>
      {footer}
    </form>
  </Wrap>
);
