import { useState } from "react";
import { useSelector } from "react-redux";
import { defaultLocation, geoCodeAPIKey, googleAPIKey } from "../config";

type Cood = {
  lat: number;
  lng: number;
};

interface Address {
  city: string;
  country: string;
  zipcode: string;
  street: string;
  state: string;
};

interface State {
  loading: boolean;
  error: boolean;
  result?: {
    geo: Cood;
    formattedAddress: string;
    address: Address;
  };
}

export const usePlaces = (limitToHomeCountry = false) => {
  const { details } = useSelector(
    (state: any) => state.user
);
  const defaultAddress = {
    city: "",
    country: "",
    zipcode: "",
    street: "",
    state: "",
  };

  const address_components = [
    "route",
    "locality",
    "administrative_area_level_1",
    "country",
    "postal_code",
  ];

  const getAddressComponent = (addresses: any[], component: string) => {
    const result = addresses.filter((address: any) =>
      address.types.includes(component)
    );

    return result[0]?.short_name || "";
  };

  const origin = { lat: 32, lng: -92 };
  const defaultSearch = {
    found: false,
    result: "",
    address: defaultAddress,
    resultLocation: origin,
  };

  const [error, setError] = useState(true);
  const [search, setSearch] = useState(defaultSearch);
  const [searchText, setSearchText] = useState("");
  const [location, setLocation] = useState(defaultLocation);

  const getAddress = (addr: string) => {
    setError(true);
    //API_KEY
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${addr}&key=${geoCodeAPIKey}`;

    if (addr.length > 0)
      fetch(url)
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "OK" && res.results && res.results.length > 0) {
            const filteredResults = res.results.filter(({ address_components}: any)=>{
              const [ countryComponent ] = address_components.filter(({ types }: { types: string[]})=> types?.includes('country'))
              return (countryComponent?.short_name === details.country)
            })

            let results = res.results

            if(limitToHomeCountry){
              results = filteredResults;
            }

            if(results.length === 0)
              throw new Error();

            const result = results[0].formatted_address;
            const resultLocation = {
              lat: results[0].geometry.location.lat,
              lng: results[0].geometry.location.lng,
            } as Cood;

            const addresses = results[0].address_components;
            let address = defaultAddress;
            if (addresses.length >= 5) {
              const hasAll = address_components.reduce(
                (acc, curr) =>
                  acc &&
                  addresses.reduce(
                    (ac: boolean, address: any) =>
                      ac || address.types?.includes(curr),
                    false
                  ),
                true
              );
              if (hasAll) {
                address = {
                  street: `${getAddressComponent(
                    addresses,
                    "street_number"
                  )} ${getAddressComponent(addresses, "route")}`.trim(),
                  city: getAddressComponent(addresses, "locality"),
                  state: getAddressComponent(
                    addresses,
                    "administrative_area_level_1"
                  ),
                  country: getAddressComponent(addresses, "country"),
                  zipcode: getAddressComponent(addresses, "postal_code"),
                };
              }
            };

            setSearch({
              ...search,
              result,
              resultLocation,
              found: true,
              address,
            });
          } else throw new Error();
        })
        .catch((e) => {
          setSearch({
            ...search,
            result: "No Result",
            found: false,
            address: defaultAddress,
          });
        });
    else setSearch({ ...search, result: "", address: defaultAddress });
  };

  const selectResult = (result: string) => {
    setSearchText(result);
    setError(false);
    setLocation(search.resultLocation);
  };


  return {
    searchText,
    search,
    error,
    location,
    selectResult,
    resetSearch: () => setSearch(defaultSearch),
    getAddress,
    setSearchText,
    setLocation,
  };
};

export default { usePlaces };
