import React from "react";
import { Grid, Modal } from "platyplex_ui";
import { LAYER, } from "../../config";
import PrimaryModal from "../primary-modal";

const PopupInfo = ({
  children,
  title
}: {
  children: JSX.Element | string;
  title: JSX.Element | string;
}) => {


  const openModal = (info: any) => {
    const InfoModal = Modal({
      title: "",
      className: 'top1',
      modal: (
        <PrimaryModal
          style={{ zIndex: LAYER.last }}
          onCancel={() => { InfoModal.close() }}
        >
          <Grid gridGap=".25em" padding="1em">
            {info}
          </Grid>
        </PrimaryModal>
      ),
      hideCancel: true,
    });
    InfoModal.open();
  }


  return (
    <span onClick={() => openModal(title)} className={'pointer'}>
      {children}
    </span>
  );
};

export default PopupInfo;
