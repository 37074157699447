import React from "react";
import { Grid, Text } from "platyplex_ui";
import { Select, Tooltip, Input } from "antd";
import { primaryColor } from "../../../config";
import PopupInfo from "../../../components/popup-info";

const SpotType = ({ spotType, setSpotType, setSpotDecription, spotDescription }: any) => (
  <>
    <div
      style={{ placeSelf: "stretch", display: "grid", placeItems: "stretch" }}
    >
      <Text textAlign="left" bold>
        Choose a type of your parking space <span className="red">* </span>
        <PopupInfo
          title={
            <div><b>Driveway</b><br />
              A driveway is a type of private road for local access to one or a
              small group of structures, and is owned and maintained by an
              individual or group.

              <br /><br /><b>Carport</b><br />
              A carport is a covered structure used to offer limited protection
              to vehicles, primarily cars, from rain and snow. The structure can
              either be free standing or attached to a wall.

              <br /><br /><b>Garage</b><br />
              By garage we refer to building or shed for housing a motor vehicle
              or vehicles. These can be residential garages attached to
              individual homes or garages owned by various businesses for
              parking. You can also rent out your parking permits or any other
              space you can think of which can be used for parking and if it's
              legal to do so. Contact us at 
              <a href="mailto:support@findparkstash.com">support@findparkstash.com</a> if you
              have any questions about renting your space.
            </div>
          }
        >
          <i
            className={"fa fa-info-circle"}
            style={{ color: primaryColor }}
          ></i>
        </PopupInfo>
      </Text>
      <Select value={spotType} onChange={(e) => {
        setSpotType(e);
        if(e !== 'other'){
          setSpotDecription(undefined)
        }
      }}>
        {["garage", "carport", "driveway", "other"].map((type) => (
          <Select.Option value={type} key={type}>
            <span className='capitalize'>{type}</span>
          </Select.Option>
        ))}
      </Select>
    </div>

    {spotType.toLowerCase() === "other" && (
      <Grid
        margin=".6em 0"
        placeSelf="stretch"
        placeItems="stretch"
        gridGap="0"
      >
        <Text textAlign="left" bold>
          Type of parking space <span className="red">*</span>
        </Text>
        <Input name="spot" onChange={(e)=> setSpotDecription(e.target.value)} value={spotDescription}/>
      </Grid>
    )}
  </>
);

export default SpotType;
