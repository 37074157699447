import { authHeader , header  } from './header';
const delay = (f: any) => new Promise(resolve => setTimeout(resolve, f));

const DELAY = 400; 

const delayedResponse = async (response: any) => {
  await delay(DELAY)
  return response.json()
};

const response = (res: any) => res.json()

export function post(url: string, body: object, useDelay = true) {
  return fetch(url, { method: 'POST', headers: header(), body: JSON.stringify(body) })
    .then(useDelay? delayedResponse: response);
}

export  function get(url: string, useDelay = true) {
  return fetch(url, { method: 'GET', headers: header() })
  .then(useDelay? delayedResponse: response);
}

export  function standardGet(url: string, useDelay = true) {
  return fetch(url, { method: 'GET' })
  .then(useDelay? delayedResponse: response);
}


export function authGet(url: string,  useDelay = true, query = {} ){
  return fetch(url, { method: 'GET', headers: authHeader(), ...query })
  .then(useDelay? delayedResponse: response);
}

export function authCustom(url: string, header: any, useDelay = true){
  return fetch(url, { method: 'GET', headers: header} )
  .then(useDelay? delayedResponse: response);
}

export function authDelete(url: string, body = undefined, useDelay = true){
  return fetch(`${url}`, { method: 'DELETE', headers: authHeader(), ...body?{body: JSON.stringify(body)}: {} } )
  .then(useDelay? delayedResponse: response);
}

export function authDeleteBody(url: string, body: any, useDelay = true){
  return fetch(`${url}`, { method: 'DELETE', headers: authHeader(), body: JSON.stringify(body)} )
  .then(useDelay? delayedResponse: response);
}

export function customPost(url: string, header: any, body: object, useDelay = true) {
  return fetch(url, { method: 'POST', headers: {"Content-Type": "application/json", ...header} , body: JSON.stringify(body) })
  .then(useDelay? delayedResponse: response);
}

export function authPost(url : string, body : any, useDelay = true) {
  return fetch(url, { method: 'POST', headers: authHeader(), body: JSON.stringify(body) })
  .then(useDelay? delayedResponse: response);
}


export function postFormData(url : string, body : any, useDelay = true) {
  return fetch(url, { method: 'POST',   headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }, body })
  .then(useDelay? delayedResponse: response);
}

export function authPut(url : string, body : any, useDelay = true) {
  return fetch(url, { method: 'PUT', headers: authHeader(), body: JSON.stringify(body) })
  .then(response);
}

export function authGetQuery(url: string, query : string){
  return fetch(`${url}/?${query}`, { method: 'GET', headers: authHeader() })
    .then((response : any) => response.json());
}

export function authGetParams(url: string, param : string){
  return fetch(`${url}/${param}`, { method: 'GET', headers: authHeader() })
    .then((response : any) => response.json());
}

export function authUpload(url : string, file : any) {
  return fetch(url, { method: 'POST', body: file })
  .then((response : any) => response.json());
}
