import React from "react";
import { Grid } from "platyplex_ui";
import moment from 'moment';

const DailyParking = (props: any) => {
  return (
    <Grid customCols="auto 1fr" placeSelf="stretch">
      {["Sun", "Mon", "Tue", "Wed", "Thru", "Fri", "Sat"].map((day, i) => {
        const format = "hh:mm A";
        const time = props.daily_parking.time[i];
        const startTime = moment()
          .hours(time.startHour[0])
          .minutes(time.startHour[1])
          .format(format);
        const endTime = moment()
          .hours(time.endHour[0])
          .minutes(time.endHour[1])
          .format(format);

        return (
          <>
            <div>{day}</div>
            {time.active ? (
              <div>
                {startTime} to {endTime}
              </div>
            ) : (
              "None"
            )}
          </>
        );
      })}
    </Grid>
  );
};

export default DailyParking;
