import { Dispatch } from "react";
import { AddressData } from "../../hooks/places_v1";
import {
  ActionTypes,
  SearchNearbyQuery,
  SearchAction,
} from "../reducer/search";

export const setQuery = (query: Partial<SearchNearbyQuery>) => {
  return async (dispatch: Dispatch<SearchAction | any>) => {
    dispatch({
      type: ActionTypes.SET_SEARCH,
      payload: {
        query,
      },
    });
  };
};

export const setInitQuery = (query:  Partial<SearchNearbyQuery>) => {
  return async (dispatch: Dispatch<SearchAction | any>) => {
    dispatch({
      type: ActionTypes.INIT_QUERY,
      payload: {
        query,
      },
    });
  };
};

export const setAddressData = (
  addressData: Partial<AddressData>,
  query = true,
  userMarker = true
) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.SET_ADDRESS_DATA,
      payload: { addressData },
    });

    if (userMarker) {
      dispatch({
        type: ActionTypes.SET_USER_GEO,
        payload: { addressData },
      });
    }
    if (query) {
      dispatch({
        type: ActionTypes.SET_SEARCH,
        payload: {
          query: {
            lat: addressData.geo?.lat,
            long: addressData.geo?.lng,
          },
        },
      });
    }
  };
};
