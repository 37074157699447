import * as TS from '@pstash/model/domain/timeseries'
import { API } from '../config';
import { authHeader } from './utils/header';

const route = (r: string) => API + r;
async function getTimeseries(tsRequest: TS.TimeseriesRequest): Promise<TS.TimeseriesResult>
{
  const resultFromCache = cache_get_timeSeries(tsRequest);
  if (resultFromCache)
  {
    console.log('returning timeseries from cache:');
    return resultFromCache;
  }

  const url = route('/v1/timeseries');

  return fetch(url, { method: 'POST', headers: authHeader(), body: JSON.stringify(tsRequest) })
    .then(parseFetchResponse_onlySuccess)
    .then(result =>
    {
      const timeseriesResult = result.timeseriesResult as TS.TimeseriesResult;
      timeseriesResult.t0 = new Date(timeseriesResult.t0);
      timeseriesResult.t1 = new Date(timeseriesResult.t1);
      timeseriesResult.points = timeseriesResult.points.map(p => ({ value: p.value, at: new Date(p.at) }));

      cache_set_timeSeries(tsRequest, timeseriesResult, 60);

      return timeseriesResult;
    });
}

async function parseFetchResponse_onlySuccess(res: Response)
{
  let result;
  try
  {
    result = await res.json();
  }
  catch(e)
  {
    throw new Error(`Couldn't parse json`);
  }

  if (!result.success)
  {
    throw new Error(result.message);
  }

  return result;
}

//BEGIN: local storage caching of timeseries

//NOTE: copied from permit app

function cache_get_timeSeries(request: TS.TimeseriesRequest): TS.TimeseriesResult | null
{
  const key   = serializeTimeseriesRequest(request);
  const value = getLocalStorage(key);
  if (value)
  {
    const o =  JSON.parse(value);
    const result: TS.TimeseriesResult = {
      t0: new Date(o.t0),
      t1: new Date(o.t1),
      points: o.points.map((p: any) => ({ at: new Date(p.at), value: p.value }))
    };
    return result;
  }
  return null;
}

function cache_set_timeSeries(request: TS.TimeseriesRequest, result: TS.TimeseriesResult, expiresInSeconds: number)
{
  const key = serializeTimeseriesRequest(request);
  const value = JSON.stringify(result);
  setLocalStorage(key, value, expiresInSeconds);
}

function setLocalStorage(key: string, value: string, expiresInSeconds?: number)
{
  let expiresAt = -1;
  if (expiresInSeconds && expiresInSeconds > 0)
  {
    expiresAt = new Date().valueOf() + expiresInSeconds * 1000;
  } 

  const valueWithExpiry = JSON.stringify({ value, expiresAt });
  localStorage.setItem(key, valueWithExpiry);
}

function getLocalStorage(key: string): string | null
{
  const value = localStorage.getItem(key) || null;
  if (value)
  {
    try
    {
      const valueWithExpiry = JSON.parse(value);
      let result = valueWithExpiry.value as string;

      if (valueWithExpiry.expiresAt > 0)
      {
        const now = new Date().valueOf();
        if (now >= valueWithExpiry.expiresAt)
        {
          removeLocalStorage(key);
        }
        else
        {
          return result;
        }
      }
      else if (valueWithExpiry.expiresAt == -1)
      {
        return result;
      }
      else
      {
        //caught a fish that hadn't been set via setLocalStorage above
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  return null;
}

function removeLocalStorage(key: string)
{
  localStorage.removeItem(key);
}

function serializeTimeseriesRequest(req: TS.TimeseriesRequest)
{
  const x = {
    metricType: req.metricType,
    binSize: req.binSize,
    binUnit: req.binUnit,
    tz: req.tz,
    intervalPreset: req.intervalPreset,
    customInterval: req.customInterval
      ? ({
          t0: req.customInterval.t0.toISOString(),
          t1: req.customInterval.t1.toISOString()
        })
      : null,
    reducerFunction: req.reducerFunction,
    metricSpecificParameter: req.metricSpecificParameter
  };

  return JSON.stringify(x);
}

export default getTimeseries
