import { authPost } from "./utils/request";
import { API } from "../config";
import { EventIntent } from "../pages/events/type";

const route = (r: string) => API + r;

let events = {
  getEvents: ({ enterprise_id }: { enterprise_id: string }) =>
    authPost(
      route(
        `/v1/events/list?extras=evt.pretty_address,evt.pretty_event_start,evt.pretty_event_end,evt.pretty_publish_event_at,evt.pretty_created_at,evt.etkt.pretty_sales_start,evt.etkt.pretty_sales_end,evt.etkt.pretty_pricing,evt.etkt.pretty_dynamic_pricing,evt.etkt.is_active,evt.etkt.precise_available_count,evt.etkt.rough_available_count,evt.ptkt.pretty_price,evt.ptkt.parking`
      ),
      {
        filter1: "active_or_upcoming",
        filter2: "enterprise_id",
        page_no: 1,
        batch_count: 10,
        enterprise_id,
      }
    ),

  reviewReservation: (e: EventIntent | any) =>
    authPost(route(`/v1/events/event-reservations/review`), e),
  reserveReservation: (e: EventIntent | any) =>
    authPost(route(`/v1/events/event-reservations/reserve`), e),
};

export default events;
