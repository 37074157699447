import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddressData } from "../store/actions/search";
import { SearchStoreState } from "../store/reducer/search";

const useLocation = () => {
  const { query }: SearchStoreState = useSelector((s: any) => s.search);
  const dispatch = useDispatch();

  const checkLocation = () => {
    try {
      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
          if (!coords?.latitude || !coords.longitude)
            throw new Error("No Location");

          dispatch(
            setAddressData({
              geo: {
                lat: coords.latitude + Math.random() * 0.0005,
                lng: coords.longitude + Math.random() * 0.0005,
              },
              updatedLocation: true,
              formattedAddress: "",
            })
          );

          return;
        });
      }
      throw new Error("No Location");
    } catch {
    }
  };

  useEffect(() => {
    setTimeout(() => {
      checkLocation();
    }, 500);
  }, []);
  return { checkLocation };
};

export default useLocation;
