import { combineReducers } from 'redux';
import user from './user';
import vehicles from './vehicles';
import cards from './cards';
import banks from './bank';
import search from './search';

export default combineReducers({
  user,
  vehicles,
  banks,
  cards,
  search
})