import { Notification } from "platyplex_ui";
import { useEffect, useState } from "react";



interface State<Data> {
  data?: Data;
  loading: boolean;
  error: boolean;
}

export function useData<Response, Data>({
  request,
  dataKey,
  successMessage
}: {
  request: (...arg: any) => Promise<Response>;
  dataKey?: string;
  successMessage?: boolean;
}, trigger = true) {
  const [state, setter] = useState<State<Data>>({
    loading: false,
    error: false,
  });

  const setState = (obj: Partial<State<Data>>) => setter({ ...state, ...obj });

  const getData = async () => {
    setState({ loading: true, error: false });

    const { data, success } = await requestHandler<Response, Data>({
      request,
      dataKey,
      successMessage
    });

    setState({
      loading: false,
      error: !success,
      data: data as any,
    });
  };

  useEffect(() => {
    if(trigger) getData();
  }, [trigger]);

  return {
    state,
    getData,
    setState,
    refresh: getData,
  };
}

interface ErrorResponse {
  success: false;
  data: undefined; 
  message: string;
}

interface SuccessResponse<T> {
  success: false;
  data: T;
  message: string;
}

export async function requestHandler<Response, Data>(props: {
  request: (...arg: any) => Promise<Response>;
  successMessage?: boolean;
  errorMessage?: string;
  dataKey?: string;
}): Promise<SuccessResponse<Data> | ErrorResponse> {
  let { errorMessage, successMessage, dataKey } = props;
  dataKey = dataKey ? dataKey : "data";

  try {
    let res: any = await props.request() as any;
    if (res?.success !== true) throw new Error(res?.message || "Error");

    if(successMessage)
    Notification.success({
      title: "Success",
      message: res?.message || "Success",
    });

  
    res.data =  res[props.dataKey as any];
    return res;
  } catch (error) {
    const e = error as any;
    if (errorMessage) {
      Notification.error({ title: "Error", message: errorMessage });
    } else {
      Notification.error({
        title: "Error",
        message: e.message || e || "Error",
      });
    }
    return { success: false, message: e.message || e, data: undefined };
  }
}

export default useData;
