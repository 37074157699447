import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import React from 'react';
import { Notification } from 'platyplex_ui'
import { addCard } from '../../store/actions/card';
import { useDispatch } from 'react-redux';
const CARD_OPTIONS = {
    iconStyle: 'solid',
    // style: {
    //   base: {
    //     iconColor: '#c4f0ff',
    //     color: '#fff',
    //     fontWeight: 500,
    //     fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    //     fontSize: '16px',
    //     fontSmoothing: 'antialiased',
    //     ':-webkit-autofill': {color: '#fce883'},
    //     '::placeholder': {color: '#87bbfd'},
    //     display: 'grid'
    //   },
    //   invalid: {
    //     iconColor: '#ffc7ee',
    //     color: '#ffc7ee',
    //   },
   // },
  };
  

const  StripeCard = ({children, close, reload, setLoading }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const handleSubmit = async (event: any) => {

    event.preventDefault();
    setLoading && setLoading(true)
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);


    const {error, token } = await stripe.createToken(cardElement as any);

    if (error) {
      Notification.error({ message: error.code || '', title: 'Error'})
    } else {
      const { success } = await dispatch(addCard(token?.id || '') as any)
      if(success)
        close();
    }

    setLoading && setLoading(false)
  };

  return (
    <form onSubmit={handleSubmit}>
        <CardElement  options={CARD_OPTIONS as any}/>
      {children}
    </form>
  );
};

export default StripeCard;