import React from 'react';
import Filter from './filter';
import { Drawer, message } from 'antd';

export default ({ query,  close, setQuery}: any) => {
    const submit = async (query: any) => {
        setQuery(query)
        close();
    }

    const closeModal = ()=>{
        close();
        message.error('Your changes were not applied')
    }

    return (
        <Drawer
            placement="right"
            width={450}
            closable={false}
            bodyStyle={{padding: 0}}
          //  onClose={closeModal}
            visible={true}
        > 
            <Filter submit={submit} query={query} loading={false} close={closeModal}/>
        </Drawer>);
}