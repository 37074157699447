import { PayoutMethod_StripeBankAccount } from "../../pages/wallet/payout-method";


interface State {
  payoutMethods : PayoutMethod_StripeBankAccount [];
  selectedPayoutMethod?: string;
  error: boolean;
  loading: boolean;
}
interface Actions {
  type: ActionTypes;
  payload: Partial<State> & {
    selectedId : string ;
  };
}

const intialState: State = {
  payoutMethods: [],
  selectedPayoutMethod : undefined,
  error: false,
  loading: false,
};

export enum ActionTypes {
  ADD_BANK = "ADD_BANK",
  REMOVE_BANK = "REMOVE_BANK",
  LOAD_BANK = "LOAD_BANK",
  SELECT_BANK = "SELECT_BANK",
  REQUEST_BANK = "REQUEST_BANK",
  ERROR_BANK= "ERROR_BANK",
  SUCCESS_BANK = "SUCCESS_BANK",
}

export default function rootReducer(
  state = intialState,
  action: Actions
): State {
  switch (action.type) {
    case ActionTypes.LOAD_BANK:
      return { ...state, payoutMethods: action.payload.payoutMethods as any };

    case ActionTypes.SELECT_BANK:
      return { ...state, selectedPayoutMethod: action.payload.selectedId };

    
    case ActionTypes.REMOVE_BANK:
      return { ...state, payoutMethods: state.payoutMethods.filter((v: PayoutMethod_StripeBankAccount)=> v.stripeId !== action.payload.selectedId ) };

    case ActionTypes.ERROR_BANK:
      return { ...intialState, error: true, loading: false };

    case ActionTypes.REQUEST_BANK:
      return { ...intialState, error: false, loading: true };

    case ActionTypes.SUCCESS_BANK:
      return { ...state, loading: false };
    default:
      return state;
  }
}
