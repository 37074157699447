import { ActionTypes } from "../reducer/bank";
import api from "../../api";
import { Notification } from 'platyplex_ui';

const ErrorModal = ({error}: {error: any}) => Notification.error({ title: 'Error', message: error.message || error})
export const asyncActions = async (
  apiCall: () => Promise<any>,
  dispatch: any,
  showError = false,
  modal = ErrorModal
) => {
  let payload = null;
  try {
    dispatch({ type: ActionTypes.REQUEST_BANK });
    payload = await apiCall();
    if (!payload || !payload.success)
      throw new Error(payload?.message || "Error");

    dispatch({ type: ActionTypes.SUCCESS_BANK });
    return await payload;
  } catch (e) {
    if (showError) {
      modal({ error: e });
    }
    dispatch({ type: ActionTypes.ERROR_BANK });
    return payload;
  }
};

export const getPayoutMethods = (modal = ErrorModal) => {
  return async (dispatch: any) => {
    const payload = await asyncActions(
      () => api.getPayoutMethods(),
      dispatch,
      true,
      modal
    );
    if (payload && payload.success) {
      dispatch({
        type: ActionTypes.LOAD_BANK,
        payload: { payoutMethods : payload.payoutMethods },
      });
    }
  };
};

export const addBank = (id: string, modal = ErrorModal) => {
  return async (dispatch: any) => {
    const payload = await asyncActions(
      () => api.addPayment(id),
      dispatch,
      true,
      modal
    );

    if (payload && payload.success) {
      Notification.success({ title: 'Success', message: payload.message});
      dispatch(getPayoutMethods());
    } 

    return payload;
  };
};



export const removeBank = (id: string, modal = ErrorModal) => {
  return async (dispatch: any) => {
    const payload = await asyncActions(
      () => api.removeBank(id),
      dispatch,
      true,
      modal
    );

    if (payload && payload.success) {
      dispatch(getPayoutMethods());
    }
    return payload;
  };
};

export const selectBank = (selectedId: string) => {
  return async (dispatch: any) => {

    dispatch({
      type: ActionTypes.SELECT_BANK,
      payload: { selectedId },
    });
  };
};
