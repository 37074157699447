import React from "react";
import { Grid, Text, Button, Header} from "platyplex_ui";
import { useFormik } from "formik";
import ParkstashText from "../../../components/parkstash-text";
import { disabledTxtColor, gradient } from "../../../config";
import { AddParkingModel, Parking } from "../../../model/domain/parking";
import TextArea from "antd/lib/input/TextArea";
import { Form } from ".";
import ListingImages from "../components/listing-images";
import Bar from "../../checkout/components/bar";
import FileUpload from "../../../components/file-upload";

const defaultValue: Partial<AddParkingModel> = {
  instruction: "",
  temp_image_filenames: [],
  description: "",
  terms_and_conditions: "",
};

const Descriptions = (props: any) => {
  const { loading } = props;

  const { handleSubmit, handleChange, setFieldValue, values } = useFormik({
    initialValues: { ...defaultValue, ...props.listing } as Parking | any,
    onSubmit(values) {
        props.setInfo(values);
    },
  });

  const error = values.description.length < 1 || values. temp_image_filenames?.length === 0;
  return (
    <Form back={props.back} onSubmit={handleSubmit}>
      <Header bold>
        Personalize your <ParkstashText /> Listing
      </Header>
      {props.progress}


        <ListingImages
          image_url={[]}
          addImages={values.temp_image_filenames}
          setAddImages={(v: string[]) => setFieldValue("temp_image_filenames", v)}
          deleteImages={[]}
          setDeleteImages={(v: string[]) => {}}
          uploadButton={
            <FileUpload
              setImages={(i: string) =>
                setFieldValue("temp_image_filenames", [
                  ...values.temp_image_filenames,
                  i,
                ])
              }
            />}
        />

      <Bar />
      <div
        style={{ placeSelf: "stretch", display: "grid", placeItems: "stretch" }}
      >
        <Text textAlign="left" bold>
          Add a description <span className="red">*</span>
        </Text>
        <Text textAlign="left" color={disabledTxtColor}>
          Add a brief description/selling points of your space. E.g. Paved
          driveway parking spot, 10 min walk to SAP Center, in downtown SJ.
        </Text>

        <TextArea
          autoSize
          name="description"
          onChange={handleChange}
          value={values.description}
        />
      </div>

      <Grid
        margin=".6em 0"
        placeSelf="stretch"
        placeItems="stretch"
        gridGap="0"
      >
        <Text textAlign="left" placeSelf="end start" bold>
          Add access instructions (Optional)
        </Text>
        <Text textAlign="left" color={disabledTxtColor}>
          {" "}
          Add any special instructions required to access your parking spot like
          keys, access code etc.
        </Text>
        <TextArea
          autoSize
          name="instruction"
          onChange={handleChange}
          value={values.instruction}
        />
      </Grid>
      <Grid
        margin=".6em 0"
        placeSelf="stretch"
        placeItems="stretch"
        gridGap="0"
      >
        <Text textAlign="left" placeSelf="end start" bold>
          Additional Terms and Conditions (Optional)
        </Text>
        <TextArea
          name="terms_and_conditions"
          onChange={handleChange}
          value={values.terms_and_conditions}
          autoSize
          placeholder="Add any Terms and Conditions for your parking space"
        />
      </Grid>

      {loading ? (
        <Button
          loading
          disabled
          style={{ borderRadius: "1.3em", padding: ".5em" }}
        >
          Loading
        </Button>
      ) : (
        <Button.Normal
          style={{
            borderRadius: "1.3em",
            padding: ".5em",
            ...(!error
              ? { backgroundImage: `${gradient}`, color: "white", border: 0 }
              : {}),
          }}
     
          disabled={error}
          onClick={(e: any) => handleSubmit(e)}
        >
          Next
        </Button.Normal>
      )}
    </Form>
  );
};

export default Descriptions;
