import { useState, useEffect } from "react";
import API from "../api";
import { asyncHandler, toQuery } from "../utils";

interface Query {
  as?: "driver" | "host" | string;
  type?: "past" | "cancelled" | "upcoming" | "canceled" | string;
  batch_count?: number;
  page_no?: number;
}

const defaultQuery: Query = {
  as: "driver",
  type: "upcoming",
  batch_count: 10,
  page_no: 1,
};

export const useBookingHistory = (q = defaultQuery) => {
  const [state, setter] = useState({
    bookingHistory: [],
    totalCount: 0,
    loading: false,
    error: false,
  });

  const [query, setQ] = useState({ ...defaultQuery, ...q });

  const setState = (obj: any) => setter({ ...state, ...obj });

  const getBookingHistory = async () => {
    setState({ loading: true, error: false });
    const res = await asyncHandler({
      apiCall: () => API.getBookingHistory(toQuery(query)),
    });
    const { success, threads, count } = res;
    setState({
      loading: false,
      error: !success,
      totalCount: count || 0,
      bookingHistory: threads || [],
    });
  };

  const setQuery = (props: Query) => {
    if (props.type === "canceled") {
      props.type = "cancelled";
    }
    const newQuery = {
      ...query,
      ...props,
    };
    setQ(newQuery);
  };

  useEffect(() => {
    getBookingHistory();
  }, [query]);

  

  return {
    ...state,
    setQuery,
    query,
    changePage: (p: number)=>setQ({...query, page_no: p}),
    reload: getBookingHistory,
  };
};

export default useBookingHistory;
