import React, { useRef } from "react";
import { Grid, Text } from "platyplex_ui";
import { API, cloudinaryPrefix, primaryColor } from "../../../config";
import Landscape from "../../../assets/landscape.png";
import Sun from "../../../assets/sun.png";
import Example from "../../../assets/example.jpg";
import PopupInfo from "../../../components/popup-info";
import FileUpload from "../../../components/file-upload";

interface Props {
  image_url: string[];
  addImages: string[];
  setAddImages: (s: string[]) => void;
  deleteImages: string[];
  setDeleteImages: (s: string[]) => void;
  onSubmit?: () => void;
  uploadButton?: JSX.Element;
}

const ListingImages = ({
  image_url,
  addImages,
  setAddImages,
  deleteImages,
  setDeleteImages,
  uploadButton,
}: Props) => {
  const ref = useRef<any>(null);
  const totalImages = addImages.length + image_url.length;
  const withPrefix = (src: string) =>
    src?.includes(cloudinaryPrefix) ? src : cloudinaryPrefix + src;
  const currentImages = image_url.filter(
    (url: string) => !deleteImages.includes(url)
  );
  return (
    <Grid margin=".6em 0" placeSelf="stretch" placeItems="stretch" gridGap="0">
      <ParkingImageUploadLabel />
      <Grid
        customCols={`repeat(${
          totalImages + 1 <= 3 ? totalImages + 1 : 3
        }, auto) 1fr`}
        width="100%"
      >
        {uploadButton ? (
          uploadButton
        ) : (
          <>
            <FileUpload setImages={(i: string) => {}} />
          </>
        )}
        {currentImages?.map((img: string) => (
          <ListingImage
            key={img}
            src={withPrefix(img)}
            onDelete={() => setDeleteImages([...deleteImages, img])}
          />
        ))}

        {addImages?.map((img: string) => (
          <ListingImage
            key={img}
            src={API + `/v1/utils/downloadTempImage/${img}`}
            onDelete={() =>
              setAddImages(addImages.filter((i: string) => i !== img))
            }
          />
        ))}
      </Grid>
      <Text textAlign="left" width="100%" bold color="red">
        Size Limit: 5MB
      </Text>
    </Grid>
  );
};

export const ParkingImageUploadLabel = () => (
  <Text textAlign="left" bold margin="1em 0 0 0">
    Add pictures (Required) <span className="red">* </span>
    <PopupInfo
      title={
        <span>
          <Text textAlign="left" bold size="1.2em">
            Sell your parking space faster with some great pics
          </Text>
          <Text textAlign="left">
            Here are some tips to take great pics of your space:
          </Text>

          <Text>
            <img
              src={Landscape}
              alt="landscape"
              width={150}
              className="center"
            />
          </Text>
          <Text textAlign="left">
            Take pics in landscape mode to capture as much of your space as
            possible.
          </Text>

          <Text>
            <img src={Sun} alt="sun" width={100} className="center" />
          </Text>

          <Text textAlign="left">
            Finally, here is an example of a nice driveway pic!
          </Text>

          <Text>
            <img src={Example} alt="example" width={150} className="center" />
          </Text>
        </span>
      }
    >
      <i className={"fa fa-info-circle"} style={{ color: primaryColor }}></i>
    </PopupInfo>
  </Text>
);

export const ListingImage = ({
  onDelete,
  src,
  style,
}: {
  src: string;
  onDelete?: () => void;
  style?: React.CSSProperties;
}) => (
  <span style={{ width: 108, height: 108, position: "relative", ...style }}>
    {onDelete && (
      <i
        className="fa fa-trash txt-shadow red pointer hoverr"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1000,
          margin: ".5em",
        }}
        onClick={onDelete}
      />
    )}
    <img
      src={src}
      style={{ width: "100%", height: "100%" }}
      alt="listing images"
    />
  </span>
);

export default ListingImages;
