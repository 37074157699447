import { standardGet } from "./utils/request";
import { API, geoCodeAPIKey, googleAPIKey } from "../config";

const route = (r: string) => API + r;

let googlePlaces = {
getPlaceAutocomplete: (place: string) => standardGet(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${place}&key=${googleAPIKey}&components=country:us|country:ca`),
getPlaces: (place: string) => standardGet(`https://maps.googleapis.com/maps/api/geocode/json?address=${place}&key=${geoCodeAPIKey}`),
getPlacesGeocode: (place_id: string) => standardGet(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=${geoCodeAPIKey}`)
};

export default googlePlaces;
