import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { auth, guestSignup, logout } from "../store/actions/user";
import { getUserToken, isGuest, isOtpVerified } from "../utils/user";

import useQuery from "./query";

export enum USER_STATE {
  UNAUTH = 'UNAUTH',
  GUEST = 'GUEST',
  REQ_PHONE_SELECT = 'REQ_PHONE_SELECT',
  REQ_PHONE_VERIFY = 'REQ_PHONE_VERIFY',
  REQ_INITIAL_OTP_VERIFY = 'REQ_INITIAL_OTP_VERIFY',
  REQ__OTP_VERIFY = 'REQ__OTP_VERIFY',
  APPROVED = 'APPROVED',
  BANNED = 'BANNED'
}

const useAuth = ({ action }: {action: ()=> void})=>{
    const dispatch = useDispatch();
    
    const history = useHistory();
    const { query } = useQuery<{ payload_token: string }>();
    const { userState, details }  = useSelector((s: any) => s.user);
    const approved = userState === "APPROVED";

    useEffect(() => {
        if (getUserToken() && isOtpVerified()) {
          dispatch(auth(action));   
        } else if(getUserToken() && isGuest()){
          dispatch(auth(action)); 
        } else {
          dispatch(logout(false));
          action();
          dispatch(guestSignup())
        }
      }, []);

      useEffect(() => {
        if (query?.payload_token && !window.location.href.includes("login")) {
          history.push("login/?payload_token=" + query.payload_token);
        }
      }, [query]);

      return { approved, is_banned: details?.is_banned }
}

export default useAuth;