import React from "react";
import { Vehicle } from "../../pages/booking-history/type";
import { Text } from "platyplex_ui";

const VehicleFormat = ({ vehicle, style }: { vehicle: Vehicle, style?: React.CSSProperties }) => {
  const { color, model, year, license_plate, vehicle_class } = vehicle;
  return (
    <>
      <Text
        textAlign="left"
        className="capitalize"
        style={{ gridColumn: "1/-1", ...style}}
      >
        {`${year} ${color} ${model}`}
      </Text>
      <Text textAlign="left" className="capitalize">
        {`Liscense Plate# ${license_plate} | ${vehicle_class.replace(
          /_/g,
          " "
        )}`}
      </Text>
    </>
  );
};

export default VehicleFormat;
