import React from "react";
import { Grid, Text, Button} from "platyplex_ui";
import { primaryColor } from "../../../../../config";
import { Input} from "antd";
import { BankAccount } from "../../type";

const bankFields = [
    {
      id: "routing_number",
      label: "Routing Number (9 characters)",
      placeholder: "Ex: 11100000",
    },
    {
      id: "account_number",
      placeholder: "Ex: 12345678",
      label: "Account Number",
    },
  
    {
      id: "confirm_account_number",
      placeholder: "Ex: 12345678",
      label: "Confirm Account Number",
    },
  ];
  
  const BankForm = ({
    submit,
    loading,
    bankInfo,
    setBankInfo,
    country,
  }: {
    loading: boolean;
    submit: () => void;
    bankInfo: BankAccount;
    setBankInfo: (b: BankAccount) => void;
    country: string;
  }) => {
    const handleBankInfoChange = (p: Partial<BankAccount>) =>
      setBankInfo({ ...bankInfo, ...p });
    const disabled =
      !bankInfo.account_number ||
      !bankInfo.confirm_account_number ||
      !bankInfo.routing_number ||
      bankInfo.account_number !== bankInfo.confirm_account_number;
    return (
      <Grid placeItems="stretch" placeSelf="stretch">
        <Text textAlign="left">Add bank account info</Text>
        <Grid placeItems="center stretch">
          {country === "CA" && (
            <div>
              <Text textAlign="left" bold color={primaryColor}>
                Transit Number (5 Digits)
              </Text>
              <Input
                name={"tansit_number"}
                onChange={(e) =>
                  handleBankInfoChange({ transit_number: e.target.value })
                }
                placeholder={"12345"}
                value={bankInfo.transit_number}
              />
            </div>
          )}
          {bankFields.map(({ label, id, placeholder }) => {
            return (
              <div key={id}>
                <Text textAlign="left" bold color={primaryColor}>
                  {label}
                </Text>
                <Input
                  name={label}
                  onChange={(e) => handleBankInfoChange({ [id]: e.target.value })}
                  placeholder={placeholder}
                  value={bankInfo[id as "routing_number"]}
                />
              </div>
            );
          })}
        </Grid>
  
        <Button
          rounded
          onClick={submit}
          disabled={disabled || loading}
          loading={loading}
        >
          Link Bank Account
        </Button>
      </Grid>
    );
  };

  export default BankForm;