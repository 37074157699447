import { useEffect, useState } from "react";
import API from "../api";
import { asyncHandler } from "../utils";

export interface Strings {
  listing_success_pleaseLookForAnEmail: string;
  listing_success_weAreExcitedToHaveYouOnboard: string;
  listing_inReview_title: string;
  listing_inReview_body: string;
  listing_securityDeposit: string;
  bookingReview_securityDeposit: string;
}

interface State {
  strings?: Strings;
  loading: boolean;
  error: boolean;
}

const fallBackString = {
  listing_success_pleaseLookForAnEmail:
    "Please look for an email from support@findparkstash.com and submit the required documents to complete verification.",
  listing_success_weAreExcitedToHaveYouOnboard:
    "We are excited to have you onboard! If you have any questions, send an email to support@findparkstash.com or raise a help ticket from the app!",
  listing_inReview_title: "Your listing is in review",
  listing_inReview_body:
    "Please look for an email from support@findparkstash.com and submit the required documents at your earliest convenience to complete verification of your listing.",
  listing_securityDeposit:
    "Security Deposit is fully refunded to driver automatically 3-5 days after booking ends if no issues are reported.",
  bookingReview_securityDeposit:
    "We will place a temporary charge on your payment method which will be refunded 3-5 days after your booking ends if no issues are reported.",
};

export const useStrings = () => {
  const [state, setter] = useState<State>({
    strings: fallBackString,
    loading: false,
    error: false,
  });

  const setState = (obj: Partial<State>) => setter({ ...state, ...obj });

  const getStrings = async () => {
    setState({ loading: true, error: false });
    const { success, strings } = await asyncHandler({
      apiCall: () => API.getString(),
    });

    setState({
      loading: false,
      error: !success,
      strings:
        success && typeof strings?.listing_inReview_body === "string"
          ? strings
          : fallBackString,
    });
  };

  useEffect(() => {
    getStrings();
  }, []);

  return {
    ...state,
    getStrings,
  };
};

export default useStrings;
