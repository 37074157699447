import { InputNumber } from "antd";
import { Grid, Text } from "platyplex_ui";
import React from "react";

interface ParkingSize {
  width_ft: number | any;
  length_ft: number | any;
};

interface Props {
  parkingSize: ParkingSize;
  setParkingSize: (p: ParkingSize) => void;
}
const Sizing = ({ parkingSize, setParkingSize }: Props) => {
 const handleChange = (props: Partial<ParkingSize>)=>{
    setParkingSize({
        ...parkingSize,
        ...props,
      });
 }
  return (
    <Grid
      margin=".6em 0"
      placeSelf="stretch"
      placeItems="stretch"
      gridGap="0.5em"
    >
      <Text textAlign="left" placeSelf="end start" bold>
       Based on your selection above, we estimate the size of your parking space to be
      </Text>
      <div style={{ placeSelf: "center start" }}>
        <span>W </span>
        <InputNumber
          value={parkingSize.width_ft}
          onChange={(width_ft) => handleChange({ width_ft  })}
        />
        <span> X L </span>
        <InputNumber
          value={parkingSize.length_ft}
          onChange={(length_ft) => handleChange({ length_ft })}
        />  
        <span>  (in ft) </span>
      </div>
    </Grid>
  );
};

export default Sizing;
