import React, { useState } from "react";
import { Grid } from "platyplex_ui";
import Wrapper from "../../../../components/overlay-wrapper";
import PayoutSelect, { PayoutType } from "./payout-select";
import Bank from "./bank";
import Paypal from "./paypal";


const PayoutAdder = ({ close }: { close: () => void }) => {
  const [state, setState] = useState<PayoutType | undefined>(undefined);

  const back = () => setState(undefined)
  return (
    <Wrapper
      style={{
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 100,
      }}
      back={!state ? close : back}
    >

      <Grid
        placeSelf="start center"
        placeItems="start center"
        style={{ width: 400, maxWidth: "90vw" }}
      >
        {state === 'BANK' ? <Bank close={back}/> :
          state === 'PAYPAL' ? <Paypal  close={back}/> :
            <PayoutSelect next={(p: PayoutType) => setState(p)} />
        }
      </Grid>
    </Wrapper>
  );
};


export default PayoutAdder;
