import React, { useRef } from "react";
import { Grid, Button, Text } from "platyplex_ui";
import { Input, message } from "antd";
import { gray } from "../../../config";
import ShareSources from './share-sources';

const Share = ({ id }: { id: string }) => {
    const ref = useRef<any>(null);
    let link = `${window.location.protocol}//${window.location.host}/garage/${id}`;
    link = `https://web.findparkstash.com/garage/${id}`;
    let body = "Hey,I found this garage at";

    const copy = () => {
        try {
            ref.current.select();
            document.execCommand("copy");
            message.success("Link Copied");
        } catch (e) {
            window.prompt("Copy to clipboard: Ctrl+C, Enter", link);
        }
    };

    return (
        <div style={{ background: "white", height: "100%", width: "100%" }}>
            <Grid
                customRows="1fr auto"
                height="100%"
                gridGap=".5em"
                style={{ maxWidth: "700px", margin: "auto" }}
            >
                <ShareSources link={link} body={body} />
                <Grid
                    width="100%"
                    padding=".5em 0"
                    placeItems='stretch'
                    gridGap='0.5em'
                    style={{ borderTop: "1px solid lightgray" }}
                >
                    <Text textAlign='left' bold>Page Link</Text>
                    <Grid>
                        <Input
                            value={link}
                            ref={ref}
                            style={{ background: gray }}
                            onFocus={copy}
                            suffix={
                                <Button
                                    faIcon="fa fa-clipboard"
                                    minWidth="3em"
                                    padding="0"
                                />}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};


export default Share;
