import { authGet, authPost, authDelete, authGetQuery, authDeleteBody } from './utils/request';
import { API } from '../config'
import { Parking, EditParkingModel } from '../model/domain/parking';

const route = (r: string) => API + r;

let parkings = {
    addParking: (parking: Parking) => authPost(route(`/v1/parkings`), parking),
    getParkings: () => authGet(route(`/v1/parkings/me/?extras=active_bookings_count,host_rating_summary,pretty_rates`)),
    editParking: (parking: EditParkingModel, id: string) => authPost(route(`/v1/parkings/${id}/edit`), parking),
    removeParking: (id: string) => authDelete(route(`/parkings/${id}`)),
    getParking: (query: string) => authGetQuery('/parkings', query),
    getOneParking: (pId: string) => authGet(route(`/v1/parkings/${pId}`)),
    getReviews: (pId: string) => authGet(route(`/parkings/${pId}/host-ratings`)),
    addParkingImage: (pId: string, images: string[]) => authPost(route(`/parkings/images/${pId}`), { images }, false),
    deleteParkingImage: (pId: string, images: string[]) => authDeleteBody(route(`/parkings/images/${pId}`), { images }, false),
    addImages : (images: string[], id: string) => authPost(route(`/parkings/images/` + id), { images }),
    uploadImages : (images: string[]) => authPost(route(`/v1/utils/uploadTempImages`), { images }),
    getVehicleClasses : () => authGet(route(`/v1/parkings/vehicleClassDescriptors`)),
    getAmenties : () => authGet(route(`/v1/parkings/amenityDescriptors`)),
    getNearbyParkings: (query: object) => authPost(route(`/v1/parkings/search-nearby`), query)
};

export default parkings;
