import React, { useContext, useState } from "react";
import Layout from "../../layout";
import {
  Grid,
  Button,
  Header,
  Placeholder,
  List,
  Text,
} from "platyplex_ui";
import { useSelector } from "react-redux";
import ListingCard from "./listing-card";
import {
  disabledTxtColor,
  gradient,
  LAYER,
  primaryTxtColor,
} from "../../config";
import empty from "../../assets/empty-person.svg";
import { LoginContext } from "../../context/login";
import { Parking } from "../../model/domain/parking";
import { ListingEdit } from "./listing-edit";
import { ListingMultiStep } from "./listing-add";
import useParking from "../../hooks/parkings";
import NewModal from "../../components/new-modal";
import Wrapper from "../../components/overlay-wrapper";

const Listings = () => {
  const { setOpen } = useContext(LoginContext);
  const userState = useSelector((state: any) => state.user.userState);
  const [selectedListing, setSelectedListing] = useState<Parking | undefined>(
    undefined
  );
  const loggedIn = userState === "APPROVED";

  const { parkings, loading, refresh, removeParking } = useParking();

  const props = { refresh };

  const AddModal = NewModal({
    modal: (
      <Wrapper
        style={{
          zIndex: LAYER.fifth,
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100vh",
        }}
      >
        <ListingMultiStep close={() => AddModal.close()} {...props} />
      </Wrapper>
    ),
  });

  const EditModal = NewModal({
    modal: (
      <ListingEdit
        close={() => EditModal.close()}
        {...props}
        listing={selectedListing}
      />
    ),
  });

  const width = { maxWidth: "90vw", width: "800px" };

  const Empty = (
    <Grid style={{ height: "70vh" }}>
      <Grid>
        <img
          src={empty}
          alt="empty"
          style={{
            width: "600px",
            maxWidth: "90vw",
            marginBottom: "2em",
            filter: `drop-shadow(2px 4px 6px teal)`,
          }}
        />

        {loggedIn ? (
          <div>
            <Text color={disabledTxtColor}>You haven't listed any spot.</Text>
            <Button rounded onClick={AddModal.open}>
              Create your first Listing
            </Button>
          </div>
        ) : (
          <div>
            <Text color={disabledTxtColor}>You are not logged in.</Text>
            <Button rounded onClick={() => setOpen(true)}>
              Signup or Login
            </Button>
          </div>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Layout sidebar style={{ gridTemplateRows: "1fr auto" }}>
      <Layout.Top></Layout.Top>
      <Layout.Bottom>
        <Grid placeItems="start stretch" padding="1em" height="100%">
          {AddModal.modal}
          {EditModal.modal}
          <Header bold color={primaryTxtColor} textAlign="left">
            Listings
          </Header>

          <div
            className="grid"
            style={{ placeSelf: "center", color: primaryTxtColor, ...width }}
          >
            <Button
              margin="1em"
              rounded
              noHover
              bg={gradient}
              placeSelf="end"
              disabled={loading || !loggedIn}
              shadow
              onClick={AddModal.open}
              minWidth="4.5em"
              faIcon="fa  fa-plus"
            >
              {" "}
              Add
            </Button>
            Click edit to update your listing's availability and other
            information anytime.
            <List
              loading={loading}
              empty={Empty}
              loader={(<Placeholder number={5} style={width} />) as any}
              list={parkings}
              map={(l: Parking, i: number) => (
                <ListingCard
                  key={i}
                  {...l}
                  edit={() => {
                    setSelectedListing(l);
                    EditModal.open();
                  }}
                  refresh={refresh}
                  remove={() => removeParking(l._id)}
                />
              )}
            />
          </div>
        </Grid>
      </Layout.Bottom>
    </Layout>
  );
};

export default Listings;
