import { Card, Text, Button, List, Grid, Modal } from "platyplex_ui";
import React from "react";
import { disabledTxtColor, primaryColor } from "../../../../config";
import { Review } from "../../type";
import ApplyPromo from "./apply-promo";
import { Radio, Switch } from "antd";
import { CreditCard } from "../../../booking-history/type";
import CardForm from "./card-form";
import NewModal from "../../../../components/new-modal";
import { useDispatch, useSelector } from "react-redux";
import { selectCard } from "../../../../store/actions/card";
import Bar from "../bar";
import { getPaymentLogo } from "../../../../utils/payment-logo";
import Recharge from "../../../../components/recharge";
import Wrapper from "../../../../components/overlay-wrapper";
import PrimaryModal from "../../../../components/primary-modal";
import useAsync from "../../../../hooks/async";

export type PaymentMethod = "card" | "wallet";
interface Payment {
  review: Review;
  active: boolean;
  next: (p: string) => void;
  reviewBooking: () => void;
  paymentMethod: PaymentMethod;
  setPaymentMethod: (p: PaymentMethod) => void
}

const Payment = ({ review, next, active, reviewBooking, paymentMethod, setPaymentMethod }: Payment) => {
  const dispatch = useDispatch();
  const { cards, selectedCard } = useSelector(
    (state: any) => state.cards
  );

  const { runFunction, loading } = useAsync((payment_method: string) => next(payment_method))

  const CardModal = NewModal({
    modal: <CardForm close={() => CardModal.close()} />,
  });

  const RechargeModal = NewModal({
    modal: (
      <Wrapper
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 1000,
        }}
        back={() => RechargeModal.close()}
      >
        <Recharge back={() => {
          RechargeModal.close();
          reviewBooking();
        }} />
      </Wrapper>
    ),
  });
  const WarningModal = Modal({
    title: "",
    modal: (
      <PrimaryModal
        onCancel={() => WarningModal.close()}
        confirmText="Recharge Wallet"
        onConfirm={() => {
          RechargeModal.open();
          WarningModal.close();
        }}
      >
        <Grid gridGap=".25em" padding="1em">
          <Text bold>Wallet balance low</Text>
          <Text>Please Recharge your wallet.</Text>
        </Grid>
      </PrimaryModal>
    ),
    hideCancel: true,
  });

  const totalAmount = paymentMethod === "card" ?
    <span style={{ color: primaryColor }}>{review?.total_with_card_with_security_deposit}</span> :
    <span>
      <s style={{ color: 'red', textDecoration: 'strik' }}>{review?.total_with_card_with_security_deposit}</s> &nbsp;
      <span style={{ color: primaryColor }}>{review?.total_with_wallet_with_security_deposit}</span>
    </span>;


  return (
    <Card shadow>
      {RechargeModal.modal}
      {CardModal.modal}
      <Grid
        customCols="1fr auto"
        mobileLayout={false}
        placeItems="center stretch"
      >
        <Text textAlign="left" bold style={{ fontSize: "1.5em" }}>
          <i
            className="fas fa-check-circle"
            style={{ color: disabledTxtColor }}
          />
          &nbsp; Payment Details
        </Text>
      </Grid>
      {active && (
        <Grid gridGap=".75em" placeItems="start">
          <Text bold>Total Amount Due: {totalAmount}</Text>
          <Bar />
          <ApplyPromo reload={reviewBooking} />
          <Bar />
          <Text bold>Payment Method</Text>

          {paymentMethod === "wallet" ? (
            <Text>
              Your savings with wallet: <span className='bold' style={{ color: primaryColor }} >{review?.save_with_wallet_today_amount}</span>
            </Text>
          ) : (
            <Text>
              Save&nbsp;
              <span style={{ color: primaryColor }}>
                {review?.save_with_wallet_today_percent}
              </span>
              &nbsp;by using ParkStash Wallet
            </Text>
          )}

          {paymentMethod === "wallet" ?
            <Text>That's <span className='bold' style={{ color: primaryColor }} >{review?.save_with_wallet_today_percent} </span> savings!</Text> :
            <Text>
              Amount Due with Wallet: &nbsp;
              <span style={{ color: "red", textDecoration: "line-through" }}>
                {review?.total_with_card}
              </span>{" "}
              &nbsp;
              <span style={{ color: primaryColor }}>
                {review?.total_with_wallet}
              </span>
              <Text></Text>
            </Text>}

          <Text>
            ParkStash Wallet Balance:{" "}
            {review?.wallet_balance}
            &nbsp;{" "}
            <Switch
              checked={paymentMethod === "wallet"}
              onChange={(e) => {
                if (review?.has_sufficient_wallet_balance) {
                  setPaymentMethod(e ? "wallet" : "card");
                } else {
                  WarningModal.open();
                }
              }}
            />
          </Text>

          <Text
            color="blue"
            className="pointer noselect"
            onClick={RechargeModal.open}
          >
            Recharge my Wallet
          </Text>

          <Bar />
          <Text bold>
            Select Card for remaining balance:{" "}
            {paymentMethod === "card"
              ? review?. card_for_rem_balance_with_card
              : review?.card_for_rem_balance_with_wallet}
          </Text>
          <Grid customCols="1fr auto">
            <List
              empty={
                <Text color="red">
                  No payment method present. Add a payment method to pay and
                  book.
                </Text>
              }
              list={cards}
              map={(card: CreditCard, i: number) => (
                <React.Fragment key={i}>
                  <Text>
                    <img
                      src={getPaymentLogo(card.brand)}
                      style={{ height: 25 }}
                      alt="payment logo"
                    />{" "}
                    {`${card.brand} ending in ${card.last4}`}{" "}
                  </Text>
                  <Radio
                    checked={
                      selectedCard?.id === card.id && paymentMethod !== "wallet"
                    }
                    onClick={() => {
                      dispatch(selectCard(card));
                      setPaymentMethod("card");
                    }}
                  />
                </React.Fragment>
              )}
            />
          </Grid>
          <Text color="blue" className="pointer" onClick={CardModal.open}>
            Add a Card
          </Text>

          <Button
            rounded
            loading={loading}
            onClick={() => runFunction(paymentMethod)}
            disabled={
              loading || (cards.length === 0 && !review?.has_sufficient_wallet_balance)
            }
          >
            {" "}
            Pay {paymentMethod === "card" ? review?.total_with_card : review?.total_with_wallet}
          </Button>
        </Grid>
      )}
    </Card>
  );
};

export default Payment;
