import {  Tooltip } from "antd";
import { Grid, Text } from "platyplex_ui";
import React from "react";
import { primaryColor } from "../../config";
import useStrings from "../../hooks/strings";
import { Review } from "../../pages/checkout/type";
import { PaymentMethod } from "../../pages/checkout/components/payment/index";
import { BookingExtensionReview } from "../../pages/booking-history/type";

const ListingDetails = ({ review, paymentMethod}: { review : Review | BookingExtensionReview, paymentMethod: PaymentMethod})=>{
    const { strings } = useStrings();
    return(      <Grid customCols="1fr auto" placeItems="start" gridGap=".25em">
    <Text>Listed Price</Text>
    <Text>{review?.listed_price}</Text>

    <Text>Subtotal</Text>
    <Text>{review?.subtotal}</Text>

    <Text>
      Service Fee&nbsp;
      <Tooltip
        title={
          <div>
            <Text bold color="white">
              Service Fee
            </Text>
            <Text color="white">
              This service fee helps us operate ParkStash and keeps our
              lights on
            </Text>
          </div>
        }
      >
        <i
          className="fa fa-info-circle pointer"
          style={{ color: primaryColor }}
        />
      </Tooltip>
    </Text>
    <Text>{review?.service_fee} </Text>
    {review?.tax && (
      <>
        <Text>
          Tax&nbsp;{" "}
          <Tooltip
            title={
              <div>
                <Text bold color="white">
                  Taxes
                </Text>
                {(paymentMethod === "card"
                  ? review.tax.with_card_info_strings
                  : review.tax.with_wallet_info_strings
                ).map((s) => (
                  <Text color="white">{s}</Text>
                ))}
              </div>
            }
          >
            <i
              className="fa fa-info-circle pointer"
              style={{ color: primaryColor }}
            />
          </Tooltip>
        </Text>
        <Text>
          {paymentMethod === "card"
            ? review.tax.with_card
            : review.tax.with_wallet}
        </Text>
      </>
    )}
    {review?.security_deposit && (
      <>
        <Text>
          Security Deposit&nbsp;{" "}
          <Tooltip
            title={
              <div>
                <Text bold color="white">
                  Security Deposit
                </Text>
                <Text color="white">
                  {strings?.bookingReview_securityDeposit}
                </Text>
                <Text color="white">
                  <a href="https://www.findparkstash.com/security-deposits">
                    Learn more about how we handle security deposit
                  </a>
                </Text>
              </div>
            }
          >
            <i
              className="fa fa-info-circle pointer"
              style={{ color: primaryColor }}
            />
          </Tooltip>
        </Text>
        <Text>{review.security_deposit}</Text>
      </>
    )}
         </Grid>)
}
export default ListingDetails;