import React from "react";
import { Grid, Text } from "platyplex_ui";
import Avatar from "../../../../components/avatar";
import { disabledTxtColor2 } from "../../../../config";
import { NearbyParking, Parking } from "../../../../model/domain/parking";
import Collapse from "../../../../components/collapse";
import AllowedVehicles from "../../../../components/allowed-vehicles/allowed-vehicles";

interface InformationProps {
  parking: NearbyParking;
  additionalInfo: Parking | undefined;
}

const Information = ({ parking, additionalInfo }: InformationProps) => {
  return (
    <Grid customCols="1fr" gridGap="1.5em" placeItems="stretch">
      <Grid customCols="auto 1fr" placeItems="center stretch">
        <Avatar
          style={{ maxWidth: "50px", margin: "0 1em" }}
          src={parking.owner.image_url}
        />
        <Text textAlign="left" className="capitalize">
          Hosted By {parking.owner?.user_name}
        </Text>
      </Grid>
      <Wrap>
        <Text bold textAlign="left">
          Type of Parking
        </Text>
        <Text className="capitalize" textAlign="left">
          {parking.spot_type}
        </Text>
      </Wrap>

      {additionalInfo?.description && <Wrap>
        <Text bold textAlign="left">
          Description
        </Text>
        <Text textAlign="left">
          {additionalInfo?.description}
        </Text>
      </Wrap>}

      {additionalInfo?.instruction && <Wrap>
        <Text bold textAlign="left">
          Access Instruction
        </Text>
        <Text textAlign="left">
          {additionalInfo?.instruction}
        </Text>
      </Wrap>}
      <Wrap>

        <Collapse
          heading={
            <Text bold textAlign="left">
              Types of vehicles allowed to park
            </Text>
          }
        >
          <AllowedVehicles allowedVehicles={additionalInfo?.vehicle_classes || []} />

        </Collapse>
      </Wrap>
      {additionalInfo?.terms_and_conditions &&
        <Wrap>
          <Text bold textAlign="left">
            Additional Terms and Conditions
          </Text>
          <Text textAlign="left">
            {additionalInfo?.terms_and_conditions}
          </Text>
        </Wrap>}
    </Grid>
  );
};

const Wrap = ({ children }: { children: React.ReactElement[] | any }) => (
  <div
    style={{
      fontSize: "1.2em",
      padding: "1em",
      borderBottom: "1px solid " + disabledTxtColor2,
    }}
  >
    {children}
  </div>
);

export default Information;
