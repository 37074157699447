import { useStripe } from "@stripe/react-stripe-js";
import { Notification } from "platyplex_ui";
import { useState } from "react";
import { Bank, Account } from "../pages/wallet/payout-method/type";
import api from "../api";

interface State {
  loading: boolean;
  error: boolean;
}

export enum Type {
  BANK = "BANK",
  ACCOUNT = "ACCOUNT",
}

type Token = "account" | "bank_account";
const useStripeToken = (type: "ACCOUNT" | "BANK") => {
  const stripe = useStripe();
  const [state, setter] = useState<State>({
    loading: false,
    error: false,
  });
  const request = type === Type.ACCOUNT ? api.addStripe : api.addBank;
  const tokenType: Token = type === Type.ACCOUNT ? "account" : "bank_account";

  const setState = (obj: Partial<State>) => setter({ ...state, ...obj });

  const createToken = async (data: Account | Bank) => {
    if (!stripe) return;
    try {
      setState({ error: false, loading: true });
      const { error, token } = await stripe.createToken(
        tokenType as any,
        data as Bank
      );
      if (error) {
        setState({ error: true, loading: false });
        Notification.error({ message: error.code || "", title: "Error" });
        return false;
      } else {
       
        return request(token?.id || "")
          .then((res: any) => {
            if (!res.success) {
              setState({ error: false, loading: false });
              Notification.error({
                message: res.message || res.error || "",
                title: "Error",
              });
              return false;
            } else return true;
          })
          .catch((e) => {
            return false;
          });
      }
    } catch (e) {
      Notification.error({ message: e.message || "", title: "Error" });
      return false;
    } 
  };

  return {
    createToken,
    ...state,
  };
};

export default useStripeToken;
