import React from "react";
import { Grid, Text } from "platyplex_ui";
import { InputNumber, Switch } from "antd";
import { primaryColor } from "../../../config";
import PopupInfo from "../../../components/popup-info";

interface Props {
  spaceCount: number;
  setSpaceCount: (n: number) => void;
  isAssignable: boolean;
  setIsAssignable:(n: boolean) => void;
  spotStart: number;
  setSpotStart:(n: number) => void;
  disableIsAssignable?: boolean;
}

const SpaceCount = ({
  spaceCount,
  setSpaceCount,
}: Props) => (
  <>
    <Grid
      margin=".6em 0"
      placeSelf="stretch"
      placeItems="stretch"
      gridGap="0"
      customCols="1fr auto"
    >
      <Text textAlign="left" placeSelf="end start" bold>
        How many spots do you want to rent out?
        <span className="red"> *</span>
        <PopupInfo
          title={
            <span>
             It's simple - as many as you can! Many homeowners have driveways or carports which can fit 3 cars or more easily. You should consider your own convenience and availability of your spaces when deciding on this number. 
             <br />

             If you are someone who owns a parking garage, it is upto you to decide how many spaces you want to allocate to ParkStash. Please make sure that you have those # of spaces available as bookings can happen anytime on our app. 
             <br />
             Request for cancellation of bookings 24 hrs before start time will incur penalties. All cancellation requests should be directed to

            </span>
          }
        >
          <i
            className={"fa fa-info-circle"}
            style={{ color: primaryColor }}
          ></i>
        </PopupInfo>
      </Text>
      <InputNumber
        min={1}
        step={1}
        style={{ placeSelf: "end" }}
        value={spaceCount}
        onChange={(e)=>setSpaceCount(Math.floor(Number(e)) || 0)}
        name={"space_count"}
      />
    </Grid>
  </>
);

export default SpaceCount;
