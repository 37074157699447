import { Card, Grid, Text, Button, List, Header } from "platyplex_ui";
import React, { useState } from "react";
import { disabledTxtColor2, primaryTxtColor } from "../../config";
import { Vehicle } from "../../pages/booking-history/type";
import { Checkbox, message, Popconfirm, Radio } from "antd";
import VehicleForm from "./vehicle-form";
import Wrapper from "../overlay-wrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  addVehicle,
  editVehicle,
  removeMultipleVehicle,
  removeVehicle,
  selectMultipleVehicle,
  selectVehicle,
} from "../../store/actions/vehicle";
import VehicleFormat from "./vehicle-format";

enum VehicleState {
  ADD,
  EDIT,
  VIEW,
}
const VehicleModal = ({
  close,
  maxCount,
  setSelectedVehicles,
  selectedVehicles,
}: {
  close: () => void;
  maxCount?: number;
  selectedVehicles?: Vehicle[],
  setSelectedVehicles?: (v: Vehicle[]) => void;
}) => {
  const { vehicles, loading } = useSelector((s: any) => s.vehicles);
  const dispatch = useDispatch();
  const border = `2px solid ` + disabledTxtColor2;
  const [state, setState] = useState(VehicleState.VIEW);
  const [vehicle, setVehicle] = useState<undefined | Vehicle>(undefined);

  return (
    <Wrapper
      style={{
        position: "absolute",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1000,
      }}
      back={state === VehicleState.VIEW ? close : undefined}
      header={
        <Grid
          style={{ borderTop: border, borderBottom: border }}
          placeSelf="stretch"
          customCols={state !== VehicleState.VIEW ? "auto 1fr" : "1fr auto"}
          padding="1em"
        >
          {state !== VehicleState.VIEW ? (
            <>
              <Header
                color={primaryTxtColor}
                placeSelf="start"
                onClick={() => setState(VehicleState.VIEW)}
                size={3.5}
                className="pointer med noselect"
              >
                <i className="fa fa-chevron-left"></i> Back
              </Header>
              <Header bold>
                {state === VehicleState.ADD ? "Add" : "Edit"} Vehicle
              </Header>
            </>
          ) : (
            <>
              {" "}
              <Header bold>My Vehicles</Header>
              <Text
                color="blue"
                className="pointer"
                onClick={() => setState(VehicleState.ADD)}
              >
                Add Vehicle
              </Text>
            </>
          )}
        </Grid>
      }
    >
      {state === VehicleState.ADD ? (
        <VehicleForm
          close={() => setState(VehicleState.VIEW)}
          addVehicle={(v: Vehicle) => dispatch(addVehicle(v))}
          loading={loading}
        />
      ) : state === VehicleState.EDIT ? (
        <VehicleForm
          close={() => setState(VehicleState.VIEW)}
          editVehicle={(v: Vehicle) => dispatch(editVehicle(v))}
          loading={loading}
          vehicle={vehicle}
        />
      ) : (
        <Grid placeSelf="start center" placeItems="start center">
          <Text>
            {`Please select below ${
              maxCount && maxCount > 1 ? maxCount + " vehicles" : "the vehicle"
            } that you will be parking with`}
          </Text>

          <List
            empty={
              <Text textAlign="left" color="red">
                No vehicle present. Please add a vehicle to continue.
              </Text>
            }
            loading={loading}
            list={vehicles}
            map={(v: Vehicle, i: number) => (
              <VehicleItem
                edit={() => {
                  setState(VehicleState.EDIT);
                  setVehicle(v);
                }}
                max={maxCount}
                multi={maxCount !== undefined ? maxCount > 1 : false}
                setSelectedVehicles={setSelectedVehicles}
                selectedVehicles={selectedVehicles}
                close={close}
                vehicle={v}
              />
            )}
          />
        </Grid>
      )}
    </Wrapper>
  );
};

interface VehicleItemProps {
  vehicle: Vehicle;
  close: () => void;
  multi: boolean;
  max?: number;
  edit: () => void;
  selectedVehicles?: Vehicle[],
  setSelectedVehicles?: (v: Vehicle[]) => void;
}

const VehicleItem = ({
  vehicle,
  close,
  max,
  multi,
  edit,
  selectedVehicles: localSelectedVehicles,
  setSelectedVehicles,

}: VehicleItemProps) => {
  const dispatch = useDispatch();
  const { selectedVehicle, selectedVehicles } = useSelector(
    (state: any) => state.vehicles
  );
  const selected = !multi
    ? selectedVehicle?._id === vehicle._id:
    localSelectedVehicles !== undefined?
    localSelectedVehicles.reduce(
      (prev: boolean, current: Vehicle) =>
        prev || current._id === vehicle._id,
      false
    )
    : selectedVehicles.reduce(
        (prev: boolean, current: Vehicle) =>
          prev || current._id === vehicle._id,
        false
      );
  return (
    <Card
      shadow
      grid
      placeItems="stretch"
      placeSelf="stretch"
      gridGap="0"
      mobileLayout={false}
      customCols="1fr auto"
    >
      <VehicleFormat vehicle={vehicle} />
      {multi? (
        <Checkbox    
        checked={selected} 
        disabled={!selected && max == selectedVehicles?.length} 
        onClick={()=>{
        if(setSelectedVehicles && localSelectedVehicles !== undefined){
          if (!selected) {
            const vehicles = [...localSelectedVehicles, vehicle];
            setSelectedVehicles(vehicles)
          } else {
           
            console.log(localSelectedVehicles.filter((v)=>v._id !== vehicle._id))
            setSelectedVehicles(localSelectedVehicles.filter((v)=>v._id !== vehicle._id))
          }
       
        } else{
          if (selected) {
            dispatch(removeMultipleVehicle(vehicle));
            message.success("Vehicle Deselected");
          } else {
            dispatch(selectMultipleVehicle(vehicle) );
            message.success("Vehicle Selected");
          }
        }        
        }}/>
      ) : (
        <Radio
          checked={selected}
          onClick={() => {  
            dispatch(selectVehicle(vehicle));
            message.success("Vehicle Selected");
            
            setTimeout(() => {
              close();
            }, 1000);
          }}
        />
      )}

      <Grid
        placeSelf="end stretch"
        cols="2"
        placeItems="stretch"
        gridGap="0"
        mobileLayout={false}
        style={{ gridColumn: "1/-1" }}
      >
        <Button
          faIcon="fa fa-edit"
          onClick={edit}
          noHover
          bg={"white"}
          style={{ boxShadow: "0 0 0" }}
          invert
        >
          {" "}
          Edit
        </Button>
        <Popconfirm
          title={"Are you sure you want to delete this Vehicle?"}
          onConfirm={() => dispatch(removeVehicle(vehicle))}
        >
          <Button
            faIcon="fa fa-trash"
            bg={"white"}
            invert
            noHover
            style={{ color: "red", boxShadow: "0 0 0" }}
          >
            {" "}
            Delete
          </Button>
        </Popconfirm>
      </Grid>
    </Card>
  );
};

export default VehicleModal;
