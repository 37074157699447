import React, { useState } from "react";
import { Drawer } from "antd";
import { Enterprise } from "../../../../hooks/enterprise";
import {
  EnterpriseFeature,
  Resource,
  SocialMediaUrl,
} from "../../../../hooks/enterprise-resource";
import Garages from "./garages";
import CloseBtn from "../../../../components/close-btn";
import Announcements from "./announcements";
import { Badge, Grid, Text, List, Avatar, Modal } from "platyplex_ui";
import {
  disabledTxtColor,
  disabledTxtColor2,
  primaryColor,
} from "../../../../config";
import { Badge as NotificationBadge } from "antd";
import useEnterpriseGarages from "../../../../hooks/enterprise-resource";
import { useSelector } from "react-redux";
import ParkstashText from "../../../../components/parkstash-text";
import Bar from "../../../checkout/components/bar";
import Frame from "../../../../components/frame";

interface Props {
  enterprise: Enterprise;
  close: () => void;
}
const EnterpriseDrawer = ({ close, enterprise }: Props) => {
  const [resource, setResource] = useState<Resource>("info");

  return (
    <Drawer
      placement="right"
      width={450}
      bodyStyle={{ padding: 0 }}
      visible={true}
      closable={false}
    >
      <div
        style={{
          display: "grid",
          height: "100vh",
          padding: "1.5em 1.5em .5em 1.5em",
          maxWidth: "800px",
          overflow: "hidden",
          gridTemplateRows: "auto 1fr",
          background: "white",
          placeItems: "stretch",
        }}
      >
        <div>
          <CloseBtn
            close={() => (resource === "info" ? close() : setResource("info"))}
          />
        </div>

        {resource === "garages" ? (
          <Garages
            enterprise={enterprise}
            toggleAnnoucements={() => setResource("announcements")}
          />
        ) : resource === "announcements" ? (
          <Announcements enterprise={enterprise} />
        ) : (
          <Home
            enterprise={enterprise}
            toggleResource={(r: Resource) => setResource(r)}
          />
        )}
      </div>
    </Drawer>
  );
};

interface HomeProps {
  enterprise: Enterprise;
  toggleResource: (r: Resource) => void;
}

const Home = ({ enterprise, toggleResource }: HomeProps) => {
  const { details } = useSelector((s: any) => s.user);
  const {
    loading,
    unseenActiveAnnouncementsCount,
    socialMediaUrls,
    enterpriseFeatures,
  } = useEnterpriseGarages(enterprise.id, "info");
  return (
    <Grid
      customRows={"auto auto auto auto auto 1fr"}
      placeItems="stretch"
      style={{ maxHeight: "95vh" }}
    >
      <Text textAlign="right" width="100%">
        <NotificationBadge count={unseenActiveAnnouncementsCount}>
          <i
            className="fa fa-bell txt-md pointer"
            style={{ color: primaryColor }}
            onClick={() => toggleResource("announcements")}
          />
        </NotificationBadge>
      </Text>
      <Grid>
        <img
          src={enterprise.imageUrl}
          alt="logo"
          style={{ maxWidth: "100%", width: 175, height: "auto" }}
        />
      </Grid>
      <div>
        <Text>Hey, {details.user_name}!</Text>
        <Text>Welcome to {enterprise.title}</Text>
      </div>
      <Grid
        customCols={`1fr repeat(${socialMediaUrls.length}, auto) 1fr`}
        mobileLayout={false}
        gridGap="0"
      >
        <span />
        <List
          loading={loading}
          list={socialMediaUrls}
          map={(e: SocialMediaUrl) => (
            <a target="_" href={e.url}>
              <Avatar src={e.iconUrl} />
            </a>
          )}
        />
      </Grid>

      <Grid mobileLayout={false} placeItems="stretch" gridGap="0">
        <List
          loading={loading}
          loader={<span />}
          list={enterpriseFeatures}
          map={(e: EnterpriseFeature) => (
            <FeatureHandler
              enterpriseFeature={e}
              toggleResource={toggleResource}
            >
              <Grid
                customCols={`auto 1fr`}
                mobileLayout={false}
                width="100%"
                margin=".5em 0"
              >
                <Avatar src={e.iconUrl} style={{ fontSize: "1.5em" }} />
                <Text>{e.menuLabel}</Text>
              </Grid>
              <Bar />
            </FeatureHandler>
          )}
        />
      </Grid>
      <Text placeSelf="end center">
        Patent Pending | 2019 <ParkstashText /> Inc. All Rights Reserved
      </Text>
    </Grid>
  );
};

const FeatureHandler = ({
  children,
  enterpriseFeature,
  toggleResource,
}: {
  children: JSX.Element[];
  enterpriseFeature: EnterpriseFeature;
  toggleResource: (r: Resource) => void;
}) => {

  const openFrame = (src: string) =>{
  const FrameModal = Modal({
    title: "",
    modal: (
      <Frame close={()=>FrameModal.close()} src={src.replace('http://', 'https://')}/>
    ),
  });
  FrameModal.open()
};

  if (
    enterpriseFeature.isExternal ||
    enterpriseFeature.feature === "event_reservation"
  )
    return (
      
      <a onClick={()=> openFrame(enterpriseFeature.url || '')} target="myIframe" className="pointer">
        {children}
      </a>
    );
  else if (enterpriseFeature.feature === "pay_citations")
    return <div style={{ cursor: "not-allowed" }}>{children}</div>;
  else
    return (
      <div onClick={() => toggleResource("garages")} className="pointer">
        {children}
      </div>
    );
};
export default EnterpriseDrawer;
