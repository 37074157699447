import React, { useEffect, useState } from "react";
import { Grid, Text, Button, Header } from "platyplex_ui";
import { message, Radio } from "antd";
import { useFormik } from "formik";
import {
  disabledTxtColor2,
  gradient,
  gray,
  primaryColor,
} from "../../../../config";
import Amenities from "../../../../components/amenities";
import VehicleClasses from "../../../../components/vehicle-classes";
import WeeklyScheduler from "./weekly-scheduler";
import PrimaryModal from "../../../../components/primary-modal";
import NewModal from "../../../../components/new-modal";
import Wrapper from "../../../../components/overlay-wrapper";

enum State {
  Schedule,
  Vehicle,
  Amenities,
}
const Filter = (props: any) => {
  const { loading, query, close } = props;
  const [state, setState] = useState<State | undefined>(undefined);
  const { handleSubmit, handleChange, setFieldValue, values } = useFormik({
    initialValues: query,
    onSubmit(values) {
      props.submit(values);
    },
  });


  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: !!state ? "1fr" : "auto auto 1fr",
        height: "100vh",
        padding: "1.5em 1.5em .5em 1.5em",
        maxWidth: "800px",
        background: "white",
        placeItems: "stretch",
      }}
    >
      {state === State.Schedule ? (
        <WeeklyScheduler close={() => setState(undefined)} />
      ) : state === State.Vehicle ? (
        <Vehicles
          loading={loading}
          close={() => setState(undefined)}
          setSave={(b: boolean) => setFieldValue("save_parking_preset", b)}
          vehicles={values.vehicle_classes}
          setVehicles={(s: string[]) => setFieldValue("vehicle_classes", s)}
        />
      ) : state === State.Amenities ? (
        <Grid placeItems="stretch">
          <div>
            <Text bold size="1.2em">
              Amenities
            </Text>
            <Text textAlign="left">
              Please select amenities available from the option below
            </Text>
            <br />
            <Amenities
              setAmenities={(e: any) => setFieldValue("amenities", e)}
              amenities={values.amenities}
            />
          </div>
          <Grid placeSelf="stretch" placeItems="end stretch">
            <Button
              rounded
              noHover
              onClick={() => setState(undefined)}
              bg={gradient}
              padding={".5em"}
              style={{ margin: "1em" }}
              disabled={loading}
              loading={loading}
            >
              Done
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid>
            <i
              className="fa fa-times bold pointer hoverr txt-md"
              onClick={close}
              style={{ placeSelf: "start" }}
            />
            <Header bold>Filters</Header>
          </Grid>

          <Grid
            style={{ maxHeight: "100%", overflowY: "auto", fontSize: "1em" }}
            placeItems="stretch"
          >
            <FieldWrapper>
              <Text textAlign="left" placeSelf="end start" bold>
                Duration
              </Text>
              <Radio.Group
                name="booking_type"
                onChange={handleChange}
                value={values.booking_type}
              >
                <Grid
                  customCols="auto auto auto 1fr"
                  gridGap="2em"
                  mobileLayout={false}
                >
                  {["Daily", "Weekly", "Monthly"].map((item) => (
                    <Radio value={item.toLowerCase()} key={item}>
                      <Text style={{ padding: ".5em" }}> {item}</Text>
                    </Radio>
                  ))}
                </Grid>
              </Radio.Group>

              {values.booking_type !== "daily" && (
                <>
                  <Text textAlign="left" className="slide-up">
                    Please set your weekly parking schedule for weekly/monthly
                    bookings
                  </Text>

                  <Button
                    rounded
                    onClick={() => setState(State.Schedule)}
                    className="slide-up"
                  >
                    <Grid customCols="1fr auto auto 1fr" color="inherit" mobileLayout={false}>
                      <span />
                      Set your weekly schedule
                      <i className="fas fa-angle-double-right txt-md" />
                    </Grid>
                  </Button>
                </>
              )}
            </FieldWrapper>

            <FieldWrapper
              onClick={() => setState(State.Vehicle)}
              style={{ gridTemplateColumns: "1fr auto" }}
              className="pointer"
            >
              <Text textAlign="left" placeSelf="end start" bold>
                Filter By Vehicle Size{" "}
                {values.vehicle_classes.length > 0
                  ? `(${values.vehicle_classes.length})`
                  : null}
              </Text>
              <i className="fa fa-chevron-right" />
            </FieldWrapper>
            <FieldWrapper
              onClick={() => setState(State.Amenities)}
              style={{ gridTemplateColumns: "1fr auto" }}
              className="pointer"
            >
              <Text textAlign="left" placeSelf="end start" bold>
                Filter By Amenities{" "}
                {values.amenities.length > 0
                  ? `(${values.amenities.length})`
                  : null}
              </Text>
              <i className="fa fa-chevron-right" />
            </FieldWrapper>

            <FieldWrapper
              onClick={() => {
                message.success("Filter set to default.");
                setFieldValue("amenities", []);
                setFieldValue("vehicle_classes", []);
                setFieldValue("booking_type", "daily");
              }}
            >
              <Text
                textAlign="left"
                className="pointer"
                placeSelf="stretch"
                bold
                color={primaryColor}
              >
                Clear All
              </Text>
            </FieldWrapper>
          </Grid>

          <Grid placeSelf="stretch" placeItems="end stretch">
            <Button
              rounded
              noHover
              bg={gradient}
              padding={".5em"}
              onClick={handleSubmit}
              style={{ margin: "1em" }}
              disabled={loading}
              loading={loading}
            >
              Search
            </Button>
          </Grid>
        </>
      )}
    </div>
  );
};

interface VehicleProps {
  loading: boolean;
  vehicles: string[];
  setVehicles: (s: string[]) => void;
  close: () => void;
  setSave: (b: boolean) => void;
}

const Vehicles = ({
  loading,
  vehicles,
  setVehicles,
  close,
  setSave,
}: VehicleProps) => {

  const SaveModal = NewModal({
    modal: (
      <Wrapper
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 10000,
          background: gray,
        }}
      >
        <PrimaryModal
          confirmText="Yes"
          cancelText="No"
          onConfirm={() => {
            setSave(true);
            close();
          }}
          onCancel={() => {
            setSave(false);
            close();
          }}
        >
          <Text bold>
            Do you want to save these selections as your default preference?
          </Text>
        </PrimaryModal>
      </Wrapper>
    ),
  });


  return (
    <Grid placeItems="stretch">
      {SaveModal.modal}
      <div>
        <Text bold size="1.2em">
          Vehicle Size
        </Text>
        <Text textAlign="left">
         Please select type of vehicle classes which closely match your vehicle size
        </Text>
        <br />
        <VehicleClasses
          selectedVehicles={vehicles}
          setSelectedVehicles={setVehicles}
        />
      </div>

      <Grid placeSelf="stretch" placeItems="end stretch">
        <Button
          rounded
          noHover
          bg={gradient}
          onClick={SaveModal.open}
          padding={".5em"}
          style={{ margin: "1em" }}
          disabled={loading}
          loading={loading}
        >
          Done
        </Button>
      </Grid>
    </Grid>
  );
};

const FieldWrapper = ({
  children,
  gridGap,
  onClick,
  style,
  className,
}: any) => (
  <Grid
    style={{ borderBottom: "1px solid " + disabledTxtColor2, ...style }}
    padding="0 0 1em 0"
    placeSelf="stretch"
    onClick={onClick}
    placeItems="stretch"
    className={className}
    gridGap={gridGap || "0.5em"}
  >
    {children}
  </Grid>
);
export default Filter;
