import { Card } from "platyplex_ui";
import React from "react";
import { disabledTxtColor2 } from "../../../config";

const Bar = ({ style }: any) => {
  return (
    <div
      style={{
       width: '100%',
       placeSelf: "stretch",
       borderBottom: '1px solid '+ disabledTxtColor2,
       ...style
      }}
    >
    </div>
  );
};

export default Bar;
