import { useEffect, useState } from "react";
import api from "../api";

type Cood = {
  lat: number;
  lng: number;
};

interface Address {
  city: string;
  country: string;
  zipcode: string;
  street: string;
  state: string;
}
export interface AddressData {
  geo: Cood;
  formattedAddress: string;
  address?: Address;
  updatedLocation?: boolean;
}
interface State {
  loading: boolean;
  error: boolean;
  success: boolean;
  addressData: AddressData;
}


export const useGeocode = () => {
  const defaultAddressData = {
    geo: {
      lat: -1,
      lng: -1,
    },
    address: {
      city: "",
      country: "",
      zipcode: "",
      street: "",
      state: "",
    },
    formattedAddress: "No Result Found",
  };

  const [placeId, setPlaceId] = useState("");
  const [state, setState] = useState<State>({
    loading: false,
    success: false,
    error: false,
    addressData: defaultAddressData,
  });

  const getAddressComponent = (addresses: any[], component: string) =>
    addresses.filter((address: any) => address.types.includes(component))[0];

  const formatAddress = (addresses: any) => ({
    street: `${
      getAddressComponent(addresses, "street_number")?.short_name || ""
    } ${getAddressComponent(addresses, "route")?.short_name || ""}`.trim(),
    city: getAddressComponent(addresses, "locality")?.short_name || "",
    state:
      getAddressComponent(addresses, "administrative_area_level_1")
        .short_name || "",
    country: getAddressComponent(addresses, "country")?.short_name || "",
    zipcode: getAddressComponent(addresses, "postal_code")?.short_name || "",
  });

  const getGeocode = () => {
    if (placeId.length > 0) {
      setState({ ...state, loading: placeId.length > 0, error: false });
      api
        .getPlacesGeocode(placeId)
        .then((res) => {
          if (res.status === "OK" && res?.results?.length > 0) {
            const filteredResults = res.results;

            const formattedAddress = filteredResults[0].formatted_address;
            const geo = {
              lat: filteredResults[0].geometry.location.lat,
              lng: filteredResults[0].geometry.location.lng,
            } as Cood;

            const addresses = filteredResults[0].address_components;
            const address = formatAddress(addresses);

            setState({
              error: false,
              loading: false,
              success: true,
              addressData: {
                geo,
                formattedAddress,
                address,
              },
            });
          } else throw new Error("All Component not found");
        })
        .catch((error) => {
          console.log(error);
          setState({
            loading: false,
            error: true,
            success: false,
            addressData: defaultAddressData,
          });
        });
    }
  };

  useEffect(() => {
    getGeocode()
  }, [placeId]);

  return {
    ...state,
    setPlaceId,
  };
};

export default useGeocode;
