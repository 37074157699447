import React, { useContext, useState } from 'react';
import * as CH from '@pstash/charts';
//BEGIN: Theme

//NOTE: these colors and themes are for quick copy paste of the components
//      from permit app, so as to maintain consistency
const COLOR_SILVER = '#E4E9EF';
const COLOR_GREY = '#F0F3F4';
const COLOR_STRONG_GREY = '#A6ACAF';
const COLOR_WHITE = '#FFFFFF';
const COLOR_RED = '#FF0000';
const COLOR_GREEN = '#00D69E';
const COLOR_BLACK = '#32325D';
const COLOR_ORANGE = '#F49718';
const COLOR_PARKSTASH = '#33B482';
const COLOR_GRADIENT =  'linear-gradient(to left, #00D69E, #00BAA4, #009CA0, #009CA0, #1E6378, #2F4858)';
export const theme = {
  fontSize: {
    xlg: '32px',
    lg: '24px',
    md: '16px',
    sm: '12px'
  },
  borderRadius: {
    lg: '24px',
    md: '16px',
    sm: '12px'
  },
  color: {
    gradient: COLOR_GRADIENT,
    primary: COLOR_BLACK,
    primary_parkstash: COLOR_PARKSTASH,
    secondary: COLOR_GREEN,
    inverted: COLOR_WHITE,
    danger: COLOR_RED,
    disabled: COLOR_STRONG_GREY,
    white: COLOR_WHITE,
    silver: COLOR_SILVER,
    grey: COLOR_GREY,
    orange: COLOR_ORANGE
  },
  spacing: (...args: (number | null)[]) =>
  {
    const sizes = [0, 2, 4, 8, 16, 20, 24, 32, 64, 128];
    let pxs: string[] = [];

    args.forEach(idx => {
      if (idx == null)
      {
        pxs.push('auto');
      }
      else if (!sizes[idx] && sizes[idx] !== 0)
      {
        throw new Error('Spacing args can range from 0 to ' + (sizes.length - 1));
      }
      else
      {
        pxs.push(sizes[idx] + 'px');
      }
    });

    return pxs.join(' ');
  }    
};

//END: Theme
{}
//BEGIN: Card

export interface CardStyleProps
{
  showBoxShadow?: boolean;
  shouldRoundCorner?: boolean;
  background?: string;
}
export interface CardSizeProps
{
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
}
export interface CardProps
{
  children: any;
  className?: string;
  style?: React.CSSProperties;
  styleProps?: CardStyleProps;
  sizeProps?: CardSizeProps;
}

const defaultCardStyleProps = () => 
{
  const result: CardStyleProps = {
    showBoxShadow: true,
    shouldRoundCorner: false,
    background: theme.color.white
  };

  return result;
}

const defaultCardSizeProps = () => 
{
  const result: CardSizeProps = {
    margin: theme.spacing(0),
    padding: theme.spacing(4)
  };

  return result;
}

export const Card = (props: CardProps) => 
{
  const size_defaults  = defaultCardSizeProps();

  const margin = props.sizeProps?.margin || size_defaults.margin!;
  const padding = props.sizeProps?.padding || size_defaults.padding!;
  const width = props.sizeProps?.width || size_defaults.width || '';
  const height = props.sizeProps?.height || size_defaults.height || '';

  const style_defaults = defaultCardStyleProps();

  const background = props.styleProps?.background || style_defaults.background;

  const showBoxShadow = props.styleProps?.showBoxShadow || style_defaults.showBoxShadow!;
  let boxShadow = '';
  if (showBoxShadow)
  {
    boxShadow = theme.spacing(3, 3, 3, 0) + ' ' +  theme.color.silver;
  }

  const shouldRoundCorner = props.styleProps?.shouldRoundCorner || style_defaults.shouldRoundCorner!; 
  let borderRadius = ''; 
  if (props.styleProps?.shouldRoundCorner)
  {
    borderRadius = theme.borderRadius.md;
  }

  return (
    <div
      style={{
        margin,
        padding,
        width,
        height,
        boxShadow,
        borderRadius,
        background,
        ...props.style
      }}
      className={props.className}
    >
      {props.children}
    </div>
  );
};

//END: Card
{}
//BEGIN: Chart Button

interface ChartButtonProps
{
  isSelected: boolean;
  isDisabled: boolean;
  faIcon: string;
  label: string;
  onClick: {(): void;}
}

export const ChartButton = (props: ChartButtonProps) => 
{
  //NOTE: we have set margin directly here. If this becomes inconvinient later
  //pull the margin out
  let color = theme.color.primary;
  let bg = theme.color.white;
  if (props.isSelected)
  {
    color = theme.color.white;
    bg = theme.color.primary;
  }
  else if (props.isDisabled)
  {
    color = theme.color.white;
    bg = theme.color.disabled;
  }
  return (
    <button
      disabled={props.isDisabled}
      aria-label={props.label}
      onClick={e => props.onClick() }
      style={{
        transition: 'all 200ms ease-out 0s',
        borderRadius: `${theme.borderRadius.sm}`,
        width: '3em',
        height: 'auto',
        padding: theme.spacing(2, 3),
        marginRight: theme.spacing(3),
        textAlign: 'center',
        fontSize: '1em',
        fontWeight: 'normal',
        border: `${theme.spacing(2)} solid ${bg}`,
        cursor: 'pointer',
        boxShadow: `${theme.color.silver} ${theme.spacing(2, 2, 2, 0)}`,
        background: `${bg}`,
        color: `${color}`
      }}>
        <i className={props.faIcon}></i>
    </button>
 );
}

//END: Chart Button
{}
//BEGIN: Scorecard Donut

export interface Scorecard_DonutProps
{
  donutChartData: any
  title: string;
  donutText: string;
}
export const Scorecard_Donut = (props: Scorecard_DonutProps) => 
{
  const D = 150;
  const R = D / 2;

  return (
    <div style={{width: D}}>
      <p style={{whiteSpace: 'nowrap', fontSize: theme.fontSize.lg, textAlign: 'center' }}>{props.title}</p>
      <div style={{width: D, height: D, marginTop: '.5em'}}> 
        <CH.DonutChart
          radius={R}
          emptyColor={theme.color.grey}
          data={props.donutChartData}
          donutThickness={15} 
          text={props.donutText}
          textStyleProps={{
            color: theme.color.primary,
            fontSize: theme.fontSize.lg,
          }} />
      </div>
    </div>
  );
}

//END: Scorecard Donut