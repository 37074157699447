import { useEffect, useState } from "react";
import API from "../api";
import { asyncHandler } from "../utils";

interface Amenity {
  displayName: string;
  name: string;
  imageUrl: string;
}

interface State {
  amenities: Amenity[];
  loading: boolean;
  error: boolean;
}

export const useAmenities = () => {
  const [state, setter] = useState<State>({
    amenities: [],
    loading: false,
    error: false,
  });

  const setState = (obj: Partial<State>) => setter({ ...state, ...obj });

  const getAmenities = async () => {
    setState({ loading: true, error: false });
    const { success, amenityDescriptors } = await asyncHandler({
      apiCall: () => API.getAmenties(),
    });

    setState({
      loading: false,
      error: !success,
      amenities: amenityDescriptors || [],
    });
  };

  const getAmenty = (amenity: string) => {
    return state.amenities.filter(({ name }) => amenity === name)[0];
  };

  useEffect(() => {
    getAmenities();
  }, []);

  return {
    ...state,
    getAmenty,
  };
};

export default useAmenities;
