import { DatePicker, message, Radio } from "antd";
import { Grid, Text, Button, Notification,  Modal } from "platyplex_ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/overlay-wrapper";
import Bar from "../../../checkout/components/bar";
import { BookingHistory } from "../../type";
import momentTimezone, { Moment } from "moment-timezone";
import { getDateFormat } from "../../../../utils/date";
import api from "../../../../api";
import Images from "../../../../components/images";
import PrimaryModal from "../../../../components/primary-modal";
import { reloadUserDetails } from "../../../../store/actions/user";
import Success from "../../../../components/success";
import Payment from "./payment";
import { BookingExtensionReview } from '../../type'
import ListingDetails from "../../../../components/listing-details";

interface ExtensionProps {
  close: () => void;
  reload: () => void;
  booking: BookingHistory | undefined;
}
const Extension = ({ close, booking, reload }: ExtensionProps) => {
  const moment = (m? : Moment | string)=> momentTimezone(m).tz(booking?.tz || 'America/Chicago')
  const endDate = moment(booking?.end);
  const [date, setDate] = useState(moment(endDate).add('minute', 1));
  const [selectedIndex, setSelectedIndex ] = useState(0)
  const [showTimepicker, setShowTimepicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [initialLoad, setInitalLoad] = useState(true);
  const dispatch = useDispatch();
  const { selectedCard } = useSelector((state: any) => state.cards);
  const [review, setReview] =
    useState<undefined | BookingExtensionReview>(undefined);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("wallet");

  const width = { minWidth: 400, maxWidth: '95vw' }

  const WarningModal = Modal({
    title: "",
    modal: (
      <PrimaryModal
        cancelText="Ok"
        onCancel={() => WarningModal.close()}
        confirmText="Raise Help Ticket"
        onConfirm={() => {
          // history.push('/help')
          message.info("Booking ID copied");
        }}
      >
        <Grid gridGap=".25em" padding="1em">
          <Text bold>Cannot extend Booking</Text>
          <Text>
            Your booking cannot be extended for the duration you selected. Try
            changing the duration or both the space again. You may be assigned a
            different Spot #.
          </Text>
        </Grid>
      </PrimaryModal>
    ),
    hideCancel: true,
  });

  const extendBooking = () => {
    const needHours = booking?.type === "daily";
    setLoading(true);
    const time = needHours ? { end_hour: date?.format("HH:mm") } : undefined;

    api
      .extendBooking(booking?._id || "", {
        end_date: date.format("YYYY-MM-DD"),
        payment_method: paymentMethod,
        card_id: paymentMethod === "card" ? selectedCard?.id : undefined,
        ...time,
      })
      .then((res: any) => {
        if (res.success) {
          setSuccess(true);
          reload();
          dispatch(reloadUserDetails());
          Notification.success({ title: "Success", message: res.message });
        } else throw new Error(res.message || "Error");
      })
      .catch((e) => WarningModal.open())
      .finally(() => setLoading(false));
  };

  const reviewBooking = () => {
    const needHours = booking?.type === "daily" && !initialLoad;
    const time = needHours
      ? {
        end_hour: date?.format("HH:mm"),
      }
      : undefined;

    api
      .reviewBookingExtension(booking?._id || "", {
        end_date: initialLoad? undefined: date.format("YYYY-MM-DD"),
        payment_method: paymentMethod,
        card_id: paymentMethod === "card" ? selectedCard?.id : undefined,
        ...time,
      })
      .then((res) => {
        if (res.success && res.review) {
          setReview(res.review);
        } else
          throw new Error(
            res.message || "The spot can not be extended for the set time"
          );
      })
      .catch((e) => {
        if(initialLoad){
          setError(true)
        }
        Notification.error({ message: e?.message, title: "Error" });
      });
  };
  
  useEffect(() => {
    const initial = 0
    if (review?.extend_by_options[initial] && initialLoad) {
      setDate(moment(review?.extend_by_options[initial].at));
      setSelectedIndex(initial)
      setInitalLoad(false)
    }
  }, [review]);
  
  useEffect(() => {
    reviewBooking();
  }, [date, paymentMethod]);

  useEffect(() => {
    reviewBooking();
  }, []);

  return (
    <Wrapper
      style={{
        position: "absolute",
        top: 0,
        width: "100vw",
        maxHeight: "100vh",
        zIndex: 100,
        paddingBottom: "2em",
      }}
      back={close}
    >
      {error? <Text bold size={'1.3em'}>Error! The spot can not be extended further.</Text>:
      <div style={{ overflowY: 'auto', width: '100%', maxHeight: '100%' }} className='grid'>
        <Grid placeSelf="start center" placeItems="start stretch" height="100%" style={width}>
          {success ? (
            <Grid height="100%">
              <Success
                title="Booking Extension Successful!"
                message={`New Booking End Time: ${date.format(
                  getDateFormat(booking?.type || "")
                )}`}
                onBack={close}
                style={{ boxShadow: "none", placeSelf: "center stretch" }}
              />
            </Grid>
          ) : (
            <>
              <Grid customCols='150px auto' mobileLayout={false}>
                <Images
                  hideIndex={
                    booking?.parking?.image_url?.length
                      ? booking?.parking?.image_url?.length > 5
                      : true
                  }
                  images={booking?.parking?.image_url || []}
                  style={{ width: 150, height: 150, minWidth: "100%" }}
                />
                <div style={{ placeSelf: 'stretch' }}>
                  <Text bold textAlign='left'>{booking?.parking?.name}</Text>
                  <Text textAlign='left'>Spot #{booking?.assigned_parking_spot_no}</Text>
                </div>
              </Grid>

              <Grid >
                <Text style={{ fontStyle: 'italic' }}>
                  Current Reservation Ends in
                </Text>
                <Text className='med' color='red' size='1.25em'>
                  {review?.duration_until_end}
                </Text>
                <Text>
                  {review?.end}
                </Text>
              </Grid>
              <Bar />
              <Grid customCols="1fr auto" mobileLayout={false} placeItems='stretch'>
                <Text textAlign="left" >
                  Max Extension <br /> Time Allowed
                </Text>
                <Text textAlign="left" >
                  {review?.max_end}
                </Text>
              </Grid>
              <Bar />

              <Text textAlign="left">Extend By</Text>
              <Grid cols="3">
                {review?.extend_by_options?.
                  map(({ label, at, open_picker }, index) => {
                    return (
                      <span>
                        <Radio
                          checked={index === selectedIndex}
                          onClick={() => {
                            setSelectedIndex(index)
                            if (!open_picker) {
                              setDate(moment(at));
                              setShowTimepicker(false)
                            } else {
                              setDate(moment(review?.extend_by_options[0].at))
                              setShowTimepicker(true)
                            }
                          }}
                        />
                        &nbsp; {label}
                      </span>
                    )
                  })}
              </Grid>

              <Grid placeSelf="stretch" placeItems="stretch">
                <DatePicker
                  disabled={!showTimepicker}
                  showTime={booking?.type === "daily"}
                  disabledDate={(d) =>
                    d < moment(booking?.end) ||
                    (booking?.max_extension_end? d > moment(booking?.max_extension_end) : true)
                  }
                  value={date}
                  onChange={(e) => e && setDate(e)}
                  format={getDateFormat(booking?.type || "")}
                />
              </Grid>
              <Bar />
              <Text bold textAlign='left'>Payment Details</Text>
              <ListingDetails review={review as any} paymentMethod={paymentMethod} />

              <Bar />
              <Payment
                review={review}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
              />
              <Bar />

            </>
          )}
        </Grid>
      </div>}
      {!error && <Button
        rounded
        onClick={extendBooking}
        disabled={loading}
        loading={loading}
        style={width}
      >
        Extend
      </Button>}
    </Wrapper>
  );
};

export type PaymentMethod = "card" | "wallet";

export default Extension;
