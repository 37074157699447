import { CreditCard  } from "../../pages/booking-history/type";

interface State {
  cards: CreditCard[];
  selectedCard?: CreditCard;
  error: boolean;
  loading: boolean;
}
interface VehicleActions {
  type: ActionTypes;
  payload: Partial<State> & {
    card : CreditCard;
  };
}

const intialState = {
  cards: [],
  selectedVehicle: undefined,
  error: false,
  loading: false,
};

export enum ActionTypes {
  ADD_CARD = "ADD_CARD",
  REMOVE_CARD = "REMOVE_CARD",
  LOAD_CARD = "LOAD_CARD",
  SELECT_CARD = "SELECT_CARD",
  REQUEST_CARD = "REQUEST_CARD",
  ERROR_CARD= "ERROR_CARD",
  SUCCESS_CARD = "SUCCESS_CARD",
}

export default function rootReducer(
  state = intialState,
  action: VehicleActions
): any {
  switch (action.type) {
    case ActionTypes.LOAD_CARD:
      const [selectedCard] = action.payload?.cards as any;
      return { ...state, cards: action.payload.cards, selectedCard };

    case ActionTypes.SELECT_CARD:
      return { ...state, selectedCard: action.payload.card};

    
    case ActionTypes.REMOVE_CARD:
      return { ...state, cards: state.cards.filter((v: CreditCard)=> v.id !== action.payload.card.id) };

    case ActionTypes.ERROR_CARD:
      return { ...intialState, error: true, loading: false };

    case ActionTypes.REQUEST_CARD:
      return { ...intialState, error: false, loading: true };

    case ActionTypes.SUCCESS_CARD:
      return { ...state, loading: false };
    default:
      return state;
  }
}
