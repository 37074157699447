import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Grid, Header, Switch } from "platyplex_ui";
import { disabledTxtColor, primaryColor, primaryTxtColor } from "../../config";
import useBookingHistory from "../../hooks/booking-history";
import BookingList from "./booking-list";
import { BookingHistory as Booking } from "./type";
import BookingInfo from "./components/booking-info";
import NewModal from "../../components/new-modal";
import Extension from "./components/extension";
import useQuery from "../../hooks/query";
import { useHistory } from "react-router";
import usePagination from "../../hooks/paginateion_v2";
import PageInput from "../../components/page-input";

enum User {
  HOST = "HOST",
  DRIVER = "DRIVER",
}

enum History {
  UPCOMING = "UPCOMING",
  PAST = "PAST",
  CANCELLED = "CANCELED",
}

const BookingHistory = () => {
  const [state, setter] = useState<any>({
    as: User.DRIVER,
    type: History.UPCOMING,
  });

  const { query } = useQuery<{ booking: string }>();

  const [selectedBooking, setSelectedBooking] = useState<undefined | string>(
    query.booking
  );
  const history = useHistory();
  const setState = (props: any) => setter({ ...state, ...props });
  const {
    bookingHistory: bookings,
    loading,
    setQuery,
    changePage,
    reload,
    query: bookingQuery,
    totalCount
  } = useBookingHistory({});

  useEffect(() => {
    setPage(1);
    setQuery({ as: state.as.toLowerCase(), type: state.type.toLowerCase() });
  }, [state]);
  const currentSelected = bookings.filter(
    ({ _id }: Booking) => _id === selectedBooking
  )[0];
  const extensionModal = NewModal({
    modal: (
      <Extension
        reload={reload}
        close={() => extensionModal.close()}
        booking={currentSelected as Booking}
      />
    ),
  });

  const extend = (b: Booking) => {
    setSelectedBooking(b._id);
    extensionModal.open();
  };
  const BookingModal = NewModal({
    modal: (
      <BookingInfo
        reload={reload}
        close={() => {
          history.push("/booking-history");
          BookingModal.close();
        }}
        booking={currentSelected as Booking}
        as={state.as}
        extend={extend}
        type={state.type}
      />
    ),
  });

  const maxPage = Math.ceil(totalCount / 10);
  const { page, actions, canGoBack, setPage } = usePagination(
    10,
    totalCount,
    bookingQuery.page_no || 0,
    changePage
  );

  useEffect(() => {
    if (selectedBooking && currentSelected) {
      BookingModal.open();
    }
  }, [bookings]);

  if (extensionModal.modal) return extensionModal.modal;
  return (
    <Layout sidebar style={{ gridTemplateRows: "1fr auto" }}>
      <Layout.Top></Layout.Top>
      <Layout.Bottom>
        <Grid placeItems="start stretch" padding="1em" height="100%">
          {BookingModal.modal}
          <Header bold color={primaryTxtColor} textAlign="left">
            Booking History
          </Header>
          <Grid
            placeItems="end start"
            width="100%"
            style={{
              borderBottom: "1px solid " + disabledTxtColor,
              paddingBottom: 0,
            }}
          >
            <Switch.underlined
              style={{ padding: 0 }}
              onClick={(as) => setState({ as })}
              color={primaryTxtColor}
              border={"2px solid " + primaryColor}
              items={Object.keys(User)}
              active={state.as}
            />
          </Grid>

          <Switch
            style={{ padding: 0 }}
            onClick={(type: any) => setState({ type })}
            color={primaryTxtColor}
            border={"2px solid " + primaryColor}
            items={Object.keys(History)}
            active={state.type}
          />

          {!loading && <div
            style={{ color: primaryTxtColor, margin: "1em 0", fontSize: '1.2em' }}
            className="bold noselect"
          >
            <i
              className={"fa fa-chevron-circle-left pointer"}
              style={{ color: canGoBack ? primaryTxtColor : disabledTxtColor }}
              onClick={() => (canGoBack ? actions.backward() : {})}
            />
            &nbsp;
            <PageInput onChange={setPage} value={page} max={maxPage} />
            &nbsp; of {maxPage || 1} &nbsp;
            <i
              className="fa fa-chevron-circle-right pointer"
              style={{
                color: maxPage > page ? primaryTxtColor : disabledTxtColor,
              }}
              onClick={() => (maxPage > page ? actions.forward() : {})}
            />
          </div>}

          <BookingList
            bookings={bookings}
            loading={loading}
            type={state.type}
            reload={reload}
            extend={extend}
            selectBooking={(id: string) => {
              setSelectedBooking(id);
              BookingModal.open();
            }}
            user={state.as}
          />
        </Grid>
      </Layout.Bottom>
    </Layout>
  );
};

export default BookingHistory;
