import React from "react";
import { Empty, Grid, List, Text } from "platyplex_ui";
import { Rate } from "antd";
import { cloudinaryPrefix, disabledTxtColor2 } from "../../../../config";
import avatar from "../../../../assets/user.svg";
import moment from 'moment';

interface ReviewProps {
  loading: boolean;
  reviews: any;
}

const Reviews = ({ loading, reviews }: ReviewProps) => (
  <Grid placeItems="stretch">
    <Wrap>
      {reviews?.avg_host_rating.toFixed(1)}{" "}
      <Rate  disabled value={reviews?.avg_host_rating} /> &nbsp; ({reviews?.rating_count}{" "}
      Rating{reviews?.rating_count > 1 && "s"})
      <Text>{reviews?.review_count} Reviews </Text>
    </Wrap>
    <List
      loading={loading}
      empty={<Empty text={"No Reviews"} style={{ marginTop: 0 }} />}
      list={
        reviews?.ratings?.filter(
          ({ review }: any) => typeof review === "string"
        ) || []
      }
      map={(props: any, i: number) => (
        <Wrap key={i}>
          <Grid customCols="auto 1fr" placeItems="stretch">
            <img
              alt='avatar'
              style={{
                width: "4em",
                height: "4em",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={
                props?.driver?.image_url
                  ? cloudinaryPrefix + props?.driver?.image_url
                  : avatar
              }
            />
            <div>
              <Text textAlign="left" className="capitalize">
                {props.driver.user_name}
              </Text>
              <Text textAlign="left" className="capitalize">
                {moment(props.created_at).format("MMM YYYY")}
              </Text>
            </div>
          </Grid>
          <Text textAlign="left">
            {props.review}
          </Text>
        </Wrap>
      )}
    />
  </Grid>
);

const Wrap = ({ children }: { children: React.ReactElement[] | any }) => (
  <div
    style={{
      fontSize: "1.2em",
      padding: "1em",
      borderBottom: "1px solid " + disabledTxtColor2,
    }}
  >
    {children}
  </div>
);
export default Reviews;
