import React, { useEffect, useState } from "react";
import { Grid, Text, Button } from "platyplex_ui";
import { primaryColor } from "../../../../../config";
import { Select, Input, DatePicker } from "antd";
import { useSelector } from "react-redux";
import { Account, Company, Entity, Individual, Address } from "../../type";
import moment from 'moment';

const companyFields = [
  {
    id: "name",
    label: "Company Name",
    placeholder: "Ex; ParkStash Inc.",
  },
  {
    id: "tax_id",
    placeholder: "123456789",
    label: "Company Tax ID(EIN)",
  },
];

const addressField = [
  {
    id: "line1",
    label: "Address Line 1",
    placeholder: "123 NY St",
    span: "1/-1",
  },
  {
    id: "line2",
    label: "Address Line 2",
    placeholder: "Apt 1",
    span: "1/-1",
  },
  {
    id: "postal_code",
    placeholder: "12345",
    label: "Zip Code",
  },
  {
    id: "city",
    placeholder: "City",
    label: "City",
  },

  {
    id: "state",
    placeholder: "State",
    label: "State",
  },
];

const states = {
  CA: [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunabut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon",
  ],
};

const BankAccountForm = ({
  next,
  loading,
  setAccountInfo,
  accountInfo,
}: {
  loading: boolean;
  next: () => void;
  setAccountInfo: (p: Account) => void;
  accountInfo: Account;
}) => {
  const { country } = useSelector((s: any) => s.user.details);

  const individualFields = [
    {
      id: "first_name",
      label: "First Name",
      placeholder: "John",
    },
    {
      id: "last_name",
      placeholder: "Doe",
      label: "Last Name",
    },
    {
      id: country === "CA" ? "id_number" : "ssn_last_4",
      placeholder: country === "CA" ? "9 digits" : "Last 4 digits",
      label: country === "CA" ? "SIN" : "SSN",
    },
  ];

  const isIndivisual = accountInfo.business_type === Entity.INDIVIDUAL;
  const fields = isIndivisual ? individualFields : companyFields;
  const data = accountInfo[accountInfo.business_type] as
    | Company
    | Individual
    | any;
  const address = data.address;

  const time = moment();
  time.add('year', -18)
 const [ dob, setDob ] = useState(time);

  const handleTaxInfoChange = (b: Entity, p: Partial<Individual | Company>) =>
    setAccountInfo({
      ...accountInfo,
      [b]: { ...accountInfo[b], ...p } as Individual | Company,
    });

  const handleAddressChange = (b: Entity, p: Partial<Address>) =>
    handleTaxInfoChange(b, {
      address: { ...accountInfo[b]?.address, ...p } as Address,
    });
  const disabled = isIndivisual
    ? !data.first_name ||
      !data.last_name ||
      !data.dob ||
      !(data.ssn_last_4 || data.id_number)
    : !data.name || !data.tax_id;

  const invalidAddress =
    !address.postal_code || !address.city || !address.state || !address.line1;


    useEffect(()=>{
      isIndivisual && handleTaxInfoChange(accountInfo.business_type, {
        dob: {
          day: dob.date().toString(),
          year: dob.year().toString(),
          month: (dob.month() + 1).toString(),
        },
      });
    }, [dob])
  return (
    <Grid placeItems="stretch" placeSelf="stretch">
      <Text textAlign="left">
        Please enter the following detail as it appears in your bank account
      </Text>
      <div>
        <Text textAlign="left" bold color={primaryColor}>
          Entity Type
        </Text>
        <Select
          onChange={(business_type: Entity) =>
            setAccountInfo({ ...accountInfo, business_type })
          }
          style={{ width: "100%" }}
          value={accountInfo.business_type}
        >
          <Select.Option value={Entity.INDIVIDUAL}>
            Individual/Sole Proprietorship
          </Select.Option>
          <Select.Option value={Entity.COMPANY}>
            Company/LLC/Partnership
          </Select.Option>
        </Select>
      </div>

      <Grid cols={isIndivisual ? 2 : 1} placeItems="center stretch">
        {fields.map(({ label, id, placeholder }) => {
          if (data)
            return (
              <div key={id}>
                <Text textAlign="left" bold color={primaryColor}>
                  {label}
                </Text>
                <Input
                  onChange={(e) =>
                    handleTaxInfoChange(accountInfo.business_type, {
                      [id]: e.target.value,
                    })
                  }
                  name={label}
                  placeholder={placeholder}
                  value={data[id]}
                />
              </div>
            );
          else return null;
        })}
        {isIndivisual &&  <div className="dates">
          <Text textAlign="left" bold color={primaryColor}>
            D.O.B
          </Text>
          <DatePicker
            value={dob}
            style={{ width: "100%" }}
            onChange={(e) => {
              e && setDob(e)   
            }}
          />
        </div>}
      </Grid>
      <Grid cols="3" placeItems="center stretch">
        {addressField.map(({ span, label, id, placeholder }) => {
          if (id === "state" && country === "CA") {
            return (
              <div style={{ gridColumn: span }} key={id}>
                <Text textAlign="left" bold color={primaryColor}>
                 Province
                </Text>
                <Select
                  style={{minWidth: 200}}
                  onChange={(e: string) =>
                    handleAddressChange(accountInfo.business_type, {
                      [id]: e,
                    })
                  }
                  placeholder={placeholder}
                  value={address[id as "state"]}
                >
                  {states.CA.map((s: string)=> <Select.Option value={s}>{s}</Select.Option>)}
                </Select>
              </div>
            );
          } else if (address){
            return (
              <div style={{ gridColumn: span }} key={id}>
                <Text textAlign="left" bold color={primaryColor}>
                  {id === 'postal_code' && country === "CA"? 'Postal Code' : label}
                </Text>
                <Input
                  name={label}
                  onChange={(e) =>
                    handleAddressChange(accountInfo.business_type, {
                      [id]: e.target.value,
                    })
                  }
                  placeholder={placeholder}
                  value={address[id as "city"]}
                />
              </div>
            );
                }
          else return null;
        })}
      </Grid>

      <Button
        rounded
        onClick={next}
        disabled={disabled || invalidAddress || loading}
        loading={loading}
      >
        Next
      </Button>
    </Grid>
  );
};

export default BankAccountForm;
