import { primaryColor, primaryTxtColor } from "../../config";

import React from "react";

export default () => (
  <span style={{ color: primaryTxtColor }}>
    <span>Park</span>
    <span style={{ color: primaryColor }}>Stash</span>
  </span>
);
