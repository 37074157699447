import React, { useState, useEffect } from "react";
import { Button, Card, Grid } from "platyplex_ui";
import Layout from "../../layout";
import Map from "./component/map";
import ParkingList from "./component/parking/parking-list";
import DateSelector from "./component/date-selector";
import useNearbyParking from "../../hooks/nearby-parking";
import { disabledTxtColor, primaryColor } from "../../config";
import ParkingInfo from "./component/parking-info";
import Filter from "./component/filter";
import { NearbyParking } from "../../model/domain/parking";
import NewModal from "../../components/new-modal";
import { useDispatch, useSelector } from "react-redux";
import { setAddressData, setQuery } from "../../store/actions/search";
import LocationSearcher from "./component/location-searcher_v2";
import MobileParkingList from "./component/parking/mobile-parking-list";
import BookingPopup from "./component/booking-npopup";
import { SearchStoreState } from "../.../../../store/reducer/search";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";

const Home = () => {
  const { query }: SearchStoreState = useSelector((s: any) => s.search);
  const { parkings, loading, reload } = useNearbyParking({});

  const dispatch = useDispatch();

  const [activeParking, setActiveParking] = useState(-1);
  const [openParking, setOpenParking] = useState<undefined | NearbyParking>(
    undefined
  );

  // const history = useHistory();
  // const parsedQueryUrl = queryString.parse(useLocation().search);
  // const [ loadedUrl, setLoadedUrl ] = useState(false)
  // useEffect(()=>{
  //   if(!parsedQueryUrl) return;
  //    const newQuery = {
  //     booking_type: parsedQueryUrl.booking_type || query.booking_type,
  //     lat: parsedQueryUrl.lat || query.lat,
  //     long: parsedQueryUrl.long || query.long
  //   } as any;
  //   history.push(`/?${queryString.stringify(newQuery as any)}`, {})
  //   dispatch(setQuery(newQuery))
  //   setLoadedUrl(true)
  // }, []);

  // useEffect(() => {
  //   if(!loadedUrl) return;
  //   history.push(`/?${queryString.stringify(newQuery as any)}`, {})
  // }, [query]);

  useEffect(() => {
    setOpenParking(undefined);
  }, [query]);

  useEffect(() => {
    if (parkings && parkings?.length > 0) {
      dispatch(
        setAddressData(
          {
            geo: {
              lng: parkings[0].geo[0],
              lat: parkings[0].geo[1],
            },
          },
          false,
          false
        )
      );
      setActiveParking(0);
    }
  }, [parkings]);

  const FilterModal = NewModal({
    modal: (
      <Filter
        close={() => FilterModal.close()}
        setQuery={(e: any) => dispatch(setQuery(e))}
        query={query}
      />
    ),
  });



  return (
    <Layout sidebar style={{ gridTemplateRows: "1fr auto" }}>
      <Grid style={{ height: "100%" }}>
        {FilterModal.modal}
        <Grid
          padding="0"
          placeItems={"stretch"}
          placeSelf="stretch"
          gridGap="0"
          customRows="auto auto 1fr"
          height="100%"
          customCols="auto 1fr"
        >
          <LocationSearcher />
          <Card
            margin="0"
            grid
            customCols="auto auto 1fr"
            width="100%"
            mobileLayout={false}
            gridGap=".5em"
            placeItems="center start"
          >
            <DateSelector />
            <Button
              invert
              rounded
              bg={disabledTxtColor}
              minWidth="3.5em"
              className="show-mobile"
              onClick={FilterModal.open}
            >
              <i
                className="fa fas fa-sliders-h"
                style={{ color: primaryColor }}
              />
            </Button>

            <Button
              invert
              rounded
              bg={disabledTxtColor}
              minWidth="6em"
              className="hide-mobile"
              onClick={FilterModal.open}
            >
              <i
                className="fa fas fa-sliders-h"
                style={{ color: primaryColor }}
              />{" "}
              &nbsp; Filter
            </Button>
          </Card>
          <ParkingList
            reloadParkings={reload}
            parkings={parkings}
            setActiveParking={setActiveParking}
            setOpenParking={setOpenParking}
            loading={loading}
            query={query}
          />

          <MobileParkingList
            parkings={parkings}
            setActiveParking={setActiveParking}
            setOpenParking={setOpenParking}
            loading={loading}
            query={query}
          />

          <Grid placeItems="stretch" placeSelf="stretch">
            {parkings && openParking && (
              <ParkingInfo
                style={{ gridColumn: 1, gridRow: 1 }}
                parking={openParking as any}
                query={query}
                close={() => setOpenParking(undefined)}
              />
            )}

            <BookingPopup style={{ gridColumn: 1, gridRow: 1 }} />
            <Map
              style={{ gridColumn: 1, gridRow: 1 }}
              parkings={parkings}
              activeParking={activeParking}
              setActiveParking={setActiveParking}
              setOpenParking={setOpenParking}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Home;
