import React, { useEffect, useState } from "react";
import { Loader } from "platyplex_ui";
import { useDispatch } from "react-redux";
import logo from "./assets/parkstash_logo.png";
import { getVehicles } from "./store/actions/vehicle";
import { getCards } from "./store/actions/card";
import { getPayoutMethods } from "./store/actions/bank";
import { initFirebaseAnalytics } from "./utils/firebase-analytics";
import useAuth from "./hooks/auth";
import { ApprovedRoutes, UnApprovedRoutes } from "./routes";
import { logout } from "./store/actions/user";
import BannedModal from "./components/banned-modal";

function App() {
  const [state, setState] = useState({ loading: true });
  const dispatch = useDispatch();
  const { approved, is_banned } = useAuth({
    action: () =>
      setTimeout(() => setState({ ...state, loading: false }), 1000),
  });

  const log = async () => {
    const f = await initFirebaseAnalytics();
    f.setUserProperties({ is_subscriber: true });
    f.logEvent("page_views_2");
  };

  useEffect(() => {
    if (approved && !is_banned) {
      dispatch(getVehicles());
      dispatch(getCards());
      dispatch(getPayoutMethods());
    } else if (is_banned) {
      BannedModal(() => dispatch(logout()));
    }
  }, [dispatch, approved, is_banned]);

  if (state.loading) return <Loader.Custom logo={logo} />;
  else if (!approved) return <UnApprovedRoutes />;
  else return <ApprovedRoutes />;
}

export default App;
