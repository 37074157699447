import React, { CSSProperties, useEffect, useState, useMemo } from 'react';
import * as CH from '@pstash/charts';
import { Switch, Empty as EmptyEl } from "platyplex_ui";
import { primaryTxtColor, primaryColor } from '../../config';
import { theme, Card, ChartButton, Scorecard_Donut } from '../../components';
import * as PARKING from '@pstash/model/domain/parking';
import moment, { Moment } from "moment-timezone";
import * as TS from '@pstash/model/domain/timeseries';
import { GraphDataRequest, hook_loadGraphData } from '../listings/load_data';
import { DatePicker, Select } from 'antd';
import { dashboardColors } from '@pstash/model/domain/misc';

const d100: CSSProperties = {
  height: '100%', 
  width: '300px',
  textAlign: 'center', 
  marginTop: ".5em" 
};

const TZ = 'America/Los_Angeles';

const FILTER_WIDTH = 200;
const CHART_HEIGHT = 450;

const CURRENT_MONTH_STRING = moment().tz(TZ).format('MMMM');

function formatPercent(f: number, afterZero: number)
{
  return (f * 100).toFixed(afterZero) + '%';
}

function prettifyParkingFee(parking: PARKING.Parking, fractional: boolean)
{
  const toDollars = (cents: number) => 
  {
    const p = fractional? 2: 0;
    return `$${(cents / 100).toFixed(p)}`;
  }

  let fees: string[] = [];
  
  if (parking.hourly.active)
  {
    fees.push(toDollars(parking.hourly.price) + '/hr');
  }
  if (parking.max_daily.active)
  {
    fees.push(toDollars(parking.max_daily.price) + '/day');
  }
  if (parking.weekly.active)
  {
    fees.push(toDollars(parking.weekly.price) + '/wk');
  }
  if (parking.monthly.active)
  {
    fees.push(toDollars(parking.monthly.price) + '/mo');
  }

  return fees.join('; ');
}

function LoadingCard(props: { height: number, shouldRoundCorner?: boolean; })
{
  return (
    <Card 
      styleProps={{shouldRoundCorner: props.shouldRoundCorner || false}}> 
      <EmptyEl text="Loading..." style={{ ...d100, height: props.height }} /> 
    </Card>
  );
}

interface PageProps
{  
  parkings: PARKING.Parking[];
}

//BEGIN: Occupancy

interface OccupancyFilterState
{
  selectedParkingId: string | null;
  intervalPreset: TS.IntervalPreset;
}

interface OccupancyStatsProps
{
  currentParkedVehicles: number;
  totalAvailableSpaces: number;
  updatedAt?: Date;
}

function OccupancyStatsView(props: OccupancyStatsProps)
{
  const occupiedPercent = props.currentParkedVehicles / (props.totalAvailableSpaces || 1);
  let unoccupiedPercent = 1 - occupiedPercent;
  if (unoccupiedPercent < 0)
  {
    unoccupiedPercent = 0;
  }
  const donutChartData: CH.DonutChartData = [
    {
      value: occupiedPercent,
      label: 'Occupied',
      color: theme.color.orange
    },
    {
      value: unoccupiedPercent,
      label: 'Unoccupied',
      color: theme.color.grey
    }
  ];

  let hhmm = 'Data N/A';
  if (props.updatedAt)
  {
    hhmm = `As of Today, ${moment(props.updatedAt).tz(TZ).format('hh:mm A')}`;
  }

  return (
    <div style={{display: 'flex'}}>
      <div style={{flex: 1 }}></div>
      <div style={{flex: 3, display: 'flex', alignItems: 'stretch'}}>
        <div style={{fontSize: theme.fontSize.lg, display: 'flex', alignItems: 'center'}}>
          <p style={{whiteSpace: 'nowrap'}}>{hhmm}</p>
        </div>
        <div style={{flex: 1 }}></div>

        <Scorecard_Donut
          donutChartData={donutChartData}
          title={'Occupancy %'}
          donutText={formatPercent(occupiedPercent, 0)} />

        <div style={{flex: 1, borderRight: `${theme.spacing(1)} solid ${theme.color.silver}` }}></div>
        <div style={{flex: 1 }}></div>

        <div style={{fontSize: theme.fontSize.lg, textAlign: 'center'}}>
          <p style={{whiteSpace: 'nowrap'}}>Parked Vehicles</p>
          <div style={{marginTop: `calc(50% - ${theme.fontSize.lg})`, fontWeight: 'bold'}}>
            {props.currentParkedVehicles} / {props.totalAvailableSpaces}
          </div>
        </div>
      </div>
      <div style={{flex: 1 }}></div>
    </div>    
  );
}

enum OccupancyGraphChartOptions
{
  lineChart='lineChart',
  stackedAreaChart='stackedAreaChart',
  stackedBarChart='stackedBarChart'
}

interface OccupancyGraphProps
{
  graphData: CH.GraphData;
  isMetricTypePercent: boolean;
  onMetricTypeChange: {(isMetricTypePercent: boolean): void}
}
function OccupancyGraph(props: OccupancyGraphProps)
{
  const [chartOption, set_chartOption] = useState<OccupancyGraphChartOptions>(OccupancyGraphChartOptions.stackedAreaChart);

  //Only line chart when displaying pecentage data
  let ChartCtor = CH.LineChart;

  if (!props.isMetricTypePercent)
  {
    if (chartOption === OccupancyGraphChartOptions.stackedAreaChart)
    {
      ChartCtor = CH.StackedAreaChart;
    }
    else if (chartOption === OccupancyGraphChartOptions.stackedBarChart)
    {
      ChartCtor = CH.StackedBarChart;
    }
  }

  useEffect(() =>
  {
    if (props.isMetricTypePercent)
    {
      set_chartOption(OccupancyGraphChartOptions.lineChart);
    }
  }, [props.isMetricTypePercent]);

  return (
    <Card styleProps={{ shouldRoundCorner: true, showBoxShadow: true }}>
      <div style={{display: 'flex', justifyContent: 'end'}}>
        <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
          <span style={{fontWeight: 'bold'}}>Occupancy{props.isMetricTypePercent? ' %': ''}</span>
        </div>
        <div style={{marginRight: theme.spacing(4)}}>
        <ChartButton
          isSelected={!props.isMetricTypePercent}
          isDisabled={false}
          faIcon={'fas fa-sort-numeric-up'}
          label={'Count'}
          onClick={() => 
          {
            props.onMetricTypeChange(false);
          }} />

        <ChartButton
          isDisabled={false}
          isSelected={props.isMetricTypePercent}
          faIcon={'fas fa-percentage'}
          label={'Percent'}
          onClick={() => 
          {
            props.onMetricTypeChange(true);
          }} />
        </div>
        <div>
          <ChartButton
            isSelected={chartOption === OccupancyGraphChartOptions.lineChart}
            faIcon={'fas fa-chart-line'}
            label={'Line Chart'}
            isDisabled={props.isMetricTypePercent}
            onClick={() => set_chartOption(OccupancyGraphChartOptions.lineChart)} />
          <ChartButton
            isSelected={chartOption === OccupancyGraphChartOptions.stackedAreaChart}
            faIcon={'fas fa-chart-area'}
            isDisabled={props.isMetricTypePercent}
            label={'Stacked Area Chart'}
            onClick={() => set_chartOption(OccupancyGraphChartOptions.stackedAreaChart)} />
          <ChartButton
            isSelected={chartOption === OccupancyGraphChartOptions.stackedBarChart}
            faIcon={'fas fa-chart-bar'}
            label={'Stacked Bar Chart'}
            isDisabled={props.isMetricTypePercent}
            onClick={() => set_chartOption(OccupancyGraphChartOptions.stackedBarChart)} />
        </div>
      </div>
      <div style={{ marginTop: theme.spacing(1), height: CHART_HEIGHT }}>     
        <ChartCtor
          roundYValuesToTheMultipleOf={props.isMetricTypePercent? .25: 5}
          graphData={props.graphData}
          showLeftAxis={true}
          showBottomLegend={false}
          showBottomAxis={true} />
      </div>
    </Card>
  );
}

const Page_Occupancy = (props: PageProps) =>
{
  const [filterState, set_filterState] = useState<OccupancyFilterState>({
    selectedParkingId: null,
    intervalPreset: TS.IntervalPreset_E.today
  });

  let binSize = 1;
  let binUnit = TS.BinUnit_E.hour;
  switch(filterState.intervalPreset)
  {
    case TS.IntervalPreset_E.today:
    {
      binSize = 5;
      binUnit = TS.BinUnit_E.minute;
    }
    break;    
    case TS.IntervalPreset_E.last_7_days:
    {
      binSize = 1;
      binUnit = TS.BinUnit_E.hour;
    }
    break;
    case TS.IntervalPreset_E.last_30_days:
    {
      binSize = 1;
      binUnit = TS.BinUnit_E.hour;
    }
    break;
  }

  let selectedParkings = props.parkings;
  if (filterState.selectedParkingId)
  {
    const pId = filterState.selectedParkingId || '';
    selectedParkings = [props.parkings.find(p => p._id === pId)!];
  }

  const [graphMetricType, set_graphMetricType] = useState<TS.MetricType_E>(TS.MetricType_E.occupancy_count);
  
  const graphDataRequest = (() =>
  {
    const graphDataRequestItems = selectedParkings.map((p, idx) =>
    ({
      key: p.name,
      unit: graphMetricType === TS.MetricType_E.occupancy_count? CH.ValueUnit_E.none: CH.ValueUnit_E.percent,
      color: dashboardColors[idx],
      metricType: graphMetricType,
      reducerFunction: TS.ReducerFunction.max,
      metricSpecificParameter: {
        occupancy_count: {
          parking_obj_type: TS.ParkingObjType_E.parking,
          parking_obj_id: p._id
        },
        /*NOTE: sending both, so that server will pick either based on metrictype*/
        occupancy_percent: {
          parking_obj_type: TS.ParkingObjType_E.parking,
          parking_obj_id: p._id
        },
      }
    }));
  
    const request: GraphDataRequest = {
      binSize,
      binUnit,
      tz: TZ,
      intervalPreset: filterState.intervalPreset,
      graphDataRequestItems
    };

    return request;
  })();

  const statsRequest = (() =>
  {
    const graphDataRequestItems = selectedParkings.map((p, idx) =>
    ({
      key: p.name,
      unit: CH.ValueUnit_E.none,
      color: dashboardColors[idx],
      metricType: TS.MetricType_E.occupancy_count,
      reducerFunction: TS.ReducerFunction.max,
      metricSpecificParameter: {
        occupancy_count: {
          parking_obj_type: TS.ParkingObjType_E.parking,
          parking_obj_id: p._id
        }
      }
    }));
  
    const request: GraphDataRequest = {
      binSize: 5,
      binUnit: TS.BinUnit_E.minute,
      tz: TZ,
      intervalPreset: TS.IntervalPreset_E.today,
      graphDataRequestItems
    };

    return request;
  })();

  const { refresh: refresh_graph, graphDataStatus: dataStatus_graph } = hook_loadGraphData(graphDataRequest);
  const { refresh: refresh_stats, graphDataStatus: dataStatus_stats } = hook_loadGraphData(statsRequest);

  const [occupancyStatsProps, set_occupancyStatsProps] = useState<OccupancyStatsProps>({
    currentParkedVehicles: 0,
    totalAvailableSpaces: 0,
    updatedAt: undefined
  });

  useEffect(() =>
  {
    refresh_graph(graphDataRequest);
  }, [graphMetricType, filterState.intervalPreset, binUnit, binSize, 
      filterState.selectedParkingId || '', props.parkings]);

  useEffect(() =>
  {
    refresh_stats(statsRequest);
  }, [filterState.selectedParkingId || '', props.parkings]);

  useEffect(() =>
  {
    const emptyStats: OccupancyStatsProps = {
      currentParkedVehicles: 0,
      totalAvailableSpaces: 0,
      updatedAt: undefined
    };
    if (dataStatus_stats.data)
    {
      const points = dataStatus_stats.data.points2d[0] || [];
      const pointCount = points.length;
      let idx = pointCount - 1;
      for (; idx >= 0; idx--)
      {
        if (points[idx] !== null)
        {
          break;
        }
      }

      if (idx > -1)
      {
        const updatedAt = new Date(dataStatus_stats.data.dates[idx]);

        const currentParkedVehicles = dataStatus_stats.data.points2d.reduce((total: any, points: any) =>
        {
          return (points[idx] || 0) + total;
        }, 0);

        const totalAvailableSpaces = selectedParkings.reduce((total, p) =>
        {
          return p.space_count + total;
        }, 0);

        set_occupancyStatsProps({
          currentParkedVehicles,
          totalAvailableSpaces,
          updatedAt
        });
      }
      else
      {
        set_occupancyStatsProps(emptyStats);
      }
    }
    else
    {
      set_occupancyStatsProps(emptyStats);
    }
    
  }, [dataStatus_stats.data]);

  let graphElement: JSX.Element;
  if (dataStatus_graph.error) { graphElement = (<EmptyEl text="Error Occured" style={{ ...d100, }} />); }
  else if (dataStatus_graph.loading) { graphElement = (<LoadingCard height={CHART_HEIGHT}/>); }
  else
  {
    graphElement = (
      <OccupancyGraph
        isMetricTypePercent={graphMetricType === TS.MetricType_E.occupancy_percent}
        onMetricTypeChange={isPercent => set_graphMetricType(isPercent? TS.MetricType_E.occupancy_percent: TS.MetricType_E.occupancy_count)}
        graphData={dataStatus_graph.data!} />
    );
  }

  const parkingZoneSelectOptions = useMemo(() =>
  {
    const ps = props.parkings.map(p =>
    ({
      id: p._id,
      label: p.name
    }));
    ps.unshift({
      id: '',
      label: 'All Parking Zones'
    });

    return ps;
  }, [props.parkings]);


  const labelData: { color: string; name: string; }[] = [];
  if (!dataStatus_graph.loading && !dataStatus_graph.error && !!(dataStatus_graph.data))
  {
    const graphData = dataStatus_graph.data;
    
    const names  = graphData.description.map((desc: any) => desc.key);
    const colors = graphData.description.map((desc: any) => desc.color);

    for (let i = 0; i < colors.length; i++)
    {
      labelData.push({ color: colors[i], name: names[i] });
    }
  }

  return (
    <div>
      <div style={{background: theme.color.white, padding: theme.spacing(4)}}>
        <OccupancyStatsView
          currentParkedVehicles={occupancyStatsProps.currentParkedVehicles}
          totalAvailableSpaces={occupancyStatsProps.totalAvailableSpaces}
          updatedAt={occupancyStatsProps.updatedAt} />
      </div>

      <div style={{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center', 
        padding: theme.spacing(4)}}>
          {labelData.map(({ name, color }, index) => 
          (
            <div 
              key={index} 
              style={{
              marginRight: theme.spacing(5), 
              flexBasis: '175px',
              display: 'flex', 
              alignItems: 'center'
            }}>
              <span style={{height: 20, width: 20, display: 'inline-block', background: color, borderRadius: '20%'}}></span>
              <span style={{marginLeft: theme.spacing(2)}}>{name}</span>
            </div>
          ))}
      </div>

      <div style={{display: 'flex', alignItems: 'stretch'}}>
        <div style={{ flex: 1, maxWidth: '90%', marginRight: theme.spacing(4) }}>
          {graphElement}
        </div>

        <div style={{ maxWidth: FILTER_WIDTH }}>
          <Card
            sizeProps={{height: '100%'}} 
            styleProps={{ shouldRoundCorner: true, showBoxShadow: true }}>

            <div style={{ margin: theme.spacing(0, 0, 4, 0), textAlign: 'center' }}>
              <span style={{fontWeight: 'bold'}}>Filter</span>
            </div>

            <div style={{ margin: theme.spacing(0, 4, 4, 4), textAlign: 'left' }}>
              <span>Listings</span>
              <Select
                style={{ display: 'block',width: '100%' }}
                onChange={pId => {  set_filterState({...filterState, selectedParkingId: pId || null }); }}
                value={filterState.selectedParkingId || ''}>
                  {parkingZoneSelectOptions.map(option =>
                  (
                    <Select.Option key={option.id} value={option.id}>
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </div>

            <div style={{ margin: theme.spacing(0, 4, 4, 4), textAlign: 'left' }}>
              <span>Duration</span>
              <Select
                style={{ display: 'block', width: '100%' }}
                onChange={(intervalPreset) => 
                {
                  set_filterState({ ...filterState, intervalPreset });
                }}
                value={filterState.intervalPreset}>
                <Select.Option value={TS.IntervalPreset_E.today}>Today</Select.Option>
                <Select.Option value={TS.IntervalPreset_E.last_7_days}>Last 7 days</Select.Option>
                <Select.Option value={TS.IntervalPreset_E.last_30_days}>Last 30 days</Select.Option>
                <Select.Option value={TS.IntervalPreset_E.last_90_days}>Last 90 days</Select.Option>
              </Select>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}


interface TransactionCountProps
{
  graphData: CH.GraphData;
}
interface RevenueProps extends TransactionCountProps { }

function TransactionCountGraph(props: TransactionCountProps)
{
 const drawAreaEl = (
  <CH.LineChart
    roundYValuesToTheMultipleOf={2}
    graphData={props.graphData}
    showLeftAxis={true}
    showBottomLegend={false}
    showBottomAxis={true} />
  );

  return (
    <Card styleProps={{shouldRoundCorner: true, showBoxShadow: true}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <span style={{fontWeight: 'bold'}}>Booking Count</span>
      </div>
      <div style={{ marginTop: theme.spacing(1), height: CHART_HEIGHT }}>
        {drawAreaEl}
      </div>
    </Card>
  );
}

function RevenueGraph(props: RevenueProps)
{
  const drawAreaEl = (
    <CH.StackedBarChart
      roundYValuesToTheMultipleOf={2000}
      graphData={props.graphData}
      showLeftAxis={true}
      showBottomLegend={false}
      showBottomAxis={true} />
  );

  return (
    <Card styleProps={{shouldRoundCorner: true, showBoxShadow: true}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <span style={{fontWeight: 'bold'}}>Revenue</span>
      </div>
      <div style={{ marginTop: theme.spacing(1), height: CHART_HEIGHT}}>
        {drawAreaEl}
      </div>
    </Card>
  );
}

interface ScoreCard_BookingProps
{
  graphData: CH.GraphData;
  title: string;
  colors: string[];
  formatter: { (value: number): string; }
}

const ScoreCard_Booking = (props: ScoreCard_BookingProps) =>
{
  const [donutChartData, total] =  useMemo(() =>
  {
    const values = props.graphData.points2d.map(points =>
    {
      let sum = 0;
      for (let p of points) { if (p !== null) sum += p };
      return sum;
    });

    const labels = props.graphData.description.map((d, index) =>
    {
      const key = d.key;
      const value = props.formatter(values[index]);
      return key + ': ' + value;
    });

    const donutChartData_: any = [];

    for (let i = 0; i < labels.length; i++)
    {
      donutChartData_.push({
        value: values[i],
        label: labels[i],
        color: props.colors[i]
      });
    }

    const sums = values.reduce((K, v) => v + K, 0);

    return [donutChartData_, sums];
  }, [props.graphData]);

  const donutText = props.formatter(total);

  return (
    <Scorecard_Donut
      donutChartData={donutChartData}
      title={props.title}
      donutText={donutText} />
  );
}

interface Pricing_BookingProps
{
  parkings: PARKING.Parking[]
}

const Pricing_Booking = (props: Pricing_BookingProps) =>
{
  const els: JSX.Element[] = [];

  for (let i = 0; i < props.parkings.length; i++)
  {
    const p = props.parkings[i];
    const fee = prettifyParkingFee(p, false);
    els.push(
      <p style={{alignItems: 'left'}}>
        <div>{p.name}:</div>
        <div style={{fontWeight: 'bold', color: dashboardColors[i]}}>{fee}</div>
      </p>
    );
  }

  if (els.length > 5)
  {
    const L = els.length;
    els.length = 5;
    els.push(
      <p>
        <span style={{display: 'inline-block', width: '4em'}}> </span>
        <span>...and {L - els.length} other</span>
      </p>
    );
  }

  return (
    <div>
      <p style={{fontSize: theme.fontSize.lg, textAlign: 'center'}}>Pricing</p>
      <div style={{ marginTop: '.5em', textAlign: 'left' }}>
        {els}        
      </div>
    </div>
  );
}

interface BookingFilterState
{
  selectedParkingId: string | null;
  intervalPreset: TS.IntervalPreset;
  customInterval: { t0: moment.Moment, t1: moment.Moment; }
  binUnit: TS.BinUnit_E;
}

interface BookingFilterProps
{
  initialFilterState: BookingFilterState;
  parkings: PARKING.Parking[];
  onFilterStateChanged: { (filterState: BookingFilterState): void; }
}

const BookingFilter = (props: BookingFilterProps) =>
{
  const [filterState, set_filterState] = useState(props.initialFilterState);

  function changeFilterState(s: BookingFilterState)
  {
    set_filterState(s);
    props.onFilterStateChanged(s);
  }

  const parkingSelectionOptions = useMemo(() =>
  {
    const options = props.parkings.map(p =>
    ({
      id: p._id,
      label: p.name
    }));
    options.unshift({
      id: '',
      label: 'All Listings'
    });

    return options;
  }, [ props.parkings ]);

  return (
    <Card sizeProps={{height: '100%'}} styleProps={{ shouldRoundCorner: true, showBoxShadow: true }}>
      <div style={{ margin: theme.spacing(0, 0, 4, 0), textAlign: 'center' }}>
        <span style={{fontWeight: 'bold'}}>Filter</span>
      </div>

      <div style={{ margin: theme.spacing(0, 4, 4, 4), textAlign: 'left' }}>
        <span>Listings</span>
        <Select
          style={{ display: 'block', width: '100%' }}
          onChange={pId => {  changeFilterState({ ...filterState, selectedParkingId: pId || null }) }}
          value={filterState.selectedParkingId || ''}>
          {
            parkingSelectionOptions.map((option, idx) =>
            ( <Select.Option key={idx} value={option.id}>{option.label}</Select.Option> ))
          }
        </Select>
      </div>

      <div style={{ margin: theme.spacing(0, 4, 4, 4), textAlign: 'left' }}>
        <span>Duration</span>
        <Select
          style={{ display: 'block', width: '100%' }}
          onChange={(intervalPreset) => 
          {
            changeFilterState({ ...filterState, intervalPreset });
          }}
          value={filterState.intervalPreset}>
          <Select.Option value={TS.IntervalPreset_E.last_30_days}>Last 30 days</Select.Option>
          <Select.Option value={TS.IntervalPreset_E.last_90_days}>Last 90 days</Select.Option>
          <Select.Option value={TS.IntervalPreset_E.last_365_days}>Last year</Select.Option>
          <Select.Option value={TS.IntervalPreset_E.custom}>Custom</Select.Option>
        </Select>
      </div>

      <div style={{ margin: theme.spacing(0, 4, 4, 4), textAlign: 'left' }}>
        <span>Custom Start</span>
          <DatePicker
            disabled={filterState.intervalPreset !== TS.IntervalPreset_E.custom}
            clearIcon={false}
            disabledDate={(cur: Moment | null) => (cur ? cur > moment() : false)}
            style={{ borderRadius: 6, width: "100%" }}
            onChange={(t0) => 
            {
              if (t0)
              {
                changeFilterState({ ...filterState, customInterval: { t0, t1: filterState.customInterval.t1 } })
              }
            }}
            value={filterState.customInterval.t0} />
      </div>

      <div style={{ margin: theme.spacing(0, 4, 4, 4), textAlign: 'left' }}>
        <span>Custom End</span>
          <DatePicker
            disabled={filterState.intervalPreset !== TS.IntervalPreset_E.custom}
            clearIcon={false}
            disabledDate={(cur: Moment | null) => (cur ? cur > moment() : false)}
            style={{ borderRadius: 6, width: "100%" }}
            onChange={(t1) => 
            {
              if (t1)
              {
                changeFilterState({ ...filterState, customInterval: { t0: filterState.customInterval.t0, t1 } });
              }              
            }}
            value={filterState.customInterval.t1} />
      </div>
      
      <div style={{ margin: theme.spacing(0, 4, 4, 4), textAlign: 'left' }}>
        <span>Bucket</span>
        <Select
          style={{ display: 'block', width: '100%' }}
          onChange={binUnit => { changeFilterState({ ...filterState, binUnit }); }}
          value={filterState.binUnit}>
            <Select.Option value={TS.BinUnit_E.day}>Daily</Select.Option>
            <Select.Option value={TS.BinUnit_E.month}>Monthly</Select.Option>
        </Select>
      </div>

    </Card>
  );
}

const Page_Booking = (props: PageProps) =>
{
  const [filterState, setFilterState] = useState<BookingFilterState>({
    selectedParkingId: null,
    intervalPreset: TS.IntervalPreset_E.last_30_days,
    customInterval: { t0: moment().subtract(1, 'week'), t1: moment() },
    binUnit: TS.BinUnit_E.day
  });

  const graphReq_revenueScorecard = (() =>
  {
    const graphDataRequestItems = props.parkings.map((p, idx) =>
    ({
      key: p.name,
      unit: CH.ValueUnit_E.cents,
      color: dashboardColors[idx],
      metricType: TS.MetricType_E.booking_revenue,
      reducerFunction: TS.ReducerFunction.sum,
      metricSpecificParameter: {
        booking_revenue: {
          parkingId: p._id
        }
      }
    }));
    
    const request: GraphDataRequest = {
      binSize: 1,
      binUnit: TS.BinUnit_E.day,
      tz: TZ,
      intervalPreset: TS.IntervalPreset_E.current_month,
      graphDataRequestItems
    };
  
    return request;
  })();

  const graphReq_transactionCountScorecard = (() =>
  {
    const graphDataRequestItems = props.parkings.map((p, idx) =>
    ({
      key: p.name,
      unit: CH.ValueUnit_E.none,
      color: dashboardColors[idx],
      metricType: TS.MetricType_E.booking_count,
      reducerFunction: TS.ReducerFunction.sum,
      metricSpecificParameter: {
        booking_count: {
          parkingId: p._id
        }
      }
    }));
    
    const request: GraphDataRequest = {
      binSize: 1,
      binUnit: TS.BinUnit_E.day,
      tz: TZ,
      intervalPreset: TS.IntervalPreset_E.current_month,
      graphDataRequestItems
    };
  
    return request;
  })();

  const graphReq_revenue = (() =>
  {
    const selectedParkings = filterState.selectedParkingId
    ? [props.parkings.find(p => p._id === filterState.selectedParkingId)!]
    : [...props.parkings];

    const customInterval = filterState.intervalPreset === TS.IntervalPreset_E.custom
      ? { t0: filterState.customInterval.t0.toDate(), t1: filterState.customInterval.t1.toDate() }
      : undefined;

    const graphDataRequestItems = selectedParkings.map((p, idx) =>
    ({
      key: p.name,
      unit: CH.ValueUnit_E.cents,
      color: dashboardColors[idx],
      metricType: TS.MetricType_E.booking_revenue,
      reducerFunction: TS.ReducerFunction.sum,
      metricSpecificParameter: {
        booking_revenue: {
          parkingId: p._id
        }
      }
    }));
    
    const request: GraphDataRequest = {
      binSize: 1,
      binUnit: filterState.binUnit,
      tz: TZ,
      intervalPreset: filterState.intervalPreset,
      customInterval,
      graphDataRequestItems,
      setMissingValueTo: 0
    };
  
    return request;
  })();

  const graphReq_transactionCount = (() =>
  {
    const selectedParkings = filterState.selectedParkingId
    ? [props.parkings.find(p => p._id === filterState.selectedParkingId)!]
    : [...props.parkings];

    const customInterval = filterState.intervalPreset === TS.IntervalPreset_E.custom
    ? { t0: filterState.customInterval.t0.toDate(), t1: filterState.customInterval.t1.toDate() }
    : undefined;

    const graphDataRequestItems = selectedParkings.map((p, idx) =>
    ({
      key: p.name,
      unit: CH.ValueUnit_E.none,
      color: dashboardColors[idx],
      metricType: TS.MetricType_E.booking_count,
      reducerFunction: TS.ReducerFunction.sum,
      metricSpecificParameter: {
        booking_count: {
          parkingId: p._id
        }
      }
    }));
  
    const request: GraphDataRequest = {
      binSize: 1,
      binUnit: filterState.binUnit,
      tz: TZ,
      intervalPreset: filterState.intervalPreset,
      customInterval,
      graphDataRequestItems,
      setMissingValueTo: 0
    };

    return request;
  })();

  const { refresh: refresh_revenue, graphDataStatus: graphDataStatus_revenue } = hook_loadGraphData(graphReq_revenue);
  const { refresh: refresh_transactionCount, graphDataStatus: graphDataStatus_transactionCount } = hook_loadGraphData(graphReq_transactionCount);
  const { refresh: refresh_revenueScorecard, graphDataStatus: graphDataStatus_revenueScorecard } = hook_loadGraphData(graphReq_revenueScorecard);
  const { refresh: refresh_transactionCountScorecard, graphDataStatus: graphDataStatus_transactionCountScorecard } = hook_loadGraphData(graphReq_transactionCountScorecard);

  useEffect(() =>
  {
    refresh_revenue(graphReq_revenue);
    refresh_transactionCount(graphReq_transactionCount);
  }, [filterState.binUnit, filterState.intervalPreset, filterState.selectedParkingId,
      filterState.customInterval.t0.toISOString(), filterState.customInterval.t1.toISOString(),
    ]);

  useEffect(() =>
  {
    refresh_revenue(graphReq_revenue);
    refresh_transactionCount(graphReq_transactionCount);
    refresh_revenueScorecard(graphReq_revenueScorecard);
    refresh_transactionCountScorecard(graphReq_transactionCountScorecard);
  }, [props.parkings]);

  const isLoading = graphDataStatus_revenue.loading || 
                    graphDataStatus_transactionCount.loading ||
                    graphDataStatus_revenueScorecard.loading ||
                    graphDataStatus_transactionCountScorecard.loading;

  const isError = !!(graphDataStatus_revenue.error || 
                    graphDataStatus_transactionCount.error ||
                    graphDataStatus_revenueScorecard.error ||
                    graphDataStatus_transactionCountScorecard.error);

  const colors_revenue = props.parkings.map((p, idx) => dashboardColors[idx]);
  const colors_transaction = [...colors_revenue];
  
  const [graph_transactionCount, scorecard_transactionCount] = (() =>
  {
    if (isLoading) return [<LoadingCard height={CHART_HEIGHT}/>, null];
    if (isError)   return [<EmptyEl text="Error Occured" style={{ ...d100, height: CHART_HEIGHT }} />, null];

    return [
      (<TransactionCountGraph
        graphData={graphDataStatus_transactionCount.data!}  />),
      (<ScoreCard_Booking
        graphData={graphDataStatus_transactionCountScorecard.data!}
        title={'Transactions'}
        colors={colors_transaction}
        formatter={CH.getYTickFormatter(CH.ValueUnit_E.none)} />)
    ];
  })();

  const [graph_revenue, scorecard_revenue] = (() =>
  {
    if (isLoading) return [(<LoadingCard height={CHART_HEIGHT}  />), null];
    if (isError) return [(<EmptyEl text="Error Occured" style={{ ...d100, height: CHART_HEIGHT }} />), null];

    return [
      (<RevenueGraph
        graphData={graphDataStatus_revenue.data!} />),
      (<ScoreCard_Booking
        graphData={graphDataStatus_revenueScorecard.data!}
        title={'Revenue'}
        colors={colors_revenue}
        formatter={CH.getYTickFormatter(CH.ValueUnit_E.cents)} />)
    ];
  })();

  const labelData: { color: string; name: string; }[] = [];
  if (!isLoading && !isError && !!(graphDataStatus_revenue.data))
  {
    const colors = colors_revenue;
    const graphData = graphDataStatus_revenue.data!!;
    const names = graphData.description.map((desc: any) => desc.key);

    for (let i = 0; i < colors.length; i++)
    {
      labelData.push({ color: colors[i], name: names[i] });
    }
  }

  return (
    <div>
      <Card>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1, fontSize: theme.fontSize.lg, display: 'flex', alignItems: 'center'}}>
            Showing for the month of {CURRENT_MONTH_STRING}
          </div>
          <div style={{flex: 2, display: 'flex', alignItems: 'stretch'}}>

            {scorecard_revenue}
            <div style={{flex: 1, borderRight: `${theme.spacing(1)} solid ${theme.color.silver}` }}></div>
            <div style={{flex: 1 }}></div>

            {scorecard_transactionCount}
            <div style={{flex: 1, borderRight: `${theme.spacing(1)} solid ${theme.color.silver}` }}></div>
            <div style={{flex: 1 }}></div>
            
            <div><Pricing_Booking parkings={props.parkings}/></div>
            <div style={{flex: 1 }}></div>
          </div>
        </div>
      </Card>

      <div style={{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center', 
        padding: theme.spacing(4)}}>
          {labelData.map(({ name, color }, index) => 
          (
            <div 
              key={index} 
              style={{
              marginRight: theme.spacing(5), 
              flexBasis: '175px',
              display: 'flex', 
              alignItems: 'center'
            }}>
              <span style={{height: 20, width: 20, display: 'inline-block', background: color, borderRadius: '20%'}}></span>
              <span style={{marginLeft: theme.spacing(2)}}>{name}</span>
            </div>
          ))}
      </div>

      <div>
        <div style={{ display: 'flex', alignItems: 'stretch'}}>
          <div style={{ flex: 1, maxWidth: '50%', marginRight: theme.spacing(4) }}>
            {graph_revenue}
          </div>
          <div style={{ flex: 1, maxWidth: '50%', marginRight: theme.spacing(4) }}>
            {graph_transactionCount}
          </div>
          <div style={{ maxWidth: FILTER_WIDTH }}>
            <BookingFilter
              initialFilterState={filterState}
              parkings={props.parkings}
              onFilterStateChanged={filterState => setFilterState(filterState)} />
          </div>
        </div>
      </div>
    </div>
  );
}

//END: Booking

enum Tabs
{
  Booking='Booking',
  Occupancy='Occupancy'
}

interface AnalyticsProps
{
  parkings: PARKING.Parking[];
}
export const Analytics = (props: AnalyticsProps) =>
{
  const [activeTab, set_activeTab] = useState(Tabs.Booking);

  const PageComponent = activeTab === Tabs.Occupancy? Page_Occupancy: Page_Booking;

  const parkings = props.parkings.filter(p => p.active && p.approved);

  return (
    <>
      <Card>
        <div style={{width: '100px'}}>
          <Switch.underlined
            style={{ padding: 0 }}
            onClick={tab => set_activeTab(tab as Tabs)}
            color={primaryTxtColor}
            border={"2px solid " + primaryColor}
            items={[Tabs.Booking, Tabs.Occupancy]}
            active={activeTab} />
        </div>
      </Card>

      <div>
        <PageComponent parkings={parkings} />
      </div>
    </>
  );
}