import { useEffect, useState } from "react";
import API from "../api";
import { asyncHandler } from "../utils";

export const useCountry = () => {
    const [state, setter] = useState({
        currency: '',
        country: '',
        loading: false,
        error: false,
    });

    const setState = (obj: any) => setter({ ...state, ...obj });

    const getUser = async () => {
        setState({ loading: true, error: false });
        const { success, user_details } = await asyncHandler({
            apiCall: () => API.authUser(),
        });
        setState({
            loading: false,
            error: !success,
            currency: user_details?.currency || '',
            country: user_details?.country || '',
        });
    }

    useEffect(() => {
        getUser();
    }, []);

    return state;
};

export default useCountry;
