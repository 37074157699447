import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from 'react-router';
import Events from "./pages/events";
import { Home, Listings, BookingHistory, Checkout, Wallet, PendingRatings, Login, Legal, Help, Profile  } from "./pages";
import PaypalRedirectRoute from "./pages/wallet/payout-method/payout-adder/paypal/paypal-redirect-route";
import Analytics from "./pages/analytics";

export const ApprovedRoutes = ()=>{
    return <>
    
    <PendingRatings />
    <Switch>
        <Route path="/analytics" render={() => <Analytics />} />
        <Route path="/booking-history" render={() => <BookingHistory />} />
        <Route path="/garage/:garageId" render={() => <Home />} />
        <Route path="/checkout" render={() => <Checkout />} />
        <Route path="/wallet" render={() => <Wallet />} />
        <Route path="/listings" render={() => <Listings />} />
        <Route path="/legal" render={() => <Legal />} />
        <Route path="/help" render={() => <Help />} />
        <Route path="/profile" render={() => <Profile />} />
        <Route path="/paypal-redirect" render={() => <PaypalRedirectRoute />} />
        <Route path="/enterprise/:eId/events" render={() => <Events />} />
        <Route path="/" exact render={() =>  <Home />} />
    <Redirect to='/' />
  </Switch>
  </>
  }
  
export const UnApprovedRoutes = ()=>{
    return  <Switch>
    <Route path="/legal" render={() => <Legal />} />
    <Route path="/help" render={() => <Help />} />
    <Route path="/login" render={() => <Login />} />
    <Route path="/" exact render={() => <Home />} />
    <Redirect to='/login' />
  </Switch>
  }