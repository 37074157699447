import React from "react";
import { Grid } from "platyplex_ui";
import Wrapper from "../../../../../components/overlay-wrapper";
import PaypalInfo from "./paypal-info";
import { paypalLoginUrl } from '../../../../../config';

const Paypal = ({ close }: { close: () => void }) => {
  return (
    <Wrapper
      style={{
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 100,
      }}
      back={close}
    >
      <Grid
        placeSelf="start center"
        placeItems="start center"
        style={{ width: 400, maxWidth: "90vw", height: '100%'}}
      >
      <PaypalInfo next={()=>{
        window.location.href = paypalLoginUrl;
        } }/>
      </Grid>
    </Wrapper>
  );
};

export default Paypal;
