import React from "react";
import { Grid, Placeholder, List, Text, Modal } from "platyplex_ui";
import Parking from "./parking";
import Share from "../share";
import { NearbyParking } from "../../../../model/domain/parking";

const MobileParkingList = ({ parkings, loading, setActiveParking, setOpenParking, query }: any) => {
  const ShareModal = (id: string) =>
    Modal({
      title: "Share Garage",
      message: <Share id={id} />,
      hideCancel: true,
    }).open();

  return (
    <Grid
      className='show-mobile'
      mobileLayout={false}
      placeItems="start stretch"
      customCols={`repeat(${parkings.length}, 95vw)`}
      style={{
        display: 'none',
        zIndex: 10, 
        position: 'fixed', 
        bottom: 0, 
        width: '100vw', 
        overflowX: 'scroll'}}
    >
        <List
          loading={loading}
          list={parkings}
          empty={<span/>}
          map={(parking: NearbyParking, i: number) =>
            <Parking
              parking={parking}
              key={i}
              share={ShareModal}
              selectMarker={() => setActiveParking(i)}
              unSelectMarker={() => setActiveParking(-1)}
              select={(p: NearbyParking) => setOpenParking(p)}
              query={query}
            />}
        />
    </Grid>
  );
};

export default MobileParkingList;