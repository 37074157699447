import React, { useState } from "react";
import { Grid, List, Loader, Text, Modal } from "platyplex_ui";
import Collapse from "../collapse";
import useVehicleClasses from "../../hooks/vehicle-classes";
import { LAYER, primaryColor } from "../../config";
import PrimaryModal from "../primary-modal";
import NewModal from "../new-modal";

const AllowedVehicles = ({
  allowedVehicles,
}: {
  allowedVehicles: string[];
}) => {
  const { vehicles, loading, vehicleTypes } = useVehicleClasses();

  const allowedCategories = allowedVehicles.reduce(
    (prev: string[], curr: any) => {
      const [vehicle] = vehicles.filter(({ name }) => name === curr) as any;
      return [...prev, vehicle?.category];
    },
    []
  );

  const filtredVehicleTypes = vehicleTypes.filter(({ category }) =>
    allowedCategories.includes(category)
  );

  const [info, setInfo ] = useState(undefined)

   const InfoModal = NewModal({
    modal: (
      <PrimaryModal
      style={{zIndex: LAYER.last, maxWidth: '95vw'}}
      onCancel={() => {InfoModal.close()}}
    >
      <Grid gridGap=".25em" padding="1em">
       {info}
      </Grid>
    </PrimaryModal>
    ),
  });

  const openModal = (info: any) =>{
    const InfoModal = Modal({
     title: "",
     className: "top1",
     modal: (
       <PrimaryModal
       style={{zIndex: LAYER.last}}
       onCancel={() => {InfoModal.close()}}
     >
       <Grid gridGap=".25em" padding="1em">
        {info}
       </Grid>
     </PrimaryModal>
     ),
     hideCancel: true,
   });
   InfoModal.open();
 }

  if (loading) return <Loader />;
  return (
    <>
      {InfoModal.modal}
      {filtredVehicleTypes.map(({ displayName, category, tooltip }) => {
        
        return (
          <Grid
            margin="0 0 1em 0"
            gridGap={0}
            padding="0 0 0 .5em"
            key={displayName}
          >
            <Collapse
              collapse={!["passenger_vehicle", "truck"].includes(category)}
              heading={
                <Text bold textAlign="left">
                  {displayName}  &nbsp; {tooltip && <i className='fa fa-info-circle' style={{color: primaryColor}} onClick={()=>openModal(tooltip)}/>}
                </Text>
              }
            >
              <List
                loading={loading}
                list={vehicles.filter(
                  (vehicle: any) => vehicle.category === category
                )}
                map={(props: any, index: number) => {
                  const { displayName, imageUrl, name } = props;
                  if (!allowedVehicles?.includes(name)) return null;
                  return (
                    <Grid
                      key={index}
                      customCols="1fr auto"
                      placeItems="stretch"
                      gridGap=".25em"
                      padding="0 0 0 .5em"
                    >
                      <Text textAlign="left">
                        <img
                          src={imageUrl}
                          style={{ width: 30 }}
                          alt="Vehicle Types"
                        />
                        &nbsp; {displayName}
                      </Text>
                    </Grid>
                  );
                }}
              />
            </Collapse>
          </Grid>
        );
      })}
    </>
  );
};

export default AllowedVehicles;
