import React, { useState } from "react";
import { Grid, Text, Header, Modal, List } from "platyplex_ui";
import {
  disabledTxtColor2,
  primaryColor,
  primaryTxtColor,
} from "../../../../config";
import { DailyParking } from "../../../listings/components/availability";
import { BookingHistory, Vehicle } from "../../type";
import Images from "../../../../components/images";
import Avatar from "../../../../components/avatar";
import dailyParking from "../../../../utils/daily-parking";
import useConfig from "../../../../hooks/config";
import PrimaryModal from "../../../../components/primary-modal";
import QRCode from "react-qr-code";

const defaultValue: any = {
  daily_parking: {
    time: dailyParking.workdays,
  },
};

const BookingInfo = (props: {
  booking: BookingHistory;
  loading: boolean;
  close: () => void;
}) => {
  const { loading, close } = props;
  const { booking } = props;
  const { parking, owner, type } = booking;
  const [availability, setAvailability] = useState(false);
  const [driverInfo, setDriverInfo] = useState(false);
  const { config } = useConfig();
  const phone = config?.help?.phone;
  const QRModal = Modal({
    title: '',
    hideCancel: true,
    modal:  <PrimaryModal onCancel={()=>QRModal.close()} > 
    <Grid>
    <Text bold>QR Code</Text>
    <QRCode value={booking.qrstring || `booking_id=${booking._id}`} />
    </Grid>
    </PrimaryModal>,
  });

  return (
    <form
      style={{
        display: "grid",
        gridTemplateRows: availability ? "1fr" : "auto auto 1fr",
        height: "100vh",
        maxWidth: "800px",
        background: "white",
        placeItems: "stretch",
      }}
    >
      {availability ? (
        <Grid padding="1em 2em" customRows="auto auto  auto auto 1fr">
          <Grid placeSelf="start">
            <i
              className="fa fa-times bold pointer hoverr txt-md"
              onClick={() => setAvailability(false)}
              style={{ placeSelf: "start" }}
            />
          </Grid>
          <Header color={primaryColor} bold>
            {booking.driver.user_name}'s Parking Schedule
          </Header>
          <Text textAlign="left">
            {booking.driver.user_name} will be parking in your spot on the
            following days and time in a week
          </Text>
          <DailyParking
            disabled
            daily_parking={defaultValue.daily_parking}
            setDailyParking={() => {}}
          />
        </Grid>
      ) : driverInfo ? (
        <>
          <Grid padding="1.5em">
            <i
              className="fa fa-times bold pointer hoverr txt-md"
              onClick={() => setDriverInfo(false)}
              style={{ placeSelf: "start" }}
            />
          </Grid>
          <Grid padding="1em 2em" customRows="auto auto auto auto 1fr">
            <Header bold>Your Spot was booked by</Header>
            <Avatar src={booking.driver?.image_url} />

            <Text textAlign="left" bold className="capitalize">
              {booking.driver?.user_name}
            </Text>

            <Text textAlign="left" bold>
              Having issues? Please contact ParkStash support and we will strive
              to resolve any issues right away!
            </Text>
            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Call Us
              </Text>

              <a href={`tel:+${phone}`}>
                <i
                  className="fa fa-chevron-right pointer hoverr"
                  style={{ color: primaryTxtColor }}
                />
              </a>
            </FieldWrapper>

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Text Us
              </Text>
              <a href={`tel:+${phone}`}>
                <i
                  className="fa fa-chevron-right pointer hoverr"
                  style={{ color: primaryTxtColor }}
                />
              </a>
            </FieldWrapper>

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Email Us
              </Text>
              <a href="mailto:support@findparkstash.com">
                <i
                  className="fa fa-chevron-right pointer hoverr"
                  style={{ color: primaryTxtColor }}
                />
              </a>
            </FieldWrapper>
          </Grid>
        </>
      ) : (
        <>
          <Grid padding="1.5em">
            <i
              className="fa fa-times bold pointer hoverr txt-md"
              onClick={close}
              style={{ placeSelf: "start" }}
            />
          </Grid>

          <Grid
            placeSelf="stretch"
            style={{ maxHeight: "100%", overflowY: "auto", fontSize: "1em" }}
            padding="0 1.5em 1.5em 1.5em"
            placeItems="stretch"
          >
            <Images
              images={parking.image_url || []}
              style={{ width: "100%", height: "auto", maxWidth: "50vw" }}
            />
            <Text bold className="capitalize">
              {parking.name || `${owner.user_name}'s ${parking.spot_type}`}
            </Text>

            <FieldWrapper>
              <Text textAlign="left" bold>
                Booking ID
              </Text>
              <Grid width='100%' customCols='1fr auto' placeItems='stretch'>
                <Text textAlign="left">{booking.par_id}</Text>
                <span className='bold pointer' style={{color: 'dodgerblue'}} onClick={QRModal.open}>QR code</span>
              </Grid>
            </FieldWrapper>
            <FieldWrapper customCols="auto 1fr auto">
              <Text>
                <Text textAlign="left" bold>
                  {" "}
                  Parking From{" "}
                </Text>
                {booking.pretty_start}
              </Text>
              <Header>
                <i className="fa fa-chevron-right" />
              </Header>
              <Text>
                <Text bold textAlign="right">
                  {" "}
                  Parking End{" "}
                </Text>
                {booking.pretty_end}
              </Text>
            </FieldWrapper>
            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                {" "}
                Driver Info{" "}
              </Text>
              <i
                className="fa fa-chevron-right pointer hoverr"
                onClick={() => setDriverInfo(true)}
              />
            </FieldWrapper>
            {type !== "daily" && (
              <FieldWrapper customCols="1fr auto">
                <Text textAlign="left" bold>
                  {" "}
                  Driver Parking Schedule{" "}
                </Text>
                <i
                  className="fa fa-chevron-right hoverr pointer"
                  onClick={() => setAvailability(true)}
                />
              </FieldWrapper>
            )}

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Driver Vehicle Info
              </Text>
              <List 
              empty={<Text style={{gridColumn: '1/-1'}} textAlign='left'>No Vehicle</Text>}
              list={booking.vehicles? booking.vehicles: [booking.vehicle] }
              map={(v: Vehicle)=>
              <Text textAlign="left" color="inherit" key={v._id} style={{gridColumn: '1/-1'}}>
              {`${v.color} ${v.year} ${v.model}`}{" "}
              <br />
              License Plate #{v.license_plate}
            </Text>}
             />
            </FieldWrapper>

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                {" "}
                Assigned Spot{" "}
              </Text>
              <Text>{booking.assigned_parking_spot_no === -1? 'Any Available': ('#' + booking.assigned_parking_spot_no)}</Text>
            </FieldWrapper>

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                {" "}
                Number of Spots{" "}
              </Text>
              <Text>{booking.spot_count}</Text>
            </FieldWrapper>


            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                {" "}
                Address of your spot 
              </Text>
              <div>
                <Text textAlign="right">
                  {booking.parking.address.street},{" "}
                  {booking.parking.address.city},{" "}
                </Text>
                <Text textAlign="right">
                  {" "}
                  {booking.parking.address.state}{" "}
                  {booking.parking.address.zipcode}
                </Text>
              </div>
            </FieldWrapper>
            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Spot Type
              </Text>
              <Text textAlign="right" color="inherit" className="capitalize">
                {booking.parking.spot_type}
              </Text>
            </FieldWrapper>
            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Your Earnings
              </Text>
              <Text textAlign="right" color="inherit">
                {(booking?.transaction?.pretty_owner_earnings)}
              </Text>
            </FieldWrapper>
            {booking.transaction.security_deposit !== 0 &&
            <FieldWrapper customCols="1fr">
              <Text textAlign="left" bold>
                Security Deposit
              </Text>
              <Text textAlign="left" color="inherit">
              {booking.transaction.pretty_security_deposit}
              </Text>
            </FieldWrapper>}
          </Grid>
        </>
      )}
    </form>
  );
};

const FieldWrapper = ({ children, gridGap, customCols }: any) => (
  <Grid
    style={{ borderBottom: "1px solid " + disabledTxtColor2 }}
    padding="0 0 1em 0"
    placeSelf="stretch"
    placeItems="stretch"
    customCols={customCols || ""}
    gridGap={gridGap || "0.5em"}
  >
    {children}
  </Grid>
);
export default BookingInfo;
