import { useState, useEffect } from "react";
import API from "../api";
import { EditParkingModel, Parking } from "../model/domain/parking";
import { asyncHandler } from "../utils";

export interface ParkingProps extends Parking {
  avg_host_rating?: number;
  review_count?: number;
  rating_count?: number;
}

export interface ParkingState {
  parkings: Parking[];
  loading: boolean;
  error: boolean;
}

export const useParkings = () => {
  const [state, setter] = useState({
    parkings: [],
    loading: false,
    error: false,
  });

  const setState = (obj: any) => setter({ ...state, ...obj });

  const getParkings = async () => {
    setState({ loading: true, error: false });
    const { success, parkings } = await asyncHandler({
      apiCall: () => API.getParkings(),
    });

    setState({ loading: false, error: !success, parkings: parkings || [] });
  };

  const refresh = () => getParkings();

  const addListing = async (listing: Parking | any) => {
    setState({ loading: true, error: false });
    const res = await asyncHandler({
      apiCall: () => API.addParking(listing),
    });
    setState({ loading: false, error: false });
    getParkings();
    return res;
  };

  const editListing = async (parking: EditParkingModel | any) => {
    setState({ loading: true, error: false });
    const res = await asyncHandler({
      apiCall: () => API.editParking(parking, parking._id),
      successMessage: "Listing was Edited",
      defaultMessage: true,
    });
    setState({ loading: false, error: res?.success || false });
    getParkings();
    if (!res?.success) {
      alert(res.message);
    }

    return res?.success || false;
  };

  const removeParking = async (id: string) => {
    setState({ loading: true, error: false });
    const res = await asyncHandler({
      apiCall: () => API.removeParking(id),
      successMessage: "Listing was deleted",
      errorMessage: "Listing was not deleted",
    });
    setState({ loading: false, error: !res?.success });
    await getParkings();
  };

  useEffect(() => {
    getParkings();
  }, []);

  return {
    ...state,
    addListing,
    editListing,
    removeParking,
    refresh,
  };
};

export default useParkings;
