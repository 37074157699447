import React from "react";
import { Grid } from "platyplex_ui";
import useParkings from "../../../hooks/parkings";
import ListingEditor from "./listing-editor";
import { Drawer } from "antd";
import { EditParkingModel } from "../../../model/domain/parking";

export const ListingEdit = ({ close,  listing, refresh }: any) => {
  const { editListing, loading } = useParkings();
  const submit = async (listing: EditParkingModel) => {
    if (await editListing(listing)) {
      close();
      refresh();
    }
  };
  return (
    <Drawer
      placement="right"
      width={520}
      closable={false}
      bodyStyle={{ padding: 0 }}
      onClose={close}
      visible={true}
    >
      <Grid width="100%" height="100%" gridGap="0">
        <ListingEditor
          submit={submit}
          listing={listing}
          loading={loading}
          close={close}
        />
      </Grid>
    </Drawer>
  );
};
