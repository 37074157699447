
import React from "react";
import { Card, Button, Text } from "platyplex_ui";
import { TimePicker, Calendar } from "antd";
import { gradient } from "../../config";
import moment, { Moment } from 'moment';
import DatePicker from "react-datepicker";

interface State {
    start_date: Moment;
    end_date: Moment;
}

interface DateSelectorProps {
    date: State;
    hideTimePicker?: boolean;
    handleTimeChange: (field: string, time: Moment | null) => void;
    submitDate: () => void;
    error?: () => any;
}

const RangePicker = ({
    date,
    handleTimeChange,
    hideTimePicker = false,
    submitDate,
    error,
}: DateSelectorProps) => {
    const reverse = {
        points: ["bl", "tl"], // align dropdown bottom-left to top-left of input element
        offset: [0, -4], // align offset
        overflow: {
            adjustX: 0,
            adjustY: 0, // do not auto flip in y-axis
        },
    };

    return (
        <Card
            style={{ maxWidth: "700px" }}
            margin="0"
            padding="0"
            grid
            gridGap="1em 4em"
            customCols="1fr 1fr"
        >
            <Text bold>From</Text>
            <Text bold>To</Text>
            <Calendar
                className='noselect'
                value={date.start_date}
                fullscreen={false}
                disabledDate={(d) => !(d > moment().add("day", -1))}
                onChange={(e) => handleTimeChange("start", e)}
            />
            <Calendar
                className='noselect'
                value={date.end_date}
                fullscreen={false}
                disabledDate={(d) => !(d > moment().add("day", -1))}
                onChange={(e) => handleTimeChange("end", e)}
            />
            {!hideTimePicker && (
                <>
                <div>
                 <DatePicker
        
        selected={date.start_date.toDate()}
          onChange={(d: Date | null) => {
            if (!d) return;
            handleTimeChange("start", moment(d))
          }}
                 showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          
      />
      </div>
<div>
<DatePicker
        
        selected={date.end_date.toDate()}
          onChange={(d: Date | null) => {
            if (!d) return;
            handleTimeChange("end", moment(d))
          }}
                 showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          
      />
              </div>    
                   
                </>
            )}
            {error  && error() && (
                <Text color="red" style={{ gridColumn: "1/-1" }}>
                    Start date is greater than the end date.
                </Text>
            )}
            <Button
                style={{ gridColumn: "1/-1", minHeight: "2.75em" }}
                bg={gradient}
                noHover
                width="100%"
                rounded
                disabled={error && error()}
                onClick={submitDate}
            >
                Search
        </Button>
        </Card>
    );
};

export default RangePicker;