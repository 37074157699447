import React, { useEffect, useState } from "react";
import { Modal } from "platyplex_ui";
import useRating from "../../hooks/ratings";
import PendingRatingModal from "./pending-rating-modal";

const PendingRatings = () => {
  const [index, setIndex] = useState(0);
  const [completedIndex, setCompletedIndex] = useState(-1);
  const { pendingRatings, addRating, refresh } = useRating();
  const RatingModal = Modal({
    title: "",
    modal: (
      <PendingRatingModal
        pendingRating={pendingRatings[index]}
        addRating={addRating}
        close={() => {
          RatingModal.close();
          if (pendingRatings.length > (index + 1)) setIndex(index + 1);
          setCompletedIndex(completedIndex + 1);
        }}
      />
    ),
    hideCancel: true,
  });

  useEffect(() => {
    if (pendingRatings.length > 0 && index !== completedIndex) {
      RatingModal.open();
    }
  }, [index, pendingRatings ]);

  return null;
};

export default PendingRatings;
