import React from "react";
import { Empty, Grid, List } from "platyplex_ui";
import { Parking } from "../../../../model/domain/parking";
import useAmenities from "../../../../hooks/amenities";
interface AmenitiesProps {
  loading: boolean;
  additionalInfo: Parking | undefined;
}

const Amenities = ({ loading, additionalInfo }: AmenitiesProps) => {
  const { amenities, getAmenty } = useAmenities();
  return (
    <Grid
      cols={
        additionalInfo?.amenities &&
        additionalInfo?.amenities.length > 0 &&
        !loading
          ? "3"
          : "1"
      }
      gridGap="1.5em"
    >
      <List
        loading={loading}
        empty={<Empty text={"No Amenities Listed"} style={{ marginTop: 0 }} />}
        list={additionalInfo?.amenities || []}
        map={(a: any) => {
          const amenity = getAmenty(a);

          if(!amenity) return null;
          return (
            <Grid key={amenity.displayName}>
              <img
                src={amenity.imageUrl}
                style={{
                  width: "100%",
                  maxWidth: "5em",
                  padding: ".25em",
                }}
                alt={amenity.displayName}
              />
              {amenity.displayName}
            </Grid>
          );
        }}
      />
    </Grid>
  );
};

export default Amenities;
