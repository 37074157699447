import React from "react";
import { Grid, Text,  Card, List } from "platyplex_ui";
import { primaryColor, STRIPE_API_KEY } from "../../../config";
import { Link } from "react-router-dom";
import NewModal from "../../../components/new-modal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { selectBank } from "../../../store/actions/bank";
import { useDispatch, useSelector } from "react-redux";
import BankModal from "./components/bank-item";
import PayoutAdder from "./payout-adder";

const stripePromise = loadStripe(STRIPE_API_KEY);

type PayoutMethodTypes = 'paypal' | 'stripe'
interface PayoutMethod
{
  _id: string;
  ownerType: string;
  ownerId: string;
  type: PayoutMethodTypes;
  stripeBankAccount?: PayoutMethod_StripeBankAccount;
  paypal?: PayoutMethod_Paypal;
  createdAt: Date;
  deletedAt?: Date;
};

export type PayoutType = any

export interface PayoutMethod_StripeBankAccount
{
  stripeId: string;
  bankName: string;
  last4: string;
  routingNumber: string;
  country: string;
  fingerprint: string;
};

export interface PayoutMethod_Paypal
{
  paypalId: string;
  name: string;
  email: string;
  country: string;
  refreshToken: string;
};



const PayoutMethod = () => {
  const dispatch = useDispatch();
  const PayoutAddModal = NewModal({
    modal: <PayoutAdder close={() => PayoutAddModal.close()} />,
  });

  const { payoutMethods, loading: loadingBank, selectedPayoutMethod } = useSelector(
    (state: any) => state.banks
  );
  const BankInfoModal = NewModal({
    modal: (
      <BankModal 
      payoutMethod={payoutMethods.filter((v: PayoutType)=> selectedPayoutMethod === (v?._id) )[0]}
      selectedPayoutMethod={selectedPayoutMethod} 
      close={() => BankInfoModal.close()} 
      />
    ),
  });

  return (
    <Elements stripe={stripePromise}>
      {PayoutAddModal.modal}
      {BankInfoModal.modal}
      <Grid placeSelf="stretch">
        <Grid customCols="1fr auto" placeSelf="stretch">
          <Link to="/wallet" style={{ placeSelf: "start" }}>
            <Text
              textAlign="left"
              color={primaryColor}
              size={"1.25em"}
              className="pointer"
            >
              <i className="fas fa-arrow-circle-left" /> &nbsp; Back
            </Text>
          </Link>
        </Grid>
        <Grid style={{ width: 400, maxWidth: "90vw" }} placeItems="stretch">
          <Text textAlign="left">
            Use your payout method to request payout of your earnings
          </Text>
          <Text
            textAlign="left"
            color="blue"
            className="pointer"
            onClick={PayoutAddModal.open}
          >
            Add Payout Method
          </Text>

          <List
            loading={loadingBank}
            empty={
              <Text color="red">
                No payout method present. Add a payout method.
              </Text>
            }
            list={payoutMethods}
            map={(payoutMethod: PayoutMethod) => (
              <Card
                key={payoutMethod._id}
                shadow
                mobileLayout={false}
                margin="0"
                style={{ width: 400, maxWidth: "90vw" }}
                grid
                onClick={() => {
                    dispatch(selectBank((payoutMethod?._id) as any));
                  BankInfoModal.open();
                }}
                gridGap="2em"
                customCols="auto 1fr auto"
                className="pointer noselect highlight"
              >
              {payoutMethod.stripeBankAccount? <BankItem bank={payoutMethod.stripeBankAccount}/>: 
              <PaypalItem paypal={payoutMethod.paypal as any}/>}
              </Card>
            )}
          />
        </Grid>
      </Grid>
    </Elements>
  );
};



const PaypalItem = (props: { paypal: PayoutMethod_Paypal})=>{
  return (<> 
  <i className="fab fa-paypal txt-md"></i>
  <div style={{ placeSelf: "stretch" }}>
    <Text textAlign="left">{props.paypal.name} </Text>
    <Text textAlign="left">
      Account with email <span>{props.paypal.email}</span>
    </Text>
  </div>
  <i className="fa fa-chevron-right" /></>)
}




const BankItem = (_props: { bank: PayoutMethod_StripeBankAccount })=>{
  return (<> 
  <i className="fa fa-university txt-lg" aria-hidden="true" />
  <div style={{ placeSelf: "stretch" }}>
    <Text textAlign="left">{_props.bank.bankName} </Text>
    <Text textAlign="left">
      {`Account # ending in ${_props.bank.last4}`}{" "}
    </Text>
  </div>
  <i className="fa fa-chevron-right" /></>)
}


export default PayoutMethod;