import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { Input, Tooltip } from "antd";
import { Card, Button, Header, Text, Grid } from "platyplex_ui";
import { usePlaces } from "../../../hooks/places";
import { defaultLocation, googleAPIKey, gradient, LAYER, primaryColor } from "../../../config";
import { Form } from './'
import Marker from "../../home/component/map/marker";
import PopupInfo from "../../../components/popup-info";

const SimpleMap = ({ forward, setGeo, progress, back, listing }: any) => {

  const {
    searchText,
    selectResult,
    error,
    search,
    setSearchText,
    resetSearch,
    getAddress,
  } = usePlaces(true);

  const [state, setter] = useState({
    location: defaultLocation,
    pointerLocation: defaultLocation,
    zoom: 17,
    address: search.address,
    pointerMove: false,
  });
  const setState = (obj: any) => setter({ ...state, ...obj });
  const selectResultLocation = (e: any) => {
    e.preventDefault();
    if (search.found) {
      setState({
        location: search.resultLocation,
        address: search.address,
        pointerLocation: search.resultLocation,
      });
      selectResult(search.result);
    }
    resetSearch();
  }
  const errorMap = state.address.zipcode === ''
  return (
    <Form
      back={back}
      onSubmit={(e: any)=>e.preventDefault()}
      style={{
        minHeight: '600px',
        height: "50vh",
        gridGap: '.5em',
        gridTemplateRows: 'auto auto auto auto auto 1fr auto',
      }}>

      <Header className='med' margin='1em 0'>Your Space's Address</Header>
      {progress}

      <Text textAlign='left' margin='1.5em 0 0 0'>Please enter the full address of your space.&nbsp;
      <PopupInfo
          title={<span>
            <b>Drivers see your exact address only after booking is confirmed.</b><br />
            When a driver has booked your spot, they'll be able to see your exact address. Everyone else just sees an approximate location of your spot on map, so that they can get an idea of the area your spot is in. This also helps them figure out the time it would take to reach their final destination from your spot.
          </span>}>
          <i className={'fa fa-info-circle'} style={{ color: primaryColor }}></i>
        </PopupInfo>
      </Text>
      <div
        style={{
          width: "100%",
          height: "4em",
          zIndex: LAYER.fourth
        }}
      >
        <Input
          suffix={
            <Tooltip title={"Please enter the Full Address"}>
              <i className={`fa fa-${errorMap ? 'times-circle red' : 'check green'}`} style={{ position: "relative" }}></i>
            </Tooltip>
          }
          onChange={({ target }: any) => {
            setSearchText(target.value);
            setState({ pointerMoved: false });
            getAddress(target.value);
          }}

          value={searchText}
          style={{ height: "3em" }}
        />
        {search.result.length > 0 && (
          <Card
            onClick={selectResultLocation}
            className="pointer noselect"
            shadow
            margin="auto"
          >
            {search.result}
          </Card>
        )}
      </div>
      Click on Adjust button and move the pin to correct position in the map.
      <Grid placeSelf="stretch" placeItems='stretch'>
        <Button
          margin='1em 2em'
          bg={gradient}
          noHover
          onClick={() => setState({ pointerMove: !state.pointerMove })}
          style={{ gridColumn: '1', gridRow: 1, zIndex: 100, placeSelf: 'start end' }}
        >
          {!state.pointerMove? 'Adjust': 'Done'}
        </Button>
        <div style={{ gridColumn: '1', gridRow: 1 }}>
          <GoogleMapReact
            options={() => ({
              fullscreenControl: false
            })}
            //API_KEY
            bootstrapURLKeys={{ key: googleAPIKey }}
            center={state.location}

            defaultZoom={state.zoom}
            onChange={({ zoom, center: location }: any) =>
              setState({ zoom, location })
            }
            onClick={({ _, __, lat, lng }: any) => {
              if (state.pointerMove)
                setState({ pointerLocation: { lat, lng } })
            }}
          >
            <Marker
              height = {50}
              width = {42}
              className={state.pointerMove ? "grow" : ""}
              lat={state.pointerLocation.lat}
              lng={state.pointerLocation.lng}
            />
          </GoogleMapReact>
        </div>
      </Grid>
      <Button
        shadow={!error}
        disabled={error || errorMap}
        onClick={() => {
          setGeo({ ...state.pointerLocation, address: state.address });
          forward();
        }}
        bg={gradient}
        noHover
        padding=".5em"
        rounded
        margin=".8rem 0"
        width="100%"
      >
        Next &nbsp;
        <i className="fa fa-chevron-right" aria-hidden="true"></i>
      </Button>
    </Form>
  );
};

export default SimpleMap;
