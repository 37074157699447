import { post, authGet, authPost } from "./utils/request";
import { API } from "../config";
import { OTPTypes } from "../store/actions/user";

const route = (r: string) => API + r;
interface ChangePasswordProps {
  email: string;
  otp: number;
  password: string;
}
let userRoutes = {
  authUser: () => authGet(route("/users"), false),
  forgotPassword: (props: any) => post(route("/users/password/forgot"), props),
  confirmEmail: (props: any) => post(route("/users/password/verify-forgot"), props),
  changePasswordOtp: (email: string)=> post(route("/changePassword/requestOtp"), {email }),
  changePasswordVerifyOtp : (email: string, otp: number)=>post(route("/changePassword/verifyOtp"), {email, otp}),
  changePassword: (props: ChangePasswordProps) => post(route("/changePassword"), props),
  logout: () => authGet(route("/v2/logout")),
  
  requestOtp: (type: OTPTypes, props: any) => authPost(route(`/otp/resend`), { type, ...props }),
  verifyPhone: (otp: string) => authPost(route(`/changePhone/verifyOtp`), { otp: Number(otp) }),
  addCountry: (country: string) => authPost(route(`/users/change-country`), { country }),
  addProfileImage: (image: string) => authPost(route(`/users/images`), { image }),
  help: (message: string) => authPost(route(`/users/help`), { message }),

  signup_v3: (props: any) => post(route(`/v3/signup`), { ...props, name: `${props.firstName} ${props.lastName}` }),
  login_v3: (props: any) => post(route(`/v3/login/credentials`), props),
  addPhone_v3: (phone: string) => authPost(route(`/changePhone`), { phone }),
  verifyOtp: (otp: string) => authPost(route(`/v3/login/verifyOtp`), { otp: Number(otp) }, true,),
  getPayload: (token: string)=> authGet(route('/v1/utils/retrieveTempData/'+ token), false),
  addParkingSchedule: (parking_schedule: any) => authPost(route(`/users/parking-schedule`), { parking_schedule })

};

export default userRoutes;
