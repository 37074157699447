import React from "react";
import { LAYER } from "../../config";
import CloseBtn  from "../close-btn";
const ReactWindow = ({
  children,
  style,
  close
}: {
  children: JSX.Element;
  style?: React.CSSProperties;
  close: ()=> void
}) => {
  return (
    <div
      style={{
        display: "grid",
        placeItems: "stretch",
        position: "absolute",
        width: '100vw',
        height: '100vh',
        zIndex: LAYER.last,
        background: "rgba(0,0,0,0.5)",
      }}
    >
      <div
        style={{
          placeSelf: "center",
          margin: 'auto',
          width: 800,
          maxWidth: "95vw",
          height: 800,
          ...style,
        }}
      >
        <CloseBtn  close={close}/>
        {children}
      </div>
    </div>
  );
};

const Frame = ({ src , close}: { src: string,  close: ()=> void }) => {
  return (
    <ReactWindow close={close}>
      
      <iframe src={src} width="100%" height="100%" id="11" >
      <CloseBtn  close={close}/>
      </iframe>
    </ReactWindow>
  );
};

export default Frame;
