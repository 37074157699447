import {  useState } from "react";
import { asyncHandler } from "../utils";

export const useAsync = (func: (...args: any[]) => void) => {
    const [state, setter] = useState({
        success: false,
        loading: false,
        error: false,
    });

    const setState = (obj: any) => setter({ ...state, ...obj });

    const runFunction = async (...args: any[]) => {
        setState({ loading: true, error: false, success: false });
        const { success } = await asyncHandler({
            apiCall: () => func(...args)
        });
        setState({
            loading: false,
            error: !success,
            success: !!success
        });
    }

    return {
        ...state,
        runFunction
    };
};

export default useAsync;
