import { OldApiUser, User } from "../../model/domain/user";
import { removeUserToken, setUserToken, isOtpVerified, setOtpVerified } from '../../utils/user'
type UserState = "UNAUTH" | "PHONE_SELECT" | "PHONE_VERIFY" | "OTP_VERIFY" | "APPROVED" | 'GUEST';

export interface State {
  user?: User;
  details?: OldApiUser;
  token?: string;
  error: boolean,
  loading: boolean,
  userState: UserState
}
const intialState: State = {
  user: undefined,
  details: undefined,
  token: undefined,
  userState: 'UNAUTH',
  error: false,
  loading: false,
};

export interface UserAction {
  type: ActionTypes;
  payload: Partial<State> & { user_phoneno?: string };
}

export enum ActionTypes {
  LOGIN = "LOGIN",
  AUTH = "AUTH",
  LOGOUT = "LOGOUT",
  RELOAD_DETAILS = "RELOAD_DETAILS",
  VERIFY_OTP = 'VERIFY_OTP',
  PASSWORD_RESET = "PASSWORD_RESET",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  VERIFIED_OTP = 'VERIFIED_OTP',
  ADD_PHONE = 'ADD_PHONE',
  USER_REQUEST_FAILED = "USER_REQUEST_FAILED",
  USER_REQUEST_PENDING = "USER_REQUEST_PENDING",
  USER_REQUEST_SUCCESS = "USER_REQUEST_SUCCESS",
  RESET_ERROR = "RESET_ERROR",
  GUEST_LOGIN = "GUEST_LOGIN"
};

const calcUserState = (user?: OldApiUser, optVerified = false): UserState => {
  if (!user || user === undefined) return 'UNAUTH';
  else if(user?.is_guest) return 'GUEST';
  else if (!user?.user_phoneno) return 'PHONE_SELECT';
  else if (!user?.otp_verified) return 'PHONE_VERIFY';
  else if (!optVerified) return 'OTP_VERIFY';
  return 'APPROVED';
};

export default function rootReducer(
  state = intialState,
  action: UserAction
): State {
  const { type, payload } = action;
  let userState: UserState;
  let details;
  switch (type) {
    case ActionTypes.LOGOUT:
      removeUserToken();
      return { ...state, user: undefined, userState: 'UNAUTH', loading: false, details: undefined };

    case ActionTypes.LOGIN:
       details = action.payload.details;
      userState = calcUserState(details);
      setUserToken(payload.token || '', payload.details?._id || '')
      return { ...state, userState, loading: false, details, token: payload.token };

      case ActionTypes.GUEST_LOGIN:
        details = action.payload.details;
        userState = calcUserState(details);
        setUserToken(payload.token || '', payload.details?._id || '', true)
       
      return { ...state, userState, loading: false, details, token: payload.token };
    case ActionTypes.AUTH:
      userState = calcUserState(payload.details, isOtpVerified())
      return { ...state, userState, details: payload.details, loading: false };

    case ActionTypes.VERIFIED_OTP:
      setOtpVerified(true)
      return { ...state, userState: calcUserState(state.details, true) }
    
      case ActionTypes.ADD_PHONE:
        return { ...state, userState: 'PHONE_VERIFY', 
        details: {...state.details as OldApiUser, user_phoneno: payload.user_phoneno
      } }

    case ActionTypes.USER_REQUEST_PENDING:
      return { ...state, loading: true, error: false };

    case ActionTypes.USER_REQUEST_FAILED:
      return { ...state, error: true, loading: false };

    case ActionTypes.USER_REQUEST_SUCCESS:
      return { ...state, loading: false };

    case ActionTypes.RESET_ERROR:
      return { ...state, error: false };

    default:
      return state;
  }
}
