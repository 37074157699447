import React, { useEffect } from "react";
import StripeCard from "../../../../components/stripe-card";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Grid, Text, Button, Header } from "platyplex_ui";
import {
  disabledTxtColor2,
  gradient,
  primaryColor,
  primaryTxtColor,
  STRIPE_API_KEY,
} from "../../../../config";
import Wrapper from "../../../../components/overlay-wrapper";
import { Input } from "antd";
import { useFormik } from "formik";

const stripePromise = loadStripe(STRIPE_API_KEY);

const CardForm = ({ close }: {close: ()=> void}) => {
  const border = `2px solid ` + disabledTxtColor2;
  const { handleSubmit, handleChange, errors, values } = useFormik({
    initialValues: { firstName: "", lastName: "" },
    async onSubmit(values) {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper
      style={{
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 100,
      }}
      back={()=>close()}
      header={
        <Grid
          style={{ borderTop: border, borderBottom: border }}
          placeSelf="stretch"
          customCols={"1fr"}
          padding="1em"
        >
          
          <Header bold>Add Card</Header>
        </Grid>
      }
    >
      <Grid placeSelf="start center" placeItems="start center">
        <Text textAlign="left" placeSelf="stretch">
          Please add your card details below
        </Text>

        <Elements stripe={stripePromise}>
          <StripeCard close={close}>
            <Grid placeItems="stretch">
              <Grid
                cols="2"
                mobileLayout={false}
                style={{ paddingTop: ".5em" }}
                placeItems="center stretch"
              >
                <Text textAlign="left" color={primaryColor} bold>
                  First Name*
                </Text>

                <Text textAlign="left" color={primaryColor} bold>
                  Last Name*
                </Text>

                <Input
                  style={{ minWidth: "25vw" }}
                  name={"firstName"}
                  value={values.firstName}
                  onChange={handleChange}
                  placeholder={"John"}
                />

                <Input
                  style={{ minWidth: "25vw" }}
                  name={"lastName"}
                  value={values.lastName}
                  onChange={handleChange}
                  placeholder={"Doe"}
                />
              </Grid>

              <Text textAlign="left" color={primaryColor} bold>
                *Exactly as it is written on your card
              </Text>
              <Button.Normal
                style={{
                  borderRadius: "1.3em",
                  padding: ".5em",
                  marginTop: "1em",

                  backgroundImage: `${gradient}`,
                  color: "white",
                  border: 0,
                }}
                type="submit"
                onSubmit={(e: any) => handleSubmit(e)}
              >
                Add
              </Button.Normal>
            </Grid>
          </StripeCard>
        </Elements>
      </Grid>
    </Wrapper>
  );
};

export default CardForm;
