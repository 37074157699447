import * as dotenv from 'dotenv';
dotenv.config();

export const mobileWidth =  '860px';
export const tabletWidth =  '1100px';

export const primaryColor = '#00d69e';    // Lighter Bluish Green
export const secondaryColor = '#037885'; // Darker Bluish Green
export const danger = '#FF5733'
export const gradient = 'linear-gradient(to left, #00D69E, #00BAA4, #009CA0, #007F90, #1E6378, #2F4858)';
export const gradientRev = 'linear-gradient(to right, #00D69E, #00BAA4, #009CA0, #007F90, #1E6378, #2F4858)';
export const gradient1 = 'linear-gradient(#00D69E, #00BAA4)';
export const gradient2 = 'linear-gradient(#00BAA4, #009CA0, #007F90, #1E6378)'; 

export const primaryTxtColor =  '#32325D';  
export const secodaryTxtColor =  '#32325D';    
export const invertedTxtColor =  '#FFFFFF'; 
export const disabledTxtColor =  '#A6ACAF';  
export const disabledTxtColor2 =  '#F0F2F3';  

export const gray = 'rgba(247, 249, 249, 0.65)';

export const encodedToken = 'alRWbklsMGFoTA==';
export const facebookAppId = '129630787713935';
export const googleAppId = '11814926501-qm96ff2l7ou5ut2j8gaohrjhn5f0sh6s.apps.googleusercontent.com';
export const googleAPIKey = `AIzaSyAsBjRtbANXMbg8HHxt-hcsmbNMYQt9DKo`;
export const geoCodeAPIKey = 'AIzaSyAsPP4ui5ui6-aAiFH7ECOvFDPtHizddsk'
export const cloudinaryPrefix = 'https://res.cloudinary.com/ssaran97/image/upload/';

export const textOverFlowLimit = 15;
export const top4 = 10;
export const top3 = 100;
export const top2 = 1000;
export const defaultLocation = { lat: 37.3352, lng:   -121.8811 };
export const token = 'jTVnIl0ahL';

export const LAYER = {
    first: 1,
    second: 10,
    third: 11,
    fourth: 11,
    fifth: 999,
    last: 1000
}

const hostName = window.location.hostname || '';

export const IS_TEST =  hostName !== 'web.findparkstash.com';

const isLocalHost = /127\.0\.0\.1/.test(hostName) || /localhost/.test(hostName);

const PROD_API      = process.env.PROD_API || 'https://findparkstash.com/api';
const STAGING_API   = 'https://test-stage.findparkstash.com/api';
const TEST_API      = process.env.TEST_API || 'https://test.findparkstash.com/api';

export const PROD_SSO_URL = 'https://sso.findparkstash.com';
export const TEST_SSO_URL = 'https://testsso.findparkstash.com';

const PROD_STRIPE_API_KEY =  'pk_live_GglgXVq5nPpdostyg48CPji5';
const TEST_STRIPE_API_KEY ='pk_test_P4KD7LJwiXZueVLGpGlitsrG';

export const API            = !IS_TEST? PROD_API: TEST_API;
export const SSO_URL        = !IS_TEST? PROD_SSO_URL: TEST_SSO_URL;
export const STRIPE_API_KEY = !IS_TEST? PROD_STRIPE_API_KEY: TEST_STRIPE_API_KEY;


const paypal_base_url = !IS_TEST? "https://www.paypal.com/connect/": "https://www.sandbox.paypal.com/connect/";
const paypal_client_id = !IS_TEST?  "AQDBkui7oouDadbYQe3c5vHHqhaUt8BfXos6u2D0SSff0qqHUbebL_nK9npB003-mKN-y1JWL3AxZQgU": "AV6oiNnTurjKKuGwKEtmXQvsDq18iQOF2LkMlJzJvNgzxeK1pZFaAylteo7zIRVmKGmxizYOkZRWKGm0";
const paypal_redirect_url = !IS_TEST?  "https://findparkstash.com/paypal-redirect": isLocalHost? 'http://127.0.0.1:3000/paypal-redirect': 'https://testweb.findparkstash.com/paypal-redirect';
export const paypalLoginUrl = `${paypal_base_url}?flowEntry=static&client_id=${paypal_client_id}&response_type=code&scope=openid%20profile%20email%20address%20https%3A%2F%2Furi.paypal.com%2Fservices%2Fpaypalattributes&redirect_uri=${paypal_redirect_url}`



//export const paypalAuthUrl = "${PAYPAL_BASE_URL}?flowEntry=static&client_id=${PAYPAL_CLIENT_ID}&response_type=code&scope=openid%20profile%20email%20address%20https%3A%2F%2Furi.paypal.com%2Fservices%2Fpaypalattributes&redirect_uri=${PAYPAL_REDIRECTION_URL}";