
const dailyParking = {
    always: [
        { startHour: [0, 0], endHour: [23, 59], active: true },
        { startHour: [0, 0], endHour: [23, 59], active: true },
        { startHour: [0, 0], endHour: [23, 59], active: true },
        { startHour: [0, 0], endHour: [23, 59], active: true },
        { startHour: [0, 0], endHour: [23, 59], active: true },
        { startHour: [0, 0], endHour: [23, 59], active: true },
        { startHour: [0, 0], endHour: [23, 59], active: true },
      ],

      workdays: [
        { startHour: [8, 0], endHour: [18, 0], active: false },
        { startHour: [8, 0], endHour: [18, 0], active: true },
        { startHour: [8, 0], endHour: [18, 0], active: true },
        { startHour: [8, 0], endHour: [18, 0], active: true },
        { startHour: [8, 0], endHour: [18, 0], active: true },
        { startHour: [8, 0], endHour: [18, 0], active: true },
        { startHour: [8, 0], endHour: [18, 0], active: false },
      ],
 
}

export default dailyParking;