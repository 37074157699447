import React from "react";
import { Grid, Text, Button } from "platyplex_ui";
import { primaryColor } from "../../../../../config";
import Bar from "../../../../checkout/components/bar";
import pciDss from "../../../../../assets/pci-dss-compliant.jpg";
import { useSelector } from "react-redux";

const BankInfo = ({ next }: { next: () => void }) => {
    const { currency, country } = useSelector((s: any) => s.user.details);
    return ( 
      <Grid placeItems="stretch" placeSelf="stretch">
        <Text textAlign="left" bold>
          Bank Transfer in {currency}
        </Text>
        <ul>
          <li className="txt-left">Get paid in 5-7 business days</li>
          <li className="txt-left">No fees</li>
        </ul>
        <Bar />
  
        <Text textAlign="left" bold>
          Safe and Secure
        </Text>
        <Text textAlign="left">
          We strive to ensure the safety and security of your sensitive
          information
        </Text>
        <Text textAlign="left">
          <i className="fa fa-check-circle" style={{ color: primaryColor }} />{" "}
          &nbsp; ParkStash is PCI DSS Compliant
        </Text>
  
        <Text textAlign="left">
          <i className="fa fa-check-circle" style={{ color: primaryColor }} />{" "}
          &nbsp; Industy standard encryption algorithms
        </Text>
        <img src={pciDss} alt="pci-dss-compliant" style={{ maxWidth: "100%" }} />
        <Text textAlign="left">
          By clicking Next, you agree to{" "}
          <a
            target="_"
            href={
              country === "CA"
                ? "https://stripe.com/en-ca/ssa"
                : "https://stripe.com/ssa"
            }
          >
            Stripe Services Agreement
          </a>{" "}
          and the{" "}
          <a
            target="_"
            href={'https://stripe.com/connect-account/legal'}
          >
          Stripe Connected Account Agreement
         </a>
        </Text>
  
        <Button rounded onClick={next}>
          Next
        </Button>
      </Grid>
    );
  };
  
  export default BankInfo