import { pid } from 'process';
import { useState, useEffect } from 'react';
import API from '../api'
import {  Parking } from '../model/domain/parking';
import { asyncHandler } from "../utils";

interface State {
  loading: boolean;
  error: boolean;
  parking?: Parking;
}

export const useParking = (pId?: string) => {
  const [state, setter] = useState<State>({
    parking: undefined,
    loading: false,
    error: false,
  });

  const setState = (obj: Partial<State>) => setter({ ...state, ...obj });

  const getParkings = async () => {
    if(pId){
    setState({ loading: true, error: false });
    const { success, parking } = await asyncHandler({
      apiCall: () => API.getOneParking(pId),
    });

    setState({ loading: false, error: !success, parking: parking });
    }
  };

  useEffect(() => {getParkings();}, [pid]);

  return state;
};

export default useParking;