import React from "react";
import { Card, Text, Grid } from "platyplex_ui";
import { Rate } from "antd";
import { disabledTxtColor, primaryColor } from "../../../../config";
import { NearbyParking } from "../../../../model/domain/parking";
import Images from "../../../../components/images";
import ShareImg from "../../../../assets/share_icon.png";

const Parking = (props: any) => {
  const {
    owner,
    image_url,
    spot_type,
    _id,
    rate_rounded_pretty,
    avg_host_rating,
    rating_count,
    name,
    total_unrounded_pretty,
    minutes_walk_pretty
  }: NearbyParking | any = props.parking;
  const { share, select, selectMarker, unSelectMarker } = props;

  return (
    <Card
      onMouseOver={selectMarker}
      onMouseLeave={unSelectMarker}
      className="noselect pointer highlight slide-up shadow"
      margin="0.75em"
      onClick={() => select(props.parking)}
      style={{
        width: "100%",
       // borderBottom: ".1em solid " + disabledTxtColor,
        borderRadius: 0,
      }}
    >
      <Grid customCols="auto 1fr" placeItems="start stretch" mobileLayout={false}>
        <Grid placeSelf="stretch" style={{ paddingRight: "1em" }}>
          {image_url?.length > 0 ? (
            <Images style={{ width: "10em", height: '10em' }} images={image_url} hideIndex={image_url?.length > 5} />
          ) : (
            <i
              style={{ fontSize: "9em", color: disabledTxtColor }}
              className="fas fa-parking"
            ></i>
          )}
        </Grid>
        <Grid placeItems="stretch">
          <Grid gridGap="0" placeItems="stretch">
            <Text textAlign="left" style={{ fontSize: "1.25em" }}>
              <Grid customCols="auto 30px" gridGap="0" placeItems="stretch">
                <Text textAlign="left" bold className="capitalize" style={{
                  textOverflow: 'ellipsis', overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}>
                  {name || owner?.user_name + "'s " + spot_type} &nbsp;
                </Text>
                {/* <img
                  src={ShareImg}
                  onClick={() => share(_id)}
                  style={{ width: "30px", height: "30px" }}
                  alt={'Share'}
                /> */}
              </Grid>
            </Text>

            <Text color="gray" textAlign="left">
              {avg_host_rating.toFixed(1)}{" "}
              <Rate disabled defaultValue={0} value={avg_host_rating} /> (
              {rating_count})
            </Text>

            <Text color="gray" textAlign="left">
              {minutes_walk_pretty}
            </Text>
          </Grid>

          <Grid
            placeSelf="stretch"
            placeItems="start"
            gridGap=".5em"
            customCols="1fr auto"
          >
            <Text bold color="dodgerblue" placeSelf="end start" className='hide-mobile'>
              View Details
            </Text>
            <span>
              <Text
                bold
                color={primaryColor}
                textAlign="right"
                style={{ fontSize: "1.15em" }}
              >
                {rate_rounded_pretty}
              </Text>
              <Text color={disabledTxtColor} textAlign="right">
                {total_unrounded_pretty} total
              </Text>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Parking;
