import {Grid, Text, Notification, Button } from "platyplex_ui";
import { primaryColor } from "../../../config";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import api from "../../../api";
import { Input } from "antd";
import { useFormik } from "formik";

const Promo = () => {
  const [message, setMessage] = useState<undefined | string>(undefined);

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    initialValues: { promo_name: "", loading: false },
    async onSubmit(values) {
      setFieldValue('loading', true)
      api
        .addPromo(values)
        .then((res: any) => {
          if (!res.success) {
            throw new Error(res.message);
          } else {
            Notification.success({ title: "Success", message: res.message });
            setMessage(res.message);
          }
        })
        .catch((e) =>
          Notification.error({ title: "Error", message: e.message })
        )
        .finally(() => setFieldValue('loading', false));
    },
  });


  const canSubmit = !values.loading && values.promo_name.length > 0;
  return (
    <Grid placeSelf="stretch" gridGap={".5em"}>
   
      <Grid customCols="1fr auto" placeSelf="stretch">
        <Link to="/wallet" style={{ placeSelf: "start" }}>
          <Text
            textAlign="left"
            color={primaryColor}
            size={"1.25em"}
            className="pointer"
          >
            <i className="fas fa-arrow-circle-left" /> &nbsp; Back
          </Text>
        </Link>
        <Text
          bold
          placeSelf="end"
          className="pointer"
          color={primaryColor}
        ></Text>
      </Grid>
      <i className="fas fa-gift txt-lgx" />
      <Text textAlign="left">Enter your referal/promo code below</Text>
      <form onSubmit={handleSubmit}>
      <Grid customCols="1fr auto" mobileLayout={false}>
        <Input
          placeholder="Promo"
          name="promo_name"
          onChange={handleChange}
          style={{ height: "100%", background: "transparent" }}
        />

        <Button
          onClick={(e: any) => canSubmit && handleSubmit(e)}
          disabled={!canSubmit}
          loading={values.loading}
        >
          Apply
        </Button>
        {message && (
          <Text textAlign="left" color={primaryColor}>
            {message}
          </Text>
        )}
      </Grid>
      </form>
    </Grid>
  );
};

export default Promo;
