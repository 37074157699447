
import { getPlatformToken, getUserToken } from '../../utils/user';

const defaultHeader = {
  "Content-Type": "application/json",
  os: "web",
  platformtoken: getPlatformToken()
}
function header() {
  return { ...defaultHeader, usertoken: getPlatformToken() };
}

function authHeader() {
  return { ...defaultHeader, usertoken: getUserToken() };
}


function authUploadHeader() {
  return { ...defaultHeader, 'content-type': 'multipart/form-data', usertoken: getUserToken() };
}

function customHeader(obj: Object) {
  return { ...defaultHeader, ...obj };
}


export { header, authHeader, authUploadHeader, customHeader };