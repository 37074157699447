import React from "react";
import { Grid, Text } from "platyplex_ui";
import { primaryColor } from "../../../config";
import { Link } from "react-router-dom";
import Recharge from "../../../components/recharge";
const ParkstashWallet = () => {
  return (
    <>
      <Grid placeSelf="stretch">
        <Grid customCols="1fr auto" placeSelf="stretch" placeItems="stretch">
          <Link to="/wallet" style={{ placeSelf: "start" }}>
            <Text
              textAlign="left"
              color={primaryColor}
              size={"1.25em"}
              className="pointer"
            >
              <i className="fas fa-arrow-circle-left" /> &nbsp; Back
            </Text>
          </Link>
        </Grid>
        <Recharge />
      </Grid>
    </>
  );
};

export default ParkstashWallet;
