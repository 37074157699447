import { useState, useEffect } from "react";
import API from "../api";
import { asyncHandler } from "../utils";
import { defaultLocation } from "../config";

export interface Enterprise {
  id: string;
  title: string;
  tz: string;
  geo: [number, number];
  imageUrl: string;
  imageUrl_mapMarker: string;
  busUrl?: string;
}

interface Props {
  enterprises: Enterprise[];
  loading: boolean;
  error: boolean;
}
export const useEnterprise = ({
  lat = defaultLocation.lat,
  long = defaultLocation.lng,
}) => {
  const [state, setter] = useState<Props>({
    enterprises: [],
    loading: false,
    error: false,
  });

  const setState = (obj: Partial<Props>) => setter({ ...state, ...obj });

  const getEnterprise = async () => {
    setState({ loading: true, error: false });
    const res = await asyncHandler({
      apiCall: () => API.getEnterprise(lat, long),
    });
    const { success, enterprises } = res;

    setState({ loading: false, error: !success, enterprises: enterprises || [] });
  };

  useEffect(() => {
    if (lat !== -1 && long !== -1) getEnterprise();
  }, [lat, long]);

  return state;
};

export default useEnterprise;
