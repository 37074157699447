import React, { useState } from "react";
import { Badge, Button, Empty, Grid, List, Text } from "platyplex_ui";
import { disabledTxtColor, gradient, primaryColor } from "../../../../config";
import { Enterprise } from "../../../../hooks/enterprise";
import useEnterpriseGarages from "../../../../hooks/enterprise-resource";
import { getGoogleMapLink } from "../../../../utils/map-link";
import { Garage } from "../../../../model/domain/garage";
import Bar from "../../../checkout/components/bar";
import { Badge as NotificationBadge} from 'antd';

interface Props {
  enterprise: Enterprise;
  toggleAnnoucements: ()=> void
}
const Garages = ({ enterprise, toggleAnnoucements }: Props) => {
  const { garages, reload, loading, unseenActiveAnnouncementsCount } = useEnterpriseGarages(
    enterprise.id,
    "garages"
  );
  const hasData = !loading && garages.length > 0;
  return (
    <Grid
      customRows={loading ? "auto auto 1fr 1fr" : "auto auto auto 1fr"}
      placeItems="stretch"
      style={{ maxHeight: "95vh" }}
    >
       <Text textAlign="right" width="100%">
          <NotificationBadge count={unseenActiveAnnouncementsCount}>
            <i
              className="fa fa-bell txt-md pointer"
              style={{ color: primaryColor }}
              onClick={()=>toggleAnnoucements()}
            /></NotificationBadge>
          </Text>
      <Grid>
        <img
          src={enterprise.imageUrl}
          alt="logo"
          style={{ maxWidth: "100%", width: 175, height: "auto" }}
        />
      </Grid>

      <Grid
        customCols={!hasData ? "1fr" : "2fr 1fr"}
        gridGap=".5em"
        style={{
          maxHeight: "100%",
          overflowY: hasData ? "auto" : "hidden",
          fontSize: "1em",
        }}
        placeItems={!hasData ? "center" : "start stretch"}
        mobileLayout={false}
      >
        {!loading && (
          <Text bold size='1.2em' style={{ gridColumn: "1/-1" }}>
            {enterprise.title} Parking Garage Status
          </Text>
        )}
        {hasData && (
          <>
            <Text textAlign="left" color={disabledTxtColor}>
              &nbsp;Garages
            </Text>
            <Text textAlign="right" color={disabledTxtColor}>
              % Capacity&nbsp;
            </Text>
          </>
        )}
        {
          <List
            empty={<Empty text="No Garage found." />}
            loading={loading}
            map={(g: Garage) => <GarageItem garage={g} />}
            list={garages}
          />
        }
      </Grid>

      <Grid placeSelf="end stretch" placeItems="end stretch">
        {enterprise.busUrl && (
          <a
            href={enterprise.busUrl}
            target="_"
            style={{ placeSelf: "center start" }}
          >
            <Button
              rounded
              href={enterprise.busUrl}
              noHover
              bg={"#E5A823"}
              padding={".5em 1em"}
              disabled={loading}
              loading={loading}
            >
              Show Bus Routes <i className="fas fa-chevron-right" />
              <i className="fas fa-chevron-right" />
            </Button>
          </a>
        )}

        <Button
          rounded
          noHover
          bg={gradient}
          padding={".5em"}
          onClick={reload}
          style={{ marginBottom: "1em" }}
          disabled={loading}
          loading={loading}
        >
          Refresh
        </Button>
      </Grid>
    </Grid>
  );
};

const GarageItem = ({ garage: g }: { garage: Garage }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      <Bar style={{ gridColumn: "1/-1" }} />
      <Badge
        onClick={() => setCollapsed(!collapsed)}
        className="med pointer"
        style={{
          fontSize: "1em",
          background: g.extras?.displayColor || primaryColor,
        }}
      >
        {g.name}
      </Badge>
      <Grid
        mobileLayout={false}
        customCols="3em 1fr"
        className='pointer'
        onClick={() => setCollapsed(!collapsed)}
      >
        <a target="_" href={getGoogleMapLink(g.address, g.geo[1], g.geo[0])}>
          <i
            className="fas fa-location-arrow pointer hoverr"
            style={{ color: primaryColor }}
          />
        </a>
        {g.extras?.statusText}
      </Grid>
      {!collapsed && (
        <div style={{ gridColumn: "1/-1" }}>{g.extras?.detailText}</div>
      )}
    </>
  );
};

export default Garages;
