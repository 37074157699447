import imageCompression from "browser-image-compression";
import { Notification } from "platyplex_ui";
export const getBase64 = (
  file: File | Blob
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const compressImage = async (imageFile: File): Promise<File> => {

  const options = {
    maxSizeMB: 3,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    let compressedFile: any = await imageCompression(imageFile, options);
    compressedFile.name = 'img.jpg'
    return compressedFile;
  } catch (error) {
    Notification.error({ title: "Error", message: error.message });
    return imageFile;
  }
};

export const blobToFile = (theBlob: Blob, fileName:string): File => {
  let b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>b;
}