import { message } from "antd";
import { useEffect, useState } from "react";
import API from "../api";
import { asyncHandler } from "../utils";

export interface Recharge {
  amountPretty: string;
  amount: number;
}

export interface RechargeReview {
  fee: string;
  total: string;
  currentWalletBalance: string;
  rechargeWalletBy: string;
}

interface State {
  recharges: Recharge[];
  loading: boolean;
  error: boolean;
  success: boolean;
  rechargeReview?: RechargeReview;
}

export const useRecharge = (defaultAmount = -1) => {
  const [rechargeAmount, setRechargeAmount ] = useState(defaultAmount);
  const [state, setter] = useState<State>({
    recharges: [],
    loading: false,
    error: false,
    success: false,
    rechargeReview: undefined,
  });

  const setState = (obj: Partial<State>) => setter({ ...state, ...obj });

  const getRecharges = async () => {
    setState({ loading: true, error: false });
    const { success, amountBoxes } = await asyncHandler({
      apiCall: () => API.getRecharges(),
    });

    setState({
      loading: false,
      error: !success,
      recharges: amountBoxes || [],
    });
  };

  const reviewWallet = async (amount: number) => {
    setState({ loading: true, error: false });
    const { success, review, message: m } = await asyncHandler({
      apiCall: () => API.reviewWallet(amount),
    });

    if(!success) message.error(`Error: ${m || 'Failed'}`)
    setState({
      loading: false,
      error: !success,
      rechargeReview: review,
    });

  
  };

  const rechargeWallet = async (cardId: string) => {
    if (rechargeAmount > 0) {
      setState({ loading: true, error: false });
      const { success } = await asyncHandler({
        apiCall: () => API.rechargeWallet(rechargeAmount, cardId),
      });

      const { review } = await asyncHandler({
        apiCall: () => API.reviewWallet(10000),
      });

      setState({
        loading: false,
        error: !success,
        success,
        ...(success
          ? {
              rechargeReview: review,
            }
          : {}),
      });
      
      if(!!success){
        setRechargeAmount(-1);
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
   // getRecharges();
  }, []);

  useEffect(() => {
    if(rechargeAmount > 0)
      reviewWallet(rechargeAmount);
  }, [rechargeAmount]);

  return {
    ...state,
    rechargeAmount,
    setRechargeAmount,
    rechargeWallet,
  };
};

export default useRecharge;
