import React, {  useEffect, useState } from "react";
import { Grid, Text, Button, Header, Notification, Modal, List } from "platyplex_ui";
import { disabledTxtColor2,  primaryColor, primaryTxtColor, } from "../../../../config";
import { BookingHistory, Vehicle } from "../../type";
import Images from "../../../../components/images";
import Avatar from "../../../../components/avatar";
import DailyParking from "./daily-parking";
import dailyParking from "../../../../utils/daily-parking";
import useCopy from "../../../../hooks/copy";
import VehicleModal from "../../../../components/vehicle-modal";
import NewModal from "../../../../components/new-modal";
import api from "../../../../api";
import { formatAddress } from "../../../../utils/address";
import useConfig from "../../../../hooks/config";
import QRCode from "react-qr-code";
import PrimaryModal from "../../../../components/primary-modal";

const defaultValue = {
  daily_parking: {
    time: dailyParking.workdays,
  },
};

const BookingInfo = (props: {
  booking: BookingHistory;
  loading: boolean;
  close: () => void;
  reload: ()=> void;
  extend: (e: any)=> void;
  type: string;
}) => {
  const { loading, close, type: bookingType } = props;
  const { config } = useConfig();
  const phone = config?.help?.phone;
  const email = config?.help?.email;

  const { booking } = props;
  const { parking, owner, type } = booking;
  const [availability, setAvailability] = useState(false);
  const [hostInfo, setHostInfo] = useState(false);
  const { ref, copy } = useCopy();
  const canExtend = bookingType === "UPCOMING" && booking.can_extend;
  const [selectedVehicles, setSelectedVehicles] = useState<Vehicle[]>(booking.vehicles);

  const editVehicles = (vehicles: Vehicle[])=>{
    api.editBookingVehicle(booking._id, vehicles.map((v)=>v._id))
    .then((res)=>{
      if(res.success){
        props.reload();
      } else throw new Error(res.message || 'Error')
    })   
    .catch((e) => Notification.error({ title: "Error", message: e.message }));
  }

  const VehiclesModal = NewModal({
    modal: <VehicleModal 
    close={() => {VehiclesModal.close(); editVehicles(selectedVehicles)}} 
    selectedVehicles={selectedVehicles} 
    setSelectedVehicles={(v: Vehicle[])=>{
      setSelectedVehicles(v);

    }}
    maxCount={Number(booking.spot_count)}
    />,
  });

  const QRModal = Modal({
    title: '',
    hideCancel: true,
    modal:  <PrimaryModal onCancel={()=>QRModal.close()} > 
    <Grid>
    <Text bold>QR Code</Text>
    <QRCode value={booking.qrstring || `booking_id=${booking._id}`} />
    </Grid>
    </PrimaryModal>,
  });

  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: availability ? "1fr" : "auto auto 1fr",
        height: "100vh",
        maxWidth: "800px",
        background: "white",
        placeItems: "stretch",
      }}
    >
      {VehiclesModal.modal}
      {availability ? (
        <Grid padding="1em 2em" customRows="auto auto auto auto auto 1fr">
          <Grid placeSelf="start">
            <i
              className="fa fa-times bold pointer hoverr txt-md"
              onClick={() => setAvailability(false)}
              style={{ placeSelf: "start" }}
            />
          </Grid>
          <Header color={primaryColor} bold>
            Your Parking Schedule
          </Header>
          <Text textAlign="left">
            You will be parking in this spot on the following days and time in a
            week
          </Text>
          <DailyParking daily_parking={defaultValue.daily_parking} />
          <Text textAlign="left">
            To request a change in weekly schedule please email us at{" "}
            <a href={`mailto:${email}`}>
              {email}
            </a>
            . We cannot gaurentee a change after you have booked a spot but will
            try our best to adjust if they is any possibility.
            <Text textAlign="left">Applicable fees may apply</Text>
          </Text>
        </Grid>
      ) : hostInfo ? (
        <>
          <Grid padding="1.5em">
            <i
              className="fa fa-times bold pointer hoverr txt-md"
              onClick={() => setHostInfo(false)}
              style={{ placeSelf: "start" }}
            />
          </Grid>
          <Grid padding="1em 2em" customRows="auto auto auto auto 1fr">
            <Header bold>This Spot is hosted by</Header>
            <Avatar src={booking.owner?.image_url} />

            <Text textAlign="left" bold className="capitalize">
              {booking.owner?.user_name}
            </Text>

            <Text textAlign="left" bold>
              Having issues? Please contact ParkStash support and we will strive
              to resolve any issues right away!
            </Text>
            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Call Us
              </Text>

              <a href={`tel:+${phone}`}>
                <i
                  className="fa fa-chevron-right pointer hoverr"
                  style={{ color: primaryTxtColor }}
                />
              </a>
            </FieldWrapper>

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Text Us
              </Text>
              <a href={`tel:${phone}`}>
                <i
                  className="fa fa-chevron-right pointer hoverr"
                  style={{ color: primaryTxtColor }}
                />
              </a>
            </FieldWrapper>

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Email Us
              </Text>
              <a href={`mailto:${phone}`}>
                <i
                  className="fa fa-chevron-right pointer hoverr"
                  style={{ color: primaryTxtColor }}
                />
              </a>
            </FieldWrapper>
          </Grid>
        </>
      ) : (
        <>
          <Grid padding="1.5em">
            <i
              className="fa fa-times bold pointer hoverr txt-md"
              onClick={close}
              style={{ placeSelf: "start" }}
            />
          </Grid>

          <Grid
            placeSelf="stretch"
            style={{ maxHeight: "100%", overflowY: "auto", fontSize: "1em" }}
            padding="0 1.5em 1.5em 1.5em"
            placeItems="stretch"
          >
            <Images
              images={parking.image_url || []}
              style={{ width: "100%", height: "auto", maxWidth: "50vw" }}
            />
            <Text bold className="capitalize">
              {parking.name || `${owner.user_name}'s ${parking.spot_type}`}
            </Text>


            <FieldWrapper>
              <Text textAlign="left" bold>
                Booking ID
              </Text>
              <Grid width='100%' customCols='1fr auto' placeItems='stretch'>
                <Text textAlign="left">{booking.par_id}</Text>
                <span className='bold pointer' style={{color: 'dodgerblue'}} onClick={QRModal.open}>QR code</span>
              </Grid>
            </FieldWrapper>


            <FieldWrapper>
              <Text textAlign="left" bold>
                Parking From
              </Text>
              <Text textAlign="left">{booking.pretty_start}</Text>
            </FieldWrapper>

            <FieldWrapper customCols="1fr auto">
              <Text>
                <Text bold textAlign="left">
                  Parking End
                </Text>
                <Text textAlign="left">{booking.pretty_end}</Text>
              </Text>
              <Text
                color={canExtend? "blue" : "gray"}
                bold
                className="pointer"
                onClick={()=> canExtend? props.extend(booking): {}}
              >
                Extend
              </Text>
            </FieldWrapper>

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                {" "}
                Assigned Spot{" "}
              </Text>
              <Text>{booking.assigned_parking_spot_no === -1? 'Any Available': ('#' + booking.assigned_parking_spot_no)}</Text>
            </FieldWrapper>
            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                {" "}
                Number of Spots{" "}
              </Text>
              <Text>{booking.spot_count}</Text>
            </FieldWrapper>
            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Vehicle Info
              </Text>
              <Text
                textAlign="right"
                bold
                color={bookingType === "UPCOMING" ? "blue" : "grey"}
                className="pointer"
                onClick={() => {
                  if (bookingType === "UPCOMING") {
                    VehiclesModal.open();
                  }
                }}
              >
                Update
              </Text>
              <List 
                   empty={<Text style={{gridColumn: '1/-1'}} textAlign='left'>No Vehicle</Text>}
              list={booking.vehicles? booking.vehicles: [booking.vehicle] }
              map={(v: Vehicle)=>
              <Text textAlign="left" color="inherit" key={v._id} style={{gridColumn: '1/-1'}}>
              {`${v.color} ${v.year} ${v.model}`}{" "}
              <br />
              License Plate #{v.license_plate}
            </Text>}
             />
            </FieldWrapper>

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                {" "}
                Address{" "}
              </Text>
              <a
            target="_blank"
            href={
              `https://www.google.com/maps/place/${formatAddress(booking.parking.address)}` 
            }
          >
                <Text textAlign="right" bold color="blue" className="pointer">
                  Navigate
                </Text>
              </a>
              <div ref={ref}>
                <Text textAlign="left">
                  {booking.parking.address.street},{" "}
                  {booking.parking.address.city},{" "}
                </Text>
                <Text textAlign="left">
                  {" "}
                  {booking.parking.address.state}{" "}
                  {booking.parking.address.zipcode},
                </Text>
              </div>
              <Text
                textAlign="right"
                bold
                color="blue"
                className="pointer"
                onClick={() => copy(formatAddress(booking.parking.address))}
              >
                Copy Address
              </Text>
            </FieldWrapper>

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                {" "}
                Host Info{" "}
              </Text>
              <i
                className="fa fa-chevron-right pointer hoverr"
                onClick={() => setHostInfo(true)}
              />
            </FieldWrapper>

            {type !== "daily" && (
              <FieldWrapper customCols="1fr auto">
                <Text textAlign="left" bold>
                  {" "}
                  Your Parking Schedule{" "}
                </Text>
                <i
                  className="fa fa-chevron-right pointer hoverr"
                  onClick={() => setAvailability(true)}
                />
              </FieldWrapper>
            )}

            <FieldWrapper customCols="1fr">
              <Text textAlign="left" bold>
                Description
              </Text>
              <Text textAlign="left" color="inherit">
                {booking.parking.description}
              </Text>
            </FieldWrapper>

            <FieldWrapper customCols="1fr">
              <Text textAlign="left" bold>
                Access Instruction
              </Text>
              <Text textAlign="left" color="inherit">
                {booking.parking.instruction}
              </Text>
            </FieldWrapper>

            <FieldWrapper customCols="1fr">
              <Text textAlign="left" bold>
                Terms & Condition
              </Text>
              <Text textAlign="left" color="inherit">
                {booking.parking.terms_and_conditions}
              </Text>
            </FieldWrapper>

            <FieldWrapper customCols="1fr">
              <Text textAlign="left" bold>
                Payment Details
              </Text>
              <Text textAlign="left" color="inherit">
                Total Amount Paid {booking.transaction.pretty_total}
              </Text>
            </FieldWrapper>

            {booking.transaction.security_deposit !== 0 &&
            <FieldWrapper customCols="1fr">
              <Text textAlign="left" bold>
                Security Deposit
              </Text>
              <Text textAlign="left" color="inherit">
              {booking.transaction.pretty_security_deposit}
              </Text>
            </FieldWrapper>}

            <FieldWrapper customCols="1fr auto">
              <Text textAlign="left" bold>
                Spot Type
              </Text>
              <Text textAlign="right" color="inherit" className="capitalize">
                {booking.parking.spot_type}
              </Text>
            </FieldWrapper>
          </Grid>
          {(
            <Grid placeSelf="stretch" placeItems="end stretch">
              <Button
                rounded
                disabled={!canExtend}
                onClick={()=>props.extend(booking)}
                style={{
                  padding: ".5em",
                  margin: "1.5em",
                }}
              >
                Extend
              </Button>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

const FieldWrapper = ({ children, gridGap, customCols }: any) => (
  <Grid
    style={{ borderBottom: "1px solid " + disabledTxtColor2 }}
    padding="0 0 1em 0"
    placeSelf="stretch"
    placeItems="stretch"
    customCols={customCols || ""}
    gridGap={gridGap || "0.5em"}
  >
    {children}
  </Grid>
);
export default BookingInfo;
