
import { useState, useEffect } from "react";
import API from "../api";
import { asyncHandler} from "../utils";
export interface Review {
    ratings?: any[];
    avg_host_rating: number;
    review_count: number;
    rating_count: number;
    parking_id: string;
  }

interface Props {
    reviews? : Review;
    loading: boolean;
    error: boolean;
}
  
export const useReviews = (id: string) => {
  const [state, setter] = useState<Props>({
    reviews: undefined,
    loading: false,
    error: false,
  });

  const setState = (obj: any) => setter({ ...state, ...obj });

  const getReviews = async () => {
    setState({ loading: true, error: false });
    const res = await asyncHandler({
      apiCall: () => API.getReviews(id),
    });
    const { success, data } = res;
    setState({ loading: false, error: !success, reviews: data });
  };

  useEffect(() => {
    getReviews();
  }, [id]);

  return state;
}

export default useReviews;