import { authGet, authPost } from "./utils/request";
import { API } from "../config";

const route = (r: string) => API + r;

let booking = {
    getBookingHistory: (query: string) => authGet(route(`/v3/booking-thread?${query}`)),
    cancelBooking: (id: string) => authPost(route(`/booking-thread/${id}/cancel`), {}, false),
    checkCancelBooking: (id: string) => authGet(route(`/booking-thread/${id}/cancel`), false),
    editBookingVehicle: (id: string, vehicle_ids: string[]) => authPost(route(`/booking-thread/${id}/change-vehicle`), { vehicle_ids }, false),
    reviewBookingExtension:(id: string, bookingProps: any) => authPost(route(`/booking-thread/${id}/review-booking-extension`), bookingProps, false),
    extendBooking: (id: string, bookingProps: any) => authPost(route(`/booking-thread/${id}/extend`), bookingProps, false),
    getBookingPopup: () => authGet(route(`/booking-thread/popup-thread`)),
    reviewBooking: (bookingProps: any) => authPost(route(`/v2/bookings/review-booking`), bookingProps, false),
    book: (transaction: any) => authPost(route(`/v2/bookings/book`), transaction)
};

export default booking;
