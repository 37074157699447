import { Card, Grid, Text, Button, List } from "platyplex_ui";
import React from "react";
import {  disabledTxtColor } from "../../../../config";
import { Review } from "../../type";
import NewModal from "../../../../components/new-modal";
import { Vehicle } from "../../../booking-history/type";
import { useSelector } from "react-redux";
import VehicleModal from "../../../../components/vehicle-modal";
import Bar from "../bar";

interface Props {
  review: Review;
  spot_count: number;
  active: boolean;
  next: () => void;
  edit: () => void;
}
const BookingReview = ({ review, active, next, edit, spot_count }: Props) => {
  const { vehicles, loading,  selectedVehicles} = useSelector(
    (state: any) => state.vehicles
  );

  const VehiclesModal = NewModal({
    modal: <VehicleModal close={() => VehiclesModal.close()} maxCount={spot_count}/>,
  });
  return (
    <Card shadow grid placeItems="start">
      {VehiclesModal.modal}
      <Grid
        customCols="1fr auto"
        width="100%"
        mobileLayout={false}
        placeItems="center stretch"
      >
        <Text textAlign="left" bold style={{ fontSize: "1.5em" }}>
          <i
            className="fas fa-check-circle"
            style={{
              color: !active ? "green" : disabledTxtColor,
            }}
          />{" "}
          Booking Review
        </Text>
        {!active && (
          <Text className="pointer" bold onClick={edit}>
            Edit
          </Text>
        )}
      </Grid>

      {active && (
        <>
          <Bar />
          <Text textAlign="left" bold>
            Duration
            <Text textAlign="left">
              {review?.start}
              &nbsp;-&nbsp;
              {review?.end}
            </Text>
          </Text>
          <Bar />
          <Text textAlign="left"><span className='bold'>Number of Spots: </span>{spot_count}</Text>
          <Bar />
          <Text textAlign="left" bold>
            Your Vehicle Info
            
            <List
              loading={loading}
              
              empty={
                <Text textAlign="left" color="red">
                  No vehicle present. Please add a vehicle to continue.
                  <Text
                    className="pointer"
                    color="blue"
                    textAlign="left"
                    onClick={VehiclesModal.open}
                  >
                    Add Vehicle
                  </Text>
                </Text>
              }
              list={selectedVehicles ||  []}
              map={(vehicle: Vehicle, i: number) => (
                <Grid
                  key={vehicle?._id}
                  margin={'.5em 0'}
                  customCols="1fr auto"
                  placeItems="stretch"
                  gridGap="0em 1em"
                >
                  <VehicleFormat vehicle={vehicle} />
                  {i === 0 && <Text
                    className="pointer"
                    color="blue"
                    onClick={VehiclesModal.open}
                  >
                    Change/Add Vehicle
                  </Text>}
                </Grid>
              )}
            />
          </Text>

          <Text textAlign="left" color="red">
              Don't get towed or ticketed. Confirm your vehicle details before
              you park.
            </Text>
          <Bar />
          <Text textAlign="left" bold>
            Booking Type
            <Text textAlign="left" className="capitalize">
              {review.type}
            </Text>
          </Text>

          <Button rounded onClick={next} disabled={vehicles.length === 0}>
            {" "}
            Confirm
          </Button>
        </>
      )}
    </Card>
  );
};

const VehicleFormat = ({ vehicle }: { vehicle: Vehicle }) => {
  if(!vehicle?._id) return null;
  const { color, model, year, license_plate, vehicle_class } = vehicle;
  return (
    <>
      <Text
        textAlign="left"
        className="capitalize"
        style={{ gridColumn: "1/-1" }}
      >
        {`${year} ${color} ${model}`}
      </Text>
      <Text textAlign="left" className="capitalize">
        {`Liscense Plate# ${license_plate} | ${vehicle_class.replace(
          "_",
          " "
        )}`}
      </Text>
    </>
  );
};

export default BookingReview;
