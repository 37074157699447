import React from "react";
import { Grid, Placeholder, List, Text, Modal, Empty, Button } from "platyplex_ui";
import Parking from "./parking";
import Share from "../share";
import { Select } from "antd";
import { gradient, primaryColor } from "../../../../config";
import { NearbyParking } from "../../../../model/domain/parking";
import { setAddressData } from "../../../../store/actions/search";
import { useDispatch } from "react-redux";

interface Props {
  parkings: NearbyParking[];
  loading: boolean;
  setActiveParking:(i: number)=> void;
  setOpenParking: (p: NearbyParking)=> void;
  reloadParkings: ()=> void;
  query: any
}
const ParkingList = ({ parkings, loading, setActiveParking, setOpenParking, query , reloadParkings}: Props) => {
  const dispatch = useDispatch();
  const ShareModal = (id: string) =>
    Modal({
      title: "Share Garage",
      message: <Share id={id} />,
      hideCancel: true,
    }).open();

  return (

    <Grid
      placeItems="start stretch"
      padding="1em"
      className='hide-mobile shadow'
      height="100%"
      customRows={parkings?.length === 0 ? "auto auto" : "auto 1fr"}
      background={"white"}
      style={{
        height: "calc(100vh - 12em)",
        overflowY: "auto",
        maxWidth: "90vw",
        width: "550px",
      }}
    >
      <Grid width="100%">
        <Text bold textAlign="left" width="100%" padding="0 0 0 .5em">
          Sort By &nbsp; <Select defaultValue="distance" style={{ color: primaryColor }}>
            <Select.Option value='distance'>Distance</Select.Option>
          </Select>
        </Text>
      </Grid>
      <div style={{ height: "100%" }}>
        <List
          loading={loading}
          loader={<Placeholder number={5} style={{ width: "100%" }} />}
          list={parkings.filter((p: any) => !p.isSold)}
          empty={
            <>
              <Empty text={`No Results. Please Try again.`} color={primaryColor} style={{ marginTop: 0 }} />
              <Button
                rounded
                noHover
                bg={gradient}
                padding={".5em"}
                onClick={()=>reloadParkings()}
                style={{ margin: "1em" }}
                disabled={loading}
                loading={loading}
              >
                Refresh
              </Button>
            </>}
          map={(parking: NearbyParking, i: number) =>
            <Parking
              parking={parking}
              key={i}
              share={ShareModal}
              selectMarker={() => {
                dispatch(setAddressData({
                  geo: {
                    lng: parking.geo[0],
                    lat: parking.geo[1]
                  }
                }, false, false))
                setActiveParking(i)
              }
              }
              unSelectMarker={() => setActiveParking(-1)}
              select={(p: NearbyParking) => {
                setOpenParking(p);
              }}
              query={query}
            />}
        />
      </div>
    </Grid>
  );
};

export default ParkingList;