import React from "react";
import { Grid, Text, Button } from "platyplex_ui";
import { disabledTxtColor } from "../../../../../config";
import Bar from "../../../../checkout/components/bar";

const PaypalInfo = ({ next }: { next: () => void }) => {
  return (
    <Grid
      placeItems="stretch"
      customRows="auto auto 1fr"
      placeSelf="stretch"
      gridGap={"2em"}
      height="100%"
    >
      <Grid placeItems="stretch" gridGap={"0.25em"}>
        <Text textAlign="left" bold>
          PayPal Payout
        </Text>
        <Text textAlign="left" color={disabledTxtColor}>
          How PayPal works with ParkStash Payout
        </Text>
      </Grid>

      <Grid placeItems="stretch" gridGap={"0.25em"}>
        <InfoItem
          icon="fa fa-check"
          heading="Connect your PayPal Account"
          description="Use an existing PayPal account or create a new one"
        />
        <InfoItem
          icon="fa fa-check"
          heading="Connect your PayPal Account"
          description="Use an existing PayPal account or create a new one"
        />
      </Grid>

      <Grid placeItems="end stretch" margin={"0 0 1em 0"}>
        <Button rounded onClick={next}>
          Login with Paypal &nbsp; <i className="fab fa-paypal txt-md"/>
        </Button>
      </Grid>
    </Grid>
  );
};

interface InfoItemProps {
  heading: string;
  icon: string;
  description: string;
}

const InfoItem = (props: InfoItemProps) => {
  return (
    <Grid placeItems="stretch" customCols="auto 1fr" mobileLayout={false}>
      <i className={props.icon} style={{ placeSelf: "center" }} />

      <div>
        <Text textAlign="left" bold>
          {props.heading}
        </Text>

        <Text textAlign="left" color={disabledTxtColor}>
          {props.description}
        </Text>
      </div>
      <Bar />
    </Grid>
  );
};
export default PaypalInfo;
