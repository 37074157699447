import { Grid } from "platyplex_ui";
import React, { useEffect, useState } from "react";
import { cloudinaryPrefix, disabledTxtColor, gray, primaryColor, top4 } from "../../config";
import Wrapper from "../overlay-wrapper";
import NewModal from "../new-modal";
import CloseBtn from "../close-btn";
const Images = ({ images, style, hideIndex, rounded = true}: any) => {
  const [state, setter] = useState({
    images,
    index: images.length > 0 ? 0 : -1,
  });

  const withPrefix = (src: string) => src?.includes(cloudinaryPrefix) ? src : cloudinaryPrefix + src;
  const setState = (obj: any) => setter({ ...state, ...obj });

  const changeIndex = (value: number) => {
    const newIndex = state.index + value;
    if (newIndex >= 0 && newIndex < state.images.length)
      setState({ index: newIndex });
  };

  const canChange = (newIndex: number) =>
    newIndex >= 0 && newIndex < state.images.length;

  const isActive = (active: boolean) =>
    active ? "shadow hoverr" : "disable hide";
  useEffect(() => setState({ images, index: 0 }), [images]);

  const ImageModal = NewModal({
    modal: (
      <Wrapper
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 1000,
          overflow: "hidden",
          overscrollBehavior: "contain",
          background: 'rgba(0, 0, 0, 0.85)',
        }}
        onCreate={()=> {document.body.style.overflow='hidden'}}
        onDestory={()=> {document.body.style.overflow='auto'}}
      >
        <CloseBtn close={() => { ImageModal.close() }} />
        <PointerWrapper
          left
          className={isActive(canChange(state.index - 1))}
          action={() => changeIndex(-1)}
          active={canChange(state.index - 1)}
        >
          <i className={"fa fa-chevron-left"} />
        </PointerWrapper>
        <img
          src={withPrefix(state.images[state.index])}
          style={{ maxWidth: "100vw", objectFit: 'cover', maxHeight: "90vh" }}
          alt=''
        />
        <Grid
         mobileLayout={false}
          customCols={`1fr repeat(${state.images.length},auto) 1fr`}
          gridGap=".25em"
          margin=".75em"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            margin: ".2em",
            width: "95vw",
          }}
        >
          {" "}
          <span></span>
          {!hideIndex && state.images.map((_: string, i: number) => (
            <span
              key={i}
              className="round"
              style={{ background: state.index === i ? primaryColor : disabledTxtColor, zIndex: 1 }}
            ></span>
          ))}
        </Grid>
        <PointerWrapper
          active={canChange(state.index + 1)}
          className={isActive(canChange(state.index + 1))}
          action={() => changeIndex(1)}
        >
          <i className="fa fa-chevron-right pointer" />
        </PointerWrapper>
      </Wrapper>
    ),
  });

  return (
    <div style={{ width: 108, height: 108, position: "relative", ...style }}>
      {ImageModal.modal}
      <PointerWrapper
        left
        className={isActive(canChange(state.index - 1))}
        action={() => changeIndex(-1)}
        active={canChange(state.index - 1)}
      >
        <i className={"fa fa-chevron-left"} />
      </PointerWrapper>
      <img
        src={withPrefix(state.images[state.index])}
        style={{ 
          width: 
          "100%", 
          height: "100%", 
          objectFit: 'cover',
           maxHeight: "30vh" ,
           borderRadius: rounded?  15: 0,
        }}
        alt=''
        onClick={() => ImageModal.open()}
      />
      <Grid
      mobileLayout={false}
        customCols={`1fr repeat(${state.images.length},auto) 1fr`}
        gridGap=".25em"
        margin=".75em"
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          margin: ".2em",
          width: "100%",
        }}
      >
        {" "}
        <span></span>
        {!hideIndex && state.images.map((_: string, i: number) => (
          <span
            key={i}
            className="round"
            style={{ background: state.index === i ? primaryColor : disabledTxtColor, zIndex: 1 }}
          ></span>
        ))}
      </Grid>
      <PointerWrapper
        active={canChange(state.index + 1)}
        className={isActive(canChange(state.index + 1))}
        action={() => changeIndex(1)}
      >
        <i className="fa fa-chevron-right pointer" />
      </PointerWrapper>

    </div>
  );
};

const PointerWrapper = ({ action, className, children, left, active }: any) => (
  <Grid
  mobileLayout={false}
    style={{
      position: "absolute",
      height: '100%',
      top: 0,
      ...left ? { left: 0 } : { right: 0 },
    }}
  >
    <div
      style={{
        margin: ".5em",
        background: active ? "white" : disabledTxtColor,
        padding: ".15em .5em",
        borderRadius: "50%",
      }}
      className={"pointer " + className}

      onClick={action}
    >
      {children}
    </div>
  </Grid>
);

export default Images;
