import { ActionTypes, UserAction } from '../reducer/user';
import { ActionTypes as SearchActionTypes, SearchAction } from '../reducer/search';
import api from '../../api';
import ErrorModal from '../../components/modal';
import React, { Dispatch } from "react";
import { Text, Card, Header,Notification } from 'platyplex_ui';
import { setInitQuery } from './search';
import { SearchNearbyParkingQuery } from '../../model/domain/parking';
import { getUserToken, isGuest } from '../../utils/user';
import { getRedirectUrl } from '../../pages/login';

export const asyncActions = async (
    apiCall: () => Promise<any>,
    dispatch: any,
    showError = false,
    modal = ErrorModal) => {
    let payload = null;
    try {
        dispatch({ type: ActionTypes.USER_REQUEST_PENDING });
        payload = await apiCall();
        if (!payload || !payload.success)
            throw new Error(payload?.message || "Error");

        dispatch({ type: ActionTypes.USER_REQUEST_SUCCESS })
        return await payload;
    } catch (e) {
        if (showError) {
            modal({ error: e })
        }
        dispatch({ type: ActionTypes.USER_REQUEST_FAILED })
        return payload
    }
};

 type Auth =  (hasfinished?: ()=> void)=> void

export const auth : Auth = (hasfinished) => {

    return async (dispatch: Dispatch<UserAction | SearchAction | any>) => {
        const payload = (await asyncActions(() => api.authUser(), dispatch));
       
        if (payload && payload.success) {
            let details = payload.user_details;
            dispatch({
                type: ActionTypes.AUTH,
                payload: { details }
            });
            const query = details.search_nearby_parking_preset as Partial<SearchNearbyParkingQuery>
            dispatch(setInitQuery(query));

        } else {
            dispatch(logout(false));
            dispatch(guestSignup())
        }

        if(hasfinished) hasfinished()   
    }
}

export const reloadUserDetails = auth;

export const login = (props: any, modal = ErrorModal) => {
    return async (dispatch: Dispatch<any>) => {
      
        const payload = await asyncActions(() => api.login_v3(props), dispatch, true, modal);
        if (payload && payload.success) {
            dispatch({
                type: ActionTypes.LOGIN,
                payload: {
                    token: payload.token,
                    details: payload.userDetails_2
                }
            });

            const query = payload.userDetails_2.search_nearby_parking_preset as Partial<SearchNearbyParkingQuery>
            dispatch(setInitQuery(query));

            dispatch({ 
                type: SearchActionTypes.SET_SEARCH, payload : {
                query: payload.userDetails_2.search_nearby_parking_preset
            }})
        }
    }
};

export const signup = (props: any, toggleLogin = () => {}) => {
    return async (dispatch: Dispatch<any>) => {
        props =    isGuest()? {
            ...props,
            guestAuthToken: getUserToken() 
        }: props;

       alert(isGuest())
        const payload = await asyncActions(async () => await api.signup_v3(props), dispatch, false);

        if (payload && payload.success) {
            dispatch(
            login({
                email: props.email,
                authType: props.authType,
                authSecret: props.authSecret,
            }));
        } else if (payload?.code >= 110 && payload?.code <= 113) {
            ErrorModal({
                error: payload?.message,
                buttonText: 'Login',
                buttonAction: toggleLogin,
                content: <Card margin={0} style={{ paddingTop: 0 }}>
                    <Header className='med' margin='0 0 .5em 0'>Account Already Exists</Header>
                    <Text >{payload?.message}</Text>
                </Card>
            }
            )
        } else {
            ErrorModal({ error: payload?.message });
        }
    }
}

export const guestSignup = () => {
    return async (dispatch: Dispatch<any>) => {
        const payload = await asyncActions(async () => await api.signup_v3({      authType: 'guest'}), dispatch, false);

        if (payload && payload.success) {
            dispatch({
                type: ActionTypes.GUEST_LOGIN,
                payload: {
                    token: payload.guestToken,
                    details: payload.userDetails_2
                }
            });

            const query = payload.userDetails_2.search_nearby_parking_preset as Partial<SearchNearbyParkingQuery>
            dispatch(setInitQuery(query));

         
        } else {
            ErrorModal({ error: payload?.message });
        }
    }
}

export const addPhone = (user_phoneno: string, usertoken: string, onSuccess?: () => void) => {
    return async (dispatch: Dispatch<UserAction> | any) => {
        const payload = await asyncActions(async () => await api.addPhone_v3(user_phoneno,), dispatch, true);
        if (payload && payload.success) {
            dispatch({ 
                type: ActionTypes.ADD_PHONE, 
                payload: { user_phoneno }
            })
            if (onSuccess) onSuccess();
        }
    }
}

export const verifyPhone = (otp: string) => {
    return async (dispatch: Dispatch<UserAction> | any) => {
        const payload = await asyncActions(async () => await api.verifyPhone(otp),
            dispatch,
            true,
            (e: any) => Notification.error({ title: 'Error', message: e?.message || 'Wrong OTP Code. Please Try again' }));
        if (payload && payload.success) {
            await dispatch({ type: ActionTypes.VERIFIED_OTP, payload: {} });
            await dispatch(auth())
        }
    }
}
export type OTPTypes = 'changePassword' | 'changePhone' | 'login';

export const verifyOtp = (otp: string) => {
    return async (dispatch: Dispatch<UserAction> | any) => {
        const payload = await asyncActions(async () => await api.verifyOtp(otp),
            dispatch,
            true,
            (e: any) => Notification.error({ title: 'Error', message: e?.message || 'Wrong OTP Code. Please Try again' }));
        if (payload && payload.success) {
            await dispatch({ type: ActionTypes.VERIFIED_OTP, payload: {} });
        }
    }
}

export const resendCode = (type: OTPTypes, props: any) => {
    return async (dispatch: Dispatch<UserAction>) => {
        await asyncActions(async () => await api.requestOtp(type, props), dispatch, true);
    }
}

export const logout = (notify = true) => async (dispatch: Dispatch<UserAction>| (any)) => {
    dispatch({ type: ActionTypes.LOGOUT, payload: {} });
    if(notify)Notification.success({ title: 'Success', message: 'Logged Out' });
    dispatch(guestSignup())
}

export const ssoLogout = () => async () => {
    window.location.href = getRedirectUrl(true)
}