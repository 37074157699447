import { Select, InputNumber, Input } from "antd";
import { useFormik } from "formik";
import { Grid, Text, Button, Notification } from "platyplex_ui";
import React from "react";
import { gradient, primaryColor } from "../../config";
import useVehicleClasses from "../../hooks/vehicle-classes";
import carsModel from "../.../../../json/car-models.json";
type Values = "year" | "color" | "model";

const vehicleFields = [
  {
    id: "year",
    label: "Year",
    placeholder: "Ex: 2019",
    type: "number",
  },
  {
    id: "color",
    label: "Color",
    placeholder: "Ex: Black",
  },

  {
    id: "license_plate",
    label: "License Plate Number",
    placeholder: "Ex: 293DEF",
  },
] as any;

const defaultValue = {
  brand: "",
  make: "",
  year: 2019,
  color: "",
  license_plate: "",
  vehicle_class: "mid_size_car",
  id: undefined,
};

const VehicleForm = ({
  close,
  addVehicle,
  loading,
  editVehicle,
  vehicle,
}: any) => {
  const {
    handleSubmit,
    handleChange,
    errors,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: { ...defaultValue, ...vehicle },
    async onSubmit(values) {
      const res =
        editVehicle && values._id
          ? await editVehicle(values, values._id)
          : await addVehicle(values);
      if (res.success) {
        close();
      } else {
        Notification.error({ title: "Error", message: res.message || "Error" });
      }
    },
  });

  const {
    vehicles: vehicleClasses,
    vehicleTypes,
    loading: loadingVehicles,
  } = useVehicleClasses();
  const error = vehicleFields.reduce(
    (acc: boolean, { id }: any) => acc || Boolean(errors[id as Values]),
    false
  );
  return (
    <Grid placeSelf="start center" placeItems="start center">
      <Text>Please enter your vehicle information below</Text>
      <form onSubmit={handleSubmit}>
        <Grid placeItems="stretch">

          <div style={{ paddingTop: ".5em" }}>
            <Text textAlign="left" color={primaryColor} bold>
              Model
            </Text>
            <Select
              style={{ minWidth: "50vw" }}
              onChange={(e) => setFieldValue("model", e)}
              placeholder={"Select a model"}
            >
              {carsModel.brandModels.map(({ models, brand }: any) => {
                return models.map((model: string) => (
                  <Select.Option value={`${brand} ${model}`}>
                    {`${brand} ${model}`}
                  </Select.Option>
                ));
              })}
            </Select>
          </div>

          {vehicleFields.map(
            ({
              id,
              label,
              placeholder,
              type,
            }: {
              id: Values;
              label: string;
              type: string | undefined;
              placeholder: string;
            }) => (
              <div style={{ paddingTop: ".5em" }} key={id}>
                <Text textAlign="left" color={primaryColor} bold>
                  {label}
                </Text>
                {type === "number" ? (
                  <InputNumber
                    style={{ minWidth: "50vw" }}
                    min={1500}
                    value={values.year}
                    onChange={(e) => setFieldValue("year", e)}
                  />
                ) : (
                  <Input
                    style={{ minWidth: "50vw" }}
                    name={id}
                    value={values[id]}
                    onChange={handleChange}
                    placeholder={placeholder}
                  />
                )}
              </div>
            )
          )}

          <div style={{ paddingTop: ".5em" }}>
            <Text textAlign="left" color={primaryColor} bold>
              Vehicle Type
            </Text>
            <Select
              style={{ minWidth: "50vw" }}
              loading={loadingVehicles}
              onChange={(e) => setFieldValue("vehicle_class", e)}
              value={values.vehicle_class}
            >
              {vehicleTypes.map((v) => (
                <Select.OptGroup label={v.displayName}>
                  {vehicleClasses
                    .filter((vehicle: any) => vehicle.category === v.category)
                    .map((vehicle: any) => (
                      <Select.Option value={vehicle.name}>
                        {vehicle.displayName}
                      </Select.Option>
                    ))}
                </Select.OptGroup>
              ))}
            </Select>
          </div>

          {loading ? (
            <Button
              loading
              disabled
              shadow
              style={{
                borderRadius: "1.3em",
                padding: ".5em",
                marginTop: "1em",
              }}
            >
              Loading
            </Button>
          ) : (
            <Button.Normal
              style={{
                borderRadius: "1.3em",
                padding: ".5em",
                marginTop: "1em",
                ...(!error
                  ? {
                      backgroundImage: `${gradient}`,
                      color: "white",
                      border: 0,
                    }
                  : {}),
              }}
              type="submit"
              disabled={error}
              onSubmit={(e: any) => handleSubmit(e)}
            >
              {addVehicle ? "Add" : "Save"}
            </Button.Normal>
          )}
        </Grid>
      </form>
    </Grid>
  );
};

export default VehicleForm;
