import { useState, useEffect } from "react";
import API from "../api";
import { Announcement } from "../model/domain/announcements";
import { Garage } from "../model/domain/garage";
import { asyncHandler } from "../utils";

export type Resource = "announcements" | "garages" | "info";
export type SocialMediaUrl = { iconUrl: string; media: string; url: string };

export interface EnterpriseFeature {
  feature: string;
  iconUrl: string;
  url?: string;
  isExternal: boolean;
  menuLabel: string;
  pageTitle: string;
}
interface Props {
  garages: Garage[];
  unseenActiveAnnouncementsCount: number;
  enterpriseFeatures: EnterpriseFeature[];
  socialMediaUrls: SocialMediaUrl[];
  announcements: Announcement[];
  loading: boolean;
  legend: any;
  error: boolean;
}

export const useEnterpriseResource = (eId: string, resource: Resource) => {
  const [state, setter] = useState<Props>({
    garages: [],
    enterpriseFeatures: [],
    socialMediaUrls: [],
    unseenActiveAnnouncementsCount: 0,
    announcements: [],
    legend: {},
    loading: false,
    error: false,
  });

  const setState = (obj: Partial<Props>) => setter({ ...state, ...obj });

  const getEnterpriseResource = async () => {
    const query =
      resource === "announcements"
        ? resource
        : resource === "garages"
        ? "coreAppEnterprisePageData?fields=garages,unseenActiveAnnouncementsCount"
        : "coreAppEnterprisePageData?fields=unseenActiveAnnouncementsCount,socialMediaUrls,enterpriseFeatures";

    setState({ loading: true, error: false });
    const res = await asyncHandler({
      apiCall: () => API.getEnterpriseResource(eId, query),
    });
    const { success } = res;
    const unseenActiveAnnouncementsCount =
      res?.unseenActiveAnnouncementsCount || 0;
    const legend = res?.legend || {};
    if (resource === "info") {
      setState({
        loading: false,
        error: !success,
        enterpriseFeatures: res.enterpriseFeatures || [],
        socialMediaUrls: res.socialMediaUrls || [],
        legend,
        unseenActiveAnnouncementsCount,
      });
    } else {
      setState({
        loading: false,
        error: !success,
        [resource]: res[resource] || [],
        legend,
        unseenActiveAnnouncementsCount,
      });
    }
  };

  useEffect(() => {
    if (eId) getEnterpriseResource();
  }, [eId]);

  return { ...state, reload: getEnterpriseResource };
};

export default useEnterpriseResource;
