import React from "react";
import { Grid, Text, Button, Header, Modal } from "platyplex_ui";
import { useFormik } from "formik";
import TextArea from "antd/lib/input/TextArea";
import ParkstashText from "../../../components/parkstash-text";
import { gradient, primaryColor } from "../../../config";
import { EditParkingModel } from "../../../model/domain/parking";
import Availability from "../components/availability";
import SpotType from "../components/spot-type";
import SpaceCount from "../components/space-count";
import Amenities from "../../../components/amenities";
import Pricing from "../components/pricing";
import Images from "../components/listing-images";
import Collapse from "../../../components/collapse";
import VehicleClasses from "../../../components/vehicle-classes";
import SecurityDeposit from "../components/security-deposit";
import Sizing from "../components/sizing";
import useCountry from "../../../hooks/country";
import { Tooltip, Input } from "antd";
import Bar from "../../checkout/components/bar";
import useCloseConfirm from "../../../hooks/close-confirm";
import FileUpload from "../../../components/file-upload";
import PrimaryModal from "../../../components/primary-modal";
import { hasVehicleClasses } from "../../../utils/vehicles";

const defaultValue: EditParkingModel = {
  spot_type: "garage",
  space_count: 1,
  instruction: "",
  is_spot_number_assignable: false,
  description: "",
  availability: "always",
  temp_image_filenames: [],
  remove_image_url: [],
  weekly: { active: true, price: 4000 },
  monthly: { active: true, price: 10000 },
  amenities: [],
  security_deposit: {
    active: false,
    price: 100,
  },
  hourly: { active: true, price: 100 },
  max_daily: { active: true, price: 1000 },
  parking_size: {
    width_ft: 0,
    length_ft: 0,
  },
};

const ListingInfoForm = (props: any) => {
  const { loading, listing, close } = props;
  const { currency } = useCountry();
  const { handleSubmit, handleChange, setFieldValue, values } = useFormik({
    initialValues: { ...defaultValue, ...listing },
    onSubmit(values) {
      props.submit(values);
    },
  });
  useCloseConfirm();

  const AssignSpotModal = Modal({
    title: "",
    modal: (
      <PrimaryModal
        cancelText="Ok"
        onCancel={() => {
          AssignSpotModal.close();
        }}
      >
        <Grid gridGap=".25em" padding="1em">
          <Text>
            For any required change in Assigned Spot #, Please contact
            support@findparkstash.com
          </Text>
        </Grid>
      </PrimaryModal>
    ),
    hideCancel: true,
  });

  const error =
    !values.weekly.active &&
    !values.monthly.active &&
    !values.hourly.active &&
    !values.max_daily.active;

  const showSecurityDeposit: boolean = hasVehicleClasses(values.vehicle_classes);

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: "100vh",
        display: "grid",
        maxWidth: "800px",
        width: "100%",
        background: "white",
        placeItems: "center stretch",
        gridGap: "1.25em",
        zIndex: 1000,
        gridTemplateRows: "auto 1fr auto",
      }}
    >
      <Grid padding="1.5em">
        <i
          className="fa fa-times bold pointer hoverr txt-md"
          onClick={close}
          style={{ placeSelf: "start" }}
        />
        <Header bold>
          Edit your <ParkstashText /> Listing Below
        </Header>
      </Grid>

      <Grid
        style={{ maxHeight: "100%", overflowY: "auto" }}
        padding="0 1.5em 1.5em 1.5em"
        placeItems="stretch"
        placeSelf="start stretch"
        gridGap="1.5em"
      >
        <Collapse
          heading={
            <Text size="1.3em" textAlign="left">
              Availability and Price
            </Text>
          }
          pad="1em"
        >
          <Grid
            margin=".6em 0"
            placeSelf="stretch"
            placeItems="stretch"
            gridGap="0"
            customCols="1fr auto"
            mobileLayout={false}
          >
            <Text textAlign="left" placeSelf="end start" bold>
              Currency
            </Text>
            <Text bold color={primaryColor}>
              {currency}-$
            </Text>
          </Grid>
          <Bar />
          <Pricing pricings={values} setPricings={setFieldValue} currency={listing.currency}/>

          <>
            {showSecurityDeposit ? (
              <SecurityDeposit
                currency={listing.currency }
                securityDeposit={values.security_deposit}
                setSecurityDeposit={(props) =>
                  setFieldValue("security_deposit", props)
                }
              />
            ) : null}
          </>
          <Bar />

          <Availability
            setAvailability={(e: any) => setFieldValue("availability", e)}
            availability={values.availability}
            daily_parking={values.daily_parking}
            setDailyParking={(e: any) => setFieldValue("daily_parking", e)}
            collapsable
          />
        </Collapse>
        <Bar />
        <Collapse
          pad="1em"
          heading={
            <Text size="1.3em" textAlign="left">
              Picture and Description
            </Text>
          }
        >
          <Images
            addImages={values.temp_image_filenames}
            setAddImages={(v: string[]) =>
              setFieldValue("temp_image_filenames", v)
            }
            deleteImages={values.remove_image_url}
            setDeleteImages={(v: string[]) =>
              setFieldValue("remove_image_url", v)
            }
            image_url={values.image_url}
            uploadButton={
              <FileUpload
                setImages={(i: string) =>
                  setFieldValue("temp_image_filenames", [
                    ...values.temp_image_filenames,
                    i,
                  ])
                }
              />
            }
          />
          <Bar />
          <div
            style={{
              placeSelf: "stretch",
              display: "grid",
              placeItems: "stretch",
            }}
          >
            <Text textAlign="left" bold>
              Add a description <span className="red">*</span>
            </Text>
            <TextArea
              autoSize
              name="description"
              onChange={handleChange}
              value={values.description}
            />
          </div>

          <Grid
            margin=".6em 0"
            placeSelf="stretch"
            placeItems="stretch"
            gridGap="0"
          >
            <Text textAlign="left" placeSelf="end start" bold>
              Add access instructions (Optional)
            </Text>
            <TextArea
              autoSize
              name="instruction"
              onChange={handleChange}
              value={values.instruction}
              placeholder="Add any access instructionss for your parking space like keys, access code etc"
            />
          </Grid>

          <Grid
            margin=".6em 0"
            placeSelf="stretch"
            placeItems="stretch"
            gridGap="0"
          >
            <Text textAlign="left" placeSelf="end start" bold>
              Additional Terms and Conditions (Optional)
            </Text>
            <TextArea
              name="terms_and_conditions"
              onChange={handleChange}
              value={values.terms_and_conditions}
              autoSize
              placeholder="Add any Terms and Conditions for your parking space"
            />
          </Grid>
        </Collapse>
        <Bar />
        <Collapse
          pad="1em"
          heading={
            <Text size="1.3em" textAlign="left">
              About your Parking Spot
            </Text>
          }
        >
          <div>
            <Text textAlign="left" bold>
              Enter your listing's name<span className="red">*</span>
            </Text>
            <div
              style={{
                placeSelf: "stretch",
                display: "grid",
                placeItems: "stretch",
              }}
            >
              <Input
                style={{ placeSelf: "end" }}
                type={"text"}
                name={"name"}
                placeholder="Ex: KFC's Parking Garage"
                suffix={
                  <Tooltip title={"Listing must contain at least 6 characters"}>
                    <i
                      className={`fa fa-${
                        error ? "times-circle red" : "check green"
                      }`}
                      style={{ position: "relative" }}
                    ></i>
                  </Tooltip>
                }
                value={values.name}
                onChange={handleChange}
              />
            </div>
          </div>

          <SpotType
            spotType={values.spot_type}
            setSpotDecription={(e: string) =>
              setFieldValue("spot_type_other_description", e?.toLowerCase())
            }
            setSpotType={(e: string) => setFieldValue("spot_type", e)}
          />

          <SpaceCount
            isAssignable={values.is_spot_number_assignable}
            setIsAssignable={(e: boolean) => AssignSpotModal.open()}
            spaceCount={values.space_count}
            setSpaceCount={(e: number) => setFieldValue("space_count", e)}
            spotStart={values.assigned_parking_spot_start}
            setSpotStart={(e: number) =>
              setFieldValue("assigned_parking_spot_start", e)
            }
          />
          <Bar />
          <Text textAlign="left" placeSelf="start" bold>
            Choose any of the features your spot has:
          </Text>
          <Amenities
            info
            setAmenities={(e: any) => setFieldValue("amenities", e)}
            amenities={values.amenities}
          />
        </Collapse>
        <Bar />
        <Collapse
          pad="1em"
          heading={
            <Text size="1.3em" textAlign="left">
              Allowed Vehicles
            </Text>
          }
        >
          <Grid
            margin=".6em 0"
            placeSelf="stretch"
            placeItems="stretch"
            gridGap="0"
          >
            <VehicleClasses
              selectedVehicles={values.vehicle_classes}
              setSelectedVehicles={(e) => setFieldValue("vehicle_classes", e)}
              setSize={(e) => setFieldValue("parking_size", e)}
              openedCategories={["passenger_vehicle", "truck"]}
              size={values.parking_size}
            />
          </Grid>

          <Sizing
            parkingSize={values.parking_size}
            setParkingSize={(p) => setFieldValue("parking_size", p)}
          />
        </Collapse>
      </Grid>
      {loading ? (
        <Button
          loading
          disabled
          style={{ borderRadius: "1.3em", padding: ".5em", margin: "1.5em" }}
        >
          Loading
        </Button>
      ) : (
        <Button.Normal
          style={{
            borderRadius: "1.3em",
            padding: ".5em",
            margin: "1.5em",
            ...(!error
              ? { backgroundImage: `${gradient}`, color: "white", border: 0 }
              : {}),
          }}
          type="submit"
          disabled={error}
          onSubmit={(e: any) => handleSubmit(e)}
        >
          Done
        </Button.Normal>
      )}
    </form>
  );
};

export default ListingInfoForm;
