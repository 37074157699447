import { useState, useEffect } from "react";
const usePagination = (
  limit: number,
  max: number,
  initialPage?: number,
  action?: (p: number) => void
) => {
  const [length, setLength] = useState(limit);
  const [page, setPage] = useState(initialPage || 1);
  const maxPage = Math.ceil(max);
  const canGoBack = page > 1;
  const canGoForward = page < maxPage - 1;

  const backward = () => {
    if (canGoBack) {
      if (action) {
        action(page - 1);
      }
      setPage(page - 1);
    }
  };

  const forward = () => {
  
    if(action){
        action(page + 1)
    }
    setPage(page + 1);
  }

  useEffect(() => {
    setLength(limit);
    if (page + 1 > limit) {
      setPage(1);
    }
  }, [limit]);

  useEffect(() => {
    if(action) action(page || 1);
  }, [page, limit]);

  useEffect(() => {
    setPage(initialPage as any);
  }, [initialPage, limit]);
  return {
    page,
    setPage,
    needsPagination: maxPage > 1,
    canGoBack,
    canGoForward,
    actions: {
      forward,
      backward,
    },
  };
};

export default usePagination;
