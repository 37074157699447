import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import api from "../../api";
import { IS_TEST, SSO_URL } from "../../config";
import useQuery from "../../hooks/query";
import { auth } from "../../store/actions/user";
import { setOtpVerified, setUserToken } from "../../utils/user";

export enum State {
  SIGNUP = "SIGNUP",
  LOGIN = "LOGIN",
  PASSWORD_SELECT_EMAIL = "PASSWORD_SELECT_EMAIL",
  PASSWORD_CONFIRM_EMAIL = "PASSWORD_CONFIRM_EMAIL",
  PASSWORD_RESET = "PASSWORD_RESET",
  PASSWORD_SUCCESS = "PASSWORD_SUCCES",
  OTP_VERIFY = "OTP_VERIFY",
}
export const getRedirectUrl = (logout = false) => {
  let url =window.location.origin + window.location.pathname;
  url = url.replace('/login', '')

  return `${ logout? SSO_URL + '/logout': SSO_URL}/?back=1&redirect_url=${url}&test=${IS_TEST}`;
};

 
const Login = () => {
  const { query } = useQuery() as any;
  const dispatch = useDispatch();
  const history = useHistory();

 
  const getPayloadFromToken = (token: string) => {
    api
      .getPayload(token)
      .then((r: any) => {
        history.replace({ search: "" });
        if (!r.success || !r?.tempData?.payload?.userToken)
          throw new Error(r?.message || "Error");
        setOtpVerified(true);
        setUserToken(r.tempData.payload.userToken, r.tempData.payload.userId);
        dispatch(auth());
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (!query.payload_token) {
      window.location.href = getRedirectUrl();
    } else {
      getPayloadFromToken(query.payload_token);
    }
  }, []);
  return <span>Trying to Login...</span>;
};

export default Login;
