import { token } from "../config";
import store from "../store";

export enum STORAGE_KEYS {
    USER_TOKEN = 'USER_TOKEN',
    OTP_VERIFIED = 'OTP_VERIFIED',
    IS_GUEST = 'IS_GUEST'
}
export const setUserToken = (usertoken: string, userId: string, isGuest = false) => {
    localStorage.setItem(STORAGE_KEYS.USER_TOKEN, btoa(`${token}:${userId}:${usertoken}`));
    localStorage.setItem(STORAGE_KEYS.IS_GUEST, String(isGuest))
}

export const setOtpVerified = (b: boolean) => localStorage.setItem(STORAGE_KEYS.OTP_VERIFIED, String(b))

export const getPlatformToken = () => btoa(token)

export const getUserToken = () => localStorage.getItem(STORAGE_KEYS.USER_TOKEN) || ''
export const isOtpVerified = () => localStorage.getItem(STORAGE_KEYS.OTP_VERIFIED) === 'true';
export const isGuest = () => localStorage.getItem(STORAGE_KEYS.IS_GUEST) === 'true' && getUserToken().length > 5;

export const removeUserToken = () => {
    localStorage.clear()
    Object.entries((e: STORAGE_KEYS) => {
        localStorage.removeItem(e);
    })
}
