import React from "react";
import { Card, Grid, List, Text } from "platyplex_ui";
import { primaryColor } from "../../../config";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CreditCard } from "../../booking-history/type";
import visa from "../../../assets/visa.jpg";
import CardForm from "./card-form";
import CardInfo from "./card-info";
import NewModal from "../../../components/new-modal";
import { selectCard } from "../../../store/actions/card";

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const { cards, loading, selectedCard } = useSelector((state: any) => state.cards);
  const CardModal = NewModal({
    modal: <CardForm close={() => CardModal.close()} />,
  });

  const CardInfoModal = NewModal({
    modal: <CardInfo close={() => CardInfoModal.close()} card={selectedCard} />,
  });

  return (
    <>
      {CardModal.modal}
      {selectedCard && CardInfoModal.modal}
      <Grid placeSelf="stretch" gridGap={".5em"} >
        <Grid customCols="1fr auto" placeSelf="stretch">
          <Link to="/wallet" style={{ placeSelf: "start" }}>
            <Text
              textAlign="left"
              color={primaryColor}
              size={"1.25em"}
              className="pointer"
            >
              <i className="fas fa-arrow-circle-left" /> &nbsp; Back
          </Text>
          </Link>
          <Text bold placeSelf="end" className="pointer" color={primaryColor} onClick={CardModal.open}>
            Add a Payment
        </Text>
        </Grid>
        <Text>Use your payment method to make purchase on ParkStash</Text>
        <List
          loading={loading}
          empty={
            <Text color="red">
              No payment method present. Add a payment method.
          </Text>
          }
          list={cards}
          map={(card: CreditCard) => (
            <Card
              shadow
              mobileLayout={false}
              margin="0"
              key={card.id}
              style={{ width: 400, maxWidth: "90vw" }}
              grid
              onClick={() => {
                dispatch(selectCard(card))
                CardInfoModal.open()
              }}
              gridGap="2em"
              customCols="auto 1fr auto"
              className="pointer noselect highlight"
            >
              <img src={visa} style={{ maxWidth: 60 }} alt="visa" />
              <div style={{ placeSelf: "stretch" }}>
                <Text textAlign="left">{card.brand} </Text>
                <Text textAlign="left">{`ending in ${card.last4}`} </Text>
              </div>
              <i className="fa fa-chevron-right" />
            </Card>
          )}
        />
      </Grid>
    </>
  );
};

export default PaymentMethod;
