import React, { useState, useEffect } from "react";
import { Grid, Text, Modal } from "platyplex_ui";
import { Progress } from "antd";
import { Form } from ".";
import api from "../../../api";
import PrimaryModal from "../../../components/primary-modal";
import security from '../../../assets/security.png'
import useStrings from "../../../hooks/strings";

const CreateListing = ({ addListing, back, finish, images }: any) => {
  const [count, setCount] = useState(0);
  const [error, setError] = useState({
    hasError: false,
    message: "Listing was added",
  });
  const { strings } = useStrings();
  const ConfimationModal = Modal({
    title: "",
    modal: <PrimaryModal
      cancelText='Great'
      onCancel={() => ConfimationModal.close()}>
      <Grid gridGap=".25em" padding="1em">
        <img src={security} alt='security' style={{width: 170, maxWidth: '90vw'}}/>
        <Text bold size={'1.25em'}>Thanks for submitting your listing information. Your listing details will now be verified by our team. </Text>  
  <Text>{strings?.listing_success_pleaseLookForAnEmail}</Text>
        <br/>
        <Text>We are excited to have you onboard! If you have any questions, send an email to <a href="mailto:support@findparkstash.com">support@findparkstash.com</a> or raise a help ticket from the app! </Text>
      </Grid>
    </PrimaryModal>,
    hideCancel: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count) => count + 5);
    }, 300);

    return () => {
     
      clearInterval(interval);
    };
  }, []);



  useEffect(() => {
    if (count === 5) {
      addListing().then((res: any) => {
     if (!res?.success) {
          setError({ hasError: true, message: res.message });
        }
      });
    } else if (count === 90) {
      if (error.hasError) {
        alert(error.message)
        back();
      } else {
        ConfimationModal.open();
        finish();
      }

    }
  }, [count]);

  return (
    <Form back={back}>
      <Grid
        width="100%"
        style={{ maxWidth: "800px" }}
        background="white"
        height="100%"
      >
        <div style={{ width: "100%" }}>
          <Text bold>Creating your Listing</Text>
          <Progress style={{ width: "70%" }} percent={count} />
        </div>
      </Grid>
    </Form>
  );
};

export default CreateListing;
