import React, { useEffect, useState } from "react";
import { Card, Grid, Loader } from "platyplex_ui";
import { Popover } from "antd";
import { disabledTxtColor2 } from "../../../../config";
import { useSize } from "../../../../hooks/size";
import { setAddressData } from "../../../../store/actions/search";
import { useDispatch } from "react-redux";
import useGeocode from "../../../../hooks/geocode";
import AutoComplete from "../../../../components/autocomplete";

const LocationSearcher = () => {
  const [selectedPlace, setSelectedPlace] = useState("");
  const { setPlaceId, addressData } = useGeocode();
  const dispatch = useDispatch();


  useEffect(() => setPlaceId(selectedPlace), [selectedPlace]);

  useEffect(() => {
    if (addressData.formattedAddress !== "No Result Found")
      dispatch(setAddressData(addressData));
  }, [addressData]);
  return (
   
      <Card margin="0" grid placeItems="center stretch">

      <AutoComplete 
      className='slide-up'
      style={{
        width: "100%",
        background: disabledTxtColor2,
        borderRadius: "1.5em",
        padding: ".5em 20px",
        border: "1px solid " + disabledTxtColor2,
      }}

      updatePlaceId={(e)=>setSelectedPlace(e)}
     
      />
      </Card>
  );
};

export default LocationSearcher;
